import logo from "./Dm_Logo.svg";
import kodak from "./kodak.png";

import "./App.css";
import { useEffect, useState } from "react";
import {
  availabilityUrl1,
  availabilityUrl2,
  availabilityUrl3,
  availabilityUrl4,
  availabilityUrl5,
  storeData,
} from "./consts";

function App() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [plzChecked, setPlzChecked] = useState(true);
  const [vorratChecked, setVorratChecked] = useState(false);
  const [sortString, setSortString] = useState("PLZ");
  const [showEmpty, setShowEmpty] = useState(false);

  useEffect(() => {
    setLoading(true);
    async function fetchDat() {
      const allRequests = [
        availabilityUrl1,
        availabilityUrl2,
        availabilityUrl3,
        availabilityUrl4,
        availabilityUrl5,
      ];

      const availableLocations = [];

      for (let index = 0; index < allRequests.length; index++) {
        const url = allRequests[index];

        const fetchRes = await fetch(url, {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,HEAD,POST,OPTIONS",
          "Access-Control-Max-Age": "86400",
        }).then(async (res) => {
          return await res.json();
        });

        const allLocations = fetchRes?.storeAvailabilities["405075"];

        for (let index = 0; index < allLocations.length; index++) {
          console.log(
            allLocations[index]?.store?.storeNumber +
              ": " +
              allLocations[index]?.stockLevel
          );
          if (showEmpty) {
            availableLocations.push({
              storeNumber: allLocations[index]?.store?.storeNumber,
              stockLevel: allLocations[index]?.stockLevel,
            });
          } else {
            if (allLocations[index]?.inStock) {
              availableLocations.push({
                storeNumber: allLocations[index]?.store?.storeNumber,
                stockLevel: allLocations[index]?.stockLevel,
              });
            }
          }
        }
      }
      const results = [];

      for (let index = 0; index < availableLocations.length; index++) {
        const store = Object.assign(storeData[availableLocations[index].storeNumber], {
          Vorrat: availableLocations[index].stockLevel,
        });

        results.push(store);
      }

      if (sortString === "PLZ") {
        results.sort((a, b) => parseInt(a.PLZ) - parseInt(b.PLZ));
      } else {
        results.sort((a, b) => parseInt(b.Vorrat) - parseInt(a.Vorrat));
      }

      setData(results);
      setLoading(false);
    }
    fetchDat();
    // eslint-disable-next-line
  }, [sortString, showEmpty]); // Or [] if effect doesn't need props or state

  const handlePlzChange = () => {
    setPlzChecked(true);
    setVorratChecked(false);
    setSortString("PLZ");
  };
  const handleVorratChange = () => {
    setPlzChecked(false);
    setVorratChecked(true);
    setSortString("Vorrat");
  };
  const handleEmptyMarketsChange = () => {
    setShowEmpty(!showEmpty);
  };

  return (
    <div className="App">
      <header className="App-header">
        {window.innerWidth > 500 ? (
          <div>
            <div>
              <img src={logo} className="DM-logo" alt="logo" />
              <img src={kodak} alt="Logo" className="Kodak-logo" />
            </div>
            <div className="MarktFenster">
              <div className="MarktFilter">
                <p>Sortieren nach: </p>
                <Checkbox
                  // style={{ display: "inline-block" }}
                  label="PLZ"
                  value={plzChecked}
                  onChange={handlePlzChange}
                />
                <Checkbox
                  // style={{ display: "inline-block" }}
                  label="Vorrat"
                  value={vorratChecked}
                  onChange={handleVorratChange}
                />
                <br />
                <br />
                <Checkbox
                  // style={{ display: "inline-block" }}
                  label="Zeig leere Märkte"
                  value={showEmpty}
                  onChange={handleEmptyMarketsChange}
                />
                <br />
                <br />
                <p className="MarktHead">
                  {data.length +
                    (data.length === 1 ? " Markt gefunden!" : " Märkte gefunden!")}{" "}
                </p>
              </div>
              {loading ? (
                <div className="Markt">
                  <p className="MarktFeld">Loading...</p>
                </div>
              ) : (
                data.map(function (item, i) {
                  return (
                    <div className="Markt" key={i}>
                      <p className="MarktFeld"> Telefon: {item["Telefon"]} </p>
                      <p className="MarktFeld"> Strasse: {item["Strasse"]} </p>
                      <p className="MarktFeld"> Postleitzahl: {item["PLZ"]} </p>
                      <p className="MarktFeld"> Stadt: {item["Stadt"]} </p>
                      <p className="MarktFeld"> Vorrat: {item["Vorrat"]} </p>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        ) : (
          <div>Kein Mobile View möglich</div>
        )}
      </header>
    </div>
  );
}

const Checkbox = ({ label, value, onChange }) => {
  return (
    <label style={{ marginLeft: "10px", marginRight: "10px" }}>
      <input type="checkbox" checked={value} onChange={onChange} />
      {label}
    </label>
  );
};

export default App;
