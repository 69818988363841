export const availabilityUrl1 = "https://dm-checker-proxy1.blue-autumn-leaf.workers.dev/",
  availabilityUrl2 = "https://dm-checker-proxy2.blue-autumn-leaf.workers.dev/",
  availabilityUrl3 = "https://dm-checker-proxy3.blue-autumn-leaf.workers.dev/",
  availabilityUrl4 = "https://dm-checker-proxy4.blue-autumn-leaf.workers.dev/",
  availabilityUrl5 = "https://dm-checker-proxy5.blue-autumn-leaf.workers.dev/",
  storeData = {
    5: {
      LadenId: 5,
      Telefon: "+4920835657",
      Strasse: "Hans-Böckler-Platz 10",
      PLZ: "45468",
      Stadt: "Mülheim an der Ruhr",
    },
    6: {
      LadenId: 6,
      Telefon: "+49215125500",
      Strasse: "Hochstraße 87",
      PLZ: "47798",
      Stadt: "Krefeld",
    },
    8: {
      LadenId: 8,
      Telefon: "+49201763807",
      Strasse: "Frohnhauser Straße 255",
      PLZ: "45144",
      Stadt: "Essen",
    },
    10: {
      LadenId: 10,
      Telefon: "+49201771155",
      Strasse: "Rüttenscheider Straße 104",
      PLZ: "45130",
      Stadt: "Essen",
    },
    13: {
      LadenId: 13,
      Telefon: "+49216222767",
      Strasse: "Hauptstraße 92",
      PLZ: "41747",
      Stadt: "Viersen",
    },
    14: {
      LadenId: 14,
      Telefon: "+49232425933",
      Strasse: "Heggerstraße 22",
      PLZ: "45525",
      Stadt: "Hattingen",
    },
    19: {
      LadenId: 19,
      Telefon: "+492641903968",
      Strasse: "Wilhelmstraße 54-56",
      PLZ: "53474",
      Stadt: "Bad Neuenahr-Ahrweiler",
    },
    24: {
      LadenId: 24,
      Telefon: "+492219320055",
      Strasse: "Chlodwigplatz 20-22",
      PLZ: "50678",
      Stadt: "Köln",
    },
    29: {
      LadenId: 29,
      Telefon: "+492319142703",
      Strasse: "Schwarze-Brüder-Straße 7",
      PLZ: "44137",
      Stadt: "Dortmund",
    },
    30: {
      LadenId: 30,
      Telefon: "+492219415077",
      Strasse: "Sülzburgstraße 9",
      PLZ: "50937",
      Stadt: "Köln",
    },
    32: {
      LadenId: 32,
      Telefon: "+49232356172",
      Strasse: "Bahnhofstraße 23",
      PLZ: "44623",
      Stadt: "Herne",
    },
    39: {
      LadenId: 39,
      Telefon: "+49208492846",
      Strasse: "Humboldtring 13",
      PLZ: "45472",
      Stadt: "Mülheim an der Ruhr",
    },
    41: {
      LadenId: 41,
      Telefon: "+492261919020",
      Strasse: "Kaiserstraße 21",
      PLZ: "51643",
      Stadt: "Gummersbach",
    },
    49: {
      LadenId: 49,
      Telefon: "+492219725097",
      Strasse: "Neusser Straße 256",
      PLZ: "50733",
      Stadt: "Köln",
    },
    56: {
      LadenId: 56,
      Telefon: "+492133259962",
      Strasse: "Kölner Straße 74-76",
      PLZ: "41539",
      Stadt: "Dormagen",
    },
    57: {
      LadenId: 57,
      Telefon: "+492219352040",
      Strasse: "Hauptstraße 108",
      PLZ: "50996",
      Stadt: "Köln",
    },
    63: {
      LadenId: 63,
      Telefon: "+492342399103",
      Strasse: "Kortumstraße 52",
      PLZ: "44787",
      Stadt: "Bochum",
    },
    68: {
      LadenId: 68,
      Telefon: "+492287669313",
      Strasse: "Sternstraße 56",
      PLZ: "53111",
      Stadt: "Bonn",
    },
    71: {
      LadenId: 71,
      Telefon: "+492152148772",
      Strasse: "Engerstraße 24",
      PLZ: "47906",
      Stadt: "Kempen",
    },
    72: {
      LadenId: 72,
      Telefon: "+49228229291",
      Strasse: "Bonner Talweg 38-40",
      PLZ: "53113",
      Stadt: "Bonn",
    },
    77: {
      LadenId: 77,
      Telefon: "+492202932090",
      Strasse: "Hauptstraße 180-182",
      PLZ: "51465",
      Stadt: "Bergisch Gladbach",
    },
    79: {
      LadenId: 79,
      Telefon: "+492012439370",
      Strasse: "Steeler Straße 190-192",
      PLZ: "45138",
      Stadt: "Essen",
    },
    81: {
      LadenId: 81,
      Telefon: "+492267880080",
      Strasse: "Hochstraße 36",
      PLZ: "51688",
      Stadt: "Wipperfürth",
    },
    83: {
      LadenId: 83,
      Telefon: "+49201231411",
      Strasse: "Porscheplatz 2",
      PLZ: "45127",
      Stadt: "Essen",
    },
    87: {
      LadenId: 87,
      Telefon: "+492225912073",
      Strasse: "Neuer Markt 5",
      PLZ: "53340",
      Stadt: "Meckenheim",
    },
    89: {
      LadenId: 89,
      Telefon: "+492018354074",
      Strasse: "Altenessener Straße 411",
      PLZ: "45329",
      Stadt: "Essen",
    },
    92: {
      LadenId: 92,
      Telefon: "+492051312849",
      Strasse: "Hebbelstraße 11",
      PLZ: "42549",
      Stadt: "Velbert",
    },
    94: {
      LadenId: 94,
      Telefon: "+492041107122",
      Strasse: "Am Südring-Center 9",
      PLZ: "46242",
      Stadt: "Bottrop",
    },
    95: {
      LadenId: 95,
      Telefon: "+492327309990",
      Strasse: "Oststraße 38",
      PLZ: "44866",
      Stadt: "Bochum",
    },
    97: {
      LadenId: 97,
      Telefon: "+492234942084",
      Strasse: "Aachener Straße 1253",
      PLZ: "50858",
      Stadt: "Köln",
    },
    98: {
      LadenId: 98,
      Telefon: "+492325932053",
      Strasse: "Hauptstraße 13-15",
      PLZ: "44651",
      Stadt: "Herne",
    },
    100: {
      LadenId: 100,
      Telefon: "+492129341500",
      Strasse: "Neuer Markt 40-42",
      PLZ: "42781",
      Stadt: "Haan",
    },
    104: {
      LadenId: 104,
      Telefon: "+492319232023",
      Strasse: "Gleiwitzstraße 275",
      PLZ: "44328",
      Stadt: "Dortmund",
    },
    117: {
      LadenId: 117,
      Telefon: "+492651498623",
      Strasse: "Hahnengasse 4",
      PLZ: "56727",
      Stadt: "Mayen",
    },
    124: {
      LadenId: 124,
      Telefon: "+492561429281",
      Strasse: "Marktstraße 16",
      PLZ: "48683",
      Stadt: "Ahaus",
    },
    136: {
      LadenId: 136,
      Telefon: "+492233978064",
      Strasse: "Hürth-Park L011",
      PLZ: "50354",
      Stadt: "Hürth",
    },
    137: {
      LadenId: 137,
      Telefon: "+49246154091",
      Strasse: "Kölnstraße 26",
      PLZ: "52428",
      Stadt: "Jülich",
    },
    139: {
      LadenId: 139,
      Telefon: "+492504880258",
      Strasse: "Orkotten 5",
      PLZ: "48291",
      Stadt: "Telgte",
    },
    140: {
      LadenId: 140,
      Telefon: "+492203503389",
      Strasse: "Josefstraße 12",
      PLZ: "51143",
      Stadt: "Köln",
    },
    149: {
      LadenId: 149,
      Telefon: "+492018496120",
      Strasse: "Brückstraße 28",
      PLZ: "45239",
      Stadt: "Essen",
    },
    155: {
      LadenId: 155,
      Telefon: "+492291808082",
      Strasse: "Otto-Eichhorn-Straße 4",
      PLZ: "51545",
      Stadt: "Waldbröl",
    },
    177: {
      LadenId: 177,
      Telefon: "+492219521903",
      Strasse: "Venloer Straße 247",
      PLZ: "50823",
      Stadt: "Köln",
    },
    211: {
      LadenId: 211,
      Telefon: "+49231713516",
      Strasse: "Harkortstraße 36",
      PLZ: "44225",
      Stadt: "Dortmund",
    },
    215: {
      LadenId: 215,
      Telefon: "+492219808030",
      Strasse: "Deutzer Freiheit 93",
      PLZ: "50679",
      Stadt: "Köln",
    },
    219: {
      LadenId: 219,
      Telefon: "+492103953903",
      Strasse: "Mittelstraße 63",
      PLZ: "40721",
      Stadt: "Hilden",
    },
    221: {
      LadenId: 221,
      Telefon: "+49211320817",
      Strasse: "Carlsplatz 14-15",
      PLZ: "40213",
      Stadt: "Düsseldorf",
    },
    229: {
      LadenId: 229,
      Telefon: "+492119718975",
      Strasse: "Hauptstraße 20",
      PLZ: "40597",
      Stadt: "Düsseldorf",
    },
    233: {
      LadenId: 233,
      Telefon: "+492219701006",
      Strasse: "Mailänder Passage 1",
      PLZ: "50765",
      Stadt: "Köln",
    },
    249: {
      LadenId: 249,
      Telefon: "+492219402068",
      Strasse: "Dürener Straße 154-158",
      PLZ: "50931",
      Stadt: "Köln",
    },
    250: {
      LadenId: 250,
      Telefon: "+4921513659200",
      Strasse: "Höhenhöfe 17",
      PLZ: "47918",
      Stadt: "Tönisvorst",
    },
    253: {
      LadenId: 253,
      Telefon: "+492064423183",
      Strasse: "Neustraße 41",
      PLZ: "46535",
      Stadt: "Dinslaken",
    },
    262: {
      LadenId: 262,
      Telefon: "+492191927096",
      Strasse: "Alleestraße 74",
      PLZ: "42853",
      Stadt: "Remscheid",
    },
    265: {
      LadenId: 265,
      Telefon: "+492216086618",
      Strasse: "Frankfurter Straße 39",
      PLZ: "51065",
      Stadt: "Köln",
    },
    277: {
      LadenId: 277,
      Telefon: "+492242869110",
      Strasse: "Marktplatz 19",
      PLZ: "53773",
      Stadt: "Hennef (Sieg)",
    },
    285: {
      LadenId: 285,
      Telefon: "+492116509592",
      Strasse: "In den Diken 10",
      PLZ: "40472",
      Stadt: "Düsseldorf",
    },
    296: {
      LadenId: 296,
      Telefon: "+492306205080",
      Strasse: "Lange Straße 18",
      PLZ: "44532",
      Stadt: "Lünen",
    },
    299: {
      LadenId: 299,
      Telefon: "+492236942061",
      Strasse: "Flach-Fengler-Straße 51",
      PLZ: "50389",
      Stadt: "Wesseling",
    },
    300: {
      LadenId: 300,
      Telefon: "+492215008427",
      Strasse: "Venloer Straße 310-316",
      PLZ: "50823",
      Stadt: "Köln",
    },
    306: {
      LadenId: 306,
      Telefon: "+492099772067",
      Strasse: "Cranger Straße 294",
      PLZ: "45891",
      Stadt: "Gelsenkirchen",
    },
    312: {
      LadenId: 312,
      Telefon: "+49202454025",
      Strasse: "Rommelspütt 5",
      PLZ: "42105",
      Stadt: "Wuppertal",
    },
    314: {
      LadenId: 314,
      Telefon: "+492219687020",
      Strasse: "Dellbrücker Hauptstraße 90",
      PLZ: "51069",
      Stadt: "Köln",
    },
    315: {
      LadenId: 315,
      Telefon: "+4921272780",
      Strasse: "Düsseldorfer Straße 46-48",
      PLZ: "42697",
      Stadt: "Solingen",
    },
    316: {
      LadenId: 316,
      Telefon: "+492043205539",
      Strasse: "Horster Straße 12",
      PLZ: "45964",
      Stadt: "Gladbeck",
    },
    321: {
      LadenId: 321,
      Telefon: "+492381926963",
      Strasse: "Richard-Matthaei-Platz 1",
      PLZ: "59065",
      Stadt: "Hamm",
    },
    323: {
      LadenId: 323,
      Telefon: "+492597690657",
      Strasse: "Herrenstraße 27",
      PLZ: "48308",
      Stadt: "Senden",
    },
    341: {
      LadenId: 341,
      Telefon: "+49201303455",
      Strasse: "Katernberger Straße 44",
      PLZ: "45327",
      Stadt: "Essen",
    },
    343: {
      LadenId: 343,
      Telefon: "+492102993844",
      Strasse: "Oberstraße 10",
      PLZ: "40878",
      Stadt: "Ratingen",
    },
    348: {
      LadenId: 348,
      Telefon: "+49203341406",
      Strasse: "Portsmouthplatz 1",
      PLZ: "47051",
      Stadt: "Duisburg",
    },
    350: {
      LadenId: 350,
      Telefon: "+49224162128",
      Strasse: "Bahnhofstraße 1",
      PLZ: "53721",
      Stadt: "Siegburg",
    },
    378: {
      LadenId: 378,
      Telefon: "+49237113182",
      Strasse: "Vinckestraße 2-4",
      PLZ: "58636",
      Stadt: "Iserlohn",
    },
    386: {
      LadenId: 386,
      Telefon: "+492349490040",
      Strasse: "Hattinger Straße 819-821",
      PLZ: "44879",
      Stadt: "Bochum",
    },
    396: {
      LadenId: 396,
      Telefon: "+492351380134",
      Strasse: "Wilhelmstraße 33",
      PLZ: "58511",
      Stadt: "Lüdenscheid",
    },
    397: {
      LadenId: 397,
      Telefon: "+492349650840",
      Strasse: "Kortumstraße 87-89",
      PLZ: "44787",
      Stadt: "Bochum",
    },
    401: {
      LadenId: 401,
      Telefon: "+492058772952",
      Strasse: "Alte Ratinger Landstraße 23",
      PLZ: "42489",
      Stadt: "Wülfrath",
    },
    414: {
      LadenId: 414,
      Telefon: "+492332554005",
      Strasse: "Mittelstraße 27-29",
      PLZ: "58285",
      Stadt: "Gevelsberg",
    },
    433: {
      LadenId: 433,
      Telefon: "+492022503002",
      Strasse: "Werth 55-61",
      PLZ: "42275",
      Stadt: "Wuppertal",
    },
    438: {
      LadenId: 438,
      Telefon: "+492111705530",
      Strasse: "Kurt-Schumacher-Straße 1",
      PLZ: "40595",
      Stadt: "Düsseldorf",
    },
    440: {
      LadenId: 440,
      Telefon: "+492681803140",
      Strasse: "Siegener Straße 67",
      PLZ: "57610",
      Stadt: "Altenkirchen",
    },
    460: {
      LadenId: 460,
      Telefon: "+492203102299",
      Strasse: "Kaiserstraße 24-26",
      PLZ: "51145",
      Stadt: "Köln",
    },
    490: {
      LadenId: 490,
      Telefon: "+492232410010",
      Strasse: "Markt 9-11",
      PLZ: "50321",
      Stadt: "Brühl",
    },
    491: {
      LadenId: 491,
      Telefon: "+492456507123",
      Strasse: "In der Fummer 1b",
      PLZ: "52538",
      Stadt: "Selfkant",
    },
    506: {
      LadenId: 506,
      Telefon: "+492631353396",
      Strasse: "Allensteiner Straße 15",
      PLZ: "56566",
      Stadt: "Neuwied",
    },
    507: {
      LadenId: 507,
      Telefon: "+492632496025",
      Strasse: "Koblenzer Straße 31",
      PLZ: "56626",
      Stadt: "Andernach",
    },
    508: {
      LadenId: 508,
      Telefon: "+492366938421",
      Strasse: "Hermannstraße 9",
      PLZ: "45699",
      Stadt: "Herten",
    },
    523: {
      LadenId: 523,
      Telefon: "+492191464065",
      Strasse: "Alte Kölner Straße 8-10",
      PLZ: "42897",
      Stadt: "Remscheid",
    },
    531: {
      LadenId: 531,
      Telefon: "+492521824279",
      Strasse: "Nordstraße 23",
      PLZ: "59269",
      Stadt: "Beckum",
    },
    538: {
      LadenId: 538,
      Telefon: "+492211394318",
      Strasse: "Eigelstein 103-113",
      PLZ: "50668",
      Stadt: "Köln",
    },
    540: {
      LadenId: 540,
      Telefon: "+492314250530",
      Strasse: "Hermannstraße 41-45",
      PLZ: "44263",
      Stadt: "Dortmund",
    },
    542: {
      LadenId: 542,
      Telefon: "+492119336075",
      Strasse: "Aachener Straße 70A",
      PLZ: "40223",
      Stadt: "Düsseldorf",
    },
    546: {
      LadenId: 546,
      Telefon: "+492594782986",
      Strasse: "Hüttenweg 7",
      PLZ: "48249",
      Stadt: "Dülmen",
    },
    547: {
      LadenId: 547,
      Telefon: "+492591940822",
      Strasse: "Markt 11",
      PLZ: "59348",
      Stadt: "Lüdinghausen",
    },
    553: {
      LadenId: 553,
      Telefon: "+492302205417",
      Strasse: "Herbeder Straße 14",
      PLZ: "58455",
      Stadt: "Witten",
    },
    567: {
      LadenId: 567,
      Telefon: "+492592980625",
      Strasse: "Kreisstraße 42",
      PLZ: "59379",
      Stadt: "Selm",
    },
    571: {
      LadenId: 571,
      Telefon: "+492086215658",
      Strasse: "Dudelerstraße 9",
      PLZ: "46147",
      Stadt: "Oberhausen",
    },
    578: {
      LadenId: 578,
      Telefon: "+492349471896",
      Strasse: "Markstraße 418",
      PLZ: "44795",
      Stadt: "Bochum",
    },
    582: {
      LadenId: 582,
      Telefon: "+492212509451",
      Strasse: "Gürzenichstraße 6-16",
      PLZ: "50667",
      Stadt: "Köln",
    },
    592: {
      LadenId: 592,
      Telefon: "+492118308883",
      Strasse: "Konrad-Adenauer-Platz 14",
      PLZ: "40210",
      Stadt: "Düsseldorf",
    },
    593: {
      LadenId: 593,
      Telefon: "+492622905751",
      Strasse: "Hauptstraße 133",
      PLZ: "56170",
      Stadt: "Bendorf",
    },
    605: {
      LadenId: 605,
      Telefon: "+492035521677",
      Strasse: "Jägerstraße 64",
      PLZ: "47166",
      Stadt: "Duisburg",
    },
    607: {
      LadenId: 607,
      Telefon: "+492311765085",
      Strasse: "Brackeler Hellweg 119a",
      PLZ: "44309",
      Stadt: "Dortmund",
    },
    616: {
      LadenId: 616,
      Telefon: "+492119215343",
      Strasse: "Gumbertstraße 147",
      PLZ: "40229",
      Stadt: "Düsseldorf",
    },
    620: {
      LadenId: 620,
      Telefon: "+49231578230",
      Strasse: "Kaiserstraße 29",
      PLZ: "44135",
      Stadt: "Dortmund",
    },
    624: {
      LadenId: 624,
      Telefon: "+492314441070",
      Strasse: "Rodenbergstraße 61",
      PLZ: "44287",
      Stadt: "Dortmund",
    },
    633: {
      LadenId: 633,
      Telefon: "+492419690261",
      Strasse: "Kapellenstraße 25",
      PLZ: "52066",
      Stadt: "Aachen",
    },
    634: {
      LadenId: 634,
      Telefon: "+492512705427",
      Strasse: "Grevener Straße 69",
      PLZ: "48159",
      Stadt: "Münster",
    },
    658: {
      LadenId: 658,
      Telefon: "+492212570619",
      Strasse: "Neumarkt 2-4",
      PLZ: "50667",
      Stadt: "Köln",
    },
    662: {
      LadenId: 662,
      Telefon: "+492406924820",
      Strasse: "Kleikstraße 17",
      PLZ: "52134",
      Stadt: "Herzogenrath",
    },
    673: {
      LadenId: 673,
      Telefon: "+495242404737",
      Strasse: "Bahnhofstraße 36",
      PLZ: "33378",
      Stadt: "Rheda-Wiedenbrück",
    },
    675: {
      LadenId: 675,
      Telefon: "+492311891348",
      Strasse: "Joachimstraße 2",
      PLZ: "44147",
      Stadt: "Dortmund",
    },
    689: {
      LadenId: 689,
      Telefon: "+492922865091",
      Strasse: "Walburgisstraße 12-14",
      PLZ: "59457",
      Stadt: "Werl",
    },
    693: {
      LadenId: 693,
      Telefon: "+492651498882",
      Strasse: "Hausener Straße 2-6",
      PLZ: "56727",
      Stadt: "Mayen",
    },
    694: {
      LadenId: 694,
      Telefon: "+492014864128",
      Strasse: "Kupferdreher Strasse 166",
      PLZ: "45257",
      Stadt: "Essen",
    },
    697: {
      LadenId: 697,
      Telefon: "+492305891957",
      Strasse: "Ickerner Straße 56",
      PLZ: "44581",
      Stadt: "Castrop-Rauxel",
    },
    753: {
      LadenId: 753,
      Telefon: "+492112096293",
      Strasse: "Hilde-und-Joseph-Neyses-Platz 8",
      PLZ: "40476",
      Stadt: "Düsseldorf",
    },
    756: {
      LadenId: 756,
      Telefon: "+492219367739",
      Strasse: "Höninger Weg 168",
      PLZ: "50969",
      Stadt: "Köln",
    },
    758: {
      LadenId: 758,
      Telefon: "+492434800601",
      Strasse: "Am Bahnhof 10",
      PLZ: "41844",
      Stadt: "Wegberg",
    },
    761: {
      LadenId: 761,
      Telefon: "+492113398863",
      Strasse: "Bilker Allee 43",
      PLZ: "40219",
      Stadt: "Düsseldorf",
    },
    769: {
      LadenId: 769,
      Telefon: "+492362993107",
      Strasse: "Markt 11",
      PLZ: "46282",
      Stadt: "Dorsten",
    },
    779: {
      LadenId: 779,
      Telefon: "+492212823948",
      Strasse: "Venloer Straße 601-603",
      PLZ: "50827",
      Stadt: "Köln",
    },
    785: {
      LadenId: 785,
      Telefon: "+492027692634",
      Strasse: "Vohwinkeler Straße 5-7",
      PLZ: "42329",
      Stadt: "Wuppertal",
    },
    792: {
      LadenId: 792,
      Telefon: "+492516068852",
      Strasse: "Wolbecker Straße 57",
      PLZ: "48155",
      Stadt: "Münster",
    },
    795: {
      LadenId: 795,
      Telefon: "+492041707800",
      Strasse: "Hochstraße 47-49",
      PLZ: "46236",
      Stadt: "Bottrop",
    },
    796: {
      LadenId: 796,
      Telefon: "+492039355822",
      Strasse: "Oststraße 136",
      PLZ: "47057",
      Stadt: "Duisburg",
    },
    802: {
      LadenId: 802,
      Telefon: "+495731683654",
      Strasse: "Mindener Straße 22",
      PLZ: "32547",
      Stadt: "Bad Oeynhausen",
    },
    807: {
      LadenId: 807,
      Telefon: "+492683945850",
      Strasse: "Anton-Limbach-Straße 10",
      PLZ: "53567",
      Stadt: "Asbach",
    },
    817: {
      LadenId: 817,
      Telefon: "+492541843594",
      Strasse: "Kupferstraße 2",
      PLZ: "48653",
      Stadt: "Coesfeld",
    },
    829: {
      LadenId: 829,
      Telefon: "+492033464482",
      Strasse: "Münchener Straße 43",
      PLZ: "47249",
      Stadt: "Duisburg",
    },
    831: {
      LadenId: 831,
      Telefon: "+492284335342",
      Strasse: "Friedrich-Breuer-Straße 72-78",
      PLZ: "53225",
      Stadt: "Bonn",
    },
    838: {
      LadenId: 838,
      Telefon: "+492251625420",
      Strasse: "Roitzheimer Straße 115a",
      PLZ: "53879",
      Stadt: "Euskirchen",
    },
    839: {
      LadenId: 839,
      Telefon: "+495214174842",
      Strasse: "Hauptstraße 99",
      PLZ: "33647",
      Stadt: "Bielefeld",
    },
    843: {
      LadenId: 843,
      Telefon: "+492841881514",
      Strasse: "Lintforter Straße 76-78",
      PLZ: "47445",
      Stadt: "Moers",
    },
    853: {
      LadenId: 853,
      Telefon: "+492273565148",
      Strasse: "Kerpener Straße 177",
      PLZ: "50170",
      Stadt: "Kerpen",
    },
    857: {
      LadenId: 857,
      Telefon: "+492311769035",
      Strasse: "Körner Hellweg 72",
      PLZ: "44143",
      Stadt: "Dortmund",
    },
    861: {
      LadenId: 861,
      Telefon: "+492175165638",
      Strasse: "Im Brückerfeld 9",
      PLZ: "42799",
      Stadt: "Leichlingen (Rheinland)",
    },
    866: {
      LadenId: 866,
      Telefon: "+492204422984",
      Strasse: "Siebenmorgen 40",
      PLZ: "51427",
      Stadt: "Bergisch Gladbach",
    },
    874: {
      LadenId: 874,
      Telefon: "+492364507248",
      Strasse: "Lohausstraße 23",
      PLZ: "45721",
      Stadt: "Haltern am See",
    },
    887: {
      LadenId: 887,
      Telefon: "+492202189674",
      Strasse: "Kürtener Straße 3",
      PLZ: "51465",
      Stadt: "Bergisch Gladbach",
    },
    891: {
      LadenId: 891,
      Telefon: "+492034105116",
      Strasse: "Berliner Straße 90",
      PLZ: "47138",
      Stadt: "Duisburg",
    },
    892: {
      LadenId: 892,
      Telefon: "+492222649779",
      Strasse: "Am Hellenkreuz 6",
      PLZ: "53332",
      Stadt: "Bornheim",
    },
    898: {
      LadenId: 898,
      Telefon: "+492311893673",
      Strasse: "Rahmer Straße 8-12",
      PLZ: "44369",
      Stadt: "Dortmund",
    },
    910: {
      LadenId: 910,
      Telefon: "+492303254177",
      Strasse: "Ostring 13",
      PLZ: "59423",
      Stadt: "Unna",
    },
    922: {
      LadenId: 922,
      Telefon: "+492091205820",
      Strasse: "Bahnhofstraße 23",
      PLZ: "45879",
      Stadt: "Gelsenkirchen",
    },
    932: {
      LadenId: 932,
      Telefon: "+492621627139",
      Strasse: "Koblenzer Straße 25",
      PLZ: "56112",
      Stadt: "Lahnstein",
    },
    940: {
      LadenId: 940,
      Telefon: "+495231301827",
      Strasse: "Bruchstraße 18-20",
      PLZ: "32756",
      Stadt: "Detmold",
    },
    956: {
      LadenId: 956,
      Telefon: "+492274700388",
      Strasse: "Hinter den Gärten 1",
      PLZ: "50189",
      Stadt: "Elsdorf",
    },
    958: {
      LadenId: 958,
      Telefon: "+492630955503",
      Strasse: "Industriestraße 30a",
      PLZ: "56218",
      Stadt: "Mülheim-Kärlich",
    },
    965: {
      LadenId: 965,
      Telefon: "+492086214912",
      Strasse: "Luchsstraße 1",
      PLZ: "46149",
      Stadt: "Oberhausen",
    },
    971: {
      LadenId: 971,
      Telefon: "+4923022025836",
      Strasse: "Bahnhofstraße 11-13",
      PLZ: "58452",
      Stadt: "Witten",
    },
    973: {
      LadenId: 973,
      Telefon: "+4921614029137",
      Strasse: "Matthias-Hoeren-Platz 1-7",
      PLZ: "41352",
      Stadt: "Korschenbroich",
    },
    978: {
      LadenId: 978,
      Telefon: "+492433441749",
      Strasse: "Am Landabsatz 9",
      PLZ: "41836",
      Stadt: "Hückelhoven",
    },
    1002: {
      LadenId: 1002,
      Telefon: "+4952411794834",
      Strasse: "Berliner Straße 34",
      PLZ: "33330",
      Stadt: "Gütersloh",
    },
    1003: {
      LadenId: 1003,
      Telefon: "+495221122054",
      Strasse: "Bäckerstraße 35",
      PLZ: "32052",
      Stadt: "Herford",
    },
    1004: {
      LadenId: 1004,
      Telefon: "+492611330598",
      Strasse: "Löhrstraße 70-72",
      PLZ: "56068",
      Stadt: "Koblenz",
    },
    1011: {
      LadenId: 1011,
      Telefon: "+4923611061667",
      Strasse: "Markt 1",
      PLZ: "45657",
      Stadt: "Recklinghausen",
    },
    1013: {
      LadenId: 1013,
      Telefon: "+4921311248567",
      Strasse: "Holzheimer Weg 44",
      PLZ: "41464",
      Stadt: "Neuss",
    },
    1019: {
      LadenId: 1019,
      Telefon: "+492022654848",
      Strasse: "Alte Freiheit 9",
      PLZ: "42103",
      Stadt: "Wuppertal",
    },
    1026: {
      LadenId: 1026,
      Telefon: "+4921511520599",
      Strasse: "Gutenbergstraße 151",
      PLZ: "47803",
      Stadt: "Krefeld",
    },
    1027: {
      LadenId: 1027,
      Telefon: "+4922411261643",
      Strasse: "Kölner Straße 9-11",
      PLZ: "53840",
      Stadt: "Troisdorf",
    },
    1037: {
      LadenId: 1037,
      Telefon: "+492282894979",
      Strasse: "Am Fronhof 9",
      PLZ: "53177",
      Stadt: "Bonn",
    },
    1044: {
      LadenId: 1044,
      Telefon: "+4921511519424",
      Strasse: "Hafelsstraße 245",
      PLZ: "47809",
      Stadt: "Krefeld",
    },
    1048: {
      LadenId: 1048,
      Telefon: "+4922411482806",
      Strasse: "Alte Heerstraße 56",
      PLZ: "53757",
      Stadt: "Sankt Augustin",
    },
    1055: {
      LadenId: 1055,
      Telefon: "+492501441509",
      Strasse: "Marktallee 38-40",
      PLZ: "48165",
      Stadt: "Münster",
    },
    1059: {
      LadenId: 1059,
      Telefon: "+4923613023565",
      Strasse: "Bochumer Straße 138",
      PLZ: "45661",
      Stadt: "Recklinghausen",
    },
    1073: {
      LadenId: 1073,
      Telefon: "+492454936140",
      Strasse: "Heinrich-Josef-Otten-Straße 12",
      PLZ: "52538",
      Stadt: "Gangelt",
    },
    1084: {
      LadenId: 1084,
      Telefon: "+492932429784",
      Strasse: "Hauptstraße 26",
      PLZ: "59755",
      Stadt: "Arnsberg",
    },
    1085: {
      LadenId: 1085,
      Telefon: "+4921612471089",
      Strasse: "Hindenburgstraße 171",
      PLZ: "41061",
      Stadt: "Mönchengladbach",
    },
    1086: {
      LadenId: 1086,
      Telefon: "+492086204642",
      Strasse: "Bahnhofstraße 80",
      PLZ: "46145",
      Stadt: "Oberhausen",
    },
    1091: {
      LadenId: 1091,
      Telefon: "+492211206340",
      Strasse: "Neusser Straße 10-12",
      PLZ: "50670",
      Stadt: "Köln",
    },
    1092: {
      LadenId: 1092,
      Telefon: "+492111674706",
      Strasse: "Schadowstraße 64-66",
      PLZ: "40212",
      Stadt: "Düsseldorf",
    },
    1110: {
      LadenId: 1110,
      Telefon: "+492043203214",
      Strasse: "Hochstraße 16",
      PLZ: "45964",
      Stadt: "Gladbeck",
    },
    1122: {
      LadenId: 1122,
      Telefon: "+492403503295",
      Strasse: "Grabenstraße 76",
      PLZ: "52249",
      Stadt: "Eschweiler",
    },
    1128: {
      LadenId: 1128,
      Telefon: "+492411603890",
      Strasse: "Adalbertstraße 80-82",
      PLZ: "52062",
      Stadt: "Aachen",
    },
    1129: {
      LadenId: 1129,
      Telefon: "+492212508730",
      Strasse: "Hohenzollernring 2-10",
      PLZ: "50672",
      Stadt: "Köln",
    },
    1132: {
      LadenId: 1132,
      Telefon: "+492065254644",
      Strasse: "Atroper Straße 9-13",
      PLZ: "47226",
      Stadt: "Duisburg",
    },
    1133: {
      LadenId: 1133,
      Telefon: "+4923731702124",
      Strasse: "Heinrich-Böll-Straße 1",
      PLZ: "58710",
      Stadt: "Menden (Sauerland)",
    },
    1152: {
      LadenId: 1152,
      Telefon: "+4952231835581",
      Strasse: "Wasserbreite 11-13",
      PLZ: "32257",
      Stadt: "Bünde",
    },
    1175: {
      LadenId: 1175,
      Telefon: "+495211644470",
      Strasse: "Bahnhofstraße 39",
      PLZ: "33602",
      Stadt: "Bielefeld",
    },
    1176: {
      LadenId: 1176,
      Telefon: "+492511364765",
      Strasse: "Gartenbreie 3",
      PLZ: "48161",
      Stadt: "Münster",
    },
    1179: {
      LadenId: 1179,
      Telefon: "+492286197700",
      Strasse: "Rochusstraße 194",
      PLZ: "53123",
      Stadt: "Bonn",
    },
    1180: {
      LadenId: 1180,
      Telefon: "+492235413199",
      Strasse: "Gustav-Heinemann-Straße 2a",
      PLZ: "50374",
      Stadt: "Erftstadt",
    },
    1181: {
      LadenId: 1181,
      Telefon: "+492033485663",
      Strasse: "Von-der-Mark-Straße 79",
      PLZ: "47137",
      Stadt: "Duisburg",
    },
    1185: {
      LadenId: 1185,
      Telefon: "+492133259733",
      Strasse: "Lübecker Straße 1",
      PLZ: "41540",
      Stadt: "Dormagen",
    },
    1189: {
      LadenId: 1189,
      Telefon: "+492511449154",
      Strasse: "Ludgeristraße 100",
      PLZ: "48143",
      Stadt: "Münster",
    },
    1190: {
      LadenId: 1190,
      Telefon: "+492022549782",
      Strasse: "Marktstraße 21b",
      PLZ: "42369",
      Stadt: "Wuppertal",
    },
    1191: {
      LadenId: 1191,
      Telefon: "+4922818499836",
      Strasse: "Karlstraße 33",
      PLZ: "53115",
      Stadt: "Bonn",
    },
    1222: {
      LadenId: 1222,
      Telefon: "+492218009871",
      Strasse: "Eiler Straße 124",
      PLZ: "51107",
      Stadt: "Köln",
    },
    1258: {
      LadenId: 1258,
      Telefon: "+492404556624",
      Strasse: "Bahnhofstraße 37-41",
      PLZ: "52477",
      Stadt: "Alsdorf",
    },
    1269: {
      LadenId: 1269,
      Telefon: "+4952619204913",
      Strasse: "Mittelstraße 31-33",
      PLZ: "32657",
      Stadt: "Lemgo",
    },
    1271: {
      LadenId: 1271,
      Telefon: "+4921120964835",
      Strasse: "Nordstraße 116",
      PLZ: "40477",
      Stadt: "Düsseldorf",
    },
    1275: {
      LadenId: 1275,
      Telefon: "+4922411652828",
      Strasse: "Spicher Straße 101",
      PLZ: "53844",
      Stadt: "Troisdorf",
    },
    1281: {
      LadenId: 1281,
      Telefon: "+4922818458957",
      Strasse: "Rheinweg 156-158",
      PLZ: "53129",
      Stadt: "Bonn",
    },
    1302: {
      LadenId: 1302,
      Telefon: "+4921311787551",
      Strasse: "Niederstraße 39-45",
      PLZ: "41460",
      Stadt: "Neuss",
    },
    1307: {
      LadenId: 1307,
      Telefon: "+4923245969948",
      Strasse: "Reschop Carre Platz 1",
      PLZ: "45525",
      Stadt: "Hattingen",
    },
    1309: {
      LadenId: 1309,
      Telefon: "+492122210747",
      Strasse: "Kölner Straße 80-82",
      PLZ: "42651",
      Stadt: "Solingen",
    },
    1310: {
      LadenId: 1310,
      Telefon: "+492011054729",
      Strasse: "Fulerumer Straße 221",
      PLZ: "45149",
      Stadt: "Essen",
    },
    1318: {
      LadenId: 1318,
      Telefon: "+492142067676",
      Strasse: "Syltstraße 10",
      PLZ: "51377",
      Stadt: "Leverkusen",
    },
    1335: {
      LadenId: 1335,
      Telefon: "+4922892599193",
      Strasse: "Basketsring 5",
      PLZ: "53123",
      Stadt: "Bonn",
    },
    1342: {
      LadenId: 1342,
      Telefon: "+4922548300297",
      Strasse: "Parkallee 12",
      PLZ: "53919",
      Stadt: "Weilerswist",
    },
    1343: {
      LadenId: 1343,
      Telefon: "+4925817899524",
      Strasse: "August-Wessing-Damm 82",
      PLZ: "48231",
      Stadt: "Warendorf",
    },
    1345: {
      LadenId: 1345,
      Telefon: "+4921621025414",
      Strasse: "Löhstraße 21",
      PLZ: "41747",
      Stadt: "Viersen",
    },
    1361: {
      LadenId: 1361,
      Telefon: "+492091657696",
      Strasse: "Hippolytusstraße 1",
      PLZ: "45899",
      Stadt: "Gelsenkirchen",
    },
    1370: {
      LadenId: 1370,
      Telefon: "+4922339399054",
      Strasse: "Eschweilerstraße 2",
      PLZ: "50354",
      Stadt: "Hürth",
    },
    1371: {
      LadenId: 1371,
      Telefon: "+492012486292",
      Strasse: "Limbeckerplatz 1a",
      PLZ: "45127",
      Stadt: "Essen",
    },
    1386: {
      LadenId: 1386,
      Telefon: "+492742911497",
      Strasse: "Nisterstraße 17",
      PLZ: "57537",
      Stadt: "Wissen",
    },
    1387: {
      LadenId: 1387,
      Telefon: "+492654881332",
      Strasse: "Vor Geisenach 6",
      PLZ: "56751",
      Stadt: "Polch",
    },
    1388: {
      LadenId: 1388,
      Telefon: "+4964349077463",
      Strasse: "Beuerbacher Landstraße 1",
      PLZ: "65520",
      Stadt: "Bad Camberg",
    },
    1398: {
      LadenId: 1398,
      Telefon: "+4921956725696",
      Strasse: "Schloßmacherstraße 8",
      PLZ: "42477",
      Stadt: "Radevormwald",
    },
    1399: {
      LadenId: 1399,
      Telefon: "+492033464339",
      Strasse: "Fischerstraße 106",
      PLZ: "47055",
      Stadt: "Duisburg",
    },
    1415: {
      LadenId: 1415,
      Telefon: "+492087404497",
      Strasse: "Mannesmannallee 31",
      PLZ: "45475",
      Stadt: "Mülheim an der Ruhr",
    },
    1418: {
      LadenId: 1418,
      Telefon: "+4920328149763",
      Strasse: "Königstraße 48",
      PLZ: "47051",
      Stadt: "Duisburg",
    },
    1422: {
      LadenId: 1422,
      Telefon: "+4923813046668",
      Strasse: "Ostwennemarstraße 100",
      PLZ: "59071",
      Stadt: "Hamm",
    },
    1445: {
      LadenId: 1445,
      Telefon: "+4922247799594",
      Strasse: "Wittichenauer Straße 13",
      PLZ: "53604",
      Stadt: "Bad Honnef",
    },
    1449: {
      LadenId: 1449,
      Telefon: "+4921312067707",
      Strasse: "Schellbergstraße 9",
      PLZ: "41469",
      Stadt: "Neuss",
    },
    1450: {
      LadenId: 1450,
      Telefon: "+492689925766",
      Strasse: "Königsberger Straße 40",
      PLZ: "56269",
      Stadt: "Dierdorf",
    },
    1451: {
      LadenId: 1451,
      Telefon: "+4928429032265",
      Strasse: "Moerser Straße 247",
      PLZ: "47475",
      Stadt: "Kamp-Lintfort",
    },
    1455: {
      LadenId: 1455,
      Telefon: "+496772962503",
      Strasse: "In der Au 5",
      PLZ: "56355",
      Stadt: "Nastätten",
    },
    1462: {
      LadenId: 1462,
      Telefon: "+4923059788257",
      Strasse: "Münsterstraße 5",
      PLZ: "44575",
      Stadt: "Castrop-Rauxel",
    },
    1464: {
      LadenId: 1464,
      Telefon: "+4922085060851",
      Strasse: "Gierslinger Straße 3 a",
      PLZ: "53859",
      Stadt: "Niederkassel",
    },
    1465: {
      LadenId: 1465,
      Telefon: "+4964312172629",
      Strasse: "Joseph-Schneider-Straße 1",
      PLZ: "65549",
      Stadt: "Limburg a. d. Lahn",
    },
    1469: {
      LadenId: 1469,
      Telefon: "+4928418810992",
      Strasse: "Franz-Haniel-Straße 92",
      PLZ: "47443",
      Stadt: "Moers",
    },
    1470: {
      LadenId: 1470,
      Telefon: "+492311897400",
      Strasse: "Bornstraße 160",
      PLZ: "44145",
      Stadt: "Dortmund",
    },
    1476: {
      LadenId: 1476,
      Telefon: "+4924433102010",
      Strasse: "Kölner Straße 133",
      PLZ: "53894",
      Stadt: "Mechernich",
    },
    1477: {
      LadenId: 1477,
      Telefon: "+4924329029973",
      Strasse: "Brabanter Straße 48-50",
      PLZ: "41849",
      Stadt: "Wassenberg",
    },
    1485: {
      LadenId: 1485,
      Telefon: "+496747597787",
      Strasse: "Am Stadion 2",
      PLZ: "56281",
      Stadt: "Emmelshausen",
    },
    1495: {
      LadenId: 1495,
      Telefon: "+4922129271140",
      Strasse: "Unter Linden 282",
      PLZ: "50859",
      Stadt: "Köln",
    },
    1497: {
      LadenId: 1497,
      Telefon: "+4924155924267",
      Strasse: "Ursulinerstraße 7-9",
      PLZ: "52062",
      Stadt: "Aachen",
    },
    1499: {
      LadenId: 1499,
      Telefon: "+4923711559489",
      Strasse: "Alter Rathausplatz 14",
      PLZ: "58636",
      Stadt: "Iserlohn",
    },
    1502: {
      LadenId: 1502,
      Telefon: "+4921130121992",
      Strasse: "Friedrichstraße 129-133",
      PLZ: "40217",
      Stadt: "Düsseldorf",
    },
    1503: {
      LadenId: 1503,
      Telefon: "+492032898293",
      Strasse: "Albertstraße 10",
      PLZ: "47059",
      Stadt: "Duisburg",
    },
    1506: {
      LadenId: 1506,
      Telefon: "+4921115973235",
      Strasse: "Schadowstraße 11",
      PLZ: "40212",
      Stadt: "Düsseldorf",
    },
    1514: {
      LadenId: 1514,
      Telefon: "+492311769537",
      Strasse: "Wickeder Hellweg 103",
      PLZ: "44319",
      Stadt: "Dortmund",
    },
    1518: {
      LadenId: 1518,
      Telefon: "+492142600679",
      Strasse: "Friedrich-Ebert-Platz 1",
      PLZ: "51373",
      Stadt: "Leverkusen",
    },
    1522: {
      LadenId: 1522,
      Telefon: "+4922892894599",
      Strasse: "Agnetendorfer Straße 4",
      PLZ: "53119",
      Stadt: "Bonn",
    },
    1529: {
      LadenId: 1529,
      Telefon: "+4928411732117",
      Strasse: "Neumarkt 15-17",
      PLZ: "47441",
      Stadt: "Moers",
    },
    1535: {
      LadenId: 1535,
      Telefon: "+4964312172149",
      Strasse: "Westerwaldstraße 107",
      PLZ: "65549",
      Stadt: "Limburg a. d. Lahn",
    },
    1546: {
      LadenId: 1546,
      Telefon: "+4922528366183",
      Strasse: "Römerallee 48d",
      PLZ: "53909",
      Stadt: "Zülpich",
    },
    1562: {
      LadenId: 1562,
      Telefon: "+4922862091980",
      Strasse: "Gartenstraße 102",
      PLZ: "53225",
      Stadt: "Bonn",
    },
    1578: {
      LadenId: 1578,
      Telefon: "+4920117789864",
      Strasse: "Am Hauptbahnhof 5",
      PLZ: "45127",
      Stadt: "Essen",
    },
    1580: {
      LadenId: 1580,
      Telefon: "+4922379799948",
      Strasse: "Am Falder 20",
      PLZ: "50171",
      Stadt: "Kerpen",
    },
    1587: {
      LadenId: 1587,
      Telefon: "+492464909541",
      Strasse: "Am Alten Bahnhof 3",
      PLZ: "52457",
      Stadt: "Aldenhoven",
    },
    1592: {
      LadenId: 1592,
      Telefon: "+4921914607843",
      Strasse: "Königstraße 205",
      PLZ: "42853",
      Stadt: "Remscheid",
    },
    1595: {
      LadenId: 1595,
      Telefon: "+4924417799916",
      Strasse: "Siemensring 17 d",
      PLZ: "53925",
      Stadt: "Kall",
    },
    1607: {
      LadenId: 1607,
      Telefon: "+4921130329597",
      Strasse: "Friedrichstrasse 13-15",
      PLZ: "40217",
      Stadt: "Düsseldorf",
    },
    1609: {
      LadenId: 1609,
      Telefon: "+4921634994037",
      Strasse: "Gewerbering 20",
      PLZ: "41372",
      Stadt: "Niederkrüchten",
    },
    1610: {
      LadenId: 1610,
      Telefon: "+4921571289810",
      Strasse: "Poststraße 42-44",
      PLZ: "41334",
      Stadt: "Nettetal",
    },
    1615: {
      LadenId: 1615,
      Telefon: "+4922129783146",
      Strasse: "Rhöndorfer Straße 15 a",
      PLZ: "50939",
      Stadt: "Köln",
    },
    1618: {
      LadenId: 1618,
      Telefon: "+4925152093849",
      Strasse: "Hammer Straße 28",
      PLZ: "48153",
      Stadt: "Münster",
    },
    1626: {
      LadenId: 1626,
      Telefon: "+4924118059765",
      Strasse: "Von-Coels-Straße 212",
      PLZ: "52080",
      Stadt: "Aachen",
    },
    1632: {
      LadenId: 1632,
      Telefon: "+4921531219943",
      Strasse: "Johannes-Cleven-Straße 4",
      PLZ: "41334",
      Stadt: "Nettetal",
    },
    1634: {
      LadenId: 1634,
      Telefon: "+4957519210578",
      Strasse: "Große Tonkuhle 9",
      PLZ: "31737",
      Stadt: "Rinteln",
    },
    1643: {
      LadenId: 1643,
      Telefon: "+4922939099415",
      Strasse: "Dorfplatz 4",
      PLZ: "51588",
      Stadt: "Nümbrecht",
    },
    1659: {
      LadenId: 1659,
      Telefon: "+4952192369253",
      Strasse: "Detmolder Straße 550-560",
      PLZ: "33699",
      Stadt: "Bielefeld",
    },
    1673: {
      LadenId: 1673,
      Telefon: "+4923725039970",
      Strasse: "Elsa-Brandström-Straße 3",
      PLZ: "58675",
      Stadt: "Hemer",
    },
    1690: {
      LadenId: 1690,
      Telefon: "+4926239247343",
      Strasse: "Pleurtuit-Straße 5",
      PLZ: "56235",
      Stadt: "Ransbach-Baumbach",
    },
    1701: {
      LadenId: 1701,
      Telefon: "+4921311511734",
      Strasse: "Breslauer Straße 2/4",
      PLZ: "41460",
      Stadt: "Neuss",
    },
    1702: {
      LadenId: 1702,
      Telefon: "+4922193729143",
      Strasse: "Schönhauser Straße 60-62",
      PLZ: "50968",
      Stadt: "Köln",
    },
    1715: {
      LadenId: 1715,
      Telefon: "+4920830159921",
      Strasse: "Wiescher Weg 97",
      PLZ: "45472",
      Stadt: "Mülheim an der Ruhr",
    },
    1724: {
      LadenId: 1724,
      Telefon: "+4921113067573",
      Strasse: "Königsallee 60d",
      PLZ: "40212",
      Stadt: "Düsseldorf",
    },
    1732: {
      LadenId: 1732,
      Telefon: "+4921032509954",
      Strasse: "Walder Straße 99",
      PLZ: "40724",
      Stadt: "Hilden",
    },
    1734: {
      LadenId: 1734,
      Telefon: "+4922193119080",
      Strasse: "Severinstraße 154-156",
      PLZ: "50678",
      Stadt: "Köln",
    },
    1738: {
      LadenId: 1738,
      Telefon: "+4923306039995",
      Strasse: "Mühlenstraße 1",
      PLZ: "58313",
      Stadt: "Herdecke",
    },
    1746: {
      LadenId: 1746,
      Telefon: "+4921731068063",
      Strasse: "Ingeborg-Friebe-Platz 11",
      PLZ: "40789",
      Stadt: "Monheim",
    },
    1755: {
      LadenId: 1755,
      Telefon: "+4922120533319",
      Strasse: "Neumarkt 35-37",
      PLZ: "50667",
      Stadt: "Köln",
    },
    1759: {
      LadenId: 1759,
      Telefon: "+4921198914021",
      Strasse: "Bavierstraße 10",
      PLZ: "40699",
      Stadt: "Erkrath",
    },
    1766: {
      LadenId: 1766,
      Telefon: "+4920159223833",
      Strasse: "Krayer Straße 234",
      PLZ: "45307",
      Stadt: "Essen",
    },
    1771: {
      LadenId: 1771,
      Telefon: "+4923072611839",
      Strasse: "Willy-Brandt-Platz 11",
      PLZ: "59174",
      Stadt: "Kamen",
    },
    1773: {
      LadenId: 1773,
      Telefon: "+4921042869494",
      Strasse: "Talstraße 10",
      PLZ: "40822",
      Stadt: "Mettmann",
    },
    1781: {
      LadenId: 1781,
      Telefon: "+4928553056961",
      Strasse: "Friedrichsfelder Straße 30",
      PLZ: "46562",
      Stadt: "Voerde (Niederrhein)",
    },
    1790: {
      LadenId: 1790,
      Telefon: "+4922892399244",
      Strasse: "Seufertstraße 73a",
      PLZ: "53173",
      Stadt: "Bonn",
    },
    1805: {
      LadenId: 1805,
      Telefon: "+4921669906373",
      Strasse: "Mittelstraße 5",
      PLZ: "41236",
      Stadt: "Mönchengladbach",
    },
    1810: {
      LadenId: 1810,
      Telefon: "+4921327594071",
      Strasse: "Düsseldorfer Straße 77",
      PLZ: "40667",
      Stadt: "Meerbusch",
    },
    1811: {
      LadenId: 1811,
      Telefon: "+4922342025834",
      Strasse: "Horbeller Straße 2-4",
      PLZ: "50858",
      Stadt: "Köln",
    },
    1813: {
      LadenId: 1813,
      Telefon: "+4922232988846",
      Strasse: "Proffenweg 2",
      PLZ: "53639",
      Stadt: "Königswinter",
    },
    1824: {
      LadenId: 1824,
      Telefon: "+4921912094434",
      Strasse: "Am Schützenplatz 6",
      PLZ: "42899",
      Stadt: "Remscheid",
    },
    1826: {
      LadenId: 1826,
      Telefon: "+4924049579964",
      Strasse: "Eschweilerstraße 2c",
      PLZ: "52477",
      Stadt: "Alsdorf",
    },
    1827: {
      LadenId: 1827,
      Telefon: "+4924514092781",
      Strasse: "Herzog-Wilhelm-Straße 17-37",
      PLZ: "52511",
      Stadt: "Geilenkirchen",
    },
    1838: {
      LadenId: 1838,
      Telefon: "+4921731068910",
      Strasse: "Solinger Straße 5",
      PLZ: "40764",
      Stadt: "Langenfeld (Rheinland)",
    },
    1848: {
      LadenId: 1848,
      Telefon: "+4921544812660",
      Strasse: "Zum Güterbahnhof 2",
      PLZ: "47877",
      Stadt: "Willich",
    },
    1853: {
      LadenId: 1853,
      Telefon: "+4923153463949",
      Strasse: "Westenhellweg 102-106",
      PLZ: "44137",
      Stadt: "Dortmund",
    },
    1855: {
      LadenId: 1855,
      Telefon: "+4923254670944",
      Strasse: "Hauptstraße 241",
      PLZ: "44649",
      Stadt: "Herne",
    },
    1875: {
      LadenId: 1875,
      Telefon: "+4924288036179",
      Strasse: "Niederzierer Straße 86",
      PLZ: "52382",
      Stadt: "Niederzier",
    },
    1881: {
      LadenId: 1881,
      Telefon: "+4920179870989",
      Strasse: "Rüttenscheider Straße 62-64",
      PLZ: "45130",
      Stadt: "Essen",
    },
    1887: {
      LadenId: 1887,
      Telefon: "+4952152191876",
      Strasse: "Herforder Straße 9",
      PLZ: "33602",
      Stadt: "Bielefeld",
    },
    1892: {
      LadenId: 1892,
      Telefon: "+4920534245410",
      Strasse: "Schieferbruch 4",
      PLZ: "42553",
      Stadt: "Velbert",
    },
    1902: {
      LadenId: 1902,
      Telefon: "+4925116245610",
      Strasse: "Westhoffstr. 140a",
      PLZ: "48159",
      Stadt: "Münster",
    },
    1913: {
      LadenId: 1913,
      Telefon: "+4920874055955",
      Strasse: "Hansastraße 20a",
      PLZ: "45478",
      Stadt: "Mülheim an der Ruhr",
    },
    1916: {
      LadenId: 1916,
      Telefon: "+4922279099015",
      Strasse: "Am Roten Boskoop 4",
      PLZ: "53332",
      Stadt: "Bornheim",
    },
    1920: {
      LadenId: 1920,
      Telefon: "+4922820709180",
      Strasse: "Friedensplatz 1-3",
      PLZ: "53111",
      Stadt: "Bonn",
    },
    1930: {
      LadenId: 1930,
      Telefon: "+4923312040553",
      Strasse: "Elberfelder Straße 29",
      PLZ: "58095",
      Stadt: "Hagen",
    },
    1939: {
      LadenId: 1939,
      Telefon: "+4920844496031",
      Strasse: "Heidestraße 17a",
      PLZ: "45476",
      Stadt: "Mülheim an der Ruhr",
    },
    1949: {
      LadenId: 1949,
      Telefon: "+4920334666832",
      Strasse: "Sternbuschweg 219",
      PLZ: "47057",
      Stadt: "Duisburg",
    },
    1953: {
      LadenId: 1953,
      Telefon: "+4923633577959",
      Strasse: "Neumarkt 5",
      PLZ: "45711",
      Stadt: "Datteln",
    },
    1956: {
      LadenId: 1956,
      Telefon: "+4926113366715",
      Strasse: "Zentralplatz 2",
      PLZ: "56068",
      Stadt: "Koblenz",
    },
    1958: {
      LadenId: 1958,
      Telefon: "+4923711559332",
      Strasse: "Mendener Straße 140",
      PLZ: "58636",
      Stadt: "Iserlohn",
    },
    1966: {
      LadenId: 1966,
      Telefon: "+4920154521918",
      Strasse: "Hansastraße 24-30",
      PLZ: "45276",
      Stadt: "Essen",
    },
    1967: {
      LadenId: 1967,
      Telefon: "+4924211216641",
      Strasse: "Josef-Schregel-Straße 57-59",
      PLZ: "52349",
      Stadt: "Düren",
    },
    1970: {
      LadenId: 1970,
      Telefon: "+4921515189087",
      Strasse: "Schönwasserstraße 8-10",
      PLZ: "47809",
      Stadt: "Krefeld",
    },
    1983: {
      LadenId: 1983,
      Telefon: "+4925148166931",
      Strasse: "Bahnhofstraße 11",
      PLZ: "48143",
      Stadt: "Münster",
    },
    1987: {
      LadenId: 1987,
      Telefon: "+4923611061841",
      Strasse: "Löhrhof 1",
      PLZ: "45657",
      Stadt: "Recklinghausen",
    },
    1999: {
      LadenId: 1999,
      Telefon: "+4922196399042",
      Strasse: "Steyler Straße 21",
      PLZ: "51067",
      Stadt: "Köln",
    },
    2005: {
      LadenId: 2005,
      Telefon: "+4926021068698",
      Strasse: "Industriestraße 16",
      PLZ: "56412",
      Stadt: "Heiligenroth",
    },
    2006: {
      LadenId: 2006,
      Telefon: "+4921238212807",
      Strasse: "Kölner Straße 99",
      PLZ: "42651",
      Stadt: "Solingen",
    },
    2008: {
      LadenId: 2008,
      Telefon: "+4924279099537",
      Strasse: "Am Eisernen Kreuz 1",
      PLZ: "52385",
      Stadt: "Nideggen",
    },
    2019: {
      LadenId: 2019,
      Telefon: "+4920648299980",
      Strasse: "Hohlstraße 9-11",
      PLZ: "46539",
      Stadt: "Dinslaken",
    },
    2028: {
      LadenId: 2028,
      Telefon: "+4952198919379",
      Strasse: "Apfelstraße 8",
      PLZ: "33613",
      Stadt: "Bielefeld",
    },
    2030: {
      LadenId: 2030,
      Telefon: "+4920373839844",
      Strasse: "Mündelheimer Straße 173",
      PLZ: "47259",
      Stadt: "Duisburg",
    },
    2031: {
      LadenId: 2031,
      Telefon: "+4923196987188",
      Strasse: "Wulfshofstraße 6-8",
      PLZ: "44149",
      Stadt: "Dortmund",
    },
    2032: {
      LadenId: 2032,
      Telefon: "+4922237002023",
      Strasse: "Aegidienberger Straße 2a",
      PLZ: "53639",
      Stadt: "Königswinter",
    },
    2038: {
      LadenId: 2038,
      Telefon: "+4921117129629",
      Strasse: "Am Wehrhahn 36",
      PLZ: "40211",
      Stadt: "Düsseldorf",
    },
    2056: {
      LadenId: 2056,
      Telefon: "+4922619130385",
      Strasse: "Kölner Straße 113",
      PLZ: "51702",
      Stadt: "Bergneustadt",
    },
    2057: {
      LadenId: 2057,
      Telefon: "+4923069279017",
      Strasse: "Viktoriastraße 3",
      PLZ: "44532",
      Stadt: "Lünen",
    },
    2058: {
      LadenId: 2058,
      Telefon: "+4928335743947",
      Strasse: "Umgehungsstraße 2a",
      PLZ: "47647",
      Stadt: "Kerken",
    },
    2073: {
      LadenId: 2073,
      Telefon: "+4928411730834",
      Strasse: "Steinstraße 43",
      PLZ: "47441",
      Stadt: "Moers",
    },
    2085: {
      LadenId: 2085,
      Telefon: "+4920882869120",
      Strasse: "Centroallee 34-35",
      PLZ: "46047",
      Stadt: "Oberhausen",
    },
    2095: {
      LadenId: 2095,
      Telefon: "+4926194249609",
      Strasse: "Peter-Klöckner-Straße 3a",
      PLZ: "56073",
      Stadt: "Koblenz",
    },
    2107: {
      LadenId: 2107,
      Telefon: "+4920862189892",
      Strasse: "Kirchhellener Straße 142",
      PLZ: "46145",
      Stadt: "Oberhausen",
    },
    2109: {
      LadenId: 2109,
      Telefon: "+4921115775954",
      Strasse: "Bruchstraße 78-80",
      PLZ: "40235",
      Stadt: "Düsseldorf",
    },
    2116: {
      LadenId: 2116,
      Telefon: "+4922249897139",
      Strasse: "Rottbitzer Straße 22",
      PLZ: "53604",
      Stadt: "Bad Honnef",
    },
    2117: {
      LadenId: 2117,
      Telefon: "+4922411484724",
      Strasse: "Schulstraße 7",
      PLZ: "53757",
      Stadt: "Sankt Augustin",
    },
    2126: {
      LadenId: 2126,
      Telefon: "+4922438473605",
      Strasse: "Markt 6",
      PLZ: "53783",
      Stadt: "Eitorf",
    },
    2127: {
      LadenId: 2127,
      Telefon: "+4921666218192",
      Strasse: "Stresemannstraße 1-7",
      PLZ: "41236",
      Stadt: "Mönchengladbach",
    },
    2128: {
      LadenId: 2128,
      Telefon: "+4924514093787",
      Strasse: "Am Wasserturm 11-17",
      PLZ: "52531",
      Stadt: "Übach-Palenberg",
    },
    2140: {
      LadenId: 2140,
      Telefon: "+4921161089611",
      Strasse: "Rethelstraße 161",
      PLZ: "40237",
      Stadt: "Düsseldorf",
    },
    2153: {
      LadenId: 2153,
      Telefon: "+4922511256762",
      Strasse: "Neustraße 43",
      PLZ: "53879",
      Stadt: "Euskirchen",
    },
    2159: {
      LadenId: 2159,
      Telefon: "+4920117843061",
      Strasse: "Haedenkampstraße 21",
      PLZ: "45143",
      Stadt: "Essen",
    },
    2160: {
      LadenId: 2160,
      Telefon: "+4923188056244",
      Strasse: "Altenderner Straße 20",
      PLZ: "44329",
      Stadt: "Dortmund",
    },
    2161: {
      LadenId: 2161,
      Telefon: "+4964312199539",
      Strasse: "Neumarkt 11",
      PLZ: "65549",
      Stadt: "Limburg a. d. Lahn",
    },
    2172: {
      LadenId: 2172,
      Telefon: "+4923514320272",
      Strasse: "Bräuckenstraße 83",
      PLZ: "58511",
      Stadt: "Lüdenscheid",
    },
    2178: {
      LadenId: 2178,
      Telefon: "+4922893494315",
      Strasse: "Bernareggio Platz 1",
      PLZ: "53343",
      Stadt: "Wachtberg",
    },
    2184: {
      LadenId: 2184,
      Telefon: "+4925022219014",
      Strasse: "Appelhülsener Straße 1c",
      PLZ: "48301",
      Stadt: "Nottuln",
    },
    2196: {
      LadenId: 2196,
      Telefon: "+4922389699322",
      Strasse: "Venloer Straße 127",
      PLZ: "50259",
      Stadt: "Pulheim",
    },
    2197: {
      LadenId: 2197,
      Telefon: "+4923364086930",
      Strasse: "Hauptstraße 50",
      PLZ: "58332",
      Stadt: "Schwelm",
    },
    2200: {
      LadenId: 2200,
      Telefon: "+4921116452366",
      Strasse: "Duisburger Straße 24",
      PLZ: "40477",
      Stadt: "Düsseldorf",
    },
    2209: {
      LadenId: 2209,
      Telefon: "+4922193729026",
      Strasse: "Goltsteinstraße 89,91",
      PLZ: "50968",
      Stadt: "Köln",
    },
    2210: {
      LadenId: 2210,
      Telefon: "+4924212063350",
      Strasse: "Am Ellernbusch 31",
      PLZ: "52355",
      Stadt: "Düren",
    },
    2211: {
      LadenId: 2211,
      Telefon: "+4924035087399",
      Strasse: "Am Fließ 4",
      PLZ: "52249",
      Stadt: "Eschweiler",
    },
    2215: {
      LadenId: 2215,
      Telefon: "+4920184379104",
      Strasse: "Frankenstraße 48",
      PLZ: "45134",
      Stadt: "Essen",
    },
    2221: {
      LadenId: 2221,
      Telefon: "+4922125099511",
      Strasse: "Minoritenstraße 1",
      PLZ: "50667",
      Stadt: "Köln",
    },
    2222: {
      LadenId: 2222,
      Telefon: "+4923622029980",
      Strasse: "Händelstraße 155",
      PLZ: "46282",
      Stadt: "Dorsten",
    },
    2224: {
      LadenId: 2224,
      Telefon: "+4920837719912",
      Strasse: "Concordiastraße 32",
      PLZ: "46049",
      Stadt: "Oberhausen",
    },
    2225: {
      LadenId: 2225,
      Telefon: "+4922039803731",
      Strasse: "Am Bahnhof 25",
      PLZ: "51147",
      Stadt: "Köln",
    },
    2233: {
      LadenId: 2233,
      Telefon: "+4920644288047",
      Strasse: "Neutorplatz 14",
      PLZ: "46535",
      Stadt: "Dinslaken",
    },
    2240: {
      LadenId: 2240,
      Telefon: "+4923731703644",
      Strasse: "Untere Promenade 10",
      PLZ: "58706",
      Stadt: "Menden",
    },
    2241: {
      LadenId: 2241,
      Telefon: "+4961209269947",
      Strasse: "Im Aartal 18 e",
      PLZ: "65326",
      Stadt: "Aarbergen",
    },
    2245: {
      LadenId: 2245,
      Telefon: "+4920912041335",
      Strasse: "Wanner Straße 157",
      PLZ: "45888",
      Stadt: "Gelsenkirchen",
    },
    2258: {
      LadenId: 2258,
      Telefon: "+4923927202830",
      Strasse: "Am Stadtgarten 4+6",
      PLZ: "58809",
      Stadt: "Neuenrade",
    },
    2259: {
      LadenId: 2259,
      Telefon: "+4964339476495",
      Strasse: "Mainzer Landstraße 15",
      PLZ: "65589",
      Stadt: "Hadamar",
    },
    2266: {
      LadenId: 2266,
      Telefon: "+4925174779917",
      Strasse: "Friedrich-Ebert-Straße 145-147",
      PLZ: "48153",
      Stadt: "Münster",
    },
    2268: {
      LadenId: 2268,
      Telefon: "+4924212079706",
      Strasse: "Wirtelstraße 23+25",
      PLZ: "52349",
      Stadt: "Düren",
    },
    2274: {
      LadenId: 2274,
      Telefon: "+4922929599165",
      Strasse: "Rathausstraße 31-37",
      PLZ: "51570",
      Stadt: "Windeck",
    },
    2282: {
      LadenId: 2282,
      Telefon: "+4920432946983",
      Strasse: "Horster Straße 344",
      PLZ: "45968",
      Stadt: "Gladbeck",
    },
    2287: {
      LadenId: 2287,
      Telefon: "+4924021020265",
      Strasse: "Eisenbahnstraße 153 d",
      PLZ: "52222",
      Stadt: "Stolberg",
    },
    2294: {
      LadenId: 2294,
      Telefon: "+4923153461686",
      Strasse: "Ostenhellweg 27-29",
      PLZ: "44135",
      Stadt: "Dortmund",
    },
    2301: {
      LadenId: 2301,
      Telefon: "+4920513117965",
      Strasse: "Sontumer Straße 73",
      PLZ: "42551",
      Stadt: "Velbert",
    },
    2304: {
      LadenId: 2304,
      Telefon: "+4920249608888",
      Strasse: "Bendahler Straße 27",
      PLZ: "42285",
      Stadt: "Wuppertal",
    },
    2308: {
      LadenId: 2308,
      Telefon: "+4952458579740",
      Strasse: "Beelener Straße 84",
      PLZ: "33442",
      Stadt: "Herzebrock-Clarholz",
    },
    2314: {
      LadenId: 2314,
      Telefon: "+4921967079691",
      Strasse: "Telegrafenstraße 41a",
      PLZ: "42929",
      Stadt: "Wermelskirchen",
    },
    2320: {
      LadenId: 2320,
      Telefon: "+4923049106235",
      Strasse: "Hüsingstraße 28",
      PLZ: "58239",
      Stadt: "Schwerte",
    },
    2321: {
      LadenId: 2321,
      Telefon: "+4920541047434",
      Strasse: "Güterstraße 22",
      PLZ: "45219",
      Stadt: "Essen",
    },
    2324: {
      LadenId: 2324,
      Telefon: "+4920664680928",
      Strasse: "Moerser Straße 2b",
      PLZ: "47198",
      Stadt: "Duisburg",
    },
    2325: {
      LadenId: 2325,
      Telefon: "+4952052369309",
      Strasse: "Hansestraße 1",
      PLZ: "33689",
      Stadt: "Bielefeld",
    },
    2326: {
      LadenId: 2326,
      Telefon: "+4926313459132",
      Strasse: "Aubachstraße 79",
      PLZ: "56567",
      Stadt: "Neuwied",
    },
    2328: {
      LadenId: 2328,
      Telefon: "+4923436969387",
      Strasse: "Riemker Str. 13",
      PLZ: "44809",
      Stadt: "Bochum",
    },
    2330: {
      LadenId: 2330,
      Telefon: "+4921613070240",
      Strasse: "Helmut-Grashoff-Straße 6",
      PLZ: "41179",
      Stadt: "Mönchengladbach",
    },
    2331: {
      LadenId: 2331,
      Telefon: "+4921669988648",
      Strasse: "Karlstraße 15",
      PLZ: "41199",
      Stadt: "Mönchengladbach",
    },
    2334: {
      LadenId: 2334,
      Telefon: "+4928389109913",
      Strasse: "Hochstraße 130",
      PLZ: "47665",
      Stadt: "Sonsbeck",
    },
    2337: {
      LadenId: 2337,
      Telefon: "+4920226549878",
      Strasse: "Wall 24 a",
      PLZ: "42103",
      Stadt: "Wuppertal",
    },
    2341: {
      LadenId: 2341,
      Telefon: "+4922229589050",
      Strasse: "Schumacherstraße 2-10",
      PLZ: "53332",
      Stadt: "Bornheim",
    },
    2343: {
      LadenId: 2343,
      Telefon: "+4926349237676",
      Strasse: "Westerwaldstraße 97",
      PLZ: "56579",
      Stadt: "Rengsdorf",
    },
    2347: {
      LadenId: 2347,
      Telefon: "+4923231479806",
      Strasse: "Bahnhofstraße 43",
      PLZ: "44623",
      Stadt: "Herne",
    },
    2350: {
      LadenId: 2350,
      Telefon: "+4921731068916",
      Strasse: "Hugo-Zade-Weg 2",
      PLZ: "40764",
      Stadt: "Langenfeld",
    },
    2357: {
      LadenId: 2357,
      Telefon: "+4923180901863",
      Strasse: "Mengeder Straße 7",
      PLZ: "44536",
      Stadt: "Lünen",
    },
    2367: {
      LadenId: 2367,
      Telefon: "+4921619993066",
      Strasse: "Holzkamp 6",
      PLZ: "41352",
      Stadt: "Korschenbroich",
    },
    2371: {
      LadenId: 2371,
      Telefon: "+4922489098186",
      Strasse: "Pantaleon-Schmitz-Platz 5-7",
      PLZ: "53773",
      Stadt: "Hennef-Uckerath",
    },
    2374: {
      LadenId: 2374,
      Telefon: "+4921651709890",
      Strasse: "Kölner Straße 29",
      PLZ: "41363",
      Stadt: "Jüchen",
    },
    2394: {
      LadenId: 2394,
      Telefon: "+4923745056843",
      Strasse: "Hagener Straße 26",
      PLZ: "58642",
      Stadt: "Iserlohn",
    },
    2415: {
      LadenId: 2415,
      Telefon: "+4926429939490",
      Strasse: "Lindenstraße 4",
      PLZ: "53489",
      Stadt: "Sinzig",
    },
    2420: {
      LadenId: 2420,
      Telefon: "+4921825700837",
      Strasse: "Am Rübenacker 2",
      PLZ: "41516",
      Stadt: "Grevenbroich",
    },
    2423: {
      LadenId: 2423,
      Telefon: "+4922664400095",
      Strasse: "Kölner Straße 19",
      PLZ: "51789",
      Stadt: "Lindlar",
    },
    2438: {
      LadenId: 2438,
      Telefon: "+4921513699001",
      Strasse: "Mühlenweg 8",
      PLZ: "47839",
      Stadt: "Krefeld",
    },
    2441: {
      LadenId: 2441,
      Telefon: "+4921511544913",
      Strasse: "Uerdinger Straße 103-107",
      PLZ: "47799",
      Stadt: "Krefeld",
    },
    2449: {
      LadenId: 2449,
      Telefon: "+4922125085496",
      Strasse: "Hohenzollernring 58",
      PLZ: "50672",
      Stadt: "Köln",
    },
    2455: {
      LadenId: 2455,
      Telefon: "+4926035049263",
      Strasse: "Nieverner Straße 13a",
      PLZ: "56130",
      Stadt: "Bad Ems",
    },
    2458: {
      LadenId: 2458,
      Telefon: "+4922829976420",
      Strasse: "Poststraße 2",
      PLZ: "53111",
      Stadt: "Bonn",
    },
    2459: {
      LadenId: 2459,
      Telefon: "+4922125099532",
      Strasse: "Hohe Strasse 73-75",
      PLZ: "50667",
      Stadt: "Köln",
    },
    2463: {
      LadenId: 2463,
      Telefon: "+4921811649299",
      Strasse: "Am Hammerwerk 24",
      PLZ: "41515",
      Stadt: "Grevenbroich",
    },
    2465: {
      LadenId: 2465,
      Telefon: "+4922022097639",
      Strasse: "Richard-Zanders-Straße 2",
      PLZ: "51465",
      Stadt: "Bergisch Gladbach",
    },
    2469: {
      LadenId: 2469,
      Telefon: "+4922142290141",
      Strasse: "Kalker Hauptstraße 55",
      PLZ: "51103",
      Stadt: "Köln",
    },
    2470: {
      LadenId: 2470,
      Telefon: "+4922412615550",
      Strasse: "Zum hohen Ufer 6",
      PLZ: "53721",
      Stadt: "Siegburg",
    },
    2475: {
      LadenId: 2475,
      Telefon: "+4920163019210",
      Strasse: "Marktstr. 10",
      PLZ: "45355",
      Stadt: "Essen",
    },
    2481: {
      LadenId: 2481,
      Telefon: "+4921612470281",
      Strasse: "Hindenburgstraße 73",
      PLZ: "41061",
      Stadt: "Mönchengladbach",
    },
    2484: {
      LadenId: 2484,
      Telefon: "+4923313629594",
      Strasse: "Kabeler Straße 25",
      PLZ: "58099",
      Stadt: "Hagen",
    },
    2488: {
      LadenId: 2488,
      Telefon: "+4921513657153",
      Strasse: "Hochstraße 25",
      PLZ: "47918",
      Stadt: "Tönisvorst",
    },
    2493: {
      LadenId: 2493,
      Telefon: "+4924318051053",
      Strasse: "Gladbacher Straße 15",
      PLZ: "41844",
      Stadt: "Wegberg",
    },
    2497: {
      LadenId: 2497,
      Telefon: "+4926359229124",
      Strasse: "Industriestraße 2a",
      PLZ: "56598",
      Stadt: "Rheinbrohl",
    },
    2498: {
      LadenId: 2498,
      Telefon: "+4921311248935",
      Strasse: "Am Reuschenberger Markt 3",
      PLZ: "41466",
      Stadt: "Neuss",
    },
    2509: {
      LadenId: 2509,
      Telefon: "+4923196987015",
      Strasse: "Provinzialstraße 387 a",
      PLZ: "44388",
      Stadt: "Dortmund",
    },
    2514: {
      LadenId: 2514,
      Telefon: "+4922413964556",
      Strasse: "Rathausallee 16",
      PLZ: "53757",
      Stadt: "Sankt Augustin",
    },
    2515: {
      LadenId: 2515,
      Telefon: "+4923172592645",
      Strasse: "Hagener Straße 148",
      PLZ: "44225",
      Stadt: "Dortmund",
    },
    2525: {
      LadenId: 2525,
      Telefon: "+4924622009967",
      Strasse: "Erkelenzerstraße 3",
      PLZ: "52441",
      Stadt: "Linnich",
    },
    2529: {
      LadenId: 2529,
      Telefon: "+4920136445962",
      Strasse: "Feilenstraße 1-5",
      PLZ: "45141",
      Stadt: "Essen",
    },
    2533: {
      LadenId: 2533,
      Telefon: "+4924116035775",
      Strasse: "Adalbertstraße 100",
      PLZ: "52062",
      Stadt: "Aachen",
    },
    2543: {
      LadenId: 2543,
      Telefon: "+4920513116951",
      Strasse: "Friedrichstraße 174-176",
      PLZ: "42551",
      Stadt: "Velbert",
    },
    2550: {
      LadenId: 2550,
      Telefon: "+4921511500214",
      Strasse: "Fütingsweg 71",
      PLZ: "47805",
      Stadt: "Krefeld",
    },
    2551: {
      LadenId: 2551,
      Telefon: "+4952140076834",
      Strasse: "Engersche Straße 96",
      PLZ: "33611",
      Stadt: "Bielefeld",
    },
    2567: {
      LadenId: 2567,
      Telefon: "+4961247887000",
      Strasse: "Bahnhofstraße 25",
      PLZ: "65307",
      Stadt: "Bad Schwalbach",
    },
    2573: {
      LadenId: 2573,
      Telefon: "+4926194251561",
      Strasse: "Trierer Straße 113",
      PLZ: "56072",
      Stadt: "Koblenz",
    },
    2574: {
      LadenId: 2574,
      Telefon: "+4926313459181",
      Strasse: "Heddesdorfer Straße 11",
      PLZ: "56564",
      Stadt: "Neuwied",
    },
    2582: {
      LadenId: 2582,
      Telefon: "+4920664029150",
      Strasse: "Moerser Straße 229",
      PLZ: "47198",
      Stadt: "Duisburg",
    },
    2585: {
      LadenId: 2585,
      Telefon: "+4922896499067",
      Strasse: "Medinghovener Str. 1",
      PLZ: "53347",
      Stadt: "Alfter",
    },
    2588: {
      LadenId: 2588,
      Telefon: "+4924140196182",
      Strasse: "Kapuzinergraben 21",
      PLZ: "52062",
      Stadt: "Aachen",
    },
    2590: {
      LadenId: 2590,
      Telefon: "+4924318051039",
      Strasse: "Adam-Opel-Straße 22",
      PLZ: "41812",
      Stadt: "Erkelenz",
    },
    2591: {
      LadenId: 2591,
      Telefon: "+4920199996540",
      Strasse: "Lierfeldstraße 1",
      PLZ: "45326",
      Stadt: "Essen",
    },
    2595: {
      LadenId: 2595,
      Telefon: "+4921197269230",
      Strasse: "Kölner Landstraße 195",
      PLZ: "40591",
      Stadt: "Düsseldorf",
    },
    2603: {
      LadenId: 2603,
      Telefon: "+4922125083058",
      Strasse: "Breite Straße 103-105",
      PLZ: "50667",
      Stadt: "Köln",
    },
    2604: {
      LadenId: 2604,
      Telefon: "+4926191469008",
      Strasse: "Hohenfelder Straße 22",
      PLZ: "56068",
      Stadt: "Koblenz",
    },
    2630: {
      LadenId: 2630,
      Telefon: "+4921834186187",
      Strasse: "Mariannenpark 10",
      PLZ: "41569",
      Stadt: "Rommerskirchen",
    },
    2641: {
      LadenId: 2641,
      Telefon: "+4922715059420",
      Strasse: "Kölner Straße 16",
      PLZ: "50126",
      Stadt: "Bergheim",
    },
    2648: {
      LadenId: 2648,
      Telefon: "+4920938903307",
      Strasse: "Hochstraße 7",
      PLZ: "45894",
      Stadt: "Gelsenkirchen",
    },
    2651: {
      LadenId: 2651,
      Telefon: "+4928431759920",
      Strasse: "Xantener Straße 51",
      PLZ: "47495",
      Stadt: "Rheinberg",
    },
    2654: {
      LadenId: 2654,
      Telefon: "+4924160844752",
      Strasse: "Trierer Straße 690",
      PLZ: "52078",
      Stadt: "Aachen",
    },
    2655: {
      LadenId: 2655,
      Telefon: "+4922413226390",
      Strasse: "Luisenstraße 86c",
      PLZ: "53721",
      Stadt: "Siegburg",
    },
    2662: {
      LadenId: 2662,
      Telefon: "+4924016028449",
      Strasse: "Kirchstraße 40",
      PLZ: "52499",
      Stadt: "Baesweiler",
    },
    2670: {
      LadenId: 2670,
      Telefon: "+4921041393959",
      Strasse: "Schwarzbachstraße 14",
      PLZ: "40822",
      Stadt: "Mettmann",
    },
    2673: {
      LadenId: 2673,
      Telefon: "+4922829975100",
      Strasse: "Brühler Str. 10a",
      PLZ: "53119",
      Stadt: "Bonn",
    },
    2674: {
      LadenId: 2674,
      Telefon: "+4925947927230",
      Strasse: "Overbergplatz 4",
      PLZ: "48249",
      Stadt: "Dülmen",
    },
    2685: {
      LadenId: 2685,
      Telefon: "+4921513873550",
      Strasse: "Friedrichstraße 15",
      PLZ: "47798",
      Stadt: "Krefeld",
    },
    2686: {
      LadenId: 2686,
      Telefon: "+4926289869435",
      Strasse: "Am Bornpfad 17c",
      PLZ: "56321",
      Stadt: "Rhens",
    },
    2689: {
      LadenId: 2689,
      Telefon: "+4923665729970",
      Strasse: "Zum alten Schacht 6",
      PLZ: "45699",
      Stadt: "Herten",
    },
    2710: {
      LadenId: 2710,
      Telefon: "+4922829975190",
      Strasse: "Gottlieb-Daimlerstraße 10",
      PLZ: "53859",
      Stadt: "Niederkassel",
    },
    2712: {
      LadenId: 2712,
      Telefon: "+4922359789820",
      Strasse: "Dirmerzheimer Straße 48",
      PLZ: "50374",
      Stadt: "Erftstadt",
    },
    2718: {
      LadenId: 2718,
      Telefon: "+4925128466830",
      Strasse: "Salzstr. 47-50",
      PLZ: "48143",
      Stadt: "Münster",
    },
    2722: {
      LadenId: 2722,
      Telefon: "+4923689600679",
      Strasse: "Stimbergstraße 134",
      PLZ: "45739",
      Stadt: "Oer-Erkenschwick",
    },
    2729: {
      LadenId: 2729,
      Telefon: "+4961282466813",
      Strasse: "Kleiststraße 8",
      PLZ: "65232",
      Stadt: "Taunusstein",
    },
    2731: {
      LadenId: 2731,
      Telefon: "+4926712290050",
      Strasse: "Markweg 1 a",
      PLZ: "56812",
      Stadt: "Cochem",
    },
    2734: {
      LadenId: 2734,
      Telefon: "+4922156958311",
      Strasse: "Aachener Straße 559",
      PLZ: "50933",
      Stadt: "Köln",
    },
    2735: {
      LadenId: 2735,
      Telefon: "+4922197589000",
      Strasse: "Kalker Hauptstraße 114",
      PLZ: "51103",
      Stadt: "Köln",
    },
    2737: {
      LadenId: 2737,
      Telefon: "+4922089348060",
      Strasse: "Spicher-Str. 1b",
      PLZ: "53859",
      Stadt: "Niederkassel",
    },
    2751: {
      LadenId: 2751,
      Telefon: "+4921507068922",
      Strasse: "Gonella Straße 1",
      PLZ: "40668",
      Stadt: "Meerbusch",
    },
    2764: {
      LadenId: 2764,
      Telefon: "+4924289021019",
      Strasse: "Rurbenden 2",
      PLZ: "52382",
      Stadt: "Niederzier",
    },
    2765: {
      LadenId: 2765,
      Telefon: "+4921516548426",
      Strasse: "Gahlingspfad 14",
      PLZ: "47803",
      Stadt: "Krefeld",
    },
    2772: {
      LadenId: 2772,
      Telefon: "+4921154241550",
      Strasse: "Gustaf-Gründgens-Platz 9c",
      PLZ: "40212",
      Stadt: "Düsseldorf",
    },
    2779: {
      LadenId: 2779,
      Telefon: "+4923195401290",
      Strasse: "Luisenglück 30",
      PLZ: "44225",
      Stadt: "Dortmund",
    },
    2780: {
      LadenId: 2780,
      Telefon: "+4921633495000",
      Strasse: "Borner Straße 58",
      PLZ: "41379",
      Stadt: "Brüggen",
    },
    2784: {
      LadenId: 2784,
      Telefon: "+4923313520670",
      Strasse: "Enneper Straße 146 B",
      PLZ: "58135",
      Stadt: "Hagen",
    },
    2785: {
      LadenId: 2785,
      Telefon: "+4922171563831",
      Strasse: "Friedrich-Karl-Straße 101",
      PLZ: "50735",
      Stadt: "Köln",
    },
    2788: {
      LadenId: 2788,
      Telefon: "+4922531859080",
      Strasse: "Kölner Straße 49",
      PLZ: "53902",
      Stadt: "Bad Münstereifel",
    },
    2789: {
      LadenId: 2789,
      Telefon: "+4922047061660",
      Strasse: "Schloßstr. 51",
      PLZ: "51429",
      Stadt: "Bergisch Gladbach",
    },
    2797: {
      LadenId: 2797,
      Telefon: "+4925229218877",
      Strasse: "Rhedaer Straße 3",
      PLZ: "59302",
      Stadt: "Oelde",
    },
    2801: {
      LadenId: 2801,
      Telefon: "+4920229789170",
      Strasse: "Döppersberg 43",
      PLZ: "42103",
      Stadt: "Wuppertal",
    },
    2803: {
      LadenId: 2803,
      Telefon: "+4952194566390",
      Strasse: "Detmolder Straße 320",
      PLZ: "33605",
      Stadt: "Bielefeld",
    },
    2818: {
      LadenId: 2818,
      Telefon: "+4923515525020",
      Strasse: "Kölner Straße 92",
      PLZ: "58509",
      Stadt: "Lüdenscheid",
    },
    2826: {
      LadenId: 2826,
      Telefon: "+4922715059230",
      Strasse: "Peter-Achnitz-Straße 4",
      PLZ: "50129",
      Stadt: "Bergheim",
    },
    2832: {
      LadenId: 2832,
      Telefon: "+4952199985010",
      Strasse: "Bahnhofstraße 2",
      PLZ: "33602",
      Stadt: "Bielefeld",
    },
    2837: {
      LadenId: 2837,
      Telefon: "+4921616217730",
      Strasse: "Tonderner Straße 29",
      PLZ: "41066",
      Stadt: "Mönchengladbach",
    },
    2840: {
      LadenId: 2840,
      Telefon: "+4922249840030",
      Strasse: "Am Hohen Weg 26",
      PLZ: "53572",
      Stadt: "Unkel",
    },
    2841: {
      LadenId: 2841,
      Telefon: "+4922829975160",
      Strasse: "Koblenzer Straße 63",
      PLZ: "53173",
      Stadt: "Bonn",
    },
    2844: {
      LadenId: 2844,
      Telefon: "+4921717949170",
      Strasse: "Kölner Straße 5-7",
      PLZ: "51379",
      Stadt: "Leverkusen",
    },
    2849: {
      LadenId: 2849,
      Telefon: "+4921431383270",
      Strasse: "Elbestraße 2",
      PLZ: "51371",
      Stadt: "Leverkusen",
    },
    2856: {
      LadenId: 2856,
      Telefon: "+4924029245120",
      Strasse: "Mauerstraße 27",
      PLZ: "52223",
      Stadt: "Stolberg",
    },
    2875: {
      LadenId: 2875,
      Telefon: "+4923827751320",
      Strasse: "Gebrüder-Kerkmann-Platz 4",
      PLZ: "59229",
      Stadt: "Ahlen",
    },
    2885: {
      LadenId: 2885,
      Telefon: "+4922829975590",
      Strasse: "Poststraße 23-27",
      PLZ: "53111",
      Stadt: "Bonn",
    },
    2886: {
      LadenId: 2886,
      Telefon: "+4952327069360",
      Strasse: "Friedrich-Petri-Straße 20",
      PLZ: "32791",
      Stadt: "Lage",
    },
    2891: {
      LadenId: 2891,
      Telefon: "+4920183886740",
      Strasse: "Holsterhauser Platz 3",
      PLZ: "45147",
      Stadt: "Essen",
    },
    2892: {
      LadenId: 2892,
      Telefon: "+4952237940300",
      Strasse: "Löhner Straße 313",
      PLZ: "32120",
      Stadt: "Hiddenhausen",
    },
    2897: {
      LadenId: 2897,
      Telefon: "+4923454295780",
      Strasse: "Am Einkaufszentrum 1",
      PLZ: "44791",
      Stadt: "Bochum",
    },
    2914: {
      LadenId: 2914,
      Telefon: "+4920183886840",
      Strasse: "Kettwiger Straße 32",
      PLZ: "45127",
      Stadt: "Essen",
    },
    2915: {
      LadenId: 2915,
      Telefon: "+4923122618750",
      Strasse: "Pariser Bogen 5-7",
      PLZ: "44269",
      Stadt: "Dortmund",
    },
    2919: {
      LadenId: 2919,
      Telefon: "+4924198093340",
      Strasse: "Großkölnstraße 57",
      PLZ: "52062",
      Stadt: "Aachen",
    },
    2925: {
      LadenId: 2925,
      Telefon: "+4923029818010",
      Strasse: "Westfalenstraße 114a",
      PLZ: "58453",
      Stadt: "Witten",
    },
    2928: {
      LadenId: 2928,
      Telefon: "+4923658725140",
      Strasse: "Marler Stern 28 D",
      PLZ: "45768",
      Stadt: "Marl",
    },
    2929: {
      LadenId: 2929,
      Telefon: "+4923812739710",
      Strasse: "Neufchateaustraße 6",
      PLZ: "59077",
      Stadt: "Hamm",
    },
    2946: {
      LadenId: 2946,
      Telefon: "+4924029066210",
      Strasse: "Kastanienweg 17",
      PLZ: "52223",
      Stadt: "Stolberg (Rheinland)",
    },
    2950: {
      LadenId: 2950,
      Telefon: "+4920569894210",
      Strasse: "Westfalenstraße 24",
      PLZ: "42579",
      Stadt: "Heiligenhaus",
    },
    2951: {
      LadenId: 2951,
      Telefon: "+4925192870780",
      Strasse: "Ludgeristraße 36",
      PLZ: "48143",
      Stadt: "Münster",
    },
    2955: {
      LadenId: 2955,
      Telefon: "+4922638029590",
      Strasse: "Bahnhofsplatz 6",
      PLZ: "51766",
      Stadt: "Engelskirchen",
    },
    2978: {
      LadenId: 2978,
      Telefon: "+4925729674090",
      Strasse: "Emsstraße 16",
      PLZ: "48282",
      Stadt: "Emsdetten",
    },
    2985: {
      LadenId: 2985,
      Telefon: "+4924198093270",
      Strasse: "Viktoriaallee 3-5",
      PLZ: "52066",
      Stadt: "Aachen",
    },
    2986: {
      LadenId: 2986,
      Telefon: "+4924075065660",
      Strasse: "Roermonder Straße 180",
      PLZ: "52134",
      Stadt: "Herzogenrath",
    },
    2987: {
      LadenId: 2987,
      Telefon: "+4925529939050",
      Strasse: "Meerstraße 1",
      PLZ: "48565",
      Stadt: "Steinfurt",
    },
    2988: {
      LadenId: 2988,
      Telefon: "+4926113498560",
      Strasse: "Moselweißer Straße 113",
      PLZ: "56073",
      Stadt: "Koblenz",
    },
    2998: {
      LadenId: 2998,
      Telefon: "+4920830677890",
      Strasse: "Weseler-Straße 28-40",
      PLZ: "45478",
      Stadt: "Mülheim an der Ruhr",
    },
    3000: {
      LadenId: 3000,
      Telefon: "+4922129205190",
      Strasse: "Hohenstaufenring 30",
      PLZ: "50674",
      Stadt: "Köln",
    },
    3003: {
      LadenId: 3003,
      Telefon: "+4923095449610",
      Strasse: "Bahnhofstraße 20 a-e",
      PLZ: "45731",
      Stadt: "Waltrop",
    },
    3015: {
      LadenId: 3015,
      Telefon: "+4922069050520",
      Strasse: "Steinhofplatz 1",
      PLZ: "51491",
      Stadt: "Overath",
    },
    3020: {
      LadenId: 3020,
      Telefon: "+4923249771320",
      Strasse: "Hauptstraße 70-72",
      PLZ: "45549",
      Stadt: "Sprockhövel",
    },
    3053: {
      LadenId: 3053,
      Telefon: "+4920830676650",
      Strasse: "Marktstraße 102",
      PLZ: "46045",
      Stadt: "Oberhausen",
    },
    3055: {
      LadenId: 3055,
      Telefon: "+4921514799970",
      Strasse: "Lange Straße 67",
      PLZ: "47829",
      Stadt: "Krefeld",
    },
    3073: {
      LadenId: 3073,
      Telefon: "+4922129255880",
      Strasse: "Im Gewerbegebiet Pesch 3",
      PLZ: "50767",
      Stadt: "Köln",
    },
    3100: {
      LadenId: 3100,
      Telefon: "+4923059298790",
      Strasse: "Siemensstraße 10",
      PLZ: "44579",
      Stadt: "Castrop-Rauxel",
    },
    16: {
      LadenId: 16,
      Telefon: "+499921904006",
      Strasse: "Auwiesenweg 36",
      PLZ: "94209",
      Stadt: "Regen",
    },
    21: {
      LadenId: 21,
      Telefon: "+497851889318",
      Strasse: "Straßburger Straße 7",
      PLZ: "77694",
      Stadt: "Kehl",
    },
    22: {
      LadenId: 22,
      Telefon: "+497114760520",
      Strasse: "Kirchheimer Straße 128",
      PLZ: "70619",
      Stadt: "Stuttgart",
    },
    36: {
      LadenId: 36,
      Telefon: "+496204912174",
      Strasse: "Robert-Schumann-Straße 1",
      PLZ: "68519",
      Stadt: "Viernheim",
    },
    38: {
      LadenId: 38,
      Telefon: "+496202126681",
      Strasse: "Hockenheimer Landstraße 4-8",
      PLZ: "68723",
      Stadt: "Schwetzingen",
    },
    46: {
      LadenId: 46,
      Telefon: "+493716661650",
      Strasse: "Markt 5",
      PLZ: "09111",
      Stadt: "Chemnitz",
    },
    47: {
      LadenId: 47,
      Telefon: "+496348919879",
      Strasse: "Hornbachstraße 17",
      PLZ: "76879",
      Stadt: "Bornheim",
    },
    48: {
      LadenId: 48,
      Telefon: "+497617910040",
      Strasse: "Günterstalstraße 46-48",
      PLZ: "79100",
      Stadt: "Freiburg im Breisgau",
    },
    53: {
      LadenId: 53,
      Telefon: "+498413704763",
      Strasse: "Frühlingstraße 35",
      PLZ: "85055",
      Stadt: "Ingolstadt",
    },
    67: {
      LadenId: 67,
      Telefon: "+499415997230",
      Strasse: "Friedenstraße 23",
      PLZ: "93053",
      Stadt: "Regensburg",
    },
    70: {
      LadenId: 70,
      Telefon: "+497116159242",
      Strasse: "Rotebühlstraße 98-100",
      PLZ: "70178",
      Stadt: "Stuttgart",
    },
    82: {
      LadenId: 82,
      Telefon: "+4961316693306",
      Strasse: "Große Bleiche 14-16",
      PLZ: "55116",
      Stadt: "Mainz",
    },
    84: {
      LadenId: 84,
      Telefon: "+497112204879",
      Strasse: "Ulmer Straße 2",
      PLZ: "70771",
      Stadt: "Leinfelden-Echterdingen",
    },
    91: {
      LadenId: 91,
      Telefon: "+497115050361",
      Strasse: "Unterländer Straße 70",
      PLZ: "70435",
      Stadt: "Stuttgart",
    },
    102: {
      LadenId: 102,
      Telefon: "+495371588655",
      Strasse: "Steinweg 44",
      PLZ: "38518",
      Stadt: "Gifhorn",
    },
    103: {
      LadenId: 103,
      Telefon: "+497112239641",
      Strasse: "Theodor-Heuss-Straße 11",
      PLZ: "70174",
      Stadt: "Stuttgart",
    },
    106: {
      LadenId: 106,
      Telefon: "+497135932321",
      Strasse: "Georg-Kohl-Straße 1",
      PLZ: "74336",
      Stadt: "Brackenheim",
    },
    114: {
      LadenId: 114,
      Telefon: "+497152976950",
      Strasse: "Leonberger Straße 98",
      PLZ: "71229",
      Stadt: "Leonberg",
    },
    116: {
      LadenId: 116,
      Telefon: "+4972311544568",
      Strasse: "Rastatter Straße 5/1",
      PLZ: "75179",
      Stadt: "Pforzheim",
    },
    121: {
      LadenId: 121,
      Telefon: "+498711436752",
      Strasse: "Am Alten Viehmarkt 5",
      PLZ: "84028",
      Stadt: "Landshut",
    },
    128: {
      LadenId: 128,
      Telefon: "+495622798435",
      Strasse: "Am Hospital 21-25",
      PLZ: "34560",
      Stadt: "Fritzlar",
    },
    131: {
      LadenId: 131,
      Telefon: "+496722406370",
      Strasse: "Geisenheimer Straße 70",
      PLZ: "65385",
      Stadt: "Rüdesheim am Rhein",
    },
    141: {
      LadenId: 141,
      Telefon: "+496211560480",
      Strasse: "F 1 2",
      PLZ: "68159",
      Stadt: "Mannheim",
    },
    144: {
      LadenId: 144,
      Telefon: "+499414613414",
      Strasse: "Im Gewerbepark C 5b",
      PLZ: "93059",
      Stadt: "Regensburg",
    },
    145: {
      LadenId: 145,
      Telefon: "+497612021970",
      Strasse: "Kaiser-Joseph-Straße 209",
      PLZ: "79098",
      Stadt: "Freiburg im Breisgau",
    },
    146: {
      LadenId: 146,
      Telefon: "+496861790976",
      Strasse: "Rieffstraße 7b",
      PLZ: "66663",
      Stadt: "Merzig",
    },
    148: {
      LadenId: 148,
      Telefon: "+496214310180",
      Strasse: "Seckenheimer Straße 62-64",
      PLZ: "68165",
      Stadt: "Mannheim",
    },
    152: {
      LadenId: 152,
      Telefon: "+497219553046",
      Strasse: "Rheinstraße 12",
      PLZ: "76185",
      Stadt: "Karlsruhe",
    },
    153: {
      LadenId: 153,
      Telefon: "+498022665780",
      Strasse: "Lindenstraße 2",
      PLZ: "83700",
      Stadt: "Rottach-Egern",
    },
    154: {
      LadenId: 154,
      Telefon: "+496241925073",
      Strasse: "Kämmererstraße 34",
      PLZ: "67547",
      Stadt: "Worms",
    },
    158: {
      LadenId: 158,
      Telefon: "+497243536120",
      Strasse: "Schillerstraße 1",
      PLZ: "76275",
      Stadt: "Ettlingen",
    },
    160: {
      LadenId: 160,
      Telefon: "+497116203624",
      Strasse: "Schwabstraße 93",
      PLZ: "70193",
      Stadt: "Stuttgart",
    },
    161: {
      LadenId: 161,
      Telefon: "+497345237675",
      Strasse: "Benzstraße 3",
      PLZ: "89129",
      Stadt: "Langenau",
    },
    164: {
      LadenId: 164,
      Telefon: "+497531914413",
      Strasse: "Kanzleistraße 11",
      PLZ: "78462",
      Stadt: "Konstanz",
    },
    169: {
      LadenId: 169,
      Telefon: "+497245911737",
      Strasse: "Auf den Heilberg 11",
      PLZ: "76448",
      Stadt: "Durmersheim",
    },
    175: {
      LadenId: 175,
      Telefon: "+497821983238",
      Strasse: "Schloßplatz 14",
      PLZ: "77933",
      Stadt: "Lahr",
    },
    178: {
      LadenId: 178,
      Telefon: "+4961311431997",
      Strasse: "Ludwigsstraße 11",
      PLZ: "55116",
      Stadt: "Mainz",
    },
    179: {
      LadenId: 179,
      Telefon: "+493914002647",
      Strasse: "Breiter Weg 23-26",
      PLZ: "39104",
      Stadt: "Magdeburg",
    },
    180: {
      LadenId: 180,
      Telefon: "+496131280271",
      Strasse: "Seppel-Glückert-Passage 10",
      PLZ: "55116",
      Stadt: "Mainz",
    },
    185: {
      LadenId: 185,
      Telefon: "+497219374209",
      Strasse: "Karlstraße 106",
      PLZ: "76137",
      Stadt: "Karlsruhe",
    },
    187: {
      LadenId: 187,
      Telefon: "+496818412873",
      Strasse: "Breslauer Straße 1a",
      PLZ: "66121",
      Stadt: "Saarbrücken",
    },
    188: {
      LadenId: 188,
      Telefon: "+496930850077",
      Strasse: "Main-Taunus-Zentrum 59",
      PLZ: "65843",
      Stadt: "Sulzbach (Taunus)",
    },
    194: {
      LadenId: 194,
      Telefon: "+497195940043",
      Strasse: "Marktstraße 41",
      PLZ: "71364",
      Stadt: "Winnenden",
    },
    195: {
      LadenId: 195,
      Telefon: "+49622161574",
      Strasse: "Rohrbacher Straße 3",
      PLZ: "69115",
      Stadt: "Heidelberg",
    },
    203: {
      LadenId: 203,
      Telefon: "+498122902430",
      Strasse: "Berghamer Straße 9",
      PLZ: "85435",
      Stadt: "Erding",
    },
    206: {
      LadenId: 206,
      Telefon: "+497751910630",
      Strasse: "Kaiserstraße 91",
      PLZ: "79761",
      Stadt: "Waldshut-Tiengen",
    },
    218: {
      LadenId: 218,
      Telefon: "+49622163110",
      Strasse: "Hertzstraße 1",
      PLZ: "69126",
      Stadt: "Heidelberg",
    },
    222: {
      LadenId: 222,
      Telefon: "+493512058836",
      Strasse: "Prager Straße 3a",
      PLZ: "01069",
      Stadt: "Dresden",
    },
    224: {
      LadenId: 224,
      Telefon: "+497112850991",
      Strasse: "Ostendstraße 66",
      PLZ: "70188",
      Stadt: "Stuttgart",
    },
    236: {
      LadenId: 236,
      Telefon: "+496251680611",
      Strasse: "Hauptstraße 17-19",
      PLZ: "64625",
      Stadt: "Bensheim",
    },
    237: {
      LadenId: 237,
      Telefon: "+498954403171",
      Strasse: "Lindwurmstraße 95",
      PLZ: "80337",
      Stadt: "München",
    },
    238: {
      LadenId: 238,
      Telefon: "+498961203407",
      Strasse: "Grünwalder Weg 22",
      PLZ: "82008",
      Stadt: "Unterhaching",
    },
    240: {
      LadenId: 240,
      Telefon: "+496233319055",
      Strasse: "Rathausplatz 7",
      PLZ: "67227",
      Stadt: "Frankenthal (Pfalz)",
    },
    241: {
      LadenId: 241,
      Telefon: "+493655510894",
      Strasse: "Dornaer Straße 6",
      PLZ: "07545",
      Stadt: "Gera",
    },
    251: {
      LadenId: 251,
      Telefon: "+495115193608",
      Strasse: "Erich-Ollenhauer-Straße 7",
      PLZ: "30851",
      Stadt: "Langenhagen",
    },
    254: {
      LadenId: 254,
      Telefon: "+497118875571",
      Strasse: "Pforzheimer Straße 360",
      PLZ: "70499",
      Stadt: "Stuttgart",
    },
    263: {
      LadenId: 263,
      Telefon: "+496332903054",
      Strasse: "Hauptstr. 57",
      PLZ: "66482",
      Stadt: "Zweibrücken",
    },
    270: {
      LadenId: 270,
      Telefon: "+49627191050",
      Strasse: "Leopoldsplatz 4",
      PLZ: "69412",
      Stadt: "Eberbach",
    },
    273: {
      LadenId: 273,
      Telefon: "+496217992050",
      Strasse: "Hauptstraße 92",
      PLZ: "68259",
      Stadt: "Mannheim",
    },
    275: {
      LadenId: 275,
      Telefon: "+497219683033",
      Strasse: "Neisser Straße 12",
      PLZ: "76139",
      Stadt: "Karlsruhe",
    },
    278: {
      LadenId: 278,
      Telefon: "+49831511410",
      Strasse: "Klostersteige 20",
      PLZ: "87435",
      Stadt: "Kempten (Allgäu)",
    },
    279: {
      LadenId: 279,
      Telefon: "+498985466365",
      Strasse: "Bahnhofstraße 103",
      PLZ: "82166",
      Stadt: "Gräfelfing",
    },
    280: {
      LadenId: 280,
      Telefon: "+498974516904",
      Strasse: "Forstenrieder Allee 61",
      PLZ: "81476",
      Stadt: "München",
    },
    287: {
      LadenId: 287,
      Telefon: "+496841120041",
      Strasse: "Talstraße 34",
      PLZ: "66424",
      Stadt: "Homburg",
    },
    292: {
      LadenId: 292,
      Telefon: "+497219203028",
      Strasse: "Kaiserstraße 152-154",
      PLZ: "76133",
      Stadt: "Karlsruhe",
    },
    295: {
      LadenId: 295,
      Telefon: "+49816143377",
      Strasse: "Untere Hauptstraße 23",
      PLZ: "85354",
      Stadt: "Freising",
    },
    302: {
      LadenId: 302,
      Telefon: "+496021440862",
      Strasse: "Herstallstraße 38-40",
      PLZ: "63739",
      Stadt: "Aschaffenburg",
    },
    304: {
      LadenId: 304,
      Telefon: "+497641915040",
      Strasse: "Theodor-Ludwig-Straße 11",
      PLZ: "79312",
      Stadt: "Emmendingen",
    },
    309: {
      LadenId: 309,
      Telefon: "+496218413210",
      Strasse: "Friedrichstraße 21",
      PLZ: "68199",
      Stadt: "Mannheim",
    },
    313: {
      LadenId: 313,
      Telefon: "+497131930151",
      Strasse: "Kaiserstraße 20",
      PLZ: "74072",
      Stadt: "Heilbronn",
    },
    318: {
      LadenId: 318,
      Telefon: "+497392150960",
      Strasse: "Biberacher Straße 67",
      PLZ: "88471",
      Stadt: "Laupheim",
    },
    319: {
      LadenId: 319,
      Telefon: "+497042374665",
      Strasse: "Hertzstraße 9",
      PLZ: "71665",
      Stadt: "Vaihingen an der Enz",
    },
    320: {
      LadenId: 320,
      Telefon: "+498976700071",
      Strasse: "Plinganserstraße 33",
      PLZ: "81369",
      Stadt: "München",
    },
    324: {
      LadenId: 324,
      Telefon: "+49812292003",
      Strasse: "Landshuter Straße 6",
      PLZ: "85435",
      Stadt: "Erding",
    },
    325: {
      LadenId: 325,
      Telefon: "+498954216960",
      Strasse: "Augustenstraße 67",
      PLZ: "80333",
      Stadt: "München",
    },
    331: {
      LadenId: 331,
      Telefon: "+497219703026",
      Strasse: "Dürerstraße 3",
      PLZ: "76149",
      Stadt: "Karlsruhe",
    },
    333: {
      LadenId: 333,
      Telefon: "+497219414043",
      Strasse: "Pfinztalstraße 46-50",
      PLZ: "76227",
      Stadt: "Karlsruhe",
    },
    335: {
      LadenId: 335,
      Telefon: "+492861903555",
      Strasse: "Am Vennehof 2",
      PLZ: "46325",
      Stadt: "Borken",
    },
    336: {
      LadenId: 336,
      Telefon: "+493464276947",
      Strasse: "Riethweg 15",
      PLZ: "06526",
      Stadt: "Sangerhausen",
    },
    346: {
      LadenId: 346,
      Telefon: "+49622161584",
      Strasse: "Sofienstraße 7a",
      PLZ: "69115",
      Stadt: "Heidelberg",
    },
    351: {
      LadenId: 351,
      Telefon: "+497141970096",
      Strasse: "Bahnhofstraße 12",
      PLZ: "71638",
      Stadt: "Ludwigsburg",
    },
    355: {
      LadenId: 355,
      Telefon: "+496341919130",
      Strasse: "Ostbahnstraße 32",
      PLZ: "76829",
      Stadt: "Landau in der Pfalz",
    },
    358: {
      LadenId: 358,
      Telefon: "+496341919124",
      Strasse: "Kronstraße 9-10",
      PLZ: "76829",
      Stadt: "Landau in der Pfalz",
    },
    361: {
      LadenId: 361,
      Telefon: "+496042952476",
      Strasse: "An der Saline 8",
      PLZ: "63654",
      Stadt: "Büdingen",
    },
    365: {
      LadenId: 365,
      Telefon: "+496232629148",
      Strasse: "Maximilianstraße 38",
      PLZ: "67346",
      Stadt: "Speyer",
    },
    367: {
      LadenId: 367,
      Telefon: "+49844189341",
      Strasse: "Joseph-Fraunhofer-Straße 24B",
      PLZ: "85276",
      Stadt: "Pfaffenhofen a.d. Ilm",
    },
    372: {
      LadenId: 372,
      Telefon: "+49821249002",
      Strasse: "Bahnhofstraße 13",
      PLZ: "86368",
      Stadt: "Gersthofen",
    },
    373: {
      LadenId: 373,
      Telefon: "+499413995037",
      Strasse: "Rennweg 12a",
      PLZ: "93049",
      Stadt: "Regensburg",
    },
    374: {
      LadenId: 374,
      Telefon: "+496053618747",
      Strasse: "Main-Kinzig-Straße 32a",
      PLZ: "63607",
      Stadt: "Wächtersbach",
    },
    375: {
      LadenId: 375,
      Telefon: "+496731496590",
      Strasse: "Karl-Heinz-Kipp-Straße 30-32",
      PLZ: "55232",
      Stadt: "Alzey",
    },
    388: {
      LadenId: 388,
      Telefon: "+497222104761",
      Strasse: "Niederwaldstraße 21/1",
      PLZ: "76437",
      Stadt: "Rastatt",
    },
    389: {
      LadenId: 389,
      Telefon: "+498938346936",
      Strasse: "Hohenzollernstraße 48",
      PLZ: "80801",
      Stadt: "München",
    },
    393: {
      LadenId: 393,
      Telefon: "+497819709079",
      Strasse: "Hauptstraße 63",
      PLZ: "77652",
      Stadt: "Offenburg",
    },
    400: {
      LadenId: 400,
      Telefon: "+493419617596",
      Strasse: "Willy-Brandt-Platz 7",
      PLZ: "04109",
      Stadt: "Leipzig",
    },
    402: {
      LadenId: 402,
      Telefon: "+497274702458",
      Strasse: "Mainzer Straße 11",
      PLZ: "76726",
      Stadt: "Germersheim",
    },
    406: {
      LadenId: 406,
      Telefon: "+492739301644",
      Strasse: "Marktplatz 6",
      PLZ: "57234",
      Stadt: "Wilnsdorf",
    },
    407: {
      LadenId: 407,
      Telefon: "+498962834071",
      Strasse: "Ollenhauerstraße 6",
      PLZ: "81737",
      Stadt: "München",
    },
    412: {
      LadenId: 412,
      Telefon: "+493722500096",
      Strasse: "Ringstr. 21",
      PLZ: "09247",
      Stadt: "Chemnitz",
    },
    418: {
      LadenId: 418,
      Telefon: "+495112282440",
      Strasse: "Lister Meile 77-79",
      PLZ: "30161",
      Stadt: "Hannover",
    },
    419: {
      LadenId: 419,
      Telefon: "+493512523030",
      Strasse: "Enderstraße 59",
      PLZ: "01277",
      Stadt: "Dresden",
    },
    423: {
      LadenId: 423,
      Telefon: "+493617491135",
      Strasse: "Nordhäuser Straße 73t",
      PLZ: "99091",
      Stadt: "Erfurt",
    },
    425: {
      LadenId: 425,
      Telefon: "+492821806089",
      Strasse: "Hoffmannallee 33a",
      PLZ: "47533",
      Stadt: "Kleve",
    },
    428: {
      LadenId: 428,
      Telefon: "+49371441940",
      Strasse: "Thomas-Mann-Platz 1",
      PLZ: "09130",
      Stadt: "Chemnitz",
    },
    430: {
      LadenId: 430,
      Telefon: "+499261501111",
      Strasse: "Hammermühlweg 9",
      PLZ: "96317",
      Stadt: "Kronach",
    },
    434: {
      LadenId: 434,
      Telefon: "+496218283046",
      Strasse: "Neckarauer Straße 126",
      PLZ: "68163",
      Stadt: "Mannheim",
    },
    439: {
      LadenId: 439,
      Telefon: "+49352238602",
      Strasse: "Frauenmarkt 26-28",
      PLZ: "01558",
      Stadt: "Großenhain",
    },
    444: {
      LadenId: 444,
      Telefon: "+493514721210",
      Strasse: "Lockwitzer Straße 1",
      PLZ: "01219",
      Stadt: "Dresden",
    },
    445: {
      LadenId: 445,
      Telefon: "+497211619752",
      Strasse: "Raiherwiesenstraße 36",
      PLZ: "76227",
      Stadt: "Karlsruhe",
    },
    449: {
      LadenId: 449,
      Telefon: "+49371300568",
      Strasse: "Reichsstraße 58",
      PLZ: "09112",
      Stadt: "Chemnitz",
    },
    450: {
      LadenId: 450,
      Telefon: "+497252535297",
      Strasse: "Melanchthonstraße 114",
      PLZ: "75015",
      Stadt: "Bretten",
    },
    453: {
      LadenId: 453,
      Telefon: "+493414804023",
      Strasse: "Lindenauer Markt 12",
      PLZ: "04177",
      Stadt: "Leipzig",
    },
    454: {
      LadenId: 454,
      Telefon: "+4976211610483",
      Strasse: "Tumringer Straße 186",
      PLZ: "79539",
      Stadt: "Lörrach",
    },
    456: {
      LadenId: 456,
      Telefon: "+49367133182",
      Strasse: "Obere Straße 6-10",
      PLZ: "07318",
      Stadt: "Saalfeld/Saale",
    },
    458: {
      LadenId: 458,
      Telefon: "+496331143470",
      Strasse: "Zweibrücker Straße 149",
      PLZ: "66953",
      Stadt: "Pirmasens",
    },
    462: {
      LadenId: 462,
      Telefon: "+492753508215",
      Strasse: "Mühlenweg 16",
      PLZ: "57339",
      Stadt: "Erndtebrück",
    },
    467: {
      LadenId: 467,
      Telefon: "+492664912258",
      Strasse: "Konnwiese 6",
      PLZ: "56477",
      Stadt: "Rennerod",
    },
    468: {
      LadenId: 468,
      Telefon: "+498071103812",
      Strasse: "Staudhamer Feld 2a",
      PLZ: "83512",
      Stadt: "Wasserburg a. Inn",
    },
    473: {
      LadenId: 473,
      Telefon: "+498161885356",
      Strasse: "Erdinger Straße 143",
      PLZ: "85356",
      Stadt: "Freising",
    },
    499: {
      LadenId: 499,
      Telefon: "+493451219152",
      Strasse: "Südstadtring 90",
      PLZ: "06128",
      Stadt: "Halle (Saale)",
    },
    500: {
      LadenId: 500,
      Telefon: "+493583795844",
      Strasse: "Bautzner Straße 2",
      PLZ: "02763",
      Stadt: "Zittau",
    },
    501: {
      LadenId: 501,
      Telefon: "+498990939449",
      Strasse: "Feringastraße 4",
      PLZ: "85774",
      Stadt: "Unterföhring",
    },
    503: {
      LadenId: 503,
      Telefon: "+493641443424",
      Strasse: "Teichgraben 1",
      PLZ: "07743",
      Stadt: "Jena",
    },
    504: {
      LadenId: 504,
      Telefon: "+4997213880439",
      Strasse: "Spitalstraße 21",
      PLZ: "97421",
      Stadt: "Schweinfurt",
    },
    510: {
      LadenId: 510,
      Telefon: "+498948997027",
      Strasse: "Rosenheimer Straße 30-32",
      PLZ: "81669",
      Stadt: "München",
    },
    515: {
      LadenId: 515,
      Telefon: "+498654670118",
      Strasse: "Sägewerkstraße 22",
      PLZ: "83395",
      Stadt: "Freilassing",
    },
    516: {
      LadenId: 516,
      Telefon: "+498061491651",
      Strasse: "Grassinger Straße 16",
      PLZ: "83043",
      Stadt: "Bad Aibling",
    },
    517: {
      LadenId: 517,
      Telefon: "+496998535230",
      Strasse: "Hartmannstraße 16",
      PLZ: "63071",
      Stadt: "Offenbach am Main",
    },
    518: {
      LadenId: 518,
      Telefon: "+497216238144",
      Strasse: "Käppelestraße 5a",
      PLZ: "76131",
      Stadt: "Karlsruhe",
    },
    520: {
      LadenId: 520,
      Telefon: "+492712316281",
      Strasse: "Bahnhofstraße 5",
      PLZ: "57072",
      Stadt: "Siegen",
    },
    522: {
      LadenId: 522,
      Telefon: "+496561949314",
      Strasse: "Saarstraße 35",
      PLZ: "54634",
      Stadt: "Bitburg",
    },
    525: {
      LadenId: 525,
      Telefon: "+499353981003",
      Strasse: "Würzburger Straße 3",
      PLZ: "97753",
      Stadt: "Karlstadt",
    },
    527: {
      LadenId: 527,
      Telefon: "+496343931527",
      Strasse: "Im Weidfeld 10a",
      PLZ: "76887",
      Stadt: "Bad Bergzabern",
    },
    529: {
      LadenId: 529,
      Telefon: "+496313114540",
      Strasse: "Königstraße 121",
      PLZ: "67655",
      Stadt: "Kaiserslautern",
    },
    534: {
      LadenId: 534,
      Telefon: "+496281560715",
      Strasse: "Hochstadtstraße 57",
      PLZ: "74722",
      Stadt: "Buchen (Odenwald)",
    },
    536: {
      LadenId: 536,
      Telefon: "+497131176674",
      Strasse: "Etzelstraße 38",
      PLZ: "74076",
      Stadt: "Heilbronn",
    },
    544: {
      LadenId: 544,
      Telefon: "+499119839600",
      Strasse: "Glogauer Straße 30-38",
      PLZ: "90473",
      Stadt: "Nürnberg",
    },
    545: {
      LadenId: 545,
      Telefon: "+493501467376",
      Strasse: "Lohmener Straße 13",
      PLZ: "01796",
      Stadt: "Pirna",
    },
    548: {
      LadenId: 548,
      Telefon: "+496821149946",
      Strasse: "Bliesstraße 66-68",
      PLZ: "66538",
      Stadt: "Neunkirchen",
    },
    550: {
      LadenId: 550,
      Telefon: "+496359924880",
      Strasse: "Kirchheimer Straße 63",
      PLZ: "67269",
      Stadt: "Grünstadt",
    },
    554: {
      LadenId: 554,
      Telefon: "+498261737645",
      Strasse: "Allgäuer Straße 32",
      PLZ: "87719",
      Stadt: "Mindelheim",
    },
    556: {
      LadenId: 556,
      Telefon: "+492801981007",
      Strasse: "Sonsbecker Straße 21A",
      PLZ: "46509",
      Stadt: "Xanten",
    },
    558: {
      LadenId: 558,
      Telefon: "+497616006998",
      Strasse: "Zähringer Straße 338",
      PLZ: "79108",
      Stadt: "Freiburg im Breisgau",
    },
    559: {
      LadenId: 559,
      Telefon: "+497127934788",
      Strasse: "Ulrich-Gminder-Straße 2/1",
      PLZ: "72654",
      Stadt: "Neckartenzlingen",
    },
    562: {
      LadenId: 562,
      Telefon: "+497431934122",
      Strasse: "Theodor-Groz-Straße 72",
      PLZ: "72458",
      Stadt: "Albstadt",
    },
    563: {
      LadenId: 563,
      Telefon: "+497202405820",
      Strasse: "Benzstraße 1-7",
      PLZ: "76307",
      Stadt: "Karlsbad",
    },
    564: {
      LadenId: 564,
      Telefon: "+496887888404",
      Strasse: "Hoher Staden 2",
      PLZ: "66839",
      Stadt: "Schmelz",
    },
    565: {
      LadenId: 565,
      Telefon: "+496441952303",
      Strasse: "Westendstraße 3",
      PLZ: "35578",
      Stadt: "Wetzlar",
    },
    569: {
      LadenId: 569,
      Telefon: "+499117809873",
      Strasse: "Waldstraße 99",
      PLZ: "90763",
      Stadt: "Fürth",
    },
    570: {
      LadenId: 570,
      Telefon: "+497361923744",
      Strasse: "Daimlerstraße 16",
      PLZ: "73431",
      Stadt: "Aalen",
    },
    574: {
      LadenId: 574,
      Telefon: "+496519668598",
      Strasse: "Kohlenstraße 62",
      PLZ: "54296",
      Stadt: "Trier",
    },
    576: {
      LadenId: 576,
      Telefon: "+496322791106",
      Strasse: "Bruchstraße 1d",
      PLZ: "67098",
      Stadt: "Bad Dürkheim",
    },
    577: {
      LadenId: 577,
      Telefon: "+498761726623",
      Strasse: "Degernpoint S 2",
      PLZ: "85368",
      Stadt: "Moosburg a.d.Isar",
    },
    580: {
      LadenId: 580,
      Telefon: "+496222306616",
      Strasse: "Frankenäcker 2",
      PLZ: "69231",
      Stadt: "Rauenberg",
    },
    587: {
      LadenId: 587,
      Telefon: "+496831128367",
      Strasse: "Im Hader 5",
      PLZ: "66740",
      Stadt: "Saarlouis",
    },
    594: {
      LadenId: 594,
      Telefon: "+497623750702",
      Strasse: "Oberrheinplatz 6-8",
      PLZ: "79618",
      Stadt: "Rheinfelden (Baden)",
    },
    595: {
      LadenId: 595,
      Telefon: "+496155822686",
      Strasse: "Feldstraße 14",
      PLZ: "64347",
      Stadt: "Griesheim",
    },
    606: {
      LadenId: 606,
      Telefon: "+496894383889",
      Strasse: "Dudweilerstraße 101-103",
      PLZ: "66386",
      Stadt: "St. Ingbert",
    },
    609: {
      LadenId: 609,
      Telefon: "+497919597263",
      Strasse: "Daimlerstraße 66",
      PLZ: "74545",
      Stadt: "Michelfeld",
    },
    612: {
      LadenId: 612,
      Telefon: "+498431430720",
      Strasse: "Am Südpark 7",
      PLZ: "86633",
      Stadt: "Neuburg a.d. Donau",
    },
    613: {
      LadenId: 613,
      Telefon: "+498948950171",
      Strasse: "Innere Wiener Straße 50",
      PLZ: "81667",
      Stadt: "München",
    },
    618: {
      LadenId: 618,
      Telefon: "+497115594520",
      Strasse: "Marktstraße 17",
      PLZ: "70372",
      Stadt: "Stuttgart",
    },
    621: {
      LadenId: 621,
      Telefon: "+497116747441",
      Strasse: "Epplestraße 6",
      PLZ: "70597",
      Stadt: "Stuttgart",
    },
    622: {
      LadenId: 622,
      Telefon: "+497132300084",
      Strasse: "Hohenloher Straße 2",
      PLZ: "74172",
      Stadt: "Neckarsulm",
    },
    628: {
      LadenId: 628,
      Telefon: "+499614703361",
      Strasse: "Untere Bauscherstraße 20",
      PLZ: "92637",
      Stadt: "Weiden i. d. OPf.",
    },
    632: {
      LadenId: 632,
      Telefon: "+496841755509",
      Strasse: "Berliner Straße 122",
      PLZ: "66424",
      Stadt: "Homburg",
    },
    635: {
      LadenId: 635,
      Telefon: "+497151600027",
      Strasse: "Kalkofenstraße 5",
      PLZ: "71384",
      Stadt: "Weinstadt",
    },
    636: {
      LadenId: 636,
      Telefon: "+498721912809",
      Strasse: "Schellenbruckplatz 49",
      PLZ: "84307",
      Stadt: "Eggenfelden",
    },
    638: {
      LadenId: 638,
      Telefon: "+497222938155",
      Strasse: "Kaiserstraße 20",
      PLZ: "76437",
      Stadt: "Rastatt",
    },
    639: {
      LadenId: 639,
      Telefon: "+497033691535",
      Strasse: "Siemensstraße 4",
      PLZ: "71263",
      Stadt: "Weil der Stadt",
    },
    643: {
      LadenId: 643,
      Telefon: "+496103947026",
      Strasse: "Kurt-Schumacher-Ring 1",
      PLZ: "63329",
      Stadt: "Egelsbach",
    },
    644: {
      LadenId: 644,
      Telefon: "+497171929446",
      Strasse: "Lorcher Straße 165",
      PLZ: "73529",
      Stadt: "Schwäbisch Gmünd",
    },
    646: {
      LadenId: 646,
      Telefon: "+498669850877",
      Strasse: "Waginger Straße 5",
      PLZ: "83301",
      Stadt: "Traunreut",
    },
    649: {
      LadenId: 649,
      Telefon: "+498331496707",
      Strasse: "Fraunhoferstraße 8",
      PLZ: "87700",
      Stadt: "Memmingen",
    },
    650: {
      LadenId: 650,
      Telefon: "+496151670283",
      Strasse: "Rudolf-Diesel-Straße 28b",
      PLZ: "64331",
      Stadt: "Weiterstadt",
    },
    653: {
      LadenId: 653,
      Telefon: "+497819903855",
      Strasse: "Marlener Str. 11",
      PLZ: "77656",
      Stadt: "Offenburg",
    },
    655: {
      LadenId: 655,
      Telefon: "+497171181243",
      Strasse: "Vordere Schmiedgasse 28",
      PLZ: "73525",
      Stadt: "Schwäbisch Gmünd",
    },
    656: {
      LadenId: 656,
      Telefon: "+497425325000",
      Strasse: "In Grubäcker 5",
      PLZ: "78647",
      Stadt: "Trossingen",
    },
    657: {
      LadenId: 657,
      Telefon: "+497614768713",
      Strasse: "Carl-Kistner-Straße 3",
      PLZ: "79115",
      Stadt: "Freiburg im Breisgau",
    },
    660: {
      LadenId: 660,
      Telefon: "+497667942023",
      Strasse: "Zum Kaiserstuhl 10",
      PLZ: "79206",
      Stadt: "Breisach am Rhein",
    },
    663: {
      LadenId: 663,
      Telefon: "+492563204230",
      Strasse: "Stegerstraße 7",
      PLZ: "48703",
      Stadt: "Stadtlohn",
    },
    666: {
      LadenId: 666,
      Telefon: "+497142980760",
      Strasse: "Talstraße 4",
      PLZ: "74321",
      Stadt: "Bietigheim-Bissingen",
    },
    667: {
      LadenId: 667,
      Telefon: "+496119103940",
      Strasse: "Langgasse 19",
      PLZ: "65183",
      Stadt: "Wiesbaden",
    },
    671: {
      LadenId: 671,
      Telefon: "+497031954319",
      Strasse: "Mercedesstraße 12",
      PLZ: "71063",
      Stadt: "Sindelfingen",
    },
    672: {
      LadenId: 672,
      Telefon: "+496151997970",
      Strasse: "Ludwigstraße 10a",
      PLZ: "64283",
      Stadt: "Darmstadt",
    },
    676: {
      LadenId: 676,
      Telefon: "+492741933138",
      Strasse: "Wilhelmstraße 56",
      PLZ: "57518",
      Stadt: "Betzdorf",
    },
    678: {
      LadenId: 678,
      Telefon: "+496116900225",
      Strasse: "Hagenauer Straße 23",
      PLZ: "65203",
      Stadt: "Wiesbaden",
    },
    680: {
      LadenId: 680,
      Telefon: "+498947084566",
      Strasse: "Einsteinstraße 130",
      PLZ: "81675",
      Stadt: "München",
    },
    682: {
      LadenId: 682,
      Telefon: "+496514361450",
      Strasse: "Brotstraße 30/31",
      PLZ: "54290",
      Stadt: "Trier",
    },
    691: {
      LadenId: 691,
      Telefon: "+492712382491",
      Strasse: "Am Bahnhof 40",
      PLZ: "57072",
      Stadt: "Siegen",
    },
    692: {
      LadenId: 692,
      Telefon: "+497726977859",
      Strasse: "Carl-Friedrich-Benz-Straße 26",
      PLZ: "78073",
      Stadt: "Bad Dürrheim",
    },
    710: {
      LadenId: 710,
      Telefon: "+496051473437",
      Strasse: "Grasweg 2",
      PLZ: "63584",
      Stadt: "Gründau",
    },
    711: {
      LadenId: 711,
      Telefon: "+499831613295",
      Strasse: "Weißenburger Straße 87",
      PLZ: "91710",
      Stadt: "Gunzenhausen",
    },
    713: {
      LadenId: 713,
      Telefon: "+498418815747",
      Strasse: "Permoserstraße 88",
      PLZ: "85057",
      Stadt: "Ingolstadt",
    },
    757: {
      LadenId: 757,
      Telefon: "+498212082552",
      Strasse: "Daimlerstraße 13-15",
      PLZ: "86356",
      Stadt: "Neusäß",
    },
    763: {
      LadenId: 763,
      Telefon: "+4960744073628",
      Strasse: "Ober-Rodener Straße 15",
      PLZ: "63322",
      Stadt: "Rödermark",
    },
    765: {
      LadenId: 765,
      Telefon: "+497731798108",
      Strasse: "August-Ruf-Straße 26",
      PLZ: "78224",
      Stadt: "Singen (Hohentwiel)",
    },
    766: {
      LadenId: 766,
      Telefon: "+499951599424",
      Strasse: "Bahnhofstraße 2",
      PLZ: "94405",
      Stadt: "Landau a. d. Isar",
    },
    767: {
      LadenId: 767,
      Telefon: "+497121678985",
      Strasse: "Wannweiler Straße 48",
      PLZ: "72138",
      Stadt: "Kirchentellinsfurt",
    },
    771: {
      LadenId: 771,
      Telefon: "+493531719618",
      Strasse: "Berliner Straße 17",
      PLZ: "03238",
      Stadt: "Finsterwalde",
    },
    772: {
      LadenId: 772,
      Telefon: "+497041818355",
      Strasse: "Bahnhofstraße 71",
      PLZ: "75417",
      Stadt: "Mühlacker",
    },
    773: {
      LadenId: 773,
      Telefon: "+499067091600",
      Strasse: "Kaiser-Karl-Straße 6-8",
      PLZ: "86609",
      Stadt: "Donauwörth",
    },
    777: {
      LadenId: 777,
      Telefon: "+4976211611186",
      Strasse: "Hauptstraße 435",
      PLZ: "79576",
      Stadt: "Weil am Rhein",
    },
    783: {
      LadenId: 783,
      Telefon: "+498323989799",
      Strasse: "Sonthofener Straße 62",
      PLZ: "87509",
      Stadt: "Immenstadt i. Allgäu",
    },
    784: {
      LadenId: 784,
      Telefon: "+4991314002786",
      Strasse: "Cumianastraße 1",
      PLZ: "91058",
      Stadt: "Erlangen",
    },
    787: {
      LadenId: 787,
      Telefon: "+496441897458",
      Strasse: "Walbergraben 4",
      PLZ: "35614",
      Stadt: "Aßlar",
    },
    789: {
      LadenId: 789,
      Telefon: "+497307800519",
      Strasse: "Danziger Straße 15",
      PLZ: "89250",
      Stadt: "Senden",
    },
    793: {
      LadenId: 793,
      Telefon: "+497255718210",
      Strasse: "Heidelberger Straße 9c",
      PLZ: "76676",
      Stadt: "Graben-Neudorf",
    },
    794: {
      LadenId: 794,
      Telefon: "+496132710890",
      Strasse: "Binger Straße 208",
      PLZ: "55218",
      Stadt: "Ingelheim am Rhein",
    },
    799: {
      LadenId: 799,
      Telefon: "+498121980394",
      Strasse: "Alte Gruber Straße 2-6",
      PLZ: "85586",
      Stadt: "Poing",
    },
    801: {
      LadenId: 801,
      Telefon: "+497115502168",
      Strasse: "Berliner Straße 2",
      PLZ: "73728",
      Stadt: "Esslingen am Neckar",
    },
    803: {
      LadenId: 803,
      Telefon: "+496321386607",
      Strasse: "Hauptstraße 106-108",
      PLZ: "67433",
      Stadt: "Neustadt an der Weinstraße",
    },
    805: {
      LadenId: 805,
      Telefon: "+497732941957",
      Strasse: "Markthallenstraße 1",
      PLZ: "78315",
      Stadt: "Radolfzell am Bodensee",
    },
    806: {
      LadenId: 806,
      Telefon: "+492831134192",
      Strasse: "Markt 30-31",
      PLZ: "47608",
      Stadt: "Geldern",
    },
    811: {
      LadenId: 811,
      Telefon: "+495258936841",
      Strasse: "Geseker Straße 10",
      PLZ: "33154",
      Stadt: "Salzkotten",
    },
    813: {
      LadenId: 813,
      Telefon: "+499115192517",
      Strasse: "Äußere Bayreuther Straße 80",
      PLZ: "90491",
      Stadt: "Nürnberg",
    },
    819: {
      LadenId: 819,
      Telefon: "+492871294461",
      Strasse: "Neutorplatz 4",
      PLZ: "46395",
      Stadt: "Bocholt",
    },
    820: {
      LadenId: 820,
      Telefon: "+497154177610",
      Strasse: "Kimry-Platz 8",
      PLZ: "70806",
      Stadt: "Kornwestheim",
    },
    821: {
      LadenId: 821,
      Telefon: "+498213463207",
      Strasse: "Willy-Brandt-Platz 1",
      PLZ: "86153",
      Stadt: "Augsburg",
    },
    822: {
      LadenId: 822,
      Telefon: "+496428448153",
      Strasse: "Teichwiesenstraße 1",
      PLZ: "35260",
      Stadt: "Stadtallendorf",
    },
    824: {
      LadenId: 824,
      Telefon: "+496241970294",
      Strasse: "Mainzer Straße 94-96",
      PLZ: "67547",
      Stadt: "Worms",
    },
    830: {
      LadenId: 830,
      Telefon: "+497541583571",
      Strasse: "Rheinstraße 3",
      PLZ: "88046",
      Stadt: "Friedrichshafen",
    },
    832: {
      LadenId: 832,
      Telefon: "+498930759883",
      Strasse: "Karl-Theodor-Straße 65",
      PLZ: "80803",
      Stadt: "München",
    },
    835: {
      LadenId: 835,
      Telefon: "+497531692641",
      Strasse: "Wollmatinger Straße 72",
      PLZ: "78467",
      Stadt: "Konstanz",
    },
    842: {
      LadenId: 842,
      Telefon: "+499443700893",
      Strasse: "Straubinger Straße 11",
      PLZ: "93326",
      Stadt: "Abensberg",
    },
    844: {
      LadenId: 844,
      Telefon: "+497181254445",
      Strasse: "Karlstraße 15",
      PLZ: "73614",
      Stadt: "Schorndorf",
    },
    846: {
      LadenId: 846,
      Telefon: "+493661455996",
      Strasse: "Puschkinplatz 10-14",
      PLZ: "07973",
      Stadt: "Greiz",
    },
    848: {
      LadenId: 848,
      Telefon: "+496761917265",
      Strasse: "Sonnenhof 2",
      PLZ: "55469",
      Stadt: "Simmern",
    },
    849: {
      LadenId: 849,
      Telefon: "+498321618632",
      Strasse: "Samuel-Bachmann-Straße 1",
      PLZ: "87527",
      Stadt: "Sonthofen",
    },
    854: {
      LadenId: 854,
      Telefon: "+493414203415",
      Strasse: "Ludwigsburger Straße 9",
      PLZ: "04209",
      Stadt: "Leipzig",
    },
    856: {
      LadenId: 856,
      Telefon: "+492851961160",
      Strasse: "Dellstrasse 22",
      PLZ: "46459",
      Stadt: "Rees",
    },
    860: {
      LadenId: 860,
      Telefon: "+496021369372",
      Strasse: "Industriestraße 2",
      PLZ: "63768",
      Stadt: "Hösbach",
    },
    868: {
      LadenId: 868,
      Telefon: "+499161663639",
      Strasse: "Riedweg 10",
      PLZ: "91413",
      Stadt: "Neustadt a.d. Aisch",
    },
    869: {
      LadenId: 869,
      Telefon: "+497071256707",
      Strasse: "Nonnengasse 14",
      PLZ: "72070",
      Stadt: "Tübingen",
    },
    871: {
      LadenId: 871,
      Telefon: "+493591530024",
      Strasse: "Reichenstraße 18",
      PLZ: "02625",
      Stadt: "Bautzen",
    },
    876: {
      LadenId: 876,
      Telefon: "+496032347616",
      Strasse: "Friedrich-Stoll-Straße 2",
      PLZ: "61231",
      Stadt: "Bad Nauheim",
    },
    879: {
      LadenId: 879,
      Telefon: "+493941567715",
      Strasse: "Breiter Weg 22",
      PLZ: "38820",
      Stadt: "Halberstadt",
    },
    883: {
      LadenId: 883,
      Telefon: "+497231139765",
      Strasse: "Leopoldstraße 6",
      PLZ: "75172",
      Stadt: "Pforzheim",
    },
    884: {
      LadenId: 884,
      Telefon: "+495617016740",
      Strasse: "Königsplatz 61",
      PLZ: "34117",
      Stadt: "Kassel",
    },
    885: {
      LadenId: 885,
      Telefon: "+493741276900",
      Strasse: "Postplatz 1",
      PLZ: "08523",
      Stadt: "Plauen",
    },
    888: {
      LadenId: 888,
      Telefon: "+499221878977",
      Strasse: "Albert-Ruckdeschel-Straße 27",
      PLZ: "95326",
      Stadt: "Kulmbach",
    },
    895: {
      LadenId: 895,
      Telefon: "+498131276074",
      Strasse: "Münchner Straße 32",
      PLZ: "85221",
      Stadt: "Dachau",
    },
    897: {
      LadenId: 897,
      Telefon: "+499932959780",
      Strasse: "Plattlinger Straße 57",
      PLZ: "94486",
      Stadt: "Osterhofen",
    },
    899: {
      LadenId: 899,
      Telefon: "+493514842789",
      Strasse: "Webergasse 1",
      PLZ: "01067",
      Stadt: "Dresden",
    },
    900: {
      LadenId: 900,
      Telefon: "+495621752114",
      Strasse: "Itzelstraße 32 A",
      PLZ: "34537",
      Stadt: "Bad Wildungen",
    },
    901: {
      LadenId: 901,
      Telefon: "+4972212761800",
      Strasse: "Wilhelm-Drapp-Straße 23",
      PLZ: "76532",
      Stadt: "Baden-Baden",
    },
    902: {
      LadenId: 902,
      Telefon: "+497611518196",
      Strasse: "Schwarzwaldstraße 78",
      PLZ: "79117",
      Stadt: "Freiburg im Breisgau",
    },
    903: {
      LadenId: 903,
      Telefon: "+496613806315",
      Strasse: "Alte Ziegelei 5",
      PLZ: "36100",
      Stadt: "Petersberg",
    },
    905: {
      LadenId: 905,
      Telefon: "+496806603276",
      Strasse: "Saarbrücker Straße 28",
      PLZ: "66265",
      Stadt: "Heusweiler",
    },
    906: {
      LadenId: 906,
      Telefon: "+499716994362",
      Strasse: "Riedgraben 7",
      PLZ: "97688",
      Stadt: "Bad Kissingen",
    },
    907: {
      LadenId: 907,
      Telefon: "+496371130526",
      Strasse: "Torfstraße 2-4",
      PLZ: "66849",
      Stadt: "Landstuhl",
    },
    909: {
      LadenId: 909,
      Telefon: "+498951265750",
      Strasse: "Karlsplatz 25",
      PLZ: "80335",
      Stadt: "München",
    },
    911: {
      LadenId: 911,
      Telefon: "+497841601244",
      Strasse: "Güterhallenstraße 1",
      PLZ: "77855",
      Stadt: "Achern",
    },
    912: {
      LadenId: 912,
      Telefon: "+498514902776",
      Strasse: "Graneckerstraße 4",
      PLZ: "94036",
      Stadt: "Passau",
    },
    914: {
      LadenId: 914,
      Telefon: "+493641207933",
      Strasse: "Leutragraben 1",
      PLZ: "07743",
      Stadt: "Jena",
    },
    915: {
      LadenId: 915,
      Telefon: "+497215164558",
      Strasse: "Carl-Metz-Straße 7",
      PLZ: "76185",
      Stadt: "Karlsruhe",
    },
    916: {
      LadenId: 916,
      Telefon: "+499517002161",
      Strasse: "Michelinstraße 142",
      PLZ: "96103",
      Stadt: "Hallstadt",
    },
    917: {
      LadenId: 917,
      Telefon: "+4928239763258",
      Strasse: "Voßstraße 49-51",
      PLZ: "47574",
      Stadt: "Goch",
    },
    918: {
      LadenId: 918,
      Telefon: "+498937066761",
      Strasse: "Schleißheimer Straße 506",
      PLZ: "80933",
      Stadt: "München",
    },
    920: {
      LadenId: 920,
      Telefon: "+496661916513",
      Strasse: "Gartenstraße 23a",
      PLZ: "36381",
      Stadt: "Schlüchtern",
    },
    921: {
      LadenId: 921,
      Telefon: "+497191368153",
      Strasse: "Im Biegel 8-10",
      PLZ: "71522",
      Stadt: "Backnang",
    },
    925: {
      LadenId: 925,
      Telefon: "+497254203752",
      Strasse: "Mannheimer Straße 189",
      PLZ: "68753",
      Stadt: "Waghäusel",
    },
    926: {
      LadenId: 926,
      Telefon: "+497113418381",
      Strasse: "Schwabenplatz 7",
      PLZ: "70563",
      Stadt: "Stuttgart",
    },
    927: {
      LadenId: 927,
      Telefon: "+496913886692",
      Strasse: "Kaiserstraße 11",
      PLZ: "60311",
      Stadt: "Frankfurt am Main",
    },
    929: {
      LadenId: 929,
      Telefon: "+496211789260",
      Strasse: "Friedrich-Ebert-Straße 100",
      PLZ: "68167",
      Stadt: "Mannheim",
    },
    930: {
      LadenId: 930,
      Telefon: "+493771250855",
      Strasse: "Wettinerstraße 4",
      PLZ: "08280",
      Stadt: "Aue-Bad Schlema",
    },
    931: {
      LadenId: 931,
      Telefon: "+498741926567",
      Strasse: "Ohmstraße 6",
      PLZ: "84137",
      Stadt: "Vilsbiburg",
    },
    933: {
      LadenId: 933,
      Telefon: "+496451210769",
      Strasse: "Ruhrstraße 15b",
      PLZ: "35066",
      Stadt: "Frankenberg (Eder)",
    },
    937: {
      LadenId: 937,
      Telefon: "+499117665708",
      Strasse: "Seeackerstraße 33",
      PLZ: "90765",
      Stadt: "Fürth",
    },
    938: {
      LadenId: 938,
      Telefon: "+496214107954",
      Strasse: "Alte Frankfurter Straße 23",
      PLZ: "68305",
      Stadt: "Mannheim",
    },
    939: {
      LadenId: 939,
      Telefon: "+498936006661",
      Strasse: "Alfred-Arndt-Straße 1",
      PLZ: "80807",
      Stadt: "München",
    },
    941: {
      LadenId: 941,
      Telefon: "+493733429355",
      Strasse: "Buchholzer Straße 16",
      PLZ: "09456",
      Stadt: "Annaberg-Buchholz",
    },
    942: {
      LadenId: 942,
      Telefon: "+4962216540689",
      Strasse: "Fritz-Frey-Straße 14",
      PLZ: "69121",
      Stadt: "Heidelberg",
    },
    944: {
      LadenId: 944,
      Telefon: "+497321273247",
      Strasse: "Karlstraße 12",
      PLZ: "89518",
      Stadt: "Heidenheim an der Brenz",
    },
    950: {
      LadenId: 950,
      Telefon: "+4951328219165",
      Strasse: "Zuckerpassage 26",
      PLZ: "31275",
      Stadt: "Lehrte",
    },
    951: {
      LadenId: 951,
      Telefon: "+496691807171",
      Strasse: "Walkmühlenweg 4",
      PLZ: "34613",
      Stadt: "Schwalmstadt",
    },
    952: {
      LadenId: 952,
      Telefon: "+492773740664",
      Strasse: "Bahnhofstraße 10",
      PLZ: "35708",
      Stadt: "Haiger",
    },
    955: {
      LadenId: 955,
      Telefon: "+497571645788",
      Strasse: "In der Au 2/3",
      PLZ: "72488",
      Stadt: "Sigmaringen",
    },
    969: {
      LadenId: 969,
      Telefon: "+498982000283",
      Strasse: "Landsberger Strasse 515",
      PLZ: "81241",
      Stadt: "München",
    },
    974: {
      LadenId: 974,
      Telefon: "+4974617801838",
      Strasse: "Rudolf-Diesel-Straße 31+35",
      PLZ: "78532",
      Stadt: "Tuttlingen",
    },
    976: {
      LadenId: 976,
      Telefon: "+497551301216",
      Strasse: "Lippertsreuter Straße 4b",
      PLZ: "88662",
      Stadt: "Überlingen",
    },
    1000: {
      LadenId: 1000,
      Telefon: "+493088007753",
      Strasse: "Kurfürstendamm 34",
      PLZ: "10719",
      Stadt: "Berlin",
    },
    1007: {
      LadenId: 1007,
      Telefon: "+493912448904",
      Strasse: "Lübecker Straße 98",
      PLZ: "39124",
      Stadt: "Magdeburg",
    },
    1008: {
      LadenId: 1008,
      Telefon: "+493402164184",
      Strasse: "Am Eichengarten 2",
      PLZ: "06842",
      Stadt: "Dessau-Roßlau",
    },
    1010: {
      LadenId: 1010,
      Telefon: "+498141524660",
      Strasse: "Oskar-von-Miller-Straße 2",
      PLZ: "82256",
      Stadt: "Fürstenfeldbruck",
    },
    1012: {
      LadenId: 1012,
      Telefon: "+499191163118",
      Strasse: "Kraftwerkstraße 7",
      PLZ: "91301",
      Stadt: "Forchheim",
    },
    1015: {
      LadenId: 1015,
      Telefon: "+493764185180",
      Strasse: "Guteborner Allee 3",
      PLZ: "08393",
      Stadt: "Meerane",
    },
    1016: {
      LadenId: 1016,
      Telefon: "+496109248363",
      Strasse: "Borsigallee 26",
      PLZ: "60388",
      Stadt: "Frankfurt am Main",
    },
    1017: {
      LadenId: 1017,
      Telefon: "+497213528890",
      Strasse: "Ettlinger-Tor-Platz 1",
      PLZ: "76133",
      Stadt: "Karlsruhe",
    },
    1018: {
      LadenId: 1018,
      Telefon: "+493045020460",
      Strasse: "Müllerstraße 40b",
      PLZ: "13353",
      Stadt: "Berlin",
    },
    1020: {
      LadenId: 1020,
      Telefon: "+498719538624",
      Strasse: "Kristallstraße 4",
      PLZ: "84032",
      Stadt: "Altdorf",
    },
    1021: {
      LadenId: 1021,
      Telefon: "+497121506427",
      Strasse: "Ferdinand-Lassalle-Straße 17-19",
      PLZ: "72770",
      Stadt: "Reutlingen",
    },
    1022: {
      LadenId: 1022,
      Telefon: "+499112029300",
      Strasse: "Breite Gasse 77",
      PLZ: "90402",
      Stadt: "Nürnberg",
    },
    1023: {
      LadenId: 1023,
      Telefon: "+493512029794",
      Strasse: "Nicolaistraße 28",
      PLZ: "01307",
      Stadt: "Dresden",
    },
    1024: {
      LadenId: 1024,
      Telefon: "+496205102026",
      Strasse: "Lußheimer Straße 8",
      PLZ: "68766",
      Stadt: "Hockenheim",
    },
    1025: {
      LadenId: 1025,
      Telefon: "+497611307363",
      Strasse: "Merzhauser Straße 179",
      PLZ: "79100",
      Stadt: "Freiburg im Breisgau",
    },
    1028: {
      LadenId: 1028,
      Telefon: "+4964413832759",
      Strasse: "Am Forum 1",
      PLZ: "35576",
      Stadt: "Wetzlar",
    },
    1029: {
      LadenId: 1029,
      Telefon: "+497971260256",
      Strasse: "Schillerstraße 5b",
      PLZ: "74405",
      Stadt: "Gaildorf",
    },
    1030: {
      LadenId: 1030,
      Telefon: "+498212670354",
      Strasse: "Neuburger Straße 227",
      PLZ: "86169",
      Stadt: "Augsburg",
    },
    1031: {
      LadenId: 1031,
      Telefon: "+495031516124",
      Strasse: "Lange Straße 21",
      PLZ: "31515",
      Stadt: "Wunstorf",
    },
    1034: {
      LadenId: 1034,
      Telefon: "+497253278390",
      Strasse: "Thomas-Howie-Straße 2-8",
      PLZ: "76684",
      Stadt: "Östringen",
    },
    1035: {
      LadenId: 1035,
      Telefon: "+4960213690761",
      Strasse: "Goldbacher Straße 2",
      PLZ: "63739",
      Stadt: "Aschaffenburg",
    },
    1036: {
      LadenId: 1036,
      Telefon: "+4951211740777",
      Strasse: "Schuhstraße 34",
      PLZ: "31134",
      Stadt: "Hildesheim",
    },
    1038: {
      LadenId: 1038,
      Telefon: "+496417962885",
      Strasse: "Seltersweg 7-9",
      PLZ: "35390",
      Stadt: "Gießen",
    },
    1039: {
      LadenId: 1039,
      Telefon: "+497121312335",
      Strasse: "Föhrstraße 32",
      PLZ: "72760",
      Stadt: "Reutlingen",
    },
    1040: {
      LadenId: 1040,
      Telefon: "+4971316421220",
      Strasse: "Eppinger Straße 20",
      PLZ: "74211",
      Stadt: "Leingarten",
    },
    1042: {
      LadenId: 1042,
      Telefon: "+4929219810309",
      Strasse: "Senator-Schwartz-Ring 24",
      PLZ: "59494",
      Stadt: "Soest",
    },
    1043: {
      LadenId: 1043,
      Telefon: "+493021014618",
      Strasse: "Tauentzienstraße 2-3",
      PLZ: "10789",
      Stadt: "Berlin",
    },
    1045: {
      LadenId: 1045,
      Telefon: "+497627922163",
      Strasse: "Gewerbestraße 3",
      PLZ: "79585",
      Stadt: "Steinen",
    },
    1046: {
      LadenId: 1046,
      Telefon: "+497575209546",
      Strasse: "Am Stachus 5",
      PLZ: "88605",
      Stadt: "Meßkirch",
    },
    1047: {
      LadenId: 1047,
      Telefon: "+4970214829824",
      Strasse: "Marktstraße 25",
      PLZ: "73230",
      Stadt: "Kirchheim unter Teck",
    },
    1049: {
      LadenId: 1049,
      Telefon: "+493093024451",
      Strasse: "Marzahner Promenade 1",
      PLZ: "12679",
      Stadt: "Berlin",
    },
    1051: {
      LadenId: 1051,
      Telefon: "+4953612757876",
      Strasse: "Porschestraße 35",
      PLZ: "38440",
      Stadt: "Wolfsburg",
    },
    1052: {
      LadenId: 1052,
      Telefon: "+496960504488",
      Strasse: "Schweizer Platz 62",
      PLZ: "60594",
      Stadt: "Frankfurt am Main",
    },
    1053: {
      LadenId: 1053,
      Telefon: "+495513707849",
      Strasse: "Weender Straße 75",
      PLZ: "37073",
      Stadt: "Göttingen",
    },
    1054: {
      LadenId: 1054,
      Telefon: "+496211075956",
      Strasse: "T 1 3",
      PLZ: "68161",
      Stadt: "Mannheim",
    },
    1056: {
      LadenId: 1056,
      Telefon: "+4971515028169",
      Strasse: "Marktgasse 5",
      PLZ: "71332",
      Stadt: "Waiblingen",
    },
    1058: {
      LadenId: 1058,
      Telefon: "+493085073176",
      Strasse: "Walther-Schreiber-Platz 1",
      PLZ: "12161",
      Stadt: "Berlin",
    },
    1060: {
      LadenId: 1060,
      Telefon: "+4971315949742",
      Strasse: "Kreuzäckerstraße 6",
      PLZ: "74081",
      Stadt: "Heilbronn",
    },
    1062: {
      LadenId: 1062,
      Telefon: "+498131908497",
      Strasse: "Münchner Straße 140",
      PLZ: "85757",
      Stadt: "Karlsfeld",
    },
    1064: {
      LadenId: 1064,
      Telefon: "+493041723730",
      Strasse: "Frankfurter Allee 111",
      PLZ: "10247",
      Stadt: "Berlin",
    },
    1065: {
      LadenId: 1065,
      Telefon: "+498215047129",
      Strasse: "Bürgermeister-Fischer-Straße 5",
      PLZ: "86150",
      Stadt: "Augsburg",
    },
    1066: {
      LadenId: 1066,
      Telefon: "+4924521060864",
      Strasse: "Hochstraße 96",
      PLZ: "52525",
      Stadt: "Heinsberg",
    },
    1067: {
      LadenId: 1067,
      Telefon: "+499414611140",
      Strasse: "Weichser Weg 5",
      PLZ: "93059",
      Stadt: "Regensburg",
    },
    1068: {
      LadenId: 1068,
      Telefon: "+493946525704",
      Strasse: "Harzweg 23",
      PLZ: "06484",
      Stadt: "Quedlinburg",
    },
    1071: {
      LadenId: 1071,
      Telefon: "+4993716699749",
      Strasse: "In der Seehecke 7",
      PLZ: "63924",
      Stadt: "Kleinheubach",
    },
    1072: {
      LadenId: 1072,
      Telefon: "+498962836791",
      Strasse: "Kirchlandweg 1+3",
      PLZ: "82008",
      Stadt: "Unterhaching",
    },
    1074: {
      LadenId: 1074,
      Telefon: "+496172138380",
      Strasse: "Louisenstraße 67",
      PLZ: "61348",
      Stadt: "Bad Homburg vor der Höhe",
    },
    1077: {
      LadenId: 1077,
      Telefon: "+498531247566",
      Strasse: "Füssinger Straße 1",
      PLZ: "94060",
      Stadt: "Pocking",
    },
    1078: {
      LadenId: 1078,
      Telefon: "+497113003409",
      Strasse: "Stuttgarter Straße 26",
      PLZ: "70736",
      Stadt: "Fellbach",
    },
    1079: {
      LadenId: 1079,
      Telefon: "+498165646947",
      Strasse: "Kurt-Kittel-Ring 5",
      PLZ: "85375",
      Stadt: "Neufahrn b. Freising",
    },
    1080: {
      LadenId: 1080,
      Telefon: "+496142174091",
      Strasse: "Adam-Opel-Straße 59-61",
      PLZ: "65428",
      Stadt: "Rüsselsheim",
    },
    1081: {
      LadenId: 1081,
      Telefon: "+497731976063",
      Strasse: "Kornblumenweg 41",
      PLZ: "78244",
      Stadt: "Gottmadingen",
    },
    1082: {
      LadenId: 1082,
      Telefon: "+498671883349",
      Strasse: "Simbacher Straße 30",
      PLZ: "84524",
      Stadt: "Neuötting",
    },
    1087: {
      LadenId: 1087,
      Telefon: "+4997213880330",
      Strasse: "August-Borsig-Straße 2",
      PLZ: "97526",
      Stadt: "Sennfeld",
    },
    1088: {
      LadenId: 1088,
      Telefon: "+4960214449375",
      Strasse: "Lange Str. 5",
      PLZ: "63741",
      Stadt: "Aschaffenburg",
    },
    1090: {
      LadenId: 1090,
      Telefon: "+496182782579",
      Strasse: "Willi-Brehm-Straße 5",
      PLZ: "63500",
      Stadt: "Seligenstadt",
    },
    1093: {
      LadenId: 1093,
      Telefon: "+499115407911",
      Strasse: "Am Gewerbepark 2",
      PLZ: "90552",
      Stadt: "Röthenbach a.d. Pegnitz",
    },
    1094: {
      LadenId: 1094,
      Telefon: "+497631704631",
      Strasse: "Max-Schweinlin-Straße 1",
      PLZ: "79395",
      Stadt: "Neuenburg am Rhein",
    },
    1095: {
      LadenId: 1095,
      Telefon: "+493752704745",
      Strasse: "Oskar-Arnold-Straße 14",
      PLZ: "08056",
      Stadt: "Zwickau",
    },
    1096: {
      LadenId: 1096,
      Telefon: "+497215164372",
      Strasse: "Zeppelinstraße 15",
      PLZ: "76185",
      Stadt: "Karlsruhe",
    },
    1097: {
      LadenId: 1097,
      Telefon: "+493451228492",
      Strasse: "Große Nikolaistraße 1",
      PLZ: "06108",
      Stadt: "Halle (Saale)",
    },
    1099: {
      LadenId: 1099,
      Telefon: "+493915979659",
      Strasse: "Ernst-Reuter-Allee 11",
      PLZ: "39104",
      Stadt: "Magdeburg",
    },
    1101: {
      LadenId: 1101,
      Telefon: "+499114311452",
      Strasse: "Aufseßplatz 21",
      PLZ: "90459",
      Stadt: "Nürnberg",
    },
    1102: {
      LadenId: 1102,
      Telefon: "+497832976049",
      Strasse: "Schwarzwaldstraße 22",
      PLZ: "77716",
      Stadt: "Haslach im Kinzigtal",
    },
    1103: {
      LadenId: 1103,
      Telefon: "+499313042305",
      Strasse: "Frankfurter Straße 56",
      PLZ: "97082",
      Stadt: "Würzburg",
    },
    1104: {
      LadenId: 1104,
      Telefon: "+497162462855",
      Strasse: "Bühlstraße 19",
      PLZ: "73079",
      Stadt: "Süßen",
    },
    1105: {
      LadenId: 1105,
      Telefon: "+497763704905",
      Strasse: "Laufenpark 5",
      PLZ: "79725",
      Stadt: "Laufenburg (Baden)",
    },
    1107: {
      LadenId: 1107,
      Telefon: "+498611660901",
      Strasse: "Franz-Xaver-Steber-Straße 7",
      PLZ: "83278",
      Stadt: "Traunstein",
    },
    1108: {
      LadenId: 1108,
      Telefon: "+4982212078192",
      Strasse: "Reindlstraße 4",
      PLZ: "89312",
      Stadt: "Günzburg",
    },
    1109: {
      LadenId: 1109,
      Telefon: "+499211504118",
      Strasse: "Richard-Wagner-Straße 4-6",
      PLZ: "95444",
      Stadt: "Bayreuth",
    },
    1113: {
      LadenId: 1113,
      Telefon: "+493402167520",
      Strasse: "Kavalierstraße 49",
      PLZ: "06844",
      Stadt: "Dessau-Roßlau",
    },
    1114: {
      LadenId: 1114,
      Telefon: "+499113218709",
      Strasse: "Fürther Straße 301-303",
      PLZ: "90429",
      Stadt: "Nürnberg",
    },
    1115: {
      LadenId: 1115,
      Telefon: "+493612629465",
      Strasse: "Hermsdorfer Straße 4",
      PLZ: "99099",
      Stadt: "Erfurt",
    },
    1116: {
      LadenId: 1116,
      Telefon: "+493463836424",
      Strasse: "Einkaufszentrum Nova 1",
      PLZ: "06237",
      Stadt: "Leuna",
    },
    1117: {
      LadenId: 1117,
      Telefon: "+497211205758",
      Strasse: "Kaiserstraße 92",
      PLZ: "76133",
      Stadt: "Karlsruhe",
    },
    1118: {
      LadenId: 1118,
      Telefon: "+495412023348",
      Strasse: "Große Straße 62",
      PLZ: "49074",
      Stadt: "Osnabrück",
    },
    1120: {
      LadenId: 1120,
      Telefon: "+499113000472",
      Strasse: "Bucher Straße 41",
      PLZ: "90419",
      Stadt: "Nürnberg",
    },
    1121: {
      LadenId: 1121,
      Telefon: "+493039063496",
      Strasse: "Turmstraße 59",
      PLZ: "10551",
      Stadt: "Berlin",
    },
    1123: {
      LadenId: 1123,
      Telefon: "+497851485769",
      Strasse: "Boschstraße 6b",
      PLZ: "77694",
      Stadt: "Kehl",
    },
    1124: {
      LadenId: 1124,
      Telefon: "+4968518000692",
      Strasse: "Tholeyer Straße 3a",
      PLZ: "66606",
      Stadt: "St. Wendel",
    },
    1125: {
      LadenId: 1125,
      Telefon: "+4981426503181",
      Strasse: "Olchinger Straße 68",
      PLZ: "82194",
      Stadt: "Gröbenzell",
    },
    1126: {
      LadenId: 1126,
      Telefon: "+493302201037",
      Strasse: "Postplatz 4-4c",
      PLZ: "16761",
      Stadt: "Hennigsdorf",
    },
    1130: {
      LadenId: 1130,
      Telefon: "+497472280081",
      Strasse: "Graf-Wolfegg-Straße 1",
      PLZ: "72108",
      Stadt: "Rottenburg am Neckar",
    },
    1134: {
      LadenId: 1134,
      Telefon: "+496241200756",
      Strasse: "Klosterstraße 40",
      PLZ: "67547",
      Stadt: "Worms",
    },
    1136: {
      LadenId: 1136,
      Telefon: "+4964213039275",
      Strasse: "Erlenring 9",
      PLZ: "35037",
      Stadt: "Marburg",
    },
    1137: {
      LadenId: 1137,
      Telefon: "+499115808988",
      Strasse: "Äußerer Laufer Platz 24",
      PLZ: "90403",
      Stadt: "Nürnberg",
    },
    1138: {
      LadenId: 1138,
      Telefon: "+4961511361846",
      Strasse: "Wilhelminenstraße 7",
      PLZ: "64283",
      Stadt: "Darmstadt",
    },
    1139: {
      LadenId: 1139,
      Telefon: "+497851484847",
      Strasse: "Hauptstraße 61",
      PLZ: "77694",
      Stadt: "Kehl",
    },
    1141: {
      LadenId: 1141,
      Telefon: "+497153612579",
      Strasse: "Plochinger Straße 40-42",
      PLZ: "73779",
      Stadt: "Deizisau",
    },
    1142: {
      LadenId: 1142,
      Telefon: "+495114733253",
      Strasse: "Badenstedter Straße 132",
      PLZ: "30455",
      Stadt: "Hannover",
    },
    1143: {
      LadenId: 1143,
      Telefon: "+495151107058",
      Strasse: "Werkstraße 17",
      PLZ: "31789",
      Stadt: "Hameln",
    },
    1144: {
      LadenId: 1144,
      Telefon: "+499391503685",
      Strasse: "Georg-Mayr-Straße 27",
      PLZ: "97828",
      Stadt: "Marktheidenfeld",
    },
    1145: {
      LadenId: 1145,
      Telefon: "+499114805341",
      Strasse: "Ernst-Sachs-Straße 2",
      PLZ: "90441",
      Stadt: "Nürnberg",
    },
    1146: {
      LadenId: 1146,
      Telefon: "+495651331555",
      Strasse: "Stad 23",
      PLZ: "37269",
      Stadt: "Eschwege",
    },
    1150: {
      LadenId: 1150,
      Telefon: "+493033308798",
      Strasse: "Klosterstraße 3",
      PLZ: "13581",
      Stadt: "Berlin",
    },
    1151: {
      LadenId: 1151,
      Telefon: "+493581421147",
      Strasse: "Berliner Straße 6",
      PLZ: "02826",
      Stadt: "Görlitz",
    },
    1155: {
      LadenId: 1155,
      Telefon: "+4970713659384",
      Strasse: "Hügelstraße 5",
      PLZ: "72072",
      Stadt: "Tübingen",
    },
    1157: {
      LadenId: 1157,
      Telefon: "+497513525512",
      Strasse: "Ziegelstraße 6",
      PLZ: "88214",
      Stadt: "Ravensburg",
    },
    1158: {
      LadenId: 1158,
      Telefon: "+4951378211928",
      Strasse: "Hannoversche Straße 81-83",
      PLZ: "30926",
      Stadt: "Seelze",
    },
    1160: {
      LadenId: 1160,
      Telefon: "+493601403690",
      Strasse: "Grasegasse 1",
      PLZ: "99974",
      Stadt: "Mühlhausen/Thüringen",
    },
    1161: {
      LadenId: 1161,
      Telefon: "+496323936713",
      Strasse: "Staatsstraße 8",
      PLZ: "67480",
      Stadt: "Edenkoben",
    },
    1162: {
      LadenId: 1162,
      Telefon: "+493054715304",
      Strasse: "Hasenheide 107-108",
      PLZ: "10967",
      Stadt: "Berlin",
    },
    1163: {
      LadenId: 1163,
      Telefon: "+493041719733",
      Strasse: "Badstraße 4",
      PLZ: "13357",
      Stadt: "Berlin",
    },
    1164: {
      LadenId: 1164,
      Telefon: "+4952511421520",
      Strasse: "Balhornstraße 9",
      PLZ: "33102",
      Stadt: "Paderborn",
    },
    1165: {
      LadenId: 1165,
      Telefon: "+493024628632",
      Strasse: "Alexanderplatz 1",
      PLZ: "10178",
      Stadt: "Berlin",
    },
    1166: {
      LadenId: 1166,
      Telefon: "+496235457704",
      Strasse: "Waldspitzweg 3",
      PLZ: "67105",
      Stadt: "Schifferstadt",
    },
    1167: {
      LadenId: 1167,
      Telefon: "+4988612561131",
      Strasse: "Schwabbrucker Straße 1",
      PLZ: "86956",
      Stadt: "Schongau",
    },
    1168: {
      LadenId: 1168,
      Telefon: "+4974249054240",
      Strasse: "Europastraße 2",
      PLZ: "78549",
      Stadt: "Spaichingen",
    },
    1169: {
      LadenId: 1169,
      Telefon: "+496313203889",
      Strasse: "Mainzer Straße 67",
      PLZ: "67657",
      Stadt: "Kaiserslautern",
    },
    1170: {
      LadenId: 1170,
      Telefon: "+4971122029832",
      Strasse: "Königstraße 2",
      PLZ: "70173",
      Stadt: "Stuttgart",
    },
    1171: {
      LadenId: 1171,
      Telefon: "+496551147861",
      Strasse: "Bahnhofstraße 51",
      PLZ: "54595",
      Stadt: "Prüm",
    },
    1172: {
      LadenId: 1172,
      Telefon: "+498931562621",
      Strasse: "Sonnenstr. 15",
      PLZ: "85764",
      Stadt: "Oberschleißheim",
    },
    1173: {
      LadenId: 1173,
      Telefon: "+493060530601",
      Strasse: "Neuköllner Straße 344",
      PLZ: "12355",
      Stadt: "Berlin",
    },
    1186: {
      LadenId: 1186,
      Telefon: "+499135721116",
      Strasse: "Im Gewerbepark 3",
      PLZ: "91093",
      Stadt: "Heßdorf",
    },
    1188: {
      LadenId: 1188,
      Telefon: "+4962218673317",
      Strasse: "Gewerbestraße 1",
      PLZ: "69221",
      Stadt: "Dossenheim",
    },
    1192: {
      LadenId: 1192,
      Telefon: "+4972513020531",
      Strasse: "Kammerforststraße 3",
      PLZ: "76646",
      Stadt: "Bruchsal",
    },
    1194: {
      LadenId: 1194,
      Telefon: "+498212401252",
      Strasse: "Daimlerstraße 1",
      PLZ: "86391",
      Stadt: "Stadtbergen",
    },
    1196: {
      LadenId: 1196,
      Telefon: "+493077008137",
      Strasse: "General-Pape-Straße 1",
      PLZ: "12101",
      Stadt: "Berlin",
    },
    1197: {
      LadenId: 1197,
      Telefon: "+495171290941",
      Strasse: "Gröpern 22",
      PLZ: "31224",
      Stadt: "Peine",
    },
    1199: {
      LadenId: 1199,
      Telefon: "+4927618286825",
      Strasse: "Stellwerkstraße 2",
      PLZ: "57462",
      Stadt: "Olpe",
    },
    1201: {
      LadenId: 1201,
      Telefon: "+496073604004",
      Strasse: "Siemensstraße 8",
      PLZ: "64832",
      Stadt: "Babenhausen",
    },
    1202: {
      LadenId: 1202,
      Telefon: "+496712983313",
      Strasse: "Mannheimer Straße 114-116",
      PLZ: "55543",
      Stadt: "Bad Kreuznach",
    },
    1205: {
      LadenId: 1205,
      Telefon: "+496924704687",
      Strasse: "Leipziger Straße 28",
      PLZ: "60487",
      Stadt: "Frankfurt am Main",
    },
    1206: {
      LadenId: 1206,
      Telefon: "+496929721591",
      Strasse: "Berger Straße 183",
      PLZ: "60385",
      Stadt: "Frankfurt am Main",
    },
    1207: {
      LadenId: 1207,
      Telefon: "+496913023727",
      Strasse: "Oeder Weg 48",
      PLZ: "60318",
      Stadt: "Frankfurt am Main",
    },
    1209: {
      LadenId: 1209,
      Telefon: "+496960329724",
      Strasse: "Dreieichstraße 59",
      PLZ: "60594",
      Stadt: "Frankfurt am Main",
    },
    1211: {
      LadenId: 1211,
      Telefon: "+496935101140",
      Strasse: "Am Kirchholz 12",
      PLZ: "60529",
      Stadt: "Frankfurt am Main",
    },
    1212: {
      LadenId: 1212,
      Telefon: "+496913023688",
      Strasse: "Grüneburgweg 58-62",
      PLZ: "60322",
      Stadt: "Frankfurt am Main",
    },
    1228: {
      LadenId: 1228,
      Telefon: "+496105404222",
      Strasse: "Farmstraße 101",
      PLZ: "64546",
      Stadt: "Mörfelden-Walldorf",
    },
    1232: {
      LadenId: 1232,
      Telefon: "+496171287374",
      Strasse: "Vorstadt 8",
      PLZ: "61440",
      Stadt: "Oberursel (Taunus)",
    },
    1234: {
      LadenId: 1234,
      Telefon: "+4980519616357",
      Strasse: "Systemformstraße 1",
      PLZ: "83209",
      Stadt: "Prien a. Chiemsee",
    },
    1237: {
      LadenId: 1237,
      Telefon: "+496282927224",
      Strasse: "Otto-Hahn-Straße 10b",
      PLZ: "74731",
      Stadt: "Walldürn",
    },
    1238: {
      LadenId: 1238,
      Telefon: "+496114090529",
      Strasse: "Dotzheimer Straße 63",
      PLZ: "65197",
      Stadt: "Wiesbaden",
    },
    1240: {
      LadenId: 1240,
      Telefon: "+499313297668",
      Strasse: "Erthalstraße 32 B",
      PLZ: "97074",
      Stadt: "Würzburg",
    },
    1242: {
      LadenId: 1242,
      Telefon: "+497822448803",
      Strasse: "Tullastraße 1",
      PLZ: "77955",
      Stadt: "Ettenheim",
    },
    1244: {
      LadenId: 1244,
      Telefon: "+495318017636",
      Strasse: "Fabrikstraße 1 d",
      PLZ: "38122",
      Stadt: "Braunschweig",
    },
    1245: {
      LadenId: 1245,
      Telefon: "+498974846689",
      Strasse: "Boschetsrieder Straße 72",
      PLZ: "81379",
      Stadt: "München",
    },
    1246: {
      LadenId: 1246,
      Telefon: "+496112049552",
      Strasse: "Otto-Wallach-Straße 5",
      PLZ: "65203",
      Stadt: "Wiesbaden",
    },
    1247: {
      LadenId: 1247,
      Telefon: "+499273500880",
      Strasse: "Bayreuther Straße 1",
      PLZ: "95502",
      Stadt: "Himmelkron",
    },
    1249: {
      LadenId: 1249,
      Telefon: "+495512054395",
      Strasse: "An der Lutter 22",
      PLZ: "37075",
      Stadt: "Göttingen",
    },
    1250: {
      LadenId: 1250,
      Telefon: "+4959213022872",
      Strasse: "Stadtring 60",
      PLZ: "48527",
      Stadt: "Nordhorn",
    },
    1251: {
      LadenId: 1251,
      Telefon: "+499614017136",
      Strasse: "Max-Reger-Straße 7-7a",
      PLZ: "92637",
      Stadt: "Weiden i. d. OPf.",
    },
    1254: {
      LadenId: 1254,
      Telefon: "+496346308267",
      Strasse: "Landauer Straße 37",
      PLZ: "76855",
      Stadt: "Annweiler am Trifels",
    },
    1257: {
      LadenId: 1257,
      Telefon: "+493040393189",
      Strasse: "Greifswalder Straße 89",
      PLZ: "10409",
      Stadt: "Berlin",
    },
    1259: {
      LadenId: 1259,
      Telefon: "+492472802897",
      Strasse: "Trierer Straße 250-264",
      PLZ: "52156",
      Stadt: "Monschau",
    },
    1260: {
      LadenId: 1260,
      Telefon: "+496253239369",
      Strasse: "Erbacher Straße 64",
      PLZ: "64658",
      Stadt: "Fürth",
    },
    1261: {
      LadenId: 1261,
      Telefon: "+496111602152",
      Strasse: "Kirchgasse 6",
      PLZ: "65185",
      Stadt: "Wiesbaden",
    },
    1264: {
      LadenId: 1264,
      Telefon: "+4975618487950",
      Strasse: "Bahnhofsarkaden 5",
      PLZ: "88299",
      Stadt: "Leutkirch im Allgäu",
    },
    1265: {
      LadenId: 1265,
      Telefon: "+497261405548",
      Strasse: "Karlsplatz 1",
      PLZ: "74889",
      Stadt: "Sinsheim",
    },
    1266: {
      LadenId: 1266,
      Telefon: "+4953414091426",
      Strasse: "Albert-Schweitzer-Straße 1",
      PLZ: "38226",
      Stadt: "Salzgitter",
    },
    1268: {
      LadenId: 1268,
      Telefon: "+495111238556",
      Strasse: "Kopernikusstraße 5",
      PLZ: "30167",
      Stadt: "Hannover",
    },
    1270: {
      LadenId: 1270,
      Telefon: "+492981802596",
      Strasse: "Neue Mitte 4",
      PLZ: "59955",
      Stadt: "Winterberg",
    },
    1272: {
      LadenId: 1272,
      Telefon: "+495117247257",
      Strasse: "Walsroder Straße 125",
      PLZ: "30853",
      Stadt: "Langenhagen",
    },
    1273: {
      LadenId: 1273,
      Telefon: "+4985716027617",
      Strasse: "Simon-Breu-Straße 59",
      PLZ: "84359",
      Stadt: "Simbach a. Inn",
    },
    1274: {
      LadenId: 1274,
      Telefon: "+4969247455780",
      Strasse: "Große Friedberger Straße 7-11",
      PLZ: "60313",
      Stadt: "Frankfurt am Main",
    },
    1276: {
      LadenId: 1276,
      Telefon: "+496226429137",
      Strasse: "Brühlweg 15",
      PLZ: "74909",
      Stadt: "Meckesheim",
    },
    1279: {
      LadenId: 1279,
      Telefon: "+497152352340",
      Strasse: "Dieselstraße 7",
      PLZ: "71277",
      Stadt: "Rutesheim",
    },
    1280: {
      LadenId: 1280,
      Telefon: "+493080581411",
      Strasse: "Teltower Damm 15",
      PLZ: "14169",
      Stadt: "Berlin",
    },
    1282: {
      LadenId: 1282,
      Telefon: "+496805615193",
      Strasse: "Konrad-Adenauer-Straße 10",
      PLZ: "66271",
      Stadt: "Kleinblittersdorf",
    },
    1283: {
      LadenId: 1283,
      Telefon: "+4961519180795",
      Strasse: "Rüdesheimer Straße 123b",
      PLZ: "64285",
      Stadt: "Darmstadt",
    },
    1284: {
      LadenId: 1284,
      Telefon: "+4961277059374",
      Strasse: "Frankfurter Straße 3a",
      PLZ: "65527",
      Stadt: "Niedernhausen",
    },
    1286: {
      LadenId: 1286,
      Telefon: "+499112528757",
      Strasse: "Ansbacher Str. 80",
      PLZ: "90449",
      Stadt: "Nürnberg",
    },
    1288: {
      LadenId: 1288,
      Telefon: "+493552886532",
      Strasse: "Karl-Liebknecht-Straße 136",
      PLZ: "03046",
      Stadt: "Cottbus",
    },
    1289: {
      LadenId: 1289,
      Telefon: "+493036403007",
      Strasse: "Heerstraße 560",
      PLZ: "13591",
      Stadt: "Berlin",
    },
    1291: {
      LadenId: 1291,
      Telefon: "+492943871495",
      Strasse: "Bahnhofstraße 14a",
      PLZ: "59597",
      Stadt: "Erwitte",
    },
    1292: {
      LadenId: 1292,
      Telefon: "+4971213045353",
      Strasse: "Wilhelmstraße 29",
      PLZ: "72764",
      Stadt: "Reutlingen",
    },
    1293: {
      LadenId: 1293,
      Telefon: "+497311762852",
      Strasse: "Wegenerstraße 7",
      PLZ: "89231",
      Stadt: "Neu-Ulm",
    },
    1294: {
      LadenId: 1294,
      Telefon: "+4962322890309",
      Strasse: "St.-German-Straße 9 a",
      PLZ: "67346",
      Stadt: "Speyer",
    },
    1295: {
      LadenId: 1295,
      Telefon: "+498751841456",
      Strasse: "Köglmühle 5",
      PLZ: "84048",
      Stadt: "Mainburg",
    },
    1296: {
      LadenId: 1296,
      Telefon: "+496158747017",
      Strasse: "Lise-Meitner-Straße 6",
      PLZ: "64560",
      Stadt: "Riedstadt",
    },
    1297: {
      LadenId: 1297,
      Telefon: "+498962000355",
      Strasse: "Giesinger Bahnhofplatz 7",
      PLZ: "81539",
      Stadt: "München",
    },
    1299: {
      LadenId: 1299,
      Telefon: "+496332471830",
      Strasse: "Saarlandstraße 27",
      PLZ: "66482",
      Stadt: "Zweibrücken",
    },
    1300: {
      LadenId: 1300,
      Telefon: "+496953054032",
      Strasse: "Riedbergplatz 2",
      PLZ: "60438",
      Stadt: "Frankfurt am Main",
    },
    1301: {
      LadenId: 1301,
      Telefon: "+4971616587686",
      Strasse: "Pfarrstraße 1-3",
      PLZ: "73033",
      Stadt: "Göppingen",
    },
    1305: {
      LadenId: 1305,
      Telefon: "+497734931466",
      Strasse: "Im Rheinauer 4",
      PLZ: "78262",
      Stadt: "Gailingen am Hochrhein",
    },
    1311: {
      LadenId: 1311,
      Telefon: "+498711434154",
      Strasse: "Industriestraße 42",
      PLZ: "84030",
      Stadt: "Ergolding",
    },
    1312: {
      LadenId: 1312,
      Telefon: "+496867560042",
      Strasse: "In der Dörrwiese 4",
      PLZ: "66706",
      Stadt: "Perl",
    },
    1313: {
      LadenId: 1313,
      Telefon: "+499251437513",
      Strasse: "August-Horch-Straße 20",
      PLZ: "95213",
      Stadt: "Münchberg",
    },
    1314: {
      LadenId: 1314,
      Telefon: "+499116412924",
      Strasse: "Eibacher Hauptstraße 123",
      PLZ: "90451",
      Stadt: "Nürnberg",
    },
    1317: {
      LadenId: 1317,
      Telefon: "+497111209475",
      Strasse: "Kronprinzstraße 6",
      PLZ: "70173",
      Stadt: "Stuttgart",
    },
    1319: {
      LadenId: 1319,
      Telefon: "+498945462637",
      Strasse: "Hans-Stießberger-Straße 5",
      PLZ: "85540",
      Stadt: "Haar",
    },
    1321: {
      LadenId: 1321,
      Telefon: "+4924739272819",
      Strasse: "Humboldtstraße 13",
      PLZ: "52152",
      Stadt: "Simmerath",
    },
    1322: {
      LadenId: 1322,
      Telefon: "+496781263102",
      Strasse: "Vollmersbachstraße 65",
      PLZ: "55743",
      Stadt: "Idar-Oberstein",
    },
    1323: {
      LadenId: 1323,
      Telefon: "+4991315302710",
      Strasse: "Nürnberger Straße 7",
      PLZ: "91052",
      Stadt: "Erlangen",
    },
    1324: {
      LadenId: 1324,
      Telefon: "+492811548682",
      Strasse: "Hohe Straße 45-49",
      PLZ: "46483",
      Stadt: "Wesel",
    },
    1325: {
      LadenId: 1325,
      Telefon: "+497445857617",
      Strasse: "Dieselstraße 7",
      PLZ: "72285",
      Stadt: "Pfalzgrafenweiler",
    },
    1326: {
      LadenId: 1326,
      Telefon: "+497941606535",
      Strasse: "Büttelbronner Straße 5",
      PLZ: "74613",
      Stadt: "Öhringen",
    },
    1327: {
      LadenId: 1327,
      Telefon: "+4962026070237",
      Strasse: "Mannheimer Landstraße 5b",
      PLZ: "68782",
      Stadt: "Brühl",
    },
    1328: {
      LadenId: 1328,
      Telefon: "+4976211619150",
      Strasse: "Bärenfelser Straße 4",
      PLZ: "79539",
      Stadt: "Lörrach",
    },
    1329: {
      LadenId: 1329,
      Telefon: "+4962575056783",
      Strasse: "Neue Bergstraße 9",
      PLZ: "64665",
      Stadt: "Alsbach-Hähnlein",
    },
    1330: {
      LadenId: 1330,
      Telefon: "+499563308067",
      Strasse: "Coburger Straße 15",
      PLZ: "96472",
      Stadt: "Rödental",
    },
    1332: {
      LadenId: 1332,
      Telefon: "+4972213989528",
      Strasse: "Gernsbacher Straße 13",
      PLZ: "76530",
      Stadt: "Baden-Baden",
    },
    1333: {
      LadenId: 1333,
      Telefon: "+493615504343",
      Strasse: "Anger 66-73",
      PLZ: "99084",
      Stadt: "Erfurt",
    },
    1336: {
      LadenId: 1336,
      Telefon: "+4950328000257",
      Strasse: "Justus-von-Liebig-Straße 1",
      PLZ: "31535",
      Stadt: "Neustadt am Rübenberge",
    },
    1337: {
      LadenId: 1337,
      Telefon: "+4962515868185",
      Strasse: "Marie-Curie-Straße 2-12",
      PLZ: "64653",
      Stadt: "Lorsch",
    },
    1339: {
      LadenId: 1339,
      Telefon: "+4971714959188",
      Strasse: "In der Breite 7",
      PLZ: "73557",
      Stadt: "Mutlangen",
    },
    1340: {
      LadenId: 1340,
      Telefon: "+4972314285450",
      Strasse: "Am Hauptgüterbahnhof 15",
      PLZ: "75177",
      Stadt: "Pforzheim",
    },
    1341: {
      LadenId: 1341,
      Telefon: "+497303902388",
      Strasse: "Friedrich-Ebert-Straße 9",
      PLZ: "89257",
      Stadt: "Illertissen",
    },
    1344: {
      LadenId: 1344,
      Telefon: "+499814661618",
      Strasse: "Welserstraße 11",
      PLZ: "91522",
      Stadt: "Ansbach",
    },
    1347: {
      LadenId: 1347,
      Telefon: "+498951267896",
      Strasse: "Hackerbrücke 4",
      PLZ: "80335",
      Stadt: "München",
    },
    1349: {
      LadenId: 1349,
      Telefon: "+497322933644",
      Strasse: "Riedstraße 40/3",
      PLZ: "89537",
      Stadt: "Giengen an der Brenz",
    },
    1351: {
      LadenId: 1351,
      Telefon: "+497544740538",
      Strasse: "Schießstattweg 15",
      PLZ: "88677",
      Stadt: "Markdorf",
    },
    1352: {
      LadenId: 1352,
      Telefon: "+493514135031",
      Strasse: "Kesselsdorfer Straße 1",
      PLZ: "01159",
      Stadt: "Dresden",
    },
    1353: {
      LadenId: 1353,
      Telefon: "+4951519565163",
      Strasse: "Pferdemarkt 1",
      PLZ: "31785",
      Stadt: "Hameln",
    },
    1354: {
      LadenId: 1354,
      Telefon: "+4968725040539",
      Strasse: "Haagstraße 6",
      PLZ: "66679",
      Stadt: "Losheim am See",
    },
    1355: {
      LadenId: 1355,
      Telefon: "+496815880213",
      Strasse: "Hirtenwies 3",
      PLZ: "66117",
      Stadt: "Saarbrücken",
    },
    1356: {
      LadenId: 1356,
      Telefon: "+4962275499594",
      Strasse: "Hauptstraße 208",
      PLZ: "68789",
      Stadt: "St. Leon-Rot",
    },
    1357: {
      LadenId: 1357,
      Telefon: "+4986389846378",
      Strasse: "Teplitzer Straße 9",
      PLZ: "84478",
      Stadt: "Waldkraiburg",
    },
    1358: {
      LadenId: 1358,
      Telefon: "+4993140470999",
      Strasse: "Eichhornstraße 13",
      PLZ: "97070",
      Stadt: "Würzburg",
    },
    1359: {
      LadenId: 1359,
      Telefon: "+495112614324",
      Strasse: "Varrelheide 200A",
      PLZ: "30657",
      Stadt: "Hannover",
    },
    1360: {
      LadenId: 1360,
      Telefon: "+4971316427148",
      Strasse: "Deutschhofstraße 19",
      PLZ: "74072",
      Stadt: "Heilbronn",
    },
    1362: {
      LadenId: 1362,
      Telefon: "+4961629680908",
      Strasse: "Darmstädter Straße 37b",
      PLZ: "64354",
      Stadt: "Reinheim",
    },
    1363: {
      LadenId: 1363,
      Telefon: "+4951057795951",
      Strasse: "Bunsenstraße 15",
      PLZ: "30890",
      Stadt: "Barsinghausen",
    },
    1364: {
      LadenId: 1364,
      Telefon: "+4961922007746",
      Strasse: "Kapellenstraße 48",
      PLZ: "65830",
      Stadt: "Kriftel",
    },
    1365: {
      LadenId: 1365,
      Telefon: "+493031004060",
      Strasse: "Wilmersdorfer Straße 66a",
      PLZ: "10627",
      Stadt: "Berlin",
    },
    1366: {
      LadenId: 1366,
      Telefon: "+4961062661060",
      Strasse: "Alfred-Delp-Straße 46",
      PLZ: "63110",
      Stadt: "Rodgau",
    },
    1367: {
      LadenId: 1367,
      Telefon: "+4962157240680",
      Strasse: "Industriestraße 11e",
      PLZ: "67063",
      Stadt: "Ludwigshafen am Rhein",
    },
    1368: {
      LadenId: 1368,
      Telefon: "+499115063353",
      Strasse: "Laufamholzstraße 100",
      PLZ: "90482",
      Stadt: "Nürnberg",
    },
    1369: {
      LadenId: 1369,
      Telefon: "+495113530964",
      Strasse: "Marktstraße 47",
      PLZ: "30159",
      Stadt: "Hannover",
    },
    1372: {
      LadenId: 1372,
      Telefon: "+497731185704",
      Strasse: "Zolltafel 2",
      PLZ: "78224",
      Stadt: "Singen (Hohentwiel)",
    },
    1373: {
      LadenId: 1373,
      Telefon: "+4971591673608",
      Strasse: "Benzstraße 7",
      PLZ: "71272",
      Stadt: "Renningen",
    },
    1374: {
      LadenId: 1374,
      Telefon: "+496132710844",
      Strasse: "Nahering 5",
      PLZ: "55218",
      Stadt: "Ingelheim am Rhein",
    },
    1377: {
      LadenId: 1377,
      Telefon: "+497413485100",
      Strasse: "Steinhäuslebühl 7",
      PLZ: "78658",
      Stadt: "Zimmern ob Rottweil",
    },
    1378: {
      LadenId: 1378,
      Telefon: "+498171216190",
      Strasse: "Margeritenstraße 8",
      PLZ: "82515",
      Stadt: "Wolfratshausen",
    },
    1379: {
      LadenId: 1379,
      Telefon: "+4981063062369",
      Strasse: "Marktplatz 21",
      PLZ: "85598",
      Stadt: "Baldham",
    },
    1380: {
      LadenId: 1380,
      Telefon: "+496234305474",
      Strasse: "An der Fohlenweide 13a",
      PLZ: "67112",
      Stadt: "Mutterstadt",
    },
    1384: {
      LadenId: 1384,
      Telefon: "+493412247541",
      Strasse: "Grimmaische Straße 14",
      PLZ: "04109",
      Stadt: "Leipzig",
    },
    1390: {
      LadenId: 1390,
      Telefon: "+4961965611733",
      Strasse: "Höchster Straße 1",
      PLZ: "65835",
      Stadt: "Liederbach am Taunus",
    },
    1391: {
      LadenId: 1391,
      Telefon: "+495312503688",
      Strasse: "Celler Straße 30",
      PLZ: "38114",
      Stadt: "Braunschweig",
    },
    1394: {
      LadenId: 1394,
      Telefon: "+497844911369",
      Strasse: "Am Glockenloch 5",
      PLZ: "77866",
      Stadt: "Rheinau",
    },
    1395: {
      LadenId: 1395,
      Telefon: "+495113742734",
      Strasse: "Hildesheimer Straße 213",
      PLZ: "30519",
      Stadt: "Hannover",
    },
    1396: {
      LadenId: 1396,
      Telefon: "+496921655607",
      Strasse: "Zeil 106",
      PLZ: "60313",
      Stadt: "Frankfurt am Main",
    },
    1397: {
      LadenId: 1397,
      Telefon: "+4961514283967",
      Strasse: "Gutenbergstraße 5",
      PLZ: "64331",
      Stadt: "Weiterstadt",
    },
    1400: {
      LadenId: 1400,
      Telefon: "+495312635019",
      Strasse: "Donauknoten 10",
      PLZ: "38120",
      Stadt: "Braunschweig",
    },
    1401: {
      LadenId: 1401,
      Telefon: "+499114007697",
      Strasse: "Kallmünzer Straße 20",
      PLZ: "90480",
      Stadt: "Nürnberg",
    },
    1403: {
      LadenId: 1403,
      Telefon: "+4962034025835",
      Strasse: "Wallstadter Straße 51a",
      PLZ: "68526",
      Stadt: "Ladenburg",
    },
    1404: {
      LadenId: 1404,
      Telefon: "+493062607221",
      Strasse: "Hermannstraße 158a",
      PLZ: "12051",
      Stadt: "Berlin",
    },
    1405: {
      LadenId: 1405,
      Telefon: "+493031801835",
      Strasse: "Wilmersdorfer Straße 46",
      PLZ: "10627",
      Stadt: "Berlin",
    },
    1407: {
      LadenId: 1407,
      Telefon: "+4992811409068",
      Strasse: "Hans-Böckler-Straße 23",
      PLZ: "95032",
      Stadt: "Hof",
    },
    1408: {
      LadenId: 1408,
      Telefon: "+495618616733",
      Strasse: "Struthbachweg 24",
      PLZ: "34127",
      Stadt: "Kassel",
    },
    1410: {
      LadenId: 1410,
      Telefon: "+496511453596",
      Strasse: "Wisportstraße 12",
      PLZ: "54295",
      Stadt: "Trier",
    },
    1412: {
      LadenId: 1412,
      Telefon: "+493631462739",
      Strasse: "Emil-Reichardt-Straße 2",
      PLZ: "99734",
      Stadt: "Nordhausen",
    },
    1413: {
      LadenId: 1413,
      Telefon: "+4976429282891",
      Strasse: "Elsässer Straße 1a",
      PLZ: "79346",
      Stadt: "Endingen am Kaiserstuhl",
    },
    1414: {
      LadenId: 1414,
      Telefon: "+4971167451887",
      Strasse: "Heinrich-Hertz-Straße 36",
      PLZ: "70794",
      Stadt: "Filderstadt",
    },
    1420: {
      LadenId: 1420,
      Telefon: "+496923807671",
      Strasse: "Kaiserstraße 75",
      PLZ: "60329",
      Stadt: "Frankfurt am Main",
    },
    1423: {
      LadenId: 1423,
      Telefon: "+496960329929",
      Strasse: "Hedderichstraße 47-49",
      PLZ: "60594",
      Stadt: "Frankfurt am Main",
    },
    1424: {
      LadenId: 1424,
      Telefon: "+4982337390085",
      Strasse: "Gaußring 7",
      PLZ: "86415",
      Stadt: "Mering",
    },
    1426: {
      LadenId: 1426,
      Telefon: "+498963848385",
      Strasse: "Albert-Schweitzer-Straße 78",
      PLZ: "81735",
      Stadt: "München",
    },
    1427: {
      LadenId: 1427,
      Telefon: "+495113530982",
      Strasse: "Ernst-August-Platz 2",
      PLZ: "30159",
      Stadt: "Hannover",
    },
    1429: {
      LadenId: 1429,
      Telefon: "+493931215612",
      Strasse: "Breite Straße 70",
      PLZ: "39576",
      Stadt: "Stendal",
    },
    1430: {
      LadenId: 1430,
      Telefon: "+4961529878241",
      Strasse: "Helvetiastraße 3",
      PLZ: "64521",
      Stadt: "Groß-Gerau",
    },
    1433: {
      LadenId: 1433,
      Telefon: "+4961225333902",
      Strasse: "Borsigstraße 9",
      PLZ: "65205",
      Stadt: "Wiesbaden",
    },
    1434: {
      LadenId: 1434,
      Telefon: "+4961967690885",
      Strasse: "Elly-Beinhorn-Straße 11",
      PLZ: "65760",
      Stadt: "Eschborn",
    },
    1436: {
      LadenId: 1436,
      Telefon: "+495321393447",
      Strasse: "Breite Straße 98",
      PLZ: "38640",
      Stadt: "Goslar",
    },
    1437: {
      LadenId: 1437,
      Telefon: "+4982319574378",
      Strasse: "Germanenstraße 17",
      PLZ: "86343",
      Stadt: "Königsbrunn",
    },
    1438: {
      LadenId: 1438,
      Telefon: "+4977518962037",
      Strasse: "Robert-Gerwig-Straße 5",
      PLZ: "79761",
      Stadt: "Waldshut-Tiengen",
    },
    1439: {
      LadenId: 1439,
      Telefon: "+493083202667",
      Strasse: "Glarner Straße 53",
      PLZ: "12205",
      Stadt: "Berlin",
    },
    1440: {
      LadenId: 1440,
      Telefon: "+4980318079642",
      Strasse: "Grubholzer Straße 2",
      PLZ: "83026",
      Stadt: "Rosenheim",
    },
    1441: {
      LadenId: 1441,
      Telefon: "+496930038127",
      Strasse: "Königsteiner Straße 11-13",
      PLZ: "65929",
      Stadt: "Frankfurt am Main",
    },
    1442: {
      LadenId: 1442,
      Telefon: "+4997214754481",
      Strasse: "Schrammstraße 5",
      PLZ: "97421",
      Stadt: "Schweinfurt",
    },
    1443: {
      LadenId: 1443,
      Telefon: "+493441226466",
      Strasse: "Friedensstraße 25",
      PLZ: "06712",
      Stadt: "Zeitz",
    },
    1444: {
      LadenId: 1444,
      Telefon: "+4972529661493",
      Strasse: "Wilhelmstraße 43",
      PLZ: "75015",
      Stadt: "Bretten",
    },
    1446: {
      LadenId: 1446,
      Telefon: "+493053010138",
      Strasse: "Rummelsburger Straße 16",
      PLZ: "12459",
      Stadt: "Berlin",
    },
    1447: {
      LadenId: 1447,
      Telefon: "+497745926780",
      Strasse: "Hauptstraße 27",
      PLZ: "79798",
      Stadt: "Jestetten",
    },
    1448: {
      LadenId: 1448,
      Telefon: "+4972314550562",
      Strasse: "Kurze Steig 4",
      PLZ: "75179",
      Stadt: "Pforzheim",
    },
    1452: {
      LadenId: 1452,
      Telefon: "+4968141620557",
      Strasse: "Trierer Straße 1",
      PLZ: "66111",
      Stadt: "Saarbrücken",
    },
    1453: {
      LadenId: 1453,
      Telefon: "+496721187728",
      Strasse: "Hitchinstraße 45",
      PLZ: "55411",
      Stadt: "Bingen am Rhein",
    },
    1454: {
      LadenId: 1454,
      Telefon: "+496419717294",
      Strasse: "Seltersweg 77",
      PLZ: "35390",
      Stadt: "Gießen",
    },
    1456: {
      LadenId: 1456,
      Telefon: "+496519372783",
      Strasse: "Hornstraße 20",
      PLZ: "54294",
      Stadt: "Trier",
    },
    1458: {
      LadenId: 1458,
      Telefon: "+4986517619932",
      Strasse: "Bahnhofplatz 4",
      PLZ: "83435",
      Stadt: "Bad Reichenhall",
    },
    1460: {
      LadenId: 1460,
      Telefon: "+4972314285449",
      Strasse: "Eutinger Straße 164",
      PLZ: "75175",
      Stadt: "Pforzheim",
    },
    1461: {
      LadenId: 1461,
      Telefon: "+496071391520",
      Strasse: "Darmstädter Straße 85a",
      PLZ: "64839",
      Stadt: "Münster (Hessen)",
    },
    1467: {
      LadenId: 1467,
      Telefon: "+493056824956",
      Strasse: "Karl-Marx-Straße 92-98",
      PLZ: "12043",
      Stadt: "Berlin",
    },
    1471: {
      LadenId: 1471,
      Telefon: "+4995434183385",
      Strasse: "Amlingstadter Straße 47",
      PLZ: "96114",
      Stadt: "Hirschaid",
    },
    1472: {
      LadenId: 1472,
      Telefon: "+495306990338",
      Strasse: "Im Moorbusche 3",
      PLZ: "38162",
      Stadt: "Cremlingen",
    },
    1473: {
      LadenId: 1473,
      Telefon: "+496351124370",
      Strasse: "In den Geldäckern 6",
      PLZ: "67304",
      Stadt: "Eisenberg (Pfalz)",
    },
    1474: {
      LadenId: 1474,
      Telefon: "+496519790961",
      Strasse: "Fleischstraße 62-65",
      PLZ: "54290",
      Stadt: "Trier",
    },
    1475: {
      LadenId: 1475,
      Telefon: "+4971635323545",
      Strasse: "Bahnhofstraße 1",
      PLZ: "73061",
      Stadt: "Ebersbach an der Fils",
    },
    1478: {
      LadenId: 1478,
      Telefon: "+4971162007023",
      Strasse: "Hauptstätter Straße 149",
      PLZ: "70178",
      Stadt: "Stuttgart",
    },
    1479: {
      LadenId: 1479,
      Telefon: "+4980244745711",
      Strasse: "Rosenheimer Straße 21",
      PLZ: "83607",
      Stadt: "Holzkirchen",
    },
    1480: {
      LadenId: 1480,
      Telefon: "+4972478009227",
      Strasse: "Durlacher Weg 2",
      PLZ: "76351",
      Stadt: "Linkenheim-Hochstetten",
    },
    1482: {
      LadenId: 1482,
      Telefon: "+499116276802",
      Strasse: "Rothenburger Straße 26",
      PLZ: "90513",
      Stadt: "Zirndorf",
    },
    1483: {
      LadenId: 1483,
      Telefon: "+498514903939",
      Strasse: "Bahnhofstraße 1",
      PLZ: "94032",
      Stadt: "Passau",
    },
    1484: {
      LadenId: 1484,
      Telefon: "+497381921745",
      Strasse: "Gewerbestraße 16",
      PLZ: "72525",
      Stadt: "Münsingen",
    },
    1486: {
      LadenId: 1486,
      Telefon: "+4971613890064",
      Strasse: "Bahnhofstraße 15",
      PLZ: "73066",
      Stadt: "Uhingen",
    },
    1487: {
      LadenId: 1487,
      Telefon: "+4960742116227",
      Strasse: "Masayaplatz 3",
      PLZ: "63128",
      Stadt: "Dietzenbach",
    },
    1488: {
      LadenId: 1488,
      Telefon: "+493514817029",
      Strasse: "Prager Straße 15",
      PLZ: "01069",
      Stadt: "Dresden",
    },
    1489: {
      LadenId: 1489,
      Telefon: "+493381209738",
      Strasse: "Sankt-Annen-Straße 23",
      PLZ: "14776",
      Stadt: "Brandenburg an der Havel",
    },
    1490: {
      LadenId: 1490,
      Telefon: "+497356923393",
      Strasse: "Alte Biberacher Straße 51/6",
      PLZ: "88433",
      Stadt: "Schemmerhofen",
    },
    1491: {
      LadenId: 1491,
      Telefon: "+493020054369",
      Strasse: "Alexanderplatz 3",
      PLZ: "10178",
      Stadt: "Berlin",
    },
    1493: {
      LadenId: 1493,
      Telefon: "+497634507575",
      Strasse: "Im Stühlinger 56",
      PLZ: "79423",
      Stadt: "Heitersheim",
    },
    1494: {
      LadenId: 1494,
      Telefon: "+4981612349324",
      Strasse: "Karwendelring 1",
      PLZ: "85354",
      Stadt: "Freising",
    },
    1498: {
      LadenId: 1498,
      Telefon: "+498551912665",
      Strasse: "Speltenbach 60",
      PLZ: "94078",
      Stadt: "Freyung",
    },
    1500: {
      LadenId: 1500,
      Telefon: "+4961966526403",
      Strasse: "Königsteiner Straße 76",
      PLZ: "65812",
      Stadt: "Bad Soden am Taunus",
    },
    1501: {
      LadenId: 1501,
      Telefon: "+4961929799824",
      Strasse: "Beyerbachstraße 2-6",
      PLZ: "65830",
      Stadt: "Kriftel",
    },
    1507: {
      LadenId: 1507,
      Telefon: "+497215316210",
      Strasse: "Grünhutstraße 1",
      PLZ: "76187",
      Stadt: "Karlsruhe",
    },
    1509: {
      LadenId: 1509,
      Telefon: "+494218718030",
      Strasse: "Bremer Straße 106",
      PLZ: "28816",
      Stadt: "Stuhr",
    },
    1511: {
      LadenId: 1511,
      Telefon: "+4971124869724",
      Strasse: "Eberhardstraße 35/37",
      PLZ: "70173",
      Stadt: "Stuttgart",
    },
    1512: {
      LadenId: 1512,
      Telefon: "+498216088627",
      Strasse: "Unterer Talweg 60",
      PLZ: "86179",
      Stadt: "Augsburg",
    },
    1515: {
      LadenId: 1515,
      Telefon: "+4960222631905",
      Strasse: "Erlenbacher Straße 8",
      PLZ: "63820",
      Stadt: "Elsenfeld",
    },
    1516: {
      LadenId: 1516,
      Telefon: "+4991718515136",
      Strasse: "Ostring 6",
      PLZ: "91154",
      Stadt: "Roth",
    },
    1517: {
      LadenId: 1517,
      Telefon: "+4968715020969",
      Strasse: "Poststraße 36",
      PLZ: "66687",
      Stadt: "Wadern",
    },
    1519: {
      LadenId: 1519,
      Telefon: "+498514901138",
      Strasse: "Lindau 7",
      PLZ: "94034",
      Stadt: "Passau",
    },
    1521: {
      LadenId: 1521,
      Telefon: "+492826916296",
      Strasse: "Großen Haag 5b",
      PLZ: "47559",
      Stadt: "Kranenburg",
    },
    1523: {
      LadenId: 1523,
      Telefon: "+4974515529673",
      Strasse: "Bahnhofplatz 20",
      PLZ: "72160",
      Stadt: "Horb am Neckar",
    },
    1524: {
      LadenId: 1524,
      Telefon: "+4943314595250",
      Strasse: "Schleswiger Chaussee 41-43",
      PLZ: "24768",
      Stadt: "Rendsburg",
    },
    1527: {
      LadenId: 1527,
      Telefon: "+497333923133",
      Strasse: "Geislinger Straße 5",
      PLZ: "89150",
      Stadt: "Laichingen",
    },
    1531: {
      LadenId: 1531,
      Telefon: "+493091426588",
      Strasse: "Große-Leege-Straße 96",
      PLZ: "13055",
      Stadt: "Berlin",
    },
    1534: {
      LadenId: 1534,
      Telefon: "+495362726213",
      Strasse: "An der Klanze 17a",
      PLZ: "38554",
      Stadt: "Weyhausen",
    },
    1536: {
      LadenId: 1536,
      Telefon: "+4962217592763",
      Strasse: "Seestraße 71/2",
      PLZ: "69214",
      Stadt: "Eppelheim",
    },
    1537: {
      LadenId: 1537,
      Telefon: "+4941312239581",
      Strasse: "Häcklinger Weg 66c",
      PLZ: "21335",
      Stadt: "Lüneburg",
    },
    1538: {
      LadenId: 1538,
      Telefon: "+4972714080816",
      Strasse: "Kühgrunddamm 4",
      PLZ: "76744",
      Stadt: "Wörth am Rhein",
    },
    1539: {
      LadenId: 1539,
      Telefon: "+4942212890083",
      Strasse: "Seestraße 5",
      PLZ: "27755",
      Stadt: "Delmenhorst",
    },
    1540: {
      LadenId: 1540,
      Telefon: "+495302804479",
      Strasse: "Bodelschwinghstraße 9",
      PLZ: "38159",
      Stadt: "Vechelde",
    },
    1541: {
      LadenId: 1541,
      Telefon: "+4991814089548",
      Strasse: "Nürnberger Straße 44",
      PLZ: "92318",
      Stadt: "Neumarkt in der Oberpfalz",
    },
    1542: {
      LadenId: 1542,
      Telefon: "+4972312805678",
      Strasse: "Strietweg 70",
      PLZ: "75181",
      Stadt: "Pforzheim",
    },
    1544: {
      LadenId: 1544,
      Telefon: "+495041649753",
      Strasse: "Osttangente 8",
      PLZ: "31832",
      Stadt: "Springe",
    },
    1547: {
      LadenId: 1547,
      Telefon: "+4966125030953",
      Strasse: "Am Emaillierwerk 1",
      PLZ: "36043",
      Stadt: "Fulda",
    },
    1548: {
      LadenId: 1548,
      Telefon: "+496980052326",
      Strasse: "Marktplatz 10",
      PLZ: "63065",
      Stadt: "Offenbach am Main",
    },
    1549: {
      LadenId: 1549,
      Telefon: "+4962213344106",
      Strasse: "Felix-Wankel-Straße 18",
      PLZ: "69126",
      Stadt: "Heidelberg",
    },
    1550: {
      LadenId: 1550,
      Telefon: "+496511454649",
      Strasse: "Herzogenbuscher Straße 95",
      PLZ: "54292",
      Stadt: "Trier",
    },
    1551: {
      LadenId: 1551,
      Telefon: "+4979197817395",
      Strasse: "Dietrich-Bonhoeffer-Platz 2",
      PLZ: "74523",
      Stadt: "Schwäbisch Hall",
    },
    1552: {
      LadenId: 1552,
      Telefon: "+4974544071349",
      Strasse: "Bahnhofstraße 49",
      PLZ: "72172",
      Stadt: "Sulz am Neckar",
    },
    1555: {
      LadenId: 1555,
      Telefon: "+497225982038",
      Strasse: "Hauptstraße 36c",
      PLZ: "76571",
      Stadt: "Gaggenau",
    },
    1556: {
      LadenId: 1556,
      Telefon: "+494071005706",
      Strasse: "Möllner Landstraße 119",
      PLZ: "21509",
      Stadt: "Glinde",
    },
    1557: {
      LadenId: 1557,
      Telefon: "+4981422842455",
      Strasse: "Gadastraße 5",
      PLZ: "85232",
      Stadt: "Bergkirchen",
    },
    1560: {
      LadenId: 1560,
      Telefon: "+494215720282",
      Strasse: "Alter Dorfweg 30-50",
      PLZ: "28259",
      Stadt: "Bremen",
    },
    1561: {
      LadenId: 1561,
      Telefon: "+4961459338943",
      Strasse: "Wickerer Straße 50",
      PLZ: "65439",
      Stadt: "Flörsheim am Main",
    },
    1563: {
      LadenId: 1563,
      Telefon: "+493514798510",
      Strasse: "Sternstraße 2b",
      PLZ: "01139",
      Stadt: "Dresden",
    },
    1564: {
      LadenId: 1564,
      Telefon: "+4938559187904",
      Strasse: "Marienplatz 11",
      PLZ: "19053",
      Stadt: "Schwerin",
    },
    1565: {
      LadenId: 1565,
      Telefon: "+494022692151",
      Strasse: "Hamburger Straße 19",
      PLZ: "22083",
      Stadt: "Hamburg",
    },
    1566: {
      LadenId: 1566,
      Telefon: "+498912007549",
      Strasse: "Rotkreuzplatz 1",
      PLZ: "80634",
      Stadt: "München",
    },
    1567: {
      LadenId: 1567,
      Telefon: "+4960787823219",
      Strasse: "Georg-August-Zinn-Straße 74",
      PLZ: "64823",
      Stadt: "Groß-Umstadt",
    },
    1569: {
      LadenId: 1569,
      Telefon: "+4966211726076",
      Strasse: "Heinrich-von-Stephan-Straße 2",
      PLZ: "36251",
      Stadt: "Bad Hersfeld",
    },
    1570: {
      LadenId: 1570,
      Telefon: "+4976237418290",
      Strasse: "Güterstraße 1e",
      PLZ: "79618",
      Stadt: "Rheinfelden (Baden)",
    },
    1571: {
      LadenId: 1571,
      Telefon: "+497246305638",
      Strasse: "Sezanner Straße 5",
      PLZ: "76316",
      Stadt: "Malsch",
    },
    1572: {
      LadenId: 1572,
      Telefon: "+4972232817769",
      Strasse: "Güterstraße 8",
      PLZ: "77815",
      Stadt: "Bühl",
    },
    1574: {
      LadenId: 1574,
      Telefon: "+4961311430775",
      Strasse: "Lotharstraße 11-13",
      PLZ: "55116",
      Stadt: "Mainz",
    },
    1576: {
      LadenId: 1576,
      Telefon: "+4968425070446",
      Strasse: "Bliestalstraße 112a",
      PLZ: "66440",
      Stadt: "Blieskastel",
    },
    1577: {
      LadenId: 1577,
      Telefon: "+4965029356611",
      Strasse: "Im Ermesgraben 1i",
      PLZ: "54338",
      Stadt: "Schweich",
    },
    1579: {
      LadenId: 1579,
      Telefon: "+493843464285",
      Strasse: "Pferdemarkt 32",
      PLZ: "18273",
      Stadt: "Güstrow",
    },
    1583: {
      LadenId: 1583,
      Telefon: "+4999717610867",
      Strasse: "Werner-von-Siemens-Straße 40",
      PLZ: "93413",
      Stadt: "Cham",
    },
    1584: {
      LadenId: 1584,
      Telefon: "+4962038399778",
      Strasse: "Treidlerstraße 1",
      PLZ: "68535",
      Stadt: "Edingen-Neckarhausen",
    },
    1585: {
      LadenId: 1585,
      Telefon: "+4937295541439",
      Strasse: "Chemnitzer Straße 1",
      PLZ: "09385",
      Stadt: "Lugau",
    },
    1586: {
      LadenId: 1586,
      Telefon: "+4953319028264",
      Strasse: "Schweigerstraße 9a",
      PLZ: "38302",
      Stadt: "Wolfenbüttel",
    },
    1589: {
      LadenId: 1589,
      Telefon: "+4960399386556",
      Strasse: "Industriestraße 2",
      PLZ: "61184",
      Stadt: "Karben",
    },
    1590: {
      LadenId: 1590,
      Telefon: "+4984636029945",
      Strasse: "Hausener Straße 2",
      PLZ: "91171",
      Stadt: "Greding",
    },
    1591: {
      LadenId: 1591,
      Telefon: "+4951122848481",
      Strasse: "Marktplatz 11",
      PLZ: "30880",
      Stadt: "Laatzen",
    },
    1593: {
      LadenId: 1593,
      Telefon: "+4973172565043",
      Strasse: "Bahnhofstraße 1/1",
      PLZ: "89231",
      Stadt: "Neu-Ulm",
    },
    1594: {
      LadenId: 1594,
      Telefon: "+4927342849182",
      Strasse: "Am Silberstern 16",
      PLZ: "57258",
      Stadt: "Freudenberg",
    },
    1596: {
      LadenId: 1596,
      Telefon: "+4976189822156",
      Strasse: "Breisacher Straße 147",
      PLZ: "79110",
      Stadt: "Freiburg im Breisgau",
    },
    1597: {
      LadenId: 1597,
      Telefon: "+4960519670254",
      Strasse: "Lützelhäuser Weg 2",
      PLZ: "63571",
      Stadt: "Gelnhausen",
    },
    1598: {
      LadenId: 1598,
      Telefon: "+4943078238955",
      Strasse: "Mergenthalerstraße 24",
      PLZ: "24223",
      Stadt: "Schwentinental",
    },
    1599: {
      LadenId: 1599,
      Telefon: "+494030031134",
      Strasse: "Winterhuder Marktplatz 8",
      PLZ: "22299",
      Stadt: "Hamburg",
    },
    1600: {
      LadenId: 1600,
      Telefon: "+4970729229175",
      Strasse: "Nehrener Straße 4/1",
      PLZ: "72810",
      Stadt: "Gomaringen",
    },
    1602: {
      LadenId: 1602,
      Telefon: "+4991314010046",
      Strasse: "Allee am Röthelheimpark 11",
      PLZ: "91052",
      Stadt: "Erlangen",
    },
    1603: {
      LadenId: 1603,
      Telefon: "+4988217301549",
      Strasse: "Von-Brug-Straße 7",
      PLZ: "82467",
      Stadt: "Garmisch-Partenkirchen",
    },
    1604: {
      LadenId: 1604,
      Telefon: "+4998148179985",
      Strasse: "Rothenburger Straße 8B",
      PLZ: "91522",
      Stadt: "Ansbach",
    },
    1605: {
      LadenId: 1605,
      Telefon: "+493811285103",
      Strasse: "Kröpeliner Straße 85",
      PLZ: "18055",
      Stadt: "Rostock",
    },
    1606: {
      LadenId: 1606,
      Telefon: "+4981222275308",
      Strasse: "Johann-Auer-Straße 2",
      PLZ: "85435",
      Stadt: "Erding",
    },
    1608: {
      LadenId: 1608,
      Telefon: "+496924779613",
      Strasse: "Leipziger Straße 41-43",
      PLZ: "60487",
      Stadt: "Frankfurt am Main",
    },
    1613: {
      LadenId: 1613,
      Telefon: "+494032871903",
      Strasse: "Große Bergstraße 152-162",
      PLZ: "22767",
      Stadt: "Hamburg",
    },
    1614: {
      LadenId: 1614,
      Telefon: "+497062674056",
      Strasse: "Porschestraße 2",
      PLZ: "74360",
      Stadt: "Ilsfeld",
    },
    1616: {
      LadenId: 1616,
      Telefon: "+493021230901",
      Strasse: "Henriette-Herz-Platz 4",
      PLZ: "10178",
      Stadt: "Berlin",
    },
    1617: {
      LadenId: 1617,
      Telefon: "+4991919745109",
      Strasse: "Bamberger Straße 51",
      PLZ: "91301",
      Stadt: "Forchheim",
    },
    1619: {
      LadenId: 1619,
      Telefon: "+4953319028082",
      Strasse: "Adersheimer Straße 62",
      PLZ: "38304",
      Stadt: "Wolfenbüttel",
    },
    1621: {
      LadenId: 1621,
      Telefon: "+4976439360170",
      Strasse: "Seeweg 12",
      PLZ: "79336",
      Stadt: "Herbolzheim",
    },
    1622: {
      LadenId: 1622,
      Telefon: "+499282931398",
      Strasse: "Kronacher Straße 131",
      PLZ: "95119",
      Stadt: "Naila",
    },
    1623: {
      LadenId: 1623,
      Telefon: "+493053021849",
      Strasse: "Neue Grottkauer Straße 23",
      PLZ: "12619",
      Stadt: "Berlin",
    },
    1624: {
      LadenId: 1624,
      Telefon: "+4956916238650",
      Strasse: "Varnhagenstraße 7a",
      PLZ: "34454",
      Stadt: "Bad Arolsen",
    },
    1625: {
      LadenId: 1625,
      Telefon: "+4961468379764",
      Strasse: "Holger-Crafoord-Straße 2",
      PLZ: "65239",
      Stadt: "Hochheim am Main",
    },
    1627: {
      LadenId: 1627,
      Telefon: "+4990717058114",
      Strasse: "Rudolf-Diesel-Straße 10",
      PLZ: "89407",
      Stadt: "Dillingen a.d.Donau",
    },
    1628: {
      LadenId: 1628,
      Telefon: "+493061076846",
      Strasse: "Bergmannstraße 102",
      PLZ: "10961",
      Stadt: "Berlin",
    },
    1630: {
      LadenId: 1630,
      Telefon: "+4992150705660",
      Strasse: "Nürnberger Straße 3c",
      PLZ: "95448",
      Stadt: "Bayreuth",
    },
    1631: {
      LadenId: 1631,
      Telefon: "+4997214997273",
      Strasse: "Oskar-von-Miller-Straße 6",
      PLZ: "97424",
      Stadt: "Schweinfurt",
    },
    1633: {
      LadenId: 1633,
      Telefon: "+4942116899256",
      Strasse: "Hutfilterstraße 12-14",
      PLZ: "28195",
      Stadt: "Bremen",
    },
    1636: {
      LadenId: 1636,
      Telefon: "+4961367608937",
      Strasse: "Oppenheimer Straße 44",
      PLZ: "55268",
      Stadt: "Nieder-Olm",
    },
    1637: {
      LadenId: 1637,
      Telefon: "+497515069570",
      Strasse: "Argonnenstraße 9/4",
      PLZ: "88250",
      Stadt: "Weingarten",
    },
    1638: {
      LadenId: 1638,
      Telefon: "+4973613600724",
      Strasse: "Nördlicher Stadtgraben 6",
      PLZ: "73430",
      Stadt: "Aalen",
    },
    1639: {
      LadenId: 1639,
      Telefon: "+4927325531947",
      Strasse: "Marburger Straße 16-18",
      PLZ: "57223",
      Stadt: "Kreuztal",
    },
    1640: {
      LadenId: 1640,
      Telefon: "+4972513679321",
      Strasse: "Unteröwisheimer Straße 19",
      PLZ: "76698",
      Stadt: "Ubstadt-Weiher",
    },
    1641: {
      LadenId: 1641,
      Telefon: "+499351602527",
      Strasse: "Würzburger Straße 54",
      PLZ: "97737",
      Stadt: "Gemünden a. Main",
    },
    1642: {
      LadenId: 1642,
      Telefon: "+495414042750",
      Strasse: "Bramscher Straße 159",
      PLZ: "49088",
      Stadt: "Osnabrück",
    },
    1645: {
      LadenId: 1645,
      Telefon: "+4961579866131",
      Strasse: "Eberstädter Straße 94d",
      PLZ: "64319",
      Stadt: "Pfungstadt",
    },
    1646: {
      LadenId: 1646,
      Telefon: "+4981656095086",
      Strasse: "Dieselstraße 35",
      PLZ: "85386",
      Stadt: "Eching",
    },
    1647: {
      LadenId: 1647,
      Telefon: "+4981415359469",
      Strasse: "Maisacher Straße 122",
      PLZ: "82256",
      Stadt: "Fürstenfeldbruck",
    },
    1648: {
      LadenId: 1648,
      Telefon: "+4981312722134",
      Strasse: "Wettersteinring 5",
      PLZ: "85221",
      Stadt: "Dachau",
    },
    1649: {
      LadenId: 1649,
      Telefon: "+498960062265",
      Strasse: "Balanstraße 188",
      PLZ: "81549",
      Stadt: "München",
    },
    1650: {
      LadenId: 1650,
      Telefon: "+493036995030",
      Strasse: "Falkenseer Chaussee 241",
      PLZ: "13583",
      Stadt: "Berlin",
    },
    1651: {
      LadenId: 1651,
      Telefon: "+4991130709554",
      Strasse: "Lichtenfelser Straße 2a",
      PLZ: "90427",
      Stadt: "Nürnberg",
    },
    1652: {
      LadenId: 1652,
      Telefon: "+498933029787",
      Strasse: "Münchner Freiheit 7",
      PLZ: "80802",
      Stadt: "München",
    },
    1654: {
      LadenId: 1654,
      Telefon: "+4960299992991",
      Strasse: "Klinger 8",
      PLZ: "63776",
      Stadt: "Mömbris",
    },
    1655: {
      LadenId: 1655,
      Telefon: "+4963135790564",
      Strasse: "Merkurstraße 38",
      PLZ: "67663",
      Stadt: "Kaiserslautern",
    },
    1656: {
      LadenId: 1656,
      Telefon: "+4962223879119",
      Strasse: "Güterstraße 1",
      PLZ: "69168",
      Stadt: "Wiesloch",
    },
    1658: {
      LadenId: 1658,
      Telefon: "+493811215956",
      Strasse: "Handwerkstraße 1",
      PLZ: "18069",
      Stadt: "Rostock",
    },
    1660: {
      LadenId: 1660,
      Telefon: "+4973313059862",
      Strasse: "Bahnhofstraße 94",
      PLZ: "73312",
      Stadt: "Geislingen an der Steige",
    },
    1662: {
      LadenId: 1662,
      Telefon: "+4923813041643",
      Strasse: "Rautenstrauchstraße 55",
      PLZ: "59075",
      Stadt: "Hamm",
    },
    1663: {
      LadenId: 1663,
      Telefon: "+4974418638392",
      Strasse: "Planckstraße 12",
      PLZ: "72250",
      Stadt: "Freudenstadt",
    },
    1664: {
      LadenId: 1664,
      Telefon: "+494621290252",
      Strasse: "Lattenkamp 1",
      PLZ: "24837",
      Stadt: "Schleswig",
    },
    1666: {
      LadenId: 1666,
      Telefon: "+4970316310785",
      Strasse: "Altdorfer Straße 26",
      PLZ: "71088",
      Stadt: "Holzgerlingen",
    },
    1671: {
      LadenId: 1671,
      Telefon: "+497562913571",
      Strasse: "Achener Weg 1",
      PLZ: "88316",
      Stadt: "Isny im Allgäu",
    },
    1674: {
      LadenId: 1674,
      Telefon: "+4928225370765",
      Strasse: "Kaßstraße 42",
      PLZ: "46446",
      Stadt: "Emmerich",
    },
    1677: {
      LadenId: 1677,
      Telefon: "+4977212061598",
      Strasse: "Rietstraße 40",
      PLZ: "78050",
      Stadt: "Villingen-Schwenningen",
    },
    1678: {
      LadenId: 1678,
      Telefon: "+4962326042807",
      Strasse: "Wormser Landstraße 192",
      PLZ: "67346",
      Stadt: "Speyer",
    },
    1680: {
      LadenId: 1680,
      Telefon: "+4974716201100",
      Strasse: "Haigerlocher Straße 24",
      PLZ: "72379",
      Stadt: "Hechingen",
    },
    1681: {
      LadenId: 1681,
      Telefon: "+4983314908337",
      Strasse: "Kramerstraße 34",
      PLZ: "87700",
      Stadt: "Memmingen",
    },
    1683: {
      LadenId: 1683,
      Telefon: "+496983007111",
      Strasse: "Odenwaldring 70",
      PLZ: "63069",
      Stadt: "Offenbach am Main",
    },
    1684: {
      LadenId: 1684,
      Telefon: "+493020216659",
      Strasse: "Friedrichstraße 100",
      PLZ: "10117",
      Stadt: "Berlin",
    },
    1685: {
      LadenId: 1685,
      Telefon: "+493064328952",
      Strasse: "Bahnhofstraße 25",
      PLZ: "12555",
      Stadt: "Berlin",
    },
    1687: {
      LadenId: 1687,
      Telefon: "+4979519645867",
      Strasse: "Industriestraße 23/1",
      PLZ: "74589",
      Stadt: "Satteldorf",
    },
    1688: {
      LadenId: 1688,
      Telefon: "+4968675610565",
      Strasse: "Trierer Straße 43",
      PLZ: "66706",
      Stadt: "Perl",
    },
    1692: {
      LadenId: 1692,
      Telefon: "+4941212623455",
      Strasse: "An der Ost-West-Brücke 2",
      PLZ: "25335",
      Stadt: "Elmshorn",
    },
    1693: {
      LadenId: 1693,
      Telefon: "+4968219315254",
      Strasse: "Stummplatz 1",
      PLZ: "66538",
      Stadt: "Neunkirchen",
    },
    1696: {
      LadenId: 1696,
      Telefon: "+494312395134",
      Strasse: "Holtenauer Straße 122",
      PLZ: "24105",
      Stadt: "Kiel",
    },
    1697: {
      LadenId: 1697,
      Telefon: "+4978549899954",
      Strasse: "Römerstraße 86",
      PLZ: "77694",
      Stadt: "Kehl",
    },
    1698: {
      LadenId: 1698,
      Telefon: "+4976814747771",
      Strasse: "Industriestraße 2",
      PLZ: "79183",
      Stadt: "Waldkirch",
    },
    1699: {
      LadenId: 1699,
      Telefon: "+4978518866586",
      Strasse: "Blumenstraße 1",
      PLZ: "77694",
      Stadt: "Kehl",
    },
    1700: {
      LadenId: 1700,
      Telefon: "+4955613130288",
      Strasse: "Grimsehlstraße 40a",
      PLZ: "37574",
      Stadt: "Einbeck",
    },
    1705: {
      LadenId: 1705,
      Telefon: "+4956147590013",
      Strasse: "Frankfurter Straße 220",
      PLZ: "34134",
      Stadt: "Kassel",
    },
    1707: {
      LadenId: 1707,
      Telefon: "+4961814288704",
      Strasse: "Am Markt 20",
      PLZ: "63450",
      Stadt: "Hanau",
    },
    1710: {
      LadenId: 1710,
      Telefon: "+4933222121983",
      Strasse: "Wilmsstraße 7",
      PLZ: "14624",
      Stadt: "Dallgow-Döberitz",
    },
    1711: {
      LadenId: 1711,
      Telefon: "+4966194250574",
      Strasse: "Frankfurter Straße 71",
      PLZ: "36043",
      Stadt: "Fulda",
    },
    1712: {
      LadenId: 1712,
      Telefon: "+498990019665",
      Strasse: "Theodor-Fontane-Straße 14",
      PLZ: "85609",
      Stadt: "Aschheim",
    },
    1713: {
      LadenId: 1713,
      Telefon: "+4938163729716",
      Strasse: "Hansestraße 35",
      PLZ: "18182",
      Stadt: "Bentwisch",
    },
    1717: {
      LadenId: 1717,
      Telefon: "+4968975010340",
      Strasse: "Quierschieder Weg 3e",
      PLZ: "66280",
      Stadt: "Sulzbach/Saar",
    },
    1718: {
      LadenId: 1718,
      Telefon: "+4944418538461",
      Strasse: "Große Straße 90",
      PLZ: "49377",
      Stadt: "Vechta",
    },
    1719: {
      LadenId: 1719,
      Telefon: "+4956659690141",
      Strasse: "Fuldastraße 1-5",
      PLZ: "34225",
      Stadt: "Baunatal",
    },
    1720: {
      LadenId: 1720,
      Telefon: "+4960439865067",
      Strasse: "Am Rauner Graben 21",
      PLZ: "63667",
      Stadt: "Nidda",
    },
    1721: {
      LadenId: 1721,
      Telefon: "+494721393161",
      Strasse: "Abschnede 210",
      PLZ: "27472",
      Stadt: "Cuxhaven",
    },
    1722: {
      LadenId: 1722,
      Telefon: "+4937415980516",
      Strasse: "Alte Jößnitzer Str. 30",
      PLZ: "08525",
      Stadt: "Plauen",
    },
    1723: {
      LadenId: 1723,
      Telefon: "+4961013041385",
      Strasse: "Friedberger Straße 77b",
      PLZ: "61118",
      Stadt: "Bad Vilbel",
    },
    1725: {
      LadenId: 1725,
      Telefon: "+4994128015445",
      Strasse: "Von-Seeckt-Straße 23",
      PLZ: "93053",
      Stadt: "Regensburg",
    },
    1726: {
      LadenId: 1726,
      Telefon: "+497276929503",
      Strasse: "Im Riegel 5",
      PLZ: "76863",
      Stadt: "Herxheim",
    },
    1728: {
      LadenId: 1728,
      Telefon: "+4962012556960",
      Strasse: "Bergstraße 19-25",
      PLZ: "69469",
      Stadt: "Weinheim",
    },
    1729: {
      LadenId: 1729,
      Telefon: "+4976211610184",
      Strasse: "Im Rebacker 8",
      PLZ: "79591",
      Stadt: "Eimeldingen",
    },
    1730: {
      LadenId: 1730,
      Telefon: "+4946197888479",
      Strasse: "Angelburger Straße 8",
      PLZ: "24937",
      Stadt: "Flensburg",
    },
    1735: {
      LadenId: 1735,
      Telefon: "+4949616647907",
      Strasse: "Deverweg 33-37",
      PLZ: "26871",
      Stadt: "Papenburg",
    },
    1737: {
      LadenId: 1737,
      Telefon: "+493041727600",
      Strasse: "Kurt-Schumacher-Damm 1",
      PLZ: "13405",
      Stadt: "Berlin",
    },
    1739: {
      LadenId: 1739,
      Telefon: "+4963136123868",
      Strasse: "Fackelstraße 15",
      PLZ: "67655",
      Stadt: "Kaiserslautern",
    },
    1740: {
      LadenId: 1740,
      Telefon: "+4941528477367",
      Strasse: "Bergedorfer Straße 30-32",
      PLZ: "21502",
      Stadt: "Geesthacht",
    },
    1741: {
      LadenId: 1741,
      Telefon: "+4970713669699",
      Strasse: "Hagellocher Weg 57",
      PLZ: "72070",
      Stadt: "Tübingen",
    },
    1742: {
      LadenId: 1742,
      Telefon: "+4968815390285",
      Strasse: "Heeresstraße 10",
      PLZ: "66822",
      Stadt: "Lebach",
    },
    1743: {
      LadenId: 1743,
      Telefon: "+4935181063884",
      Strasse: "Bautzner Straße 35",
      PLZ: "01099",
      Stadt: "Dresden",
    },
    1744: {
      LadenId: 1744,
      Telefon: "+4970519352166",
      Strasse: "Lederstraße 41",
      PLZ: "75365",
      Stadt: "Calw",
    },
    1748: {
      LadenId: 1748,
      Telefon: "+4993679861894",
      Strasse: "Wachtelberg 18",
      PLZ: "97273",
      Stadt: "Kürnach",
    },
    1749: {
      LadenId: 1749,
      Telefon: "+4971144090098",
      Strasse: "Maybachstraße 5",
      PLZ: "70771",
      Stadt: "Leinfelden-Echterdingen",
    },
    1750: {
      LadenId: 1750,
      Telefon: "+4971182086642",
      Strasse: "Stuttgarter Straße 77",
      PLZ: "70469",
      Stadt: "Stuttgart",
    },
    1751: {
      LadenId: 1751,
      Telefon: "+4961811801466",
      Strasse: "Kinzigbogen 1",
      PLZ: "63452",
      Stadt: "Hanau",
    },
    1752: {
      LadenId: 1752,
      Telefon: "+4927442179890",
      Strasse: "Schneiderstraße 13",
      PLZ: "57562",
      Stadt: "Herdorf",
    },
    1753: {
      LadenId: 1753,
      Telefon: "+4951147320338",
      Strasse: "Göttinger Chaussee 103",
      PLZ: "30459",
      Stadt: "Hannover",
    },
    1754: {
      LadenId: 1754,
      Telefon: "+494072008908",
      Strasse: "Alte Holstenstraße 23-25",
      PLZ: "21031",
      Stadt: "Hamburg",
    },
    1756: {
      LadenId: 1756,
      Telefon: "+4953627269446",
      Strasse: "Wolfsburger Landstraße 1a",
      PLZ: "38442",
      Stadt: "Wolfsburg",
    },
    1758: {
      LadenId: 1758,
      Telefon: "+4962157240070",
      Strasse: "Knollstraße 16",
      PLZ: "67061",
      Stadt: "Ludwigshafen am Rhein",
    },
    1760: {
      LadenId: 1760,
      Telefon: "+4944121713306",
      Strasse: "Posthalterweg 6-8",
      PLZ: "26129",
      Stadt: "Oldenburg",
    },
    1761: {
      LadenId: 1761,
      Telefon: "+4978039214626",
      Strasse: "Allmend 1",
      PLZ: "77723",
      Stadt: "Gengenbach",
    },
    1762: {
      LadenId: 1762,
      Telefon: "+4943164890765",
      Strasse: "Stormarnstraße 31",
      PLZ: "24113",
      Stadt: "Kiel",
    },
    1763: {
      LadenId: 1763,
      Telefon: "+4964068360107",
      Strasse: "Rothweg 3a",
      PLZ: "35457",
      Stadt: "Lollar",
    },
    1764: {
      LadenId: 1764,
      Telefon: "+493040049881",
      Strasse: "Garbatyplatz 2",
      PLZ: "13187",
      Stadt: "Berlin",
    },
    1765: {
      LadenId: 1765,
      Telefon: "+4962234876309",
      Strasse: "Kurpfalzstraße 34",
      PLZ: "69151",
      Stadt: "Neckargemünd",
    },
    1768: {
      LadenId: 1768,
      Telefon: "+493928425522",
      Strasse: "Stadionstraße 10",
      PLZ: "39218",
      Stadt: "Schönebeck",
    },
    1769: {
      LadenId: 1769,
      Telefon: "+496819066815",
      Strasse: "Discontopassage 35",
      PLZ: "66111",
      Stadt: "Saarbrücken",
    },
    1772: {
      LadenId: 1772,
      Telefon: "+494030062812",
      Strasse: "Alsterkrugchaussee 531",
      PLZ: "22335",
      Stadt: "Hamburg",
    },
    1774: {
      LadenId: 1774,
      Telefon: "+4936346929405",
      Strasse: "Mainzer Straße 18",
      PLZ: "99610",
      Stadt: "Sömmerda",
    },
    1775: {
      LadenId: 1775,
      Telefon: "+4960713936456",
      Strasse: "Max-Planck-Straße 4",
      PLZ: "64846",
      Stadt: "Groß-Zimmern",
    },
    1776: {
      LadenId: 1776,
      Telefon: "+4987196549042",
      Strasse: "Rupprechtstraße 20",
      PLZ: "84034",
      Stadt: "Landshut",
    },
    1777: {
      LadenId: 1777,
      Telefon: "+4928249615767",
      Strasse: "Bahnhofstraße 18",
      PLZ: "47546",
      Stadt: "Kalkar",
    },
    1778: {
      LadenId: 1778,
      Telefon: "+4933423070411",
      Strasse: "Hönower Weg 1c",
      PLZ: "15366",
      Stadt: "Hoppegarten",
    },
    1779: {
      LadenId: 1779,
      Telefon: "+4992178779738",
      Strasse: "Friedrich-von-Schiller-Str. 35",
      PLZ: "95444",
      Stadt: "Bayreuth",
    },
    1782: {
      LadenId: 1782,
      Telefon: "+4943434214859",
      Strasse: "Bullbrücke 2",
      PLZ: "24235",
      Stadt: "Laboe",
    },
    1783: {
      LadenId: 1783,
      Telefon: "+494030085568",
      Strasse: "Lüneburger Straße 48",
      PLZ: "21073",
      Stadt: "Hamburg",
    },
    1784: {
      LadenId: 1784,
      Telefon: "+4936938846417",
      Strasse: "Georgstraße 11",
      PLZ: "98617",
      Stadt: "Meiningen",
    },
    1785: {
      LadenId: 1785,
      Telefon: "+4980819551271",
      Strasse: "Haager Straße 62",
      PLZ: "84405",
      Stadt: "Dorfen",
    },
    1786: {
      LadenId: 1786,
      Telefon: "+498943519152",
      Strasse: "Truderinger Straße 192",
      PLZ: "81825",
      Stadt: "München",
    },
    1787: {
      LadenId: 1787,
      Telefon: "+4970248689396",
      Strasse: "Imanuel-Maier-Straße 3",
      PLZ: "73257",
      Stadt: "Köngen",
    },
    1788: {
      LadenId: 1788,
      Telefon: "+498935043100",
      Strasse: "Frankfurter Ring 5",
      PLZ: "80807",
      Stadt: "München",
    },
    1789: {
      LadenId: 1789,
      Telefon: "+498981339987",
      Strasse: "Georg-Reismüller-Straße 28a",
      PLZ: "80999",
      Stadt: "München",
    },
    1791: {
      LadenId: 1791,
      Telefon: "+4991165678740",
      Strasse: "Konstanzenstraße 90",
      PLZ: "90439",
      Stadt: "Nürnberg",
    },
    1792: {
      LadenId: 1792,
      Telefon: "+4967829837693",
      Strasse: "Maiwiese 23",
      PLZ: "55765",
      Stadt: "Birkenfeld",
    },
    1793: {
      LadenId: 1793,
      Telefon: "+4987195389993",
      Strasse: "Ritter-von-Schoch-Straße 21b",
      PLZ: "84036",
      Stadt: "Landshut",
    },
    1796: {
      LadenId: 1796,
      Telefon: "+4973172565543",
      Strasse: "Im Starkfeld 57",
      PLZ: "89231",
      Stadt: "Neu-Ulm",
    },
    1797: {
      LadenId: 1797,
      Telefon: "+4959314099422",
      Strasse: "Bahnhofstraße 10",
      PLZ: "49716",
      Stadt: "Meppen",
    },
    1798: {
      LadenId: 1798,
      Telefon: "+4994219749819",
      Strasse: "Landshuter Straße 143",
      PLZ: "94315",
      Stadt: "Straubing",
    },
    1799: {
      LadenId: 1799,
      Telefon: "+4985619836905",
      Strasse: "Max-Lanz-Straße 2",
      PLZ: "84347",
      Stadt: "Pfarrkirchen",
    },
    1800: {
      LadenId: 1800,
      Telefon: "+4971125971498",
      Strasse: "Am Kochenhof 14",
      PLZ: "70192",
      Stadt: "Stuttgart",
    },
    1803: {
      LadenId: 1803,
      Telefon: "+4974732701306",
      Strasse: "Zeppelinstraße 25-27",
      PLZ: "72116",
      Stadt: "Mössingen",
    },
    1804: {
      LadenId: 1804,
      Telefon: "+4982158979066",
      Strasse: "Edisonstraße 2",
      PLZ: "86199",
      Stadt: "Augsburg",
    },
    1806: {
      LadenId: 1806,
      Telefon: "+498982070528",
      Strasse: "Pasinger Bahnhofsplatz 5",
      PLZ: "81241",
      Stadt: "München",
    },
    1807: {
      LadenId: 1807,
      Telefon: "+4951211020699",
      Strasse: "Arnekenstraße 18",
      PLZ: "31134",
      Stadt: "Hildesheim",
    },
    1808: {
      LadenId: 1808,
      Telefon: "+4970342539526",
      Strasse: "Max-Planck-Straße 26",
      PLZ: "71116",
      Stadt: "Gärtringen",
    },
    1809: {
      LadenId: 1809,
      Telefon: "+4960039350903",
      Strasse: "Dieselstraße 14",
      PLZ: "61191",
      Stadt: "Rosbach",
    },
    1812: {
      LadenId: 1812,
      Telefon: "+4938416312917",
      Strasse: "Zierower Landstraße 7",
      PLZ: "23968",
      Stadt: "Wismar",
    },
    1814: {
      LadenId: 1814,
      Telefon: "+4993418479831",
      Strasse: "Pestalozziallee 31",
      PLZ: "97941",
      Stadt: "Tauberbischofsheim",
    },
    1815: {
      LadenId: 1815,
      Telefon: "+494050797337",
      Strasse: "Tangstedter Landstrasse 49-51",
      PLZ: "22415",
      Stadt: "Hamburg",
    },
    1816: {
      LadenId: 1816,
      Telefon: "+4949145447807",
      Strasse: "Osseweg 87",
      PLZ: "26789",
      Stadt: "Leer",
    },
    1817: {
      LadenId: 1817,
      Telefon: "+4972513042531",
      Strasse: "Martin-Luther-Strasse 1",
      PLZ: "76646",
      Stadt: "Bruchsal",
    },
    1818: {
      LadenId: 1818,
      Telefon: "+4998526133475",
      Strasse: "Schopflocher Straße 12",
      PLZ: "91555",
      Stadt: "Feuchtwangen",
    },
    1821: {
      LadenId: 1821,
      Telefon: "+4943198283919",
      Strasse: "Holstenstraße 1-11",
      PLZ: "24103",
      Stadt: "Kiel",
    },
    1822: {
      LadenId: 1822,
      Telefon: "+498996049907",
      Strasse: "Osterfeldstraße 41a",
      PLZ: "85737",
      Stadt: "Ismaning",
    },
    1823: {
      LadenId: 1823,
      Telefon: "+4962374030367",
      Strasse: "Im Reff 1c",
      PLZ: "67133",
      Stadt: "Maxdorf",
    },
    1828: {
      LadenId: 1828,
      Telefon: "+4982518197247",
      Strasse: "Augsburger Straße 25",
      PLZ: "86551",
      Stadt: "Aichach",
    },
    1829: {
      LadenId: 1829,
      Telefon: "+4938347770941",
      Strasse: "Marktflecken 1",
      PLZ: "17498",
      Stadt: "Neuenkirchen",
    },
    1830: {
      LadenId: 1830,
      Telefon: "+498951261581",
      Strasse: "Fürstenrieder Straße 51",
      PLZ: "80686",
      Stadt: "München",
    },
    1831: {
      LadenId: 1831,
      Telefon: "+4961117449932",
      Strasse: "Rostocker Straße 2",
      PLZ: "65191",
      Stadt: "Wiesbaden",
    },
    1832: {
      LadenId: 1832,
      Telefon: "+4960213697566",
      Strasse: "Kulmbacher Straße 2",
      PLZ: "63743",
      Stadt: "Aschaffenburg",
    },
    1833: {
      LadenId: 1833,
      Telefon: "+4963614590176",
      Strasse: "Kaiserslauterer Straße 7",
      PLZ: "67806",
      Stadt: "Rockenhausen",
    },
    1834: {
      LadenId: 1834,
      Telefon: "+4946197888263",
      Strasse: "Schottweg 94",
      PLZ: "24944",
      Stadt: "Flensburg",
    },
    1835: {
      LadenId: 1835,
      Telefon: "+4972224067019",
      Strasse: "In der Kleinau 1",
      PLZ: "76456",
      Stadt: "Kuppenheim",
    },
    1836: {
      LadenId: 1836,
      Telefon: "+4934433364466",
      Strasse: "Merseburger Straße 135",
      PLZ: "06667",
      Stadt: "Weißenfels",
    },
    1837: {
      LadenId: 1837,
      Telefon: "+4973613600456",
      Strasse: "Gartenstraße 101",
      PLZ: "73430",
      Stadt: "Aalen",
    },
    1839: {
      LadenId: 1839,
      Telefon: "+4971589569815",
      Strasse: "Scharnhäuser Straße 5",
      PLZ: "73765",
      Stadt: "Neuhausen auf den Fildern",
    },
    1840: {
      LadenId: 1840,
      Telefon: "+498972649889",
      Strasse: "Albert-Roßhaupter-Straße 37",
      PLZ: "81369",
      Stadt: "München",
    },
    1842: {
      LadenId: 1842,
      Telefon: "+4936555231961",
      Strasse: "Zeulsdorfer Straße 85",
      PLZ: "07549",
      Stadt: "Gera",
    },
    1843: {
      LadenId: 1843,
      Telefon: "+4995191700858",
      Strasse: "Pödeldorfer Straße 136-138",
      PLZ: "96052",
      Stadt: "Bamberg",
    },
    1845: {
      LadenId: 1845,
      Telefon: "+493067068642",
      Strasse: "Schnellerstraße 74",
      PLZ: "12439",
      Stadt: "Berlin",
    },
    1847: {
      LadenId: 1847,
      Telefon: "+4994211887344",
      Strasse: "Ludwigsplatz 37",
      PLZ: "94315",
      Stadt: "Straubing",
    },
    1849: {
      LadenId: 1849,
      Telefon: "+4994313819009",
      Strasse: "Paul-von-Denis-Straße 2",
      PLZ: "92421",
      Stadt: "Schwandorf",
    },
    1850: {
      LadenId: 1850,
      Telefon: "+493050016972",
      Strasse: "Oberfeldstraße 138",
      PLZ: "12683",
      Stadt: "Berlin",
    },
    1851: {
      LadenId: 1851,
      Telefon: "+4929219819417",
      Strasse: "Riga-Ring 11",
      PLZ: "59494",
      Stadt: "Soest",
    },
    1852: {
      LadenId: 1852,
      Telefon: "+4951121359158",
      Strasse: "Große Packhofstraße 44",
      PLZ: "30159",
      Stadt: "Hannover",
    },
    1854: {
      LadenId: 1854,
      Telefon: "+4961032022761",
      Strasse: "Industriestraße 7",
      PLZ: "63303",
      Stadt: "Dreieich",
    },
    1856: {
      LadenId: 1856,
      Telefon: "+4983314908857",
      Strasse: "Woringer Straße 9",
      PLZ: "87700",
      Stadt: "Memmingen",
    },
    1857: {
      LadenId: 1857,
      Telefon: "+4935120566996",
      Strasse: "Alttolkewitz 26",
      PLZ: "01279",
      Stadt: "Dresden",
    },
    1860: {
      LadenId: 1860,
      Telefon: "+4943314340931",
      Strasse: "Bismarckstraße 12-14",
      PLZ: "24768",
      Stadt: "Rendsburg",
    },
    1861: {
      LadenId: 1861,
      Telefon: "+4928217118843",
      Strasse: "Emmericher Straße 237b",
      PLZ: "47533",
      Stadt: "Kleve",
    },
    1862: {
      LadenId: 1862,
      Telefon: "+4971536149339",
      Strasse: "Kieswiesen 18",
      PLZ: "73776",
      Stadt: "Altbach",
    },
    1863: {
      LadenId: 1863,
      Telefon: "+4971279606273",
      Strasse: "Riedstraße 6",
      PLZ: "72631",
      Stadt: "Aichtal",
    },
    1865: {
      LadenId: 1865,
      Telefon: "+496934002805",
      Strasse: "Westerbachstraße 152",
      PLZ: "65936",
      Stadt: "Frankfurt am Main",
    },
    1866: {
      LadenId: 1866,
      Telefon: "+4993140409784",
      Strasse: "Am Geisberg 23",
      PLZ: "97209",
      Stadt: "Veitshöchheim",
    },
    1867: {
      LadenId: 1867,
      Telefon: "+4941813704819",
      Strasse: "Breite Straße 16",
      PLZ: "21244",
      Stadt: "Buchholz in der Nordheide",
    },
    1868: {
      LadenId: 1868,
      Telefon: "+4956152990006",
      Strasse: "Heiligenröder Straße 51",
      PLZ: "34123",
      Stadt: "Kassel",
    },
    1871: {
      LadenId: 1871,
      Telefon: "+4995151935439",
      Strasse: "Würzburger Straße 57a",
      PLZ: "96049",
      Stadt: "Bamberg",
    },
    1872: {
      LadenId: 1872,
      Telefon: "+4933627000622",
      Strasse: "Friedrichstraße 13",
      PLZ: "15537",
      Stadt: "Erkner",
    },
    1873: {
      LadenId: 1873,
      Telefon: "+4975313611074",
      Strasse: "Reichenaustraße 36",
      PLZ: "78467",
      Stadt: "Konstanz",
    },
    1874: {
      LadenId: 1874,
      Telefon: "+4936437737026",
      Strasse: "Theaterplatz 2a",
      PLZ: "99423",
      Stadt: "Weimar",
    },
    1876: {
      LadenId: 1876,
      Telefon: "+496956003702",
      Strasse: "Eschersheimer Landstr. 178-182",
      PLZ: "60320",
      Stadt: "Frankfurt am Main",
    },
    1877: {
      LadenId: 1877,
      Telefon: "+4973137935993",
      Strasse: "Frauenstraße 1",
      PLZ: "89073",
      Stadt: "Ulm",
    },
    1878: {
      LadenId: 1878,
      Telefon: "+493030398369",
      Strasse: "Gartenfelder Straße 95",
      PLZ: "13599",
      Stadt: "Berlin",
    },
    1879: {
      LadenId: 1879,
      Telefon: "+4951135379982",
      Strasse: "An der Weide 14",
      PLZ: "30173",
      Stadt: "Hannover",
    },
    1882: {
      LadenId: 1882,
      Telefon: "+498972440520",
      Strasse: "Kistlerhofstraße 154",
      PLZ: "81379",
      Stadt: "München",
    },
    1883: {
      LadenId: 1883,
      Telefon: "+4970824131519",
      Strasse: "Daimlerstraße 17",
      PLZ: "75305",
      Stadt: "Neuenbürg",
    },
    1885: {
      LadenId: 1885,
      Telefon: "+498961100038",
      Strasse: "Biberger Straße 64",
      PLZ: "82008",
      Stadt: "Unterhaching",
    },
    1886: {
      LadenId: 1886,
      Telefon: "+4976358240189",
      Strasse: "Freiburger Straße 21",
      PLZ: "79418",
      Stadt: "Schliengen",
    },
    1889: {
      LadenId: 1889,
      Telefon: "+4971147099983",
      Strasse: "Sophienstraße 21",
      PLZ: "70178",
      Stadt: "Stuttgart",
    },
    1890: {
      LadenId: 1890,
      Telefon: "+4944121719461",
      Strasse: "Hundsmühler Straße 135-143",
      PLZ: "26131",
      Stadt: "Oldenburg",
    },
    1891: {
      LadenId: 1891,
      Telefon: "+4937127809790",
      Strasse: "Wladimir-Sagorski-Straße 22",
      PLZ: "09122",
      Stadt: "Chemnitz",
    },
    1894: {
      LadenId: 1894,
      Telefon: "+493041719997",
      Strasse: "Badstraße 16",
      PLZ: "13357",
      Stadt: "Berlin",
    },
    1895: {
      LadenId: 1895,
      Telefon: "+493063494815",
      Strasse: "Kaiser-Wilhelm-Straße 76-78",
      PLZ: "12247",
      Stadt: "Berlin",
    },
    1897: {
      LadenId: 1897,
      Telefon: "+4967179468974",
      Strasse: "Bosenheimer Straße 251",
      PLZ: "55543",
      Stadt: "Bad Kreuznach",
    },
    1898: {
      LadenId: 1898,
      Telefon: "+4963017168718",
      Strasse: "Meisenweg 5",
      PLZ: "67731",
      Stadt: "Otterbach",
    },
    1899: {
      LadenId: 1899,
      Telefon: "+4972614021964",
      Strasse: "Steinsbergstraße 1",
      PLZ: "74889",
      Stadt: "Sinsheim",
    },
    1900: {
      LadenId: 1900,
      Telefon: "+493061202456",
      Strasse: "Hermannplatz 1",
      PLZ: "10967",
      Stadt: "Berlin",
    },
    1901: {
      LadenId: 1901,
      Telefon: "+493051061674",
      Strasse: "Massower Straße 5",
      PLZ: "10315",
      Stadt: "Berlin",
    },
    1904: {
      LadenId: 1904,
      Telefon: "+4996219177453",
      Strasse: "Am Bergsteig 2",
      PLZ: "92224",
      Stadt: "Amberg",
    },
    1905: {
      LadenId: 1905,
      Telefon: "+4994046410015",
      Strasse: "An der Steinbuchse 2",
      PLZ: "93152",
      Stadt: "Nittendorf",
    },
    1906: {
      LadenId: 1906,
      Telefon: "+4971304006850",
      Strasse: "Willsbacher Straße 13",
      PLZ: "74182",
      Stadt: "Obersulm",
    },
    1907: {
      LadenId: 1907,
      Telefon: "+496924240790",
      Strasse: "Louis-Appia-Passage 6",
      PLZ: "60314",
      Stadt: "Frankfurt am Main",
    },
    1910: {
      LadenId: 1910,
      Telefon: "+493080109139",
      Strasse: "Breisgauer Straße 5",
      PLZ: "14129",
      Stadt: "Berlin",
    },
    1911: {
      LadenId: 1911,
      Telefon: "+4941024579842",
      Strasse: "Brückenstraße 1",
      PLZ: "22926",
      Stadt: "Ahrensburg",
    },
    1912: {
      LadenId: 1912,
      Telefon: "+4927125077944",
      Strasse: "Hauptmarkt 1",
      PLZ: "57076",
      Stadt: "Siegen",
    },
    1915: {
      LadenId: 1915,
      Telefon: "+4927514119766",
      Strasse: "Sählingstraße 1a",
      PLZ: "57319",
      Stadt: "Bad Berleburg",
    },
    1917: {
      LadenId: 1917,
      Telefon: "+498962000426",
      Strasse: "Tegernseer Landstr. 64",
      PLZ: "81541",
      Stadt: "München",
    },
    1918: {
      LadenId: 1918,
      Telefon: "+4986311883929",
      Strasse: "Siemensstraße 6",
      PLZ: "84453",
      Stadt: "Mühldorf a. Inn",
    },
    1919: {
      LadenId: 1919,
      Telefon: "+4991197792386",
      Strasse: "Kurt-Scherzer-Straße 4",
      PLZ: "90768",
      Stadt: "Fürth",
    },
    1921: {
      LadenId: 1921,
      Telefon: "+4977209417933",
      Strasse: "Rottweiler Straße 120",
      PLZ: "78056",
      Stadt: "Villingen-Schwenningen",
    },
    1923: {
      LadenId: 1923,
      Telefon: "+493020050989",
      Strasse: "Revaler Straße 2",
      PLZ: "10243",
      Stadt: "Berlin",
    },
    1925: {
      LadenId: 1925,
      Telefon: "+4991037144794",
      Strasse: "Nürnberger Straße 32",
      PLZ: "90556",
      Stadt: "Cadolzburg",
    },
    1926: {
      LadenId: 1926,
      Telefon: "+4954191194504",
      Strasse: "Pagenstecherstraße 133",
      PLZ: "49090",
      Stadt: "Osnabrück",
    },
    1927: {
      LadenId: 1927,
      Telefon: "+4964216200289",
      Strasse: "Neue Kasseler Straße 26",
      PLZ: "35039",
      Stadt: "Marburg",
    },
    1928: {
      LadenId: 1928,
      Telefon: "+4962511039296",
      Strasse: "Fabrikstraße 14",
      PLZ: "64625",
      Stadt: "Bensheim",
    },
    1929: {
      LadenId: 1929,
      Telefon: "+493039407682",
      Strasse: "Turmstraße 29",
      PLZ: "10551",
      Stadt: "Berlin",
    },
    1932: {
      LadenId: 1932,
      Telefon: "+4962112508793",
      Strasse: "P 2 1-3",
      PLZ: "68161",
      Stadt: "Mannheim",
    },
    1935: {
      LadenId: 1935,
      Telefon: "+4993140480015",
      Strasse: "Heidelberger Straße 101a",
      PLZ: "97204",
      Stadt: "Höchberg",
    },
    1936: {
      LadenId: 1936,
      Telefon: "+4962110756464",
      Strasse: "Q 7 1",
      PLZ: "68161",
      Stadt: "Mannheim",
    },
    1937: {
      LadenId: 1937,
      Telefon: "+4927140598482",
      Strasse: "Hagener Straße 42",
      PLZ: "57072",
      Stadt: "Siegen",
    },
    1938: {
      LadenId: 1938,
      Telefon: "+4960279796959",
      Strasse: "Mainparkstraße 1",
      PLZ: "63801",
      Stadt: "Kleinostheim",
    },
    1940: {
      LadenId: 1940,
      Telefon: "+4933413022666",
      Strasse: "Am Annatal 59",
      PLZ: "15344",
      Stadt: "Strausberg",
    },
    1942: {
      LadenId: 1942,
      Telefon: "+4991226314400",
      Strasse: "Am Falbenholzweg 15",
      PLZ: "91126",
      Stadt: "Schwabach",
    },
    1943: {
      LadenId: 1943,
      Telefon: "+498980077588",
      Strasse: "Münchener Straße 1",
      PLZ: "82110",
      Stadt: "Germering",
    },
    1944: {
      LadenId: 1944,
      Telefon: "+493072011889",
      Strasse: "Schloßstraße 15",
      PLZ: "12163",
      Stadt: "Berlin",
    },
    1945: {
      LadenId: 1945,
      Telefon: "+4941031808562",
      Strasse: "Bahnhofstraße 24",
      PLZ: "22880",
      Stadt: "Wedel",
    },
    1946: {
      LadenId: 1946,
      Telefon: "+4967329349867",
      Strasse: "Gutenbergstraße 5",
      PLZ: "55286",
      Stadt: "Wörrstadt",
    },
    1947: {
      LadenId: 1947,
      Telefon: "+4984199343944",
      Strasse: "Ludwigstraße 15",
      PLZ: "85049",
      Stadt: "Ingolstadt",
    },
    1948: {
      LadenId: 1948,
      Telefon: "+4962014709643",
      Strasse: "Berliner Straße 5",
      PLZ: "69502",
      Stadt: "Hemsbach",
    },
    1950: {
      LadenId: 1950,
      Telefon: "+493094397276",
      Strasse: "Neue Kärntner Straße 1",
      PLZ: "16341",
      Stadt: "Panketal",
    },
    1952: {
      LadenId: 1952,
      Telefon: "+4966125030440",
      Strasse: "Bahnhofstraße 18",
      PLZ: "36037",
      Stadt: "Fulda",
    },
    1954: {
      LadenId: 1954,
      Telefon: "+493041726082",
      Strasse: "Rigaer Straße 67",
      PLZ: "10247",
      Stadt: "Berlin",
    },
    1955: {
      LadenId: 1955,
      Telefon: "+4934122541494",
      Strasse: "Brühl 1",
      PLZ: "04109",
      Stadt: "Leipzig",
    },
    1957: {
      LadenId: 1957,
      Telefon: "+4951216971734",
      Strasse: "Bavenstedter Straße 62",
      PLZ: "31135",
      Stadt: "Hildesheim",
    },
    1959: {
      LadenId: 1959,
      Telefon: "+4951614812014",
      Strasse: "Lange Straße 58-62",
      PLZ: "29664",
      Stadt: "Walsrode",
    },
    1960: {
      LadenId: 1960,
      Telefon: "+4955519099317",
      Strasse: "Nordhäuser Weg 11",
      PLZ: "37154",
      Stadt: "Northeim",
    },
    1961: {
      LadenId: 1961,
      Telefon: "+4973136080097",
      Strasse: "Blaubeurer Straße 68",
      PLZ: "89077",
      Stadt: "Ulm",
    },
    1962: {
      LadenId: 1962,
      Telefon: "+4994713062731",
      Strasse: "Regensburger Straße 62",
      PLZ: "93133",
      Stadt: "Burglengenfeld",
    },
    1963: {
      LadenId: 1963,
      Telefon: "+4972175099072",
      Strasse: "Lorbeerweg 3",
      PLZ: "76149",
      Stadt: "Karlsruhe",
    },
    1964: {
      LadenId: 1964,
      Telefon: "+4972436529580",
      Strasse: "Talstraße 6",
      PLZ: "76337",
      Stadt: "Waldbronn",
    },
    1965: {
      LadenId: 1965,
      Telefon: "+4967192028827",
      Strasse: "Arthur-Rauner-Straße 3",
      PLZ: "55595",
      Stadt: "Hargesheim",
    },
    1968: {
      LadenId: 1968,
      Telefon: "+4984188197142",
      Strasse: "Am Westpark 6",
      PLZ: "85057",
      Stadt: "Ingolstadt",
    },
    1969: {
      LadenId: 1969,
      Telefon: "+4964529110148",
      Strasse: "Ringstraße 9",
      PLZ: "35108",
      Stadt: "Allendorf (Eder)",
    },
    1971: {
      LadenId: 1971,
      Telefon: "+4981415372195",
      Strasse: "Tannenstraße 6",
      PLZ: "82223",
      Stadt: "Eichenau",
    },
    1972: {
      LadenId: 1972,
      Telefon: "+498952012613",
      Strasse: "Dachauer Straße 92",
      PLZ: "80335",
      Stadt: "München",
    },
    1973: {
      LadenId: 1973,
      Telefon: "+498950072234",
      Strasse: "Westendstraße 98",
      PLZ: "80339",
      Stadt: "München",
    },
    1974: {
      LadenId: 1974,
      Telefon: "+4963527190283",
      Strasse: "Woogmorgen 16",
      PLZ: "67292",
      Stadt: "Kirchheimbolanden",
    },
    1975: {
      LadenId: 1975,
      Telefon: "+493053014798",
      Strasse: "Baumschulenstraße 86",
      PLZ: "12437",
      Stadt: "Berlin",
    },
    1976: {
      LadenId: 1976,
      Telefon: "+4945316707973",
      Strasse: "Mühlenstraße 18-20",
      PLZ: "23843",
      Stadt: "Bad Oldesloe",
    },
    1977: {
      LadenId: 1977,
      Telefon: "+496929721689",
      Strasse: "Berger Straße 147",
      PLZ: "60385",
      Stadt: "Frankfurt am Main",
    },
    1978: {
      LadenId: 1978,
      Telefon: "+4966229150480",
      Strasse: "Nürnberger Straße 43-47",
      PLZ: "36179",
      Stadt: "Bebra",
    },
    1979: {
      LadenId: 1979,
      Telefon: "+4971523304381",
      Strasse: "Steinbeisstraße 1",
      PLZ: "71229",
      Stadt: "Leonberg",
    },
    1980: {
      LadenId: 1980,
      Telefon: "+498912003255",
      Strasse: "Friedenheimer Brücke 23",
      PLZ: "80639",
      Stadt: "München",
    },
    1981: {
      LadenId: 1981,
      Telefon: "+4933012029210",
      Strasse: "Stralsunder Straße 9",
      PLZ: "16515",
      Stadt: "Oranienburg",
    },
    1984: {
      LadenId: 1984,
      Telefon: "+4937317759858",
      Strasse: "Obermarkt 4",
      PLZ: "09599",
      Stadt: "Freiberg",
    },
    1985: {
      LadenId: 1985,
      Telefon: "+4960629562476",
      Strasse: "Werner-von-Siemens-Straße 28",
      PLZ: "64711",
      Stadt: "Erbach",
    },
    1986: {
      LadenId: 1986,
      Telefon: "+4977428505563",
      Strasse: "Weisweiler Straße 8",
      PLZ: "79771",
      Stadt: "Klettgau",
    },
    1988: {
      LadenId: 1988,
      Telefon: "+4941615007976",
      Strasse: "Breite Straße 4",
      PLZ: "21614",
      Stadt: "Buxtehude",
    },
    1989: {
      LadenId: 1989,
      Telefon: "+494030031284",
      Strasse: "Eppendorfer Landstraße 108",
      PLZ: "20249",
      Stadt: "Hamburg",
    },
    1991: {
      LadenId: 1991,
      Telefon: "+4948219574938",
      Strasse: "Lise-Meitner-Straße 3",
      PLZ: "25524",
      Stadt: "Itzehoe",
    },
    1992: {
      LadenId: 1992,
      Telefon: "+4933755216854",
      Strasse: "Freiheitstraße 57",
      PLZ: "15745",
      Stadt: "Wildau",
    },
    1993: {
      LadenId: 1993,
      Telefon: "+4961558239238",
      Strasse: "Oberndorfer Straße 109",
      PLZ: "64347",
      Stadt: "Griesheim",
    },
    1994: {
      LadenId: 1994,
      Telefon: "+493091202394",
      Strasse: "Hubertusdamm 56",
      PLZ: "13125",
      Stadt: "Berlin",
    },
    1995: {
      LadenId: 1995,
      Telefon: "+493040043622",
      Strasse: "Goethestraße 3",
      PLZ: "10409",
      Stadt: "Berlin",
    },
    1996: {
      LadenId: 1996,
      Telefon: "+493041202189",
      Strasse: "Ollenhauerstraße 105b",
      PLZ: "13403",
      Stadt: "Berlin",
    },
    1997: {
      LadenId: 1997,
      Telefon: "+4951212061135",
      Strasse: "Bischofskamp 45",
      PLZ: "31137",
      Stadt: "Hildesheim",
    },
    2000: {
      LadenId: 2000,
      Telefon: "+4936834079499",
      Strasse: "Kasseler Straße 42",
      PLZ: "98574",
      Stadt: "Schmalkalden",
    },
    2001: {
      LadenId: 2001,
      Telefon: "+4929419687732",
      Strasse: "Lange Straße 33-35",
      PLZ: "59555",
      Stadt: "Lippstadt",
    },
    2003: {
      LadenId: 2003,
      Telefon: "+4933201430080",
      Strasse: "Potsdamer Chaussee 30",
      PLZ: "14476",
      Stadt: "Potsdam",
    },
    2004: {
      LadenId: 2004,
      Telefon: "+4961092483005",
      Strasse: "Victor-Slotosch-Straße 14",
      PLZ: "60388",
      Stadt: "Frankfurt am Main",
    },
    2007: {
      LadenId: 2007,
      Telefon: "+4961639138258",
      Strasse: "Wernher-von-Braun-Straße 2-6",
      PLZ: "64739",
      Stadt: "Höchst",
    },
    2011: {
      LadenId: 2011,
      Telefon: "+4925643961612",
      Strasse: "Wüllener Straße 55",
      PLZ: "48691",
      Stadt: "Vreden",
    },
    2012: {
      LadenId: 2012,
      Telefon: "+4984219071144",
      Strasse: "Sollnau 29",
      PLZ: "85072",
      Stadt: "Eichstätt",
    },
    2013: {
      LadenId: 2013,
      Telefon: "+4980537989821",
      Strasse: "Im Gewerbegebiet 16",
      PLZ: "83093",
      Stadt: "Bad Endorf",
    },
    2014: {
      LadenId: 2014,
      Telefon: "+4971215629831",
      Strasse: "Karl-von-Drais-Straße 3",
      PLZ: "72793",
      Stadt: "Pfullingen",
    },
    2015: {
      LadenId: 2015,
      Telefon: "+4991349089461",
      Strasse: "Zum Neuntagwerk 12",
      PLZ: "91077",
      Stadt: "Neunkirchen am Brand",
    },
    2016: {
      LadenId: 2016,
      Telefon: "+4991947245940",
      Strasse: "Markgrafenweg 13",
      PLZ: "91320",
      Stadt: "Ebermannstadt",
    },
    2017: {
      LadenId: 2017,
      Telefon: "+496974093824",
      Strasse: "Europa-Allee 6",
      PLZ: "60327",
      Stadt: "Frankfurt am Main",
    },
    2020: {
      LadenId: 2020,
      Telefon: "+4972291851932",
      Strasse: "Wagnerstraße 2",
      PLZ: "76549",
      Stadt: "Hügelsheim",
    },
    2021: {
      LadenId: 2021,
      Telefon: "+494080010119",
      Strasse: "Osdorfer Landstraße 131",
      PLZ: "22609",
      Stadt: "Hamburg",
    },
    2022: {
      LadenId: 2022,
      Telefon: "+4971448060922",
      Strasse: "Im Langen Feld 3",
      PLZ: "71711",
      Stadt: "Murr",
    },
    2023: {
      LadenId: 2023,
      Telefon: "+4996219609176",
      Strasse: "Rammertshofer Weg 2a",
      PLZ: "92224",
      Stadt: "Amberg",
    },
    2024: {
      LadenId: 2024,
      Telefon: "+4945418038683",
      Strasse: "Bahnhofsallee 36",
      PLZ: "23909",
      Stadt: "Ratzeburg",
    },
    2026: {
      LadenId: 2026,
      Telefon: "+4994128077756",
      Strasse: "Hohengebrachinger Straße 30",
      PLZ: "93080",
      Stadt: "Pentling",
    },
    2027: {
      LadenId: 2027,
      Telefon: "+4977718759381",
      Strasse: "Aachenstraße 37",
      PLZ: "78333",
      Stadt: "Stockach",
    },
    2029: {
      LadenId: 2029,
      Telefon: "+4942319376561",
      Strasse: "Johanniswall 1-7",
      PLZ: "27283",
      Stadt: "Verden",
    },
    2033: {
      LadenId: 2033,
      Telefon: "+4954140669283",
      Strasse: "Weidenstraße 20",
      PLZ: "49080",
      Stadt: "Osnabrück",
    },
    2034: {
      LadenId: 2034,
      Telefon: "+498944109245",
      Strasse: "Weißenburger Straße 41",
      PLZ: "81667",
      Stadt: "München",
    },
    2036: {
      LadenId: 2036,
      Telefon: "+4934152559884",
      Strasse: "Handelsstraße 4-8",
      PLZ: "04356",
      Stadt: "Leipzig",
    },
    2037: {
      LadenId: 2037,
      Telefon: "+4982528810744",
      Strasse: "Bürgermeister-Götz-Straße 24",
      PLZ: "86529",
      Stadt: "Schrobenhausen",
    },
    2039: {
      LadenId: 2039,
      Telefon: "+4982714217886",
      Strasse: "Am Oberfeld 3a",
      PLZ: "86707",
      Stadt: "Westendorf",
    },
    2040: {
      LadenId: 2040,
      Telefon: "+496973918667",
      Strasse: "Europa-Allee 130",
      PLZ: "60486",
      Stadt: "Frankfurt am Main",
    },
    2041: {
      LadenId: 2041,
      Telefon: "+4948168375681",
      Strasse: "Markt 36",
      PLZ: "25746",
      Stadt: "Heide",
    },
    2043: {
      LadenId: 2043,
      Telefon: "+4985198863264",
      Strasse: "Jägeröd 3a",
      PLZ: "94121",
      Stadt: "Salzweg",
    },
    2045: {
      LadenId: 2045,
      Telefon: "+4943518834328",
      Strasse: "Kieler Straße 54",
      PLZ: "24340",
      Stadt: "Eckernförde",
    },
    2046: {
      LadenId: 2046,
      Telefon: "+4973719279445",
      Strasse: "Industriestraße 6/1",
      PLZ: "88499",
      Stadt: "Riedlingen",
    },
    2048: {
      LadenId: 2048,
      Telefon: "+4974421234454",
      Strasse: "Tonbachstraße 9",
      PLZ: "72270",
      Stadt: "Baiersbronn",
    },
    2050: {
      LadenId: 2050,
      Telefon: "+4941084161355",
      Strasse: "Bremer Straße 51 a",
      PLZ: "21224",
      Stadt: "Rosengarten",
    },
    2051: {
      LadenId: 2051,
      Telefon: "+493046061775",
      Strasse: "Müllerstraße 153a-154",
      PLZ: "13353",
      Stadt: "Berlin",
    },
    2054: {
      LadenId: 2054,
      Telefon: "+493050018298",
      Strasse: "Heinrich-Dathe-Platz 1",
      PLZ: "10319",
      Stadt: "Berlin",
    },
    2055: {
      LadenId: 2055,
      Telefon: "+4941229277899",
      Strasse: "Gerberstraße 5-7",
      PLZ: "25436",
      Stadt: "Uetersen",
    },
    2059: {
      LadenId: 2059,
      Telefon: "+4964228500237",
      Strasse: "Frankfurter Straße 1b",
      PLZ: "35274",
      Stadt: "Kirchhain",
    },
    2060: {
      LadenId: 2060,
      Telefon: "+493040003387",
      Strasse: "Schönhauser Allee 112",
      PLZ: "10439",
      Stadt: "Berlin",
    },
    2061: {
      LadenId: 2061,
      Telefon: "+4975229155023",
      Strasse: "Siemensstraße 7",
      PLZ: "88239",
      Stadt: "Wangen",
    },
    2062: {
      LadenId: 2062,
      Telefon: "+4954519344971",
      Strasse: "Große Straße 30",
      PLZ: "49477",
      Stadt: "Ibbenbüren",
    },
    2063: {
      LadenId: 2063,
      Telefon: "+4972135489739",
      Strasse: "Ludwig-Erhard-Allee 10",
      PLZ: "76131",
      Stadt: "Karlsruhe",
    },
    2064: {
      LadenId: 2064,
      Telefon: "+4952716985765",
      Strasse: "Pfennigbreite 36",
      PLZ: "37671",
      Stadt: "Höxter",
    },
    2066: {
      LadenId: 2066,
      Telefon: "+4945139731962",
      Strasse: "Breite Straße 83-87",
      PLZ: "23552",
      Stadt: "Lübeck",
    },
    2069: {
      LadenId: 2069,
      Telefon: "+4984195198740",
      Strasse: "Münchener Straße 186",
      PLZ: "85051",
      Stadt: "Ingolstadt",
    },
    2070: {
      LadenId: 2070,
      Telefon: "+4935334899182",
      Strasse: "Lauchhammerstraße 60",
      PLZ: "04910",
      Stadt: "Elsterwerda",
    },
    2071: {
      LadenId: 2071,
      Telefon: "+4973915004875",
      Strasse: "Pfisterstraße 65",
      PLZ: "89584",
      Stadt: "Ehingen (Donau)",
    },
    2074: {
      LadenId: 2074,
      Telefon: "+493040205081",
      Strasse: "Oraniendamm 45",
      PLZ: "13469",
      Stadt: "Berlin",
    },
    2075: {
      LadenId: 2075,
      Telefon: "+4959171009866",
      Strasse: "Lookenstraße 14a",
      PLZ: "49808",
      Stadt: "Lingen (Ems)",
    },
    2076: {
      LadenId: 2076,
      Telefon: "+4953718958053",
      Strasse: "Braunschweiger Straße 40c",
      PLZ: "38518",
      Stadt: "Gifhorn",
    },
    2077: {
      LadenId: 2077,
      Telefon: "+4936772059549",
      Strasse: "Poststraße 2",
      PLZ: "98693",
      Stadt: "Ilmenau",
    },
    2078: {
      LadenId: 2078,
      Telefon: "+4960311613937",
      Strasse: "Kaiserstraße 64-66",
      PLZ: "61169",
      Stadt: "Friedberg (Hessen)",
    },
    2080: {
      LadenId: 2080,
      Telefon: "+4977317983305",
      Strasse: "Werner-von-Siemens-Straße 3",
      PLZ: "78239",
      Stadt: "Rielasingen-Worblingen",
    },
    2086: {
      LadenId: 2086,
      Telefon: "+4971158529991",
      Strasse: "Bühlstraße 138",
      PLZ: "70736",
      Stadt: "Fellbach",
    },
    2087: {
      LadenId: 2087,
      Telefon: "+4979319231214",
      Strasse: "Johann-Hammer-Straße 1",
      PLZ: "97980",
      Stadt: "Bad Mergentheim",
    },
    2089: {
      LadenId: 2089,
      Telefon: "+4956315015451",
      Strasse: "Hanseplatz 1",
      PLZ: "34497",
      Stadt: "Korbach",
    },
    2090: {
      LadenId: 2090,
      Telefon: "+4938388227805",
      Strasse: "Bahnhofstraße 54a",
      PLZ: "18528",
      Stadt: "Bergen auf Rügen",
    },
    2091: {
      LadenId: 2091,
      Telefon: "+4947192189120",
      Strasse: "Elbestraße 120",
      PLZ: "27570",
      Stadt: "Bremerhaven",
    },
    2092: {
      LadenId: 2092,
      Telefon: "+4961077569138",
      Strasse: "Am Graf-de-Chardonnet-Platz 1-11",
      PLZ: "65451",
      Stadt: "Kelsterbach",
    },
    2093: {
      LadenId: 2093,
      Telefon: "+4977459285984",
      Strasse: "Saarstraße 8",
      PLZ: "79798",
      Stadt: "Jestetten",
    },
    2094: {
      LadenId: 2094,
      Telefon: "+493071096611",
      Strasse: "Tempelhofer Damm 191",
      PLZ: "12099",
      Stadt: "Berlin",
    },
    2096: {
      LadenId: 2096,
      Telefon: "+4960339239188",
      Strasse: "Am Hetgesborn 2",
      PLZ: "35510",
      Stadt: "Butzbach",
    },
    2097: {
      LadenId: 2097,
      Telefon: "+4937617629737",
      Strasse: "Werdauer Straße 73",
      PLZ: "08427",
      Stadt: "Fraureuth",
    },
    2099: {
      LadenId: 2099,
      Telefon: "+4976115624763",
      Strasse: "Kaiser-Joseph-Straße 274",
      PLZ: "79098",
      Stadt: "Freiburg im Breisgau",
    },
    2100: {
      LadenId: 2100,
      Telefon: "+4951412082218",
      Strasse: "Telefunkenstraße 49",
      PLZ: "29223",
      Stadt: "Celle",
    },
    2101: {
      LadenId: 2101,
      Telefon: "+4960269976723",
      Strasse: "Aschaffenburger Straße 35",
      PLZ: "63762",
      Stadt: "Großostheim",
    },
    2103: {
      LadenId: 2103,
      Telefon: "+4991123587216",
      Strasse: "Karolinenstraße 5",
      PLZ: "90402",
      Stadt: "Nürnberg",
    },
    2104: {
      LadenId: 2104,
      Telefon: "+4962247679705",
      Strasse: "Stralsunder Ring 23a",
      PLZ: "69181",
      Stadt: "Leimen",
    },
    2105: {
      LadenId: 2105,
      Telefon: "+4962249259398",
      Strasse: "Gottlieb-Daimler-Straße 9",
      PLZ: "69207",
      Stadt: "Sandhausen",
    },
    2108: {
      LadenId: 2108,
      Telefon: "+498982005622",
      Strasse: "Heimburgstraße 9-15",
      PLZ: "81243",
      Stadt: "München",
    },
    2112: {
      LadenId: 2112,
      Telefon: "+4991314001346",
      Strasse: "Hauptstraße 21-23",
      PLZ: "91054",
      Stadt: "Erlangen",
    },
    2113: {
      LadenId: 2113,
      Telefon: "+4983818916739",
      Strasse: "Staufner Straße 7",
      PLZ: "88161",
      Stadt: "Lindenberg",
    },
    2114: {
      LadenId: 2114,
      Telefon: "+4987313240042",
      Strasse: "Pappelweg 8",
      PLZ: "84130",
      Stadt: "Dingolfing",
    },
    2118: {
      LadenId: 2118,
      Telefon: "+4991314000814",
      Strasse: "Nürnberger Straße 24",
      PLZ: "91052",
      Stadt: "Erlangen",
    },
    2119: {
      LadenId: 2119,
      Telefon: "+494060823407",
      Strasse: "Schweriner Straße 4",
      PLZ: "22143",
      Stadt: "Hamburg",
    },
    2120: {
      LadenId: 2120,
      Telefon: "+4933120056550",
      Strasse: "Brandenburger Straße 49-51",
      PLZ: "14467",
      Stadt: "Potsdam",
    },
    2122: {
      LadenId: 2122,
      Telefon: "+4962131880001",
      Strasse: "Spreewaldallee 40-50",
      PLZ: "68309",
      Stadt: "Mannheim",
    },
    2123: {
      LadenId: 2123,
      Telefon: "+498912112814",
      Strasse: "Bunzlauer Platz 7",
      PLZ: "80992",
      Stadt: "München",
    },
    2124: {
      LadenId: 2124,
      Telefon: "+498912112658",
      Strasse: "Hanauer Straße 68",
      PLZ: "80993",
      Stadt: "München",
    },
    2129: {
      LadenId: 2129,
      Telefon: "+4934713009000",
      Strasse: "Lindenstraße 12",
      PLZ: "06406",
      Stadt: "Bernburg (Saale)",
    },
    2131: {
      LadenId: 2131,
      Telefon: "+496913385283",
      Strasse: "Zeil 123",
      PLZ: "60313",
      Stadt: "Frankfurt am Main",
    },
    2132: {
      LadenId: 2132,
      Telefon: "+4939916739731",
      Strasse: "Friedensstraße 15",
      PLZ: "17192",
      Stadt: "Waren (Müritz)",
    },
    2133: {
      LadenId: 2133,
      Telefon: "+4965616937190",
      Strasse: "Limbourgs Hof 4",
      PLZ: "54634",
      Stadt: "Bitburg",
    },
    2134: {
      LadenId: 2134,
      Telefon: "+4968317610446",
      Strasse: "Jahnstraße 19",
      PLZ: "66763",
      Stadt: "Dillingen/Saar",
    },
    2135: {
      LadenId: 2135,
      Telefon: "+4960214436419",
      Strasse: "Erlengrund 1",
      PLZ: "63773",
      Stadt: "Goldbach",
    },
    2136: {
      LadenId: 2136,
      Telefon: "+4987039057282",
      Strasse: "Daimlerstraße 1a",
      PLZ: "84051",
      Stadt: "Essenbach",
    },
    2137: {
      LadenId: 2137,
      Telefon: "+4934127107690",
      Strasse: "Paunsdorfer Allee 1",
      PLZ: "04329",
      Stadt: "Leipzig",
    },
    2138: {
      LadenId: 2138,
      Telefon: "+4927725720146",
      Strasse: "Konrad-Adenauer-Straße 70",
      PLZ: "35745",
      Stadt: "Herborn",
    },
    2139: {
      LadenId: 2139,
      Telefon: "+4993721398925",
      Strasse: "Pfützenäcker 2-6",
      PLZ: "63906",
      Stadt: "Erlenbach",
    },
    2141: {
      LadenId: 2141,
      Telefon: "+493025291235",
      Strasse: "Alte Potsdamer Straße 7",
      PLZ: "10785",
      Stadt: "Berlin",
    },
    2142: {
      LadenId: 2142,
      Telefon: "+4973059294095",
      Strasse: "Daimlerstraße 1-7",
      PLZ: "89155",
      Stadt: "Erbach",
    },
    2144: {
      LadenId: 2144,
      Telefon: "+4933276680336",
      Strasse: "Zum Großen Zernsee 3",
      PLZ: "14542",
      Stadt: "Werder (Havel)",
    },
    2146: {
      LadenId: 2146,
      Telefon: "+4961724956897",
      Strasse: "Louisenstraße 14-16",
      PLZ: "61348",
      Stadt: "Bad Homburg vor der Höhe",
    },
    2147: {
      LadenId: 2147,
      Telefon: "+494028059786",
      Strasse: "Rathausmarkt 7",
      PLZ: "20095",
      Stadt: "Hamburg",
    },
    2148: {
      LadenId: 2148,
      Telefon: "+4960274036964",
      Strasse: "Industriestraße 25",
      PLZ: "63811",
      Stadt: "Stockstadt a. Main",
    },
    2149: {
      LadenId: 2149,
      Telefon: "+4992178779047",
      Strasse: "Bernecker Straße 41",
      PLZ: "95448",
      Stadt: "Bayreuth",
    },
    2150: {
      LadenId: 2150,
      Telefon: "+4938539929922",
      Strasse: "Am Grünen Tal 26",
      PLZ: "19063",
      Stadt: "Schwerin",
    },
    2151: {
      LadenId: 2151,
      Telefon: "+493021230622",
      Strasse: "Invalidenstraße 119",
      PLZ: "10115",
      Stadt: "Berlin",
    },
    2152: {
      LadenId: 2152,
      Telefon: "+4944711805691",
      Strasse: "Lange Straße 4",
      PLZ: "49661",
      Stadt: "Cloppenburg",
    },
    2154: {
      LadenId: 2154,
      Telefon: "+4933915104276",
      Strasse: "Trenckmannstraße 20",
      PLZ: "16816",
      Stadt: "Neuruppin",
    },
    2155: {
      LadenId: 2155,
      Telefon: "+4941937550810",
      Strasse: "Gutenbergstraße 4",
      PLZ: "24558",
      Stadt: "Henstedt-Ulzburg",
    },
    2156: {
      LadenId: 2156,
      Telefon: "+4954199879501",
      Strasse: "Hannoversche Straße 90",
      PLZ: "49084",
      Stadt: "Osnabrück",
    },
    2158: {
      LadenId: 2158,
      Telefon: "+4946149433809",
      Strasse: "Langberger Weg 4",
      PLZ: "24941",
      Stadt: "Flensburg",
    },
    2162: {
      LadenId: 2162,
      Telefon: "+498912003998",
      Strasse: "Nymphenburger Straße 81",
      PLZ: "80636",
      Stadt: "München",
    },
    2165: {
      LadenId: 2165,
      Telefon: "+4995717553685",
      Strasse: "Mainau 11",
      PLZ: "96215",
      Stadt: "Lichtenfels",
    },
    2166: {
      LadenId: 2166,
      Telefon: "+4986526553960",
      Strasse: "Triftplatz 4",
      PLZ: "83471",
      Stadt: "Schönau am Königssee",
    },
    2167: {
      LadenId: 2167,
      Telefon: "+4999318958856",
      Strasse: "Landauer Straße 8",
      PLZ: "94447",
      Stadt: "Plattling",
    },
    2168: {
      LadenId: 2168,
      Telefon: "+4933439143075",
      Strasse: "Brückenstraße 6",
      PLZ: "15370",
      Stadt: "Fredersdorf-Vogelsdorf",
    },
    2169: {
      LadenId: 2169,
      Telefon: "+4961814286670",
      Strasse: "Im Forum 5a",
      PLZ: "63450",
      Stadt: "Hanau",
    },
    2170: {
      LadenId: 2170,
      Telefon: "+4934556369257",
      Strasse: "Delitzscher Straße 122",
      PLZ: "06116",
      Stadt: "Halle (Saale)",
    },
    2171: {
      LadenId: 2171,
      Telefon: "+4952511809732",
      Strasse: "Pohlweg 110",
      PLZ: "33100",
      Stadt: "Paderborn",
    },
    2174: {
      LadenId: 2174,
      Telefon: "+498944109621",
      Strasse: "Orleansplatz 10-12",
      PLZ: "81667",
      Stadt: "München",
    },
    2177: {
      LadenId: 2177,
      Telefon: "+4994128063977",
      Strasse: "Dr.-Gessler-Straße 41-47",
      PLZ: "93051",
      Stadt: "Regensburg",
    },
    2179: {
      LadenId: 2179,
      Telefon: "+4933120056302",
      Strasse: "Babelsberger Str. 16",
      PLZ: "14473",
      Stadt: "Potsdam",
    },
    2181: {
      LadenId: 2181,
      Telefon: "+4993318021939",
      Strasse: "Marktbreiter Straße 54 a",
      PLZ: "97199",
      Stadt: "Ochsenfurt",
    },
    2182: {
      LadenId: 2182,
      Telefon: "+498923077607",
      Strasse: "Sendlinger Straße 12a",
      PLZ: "80331",
      Stadt: "München",
    },
    2183: {
      LadenId: 2183,
      Telefon: "+4974579305397",
      Strasse: "Hailfinger Straße 17/1",
      PLZ: "71149",
      Stadt: "Bondorf",
    },
    2186: {
      LadenId: 2186,
      Telefon: "+493051653110",
      Strasse: "Semmelweisstraße 104a",
      PLZ: "12524",
      Stadt: "Berlin",
    },
    2187: {
      LadenId: 2187,
      Telefon: "+493021016297",
      Strasse: "Prager Platz 1-3",
      PLZ: "10779",
      Stadt: "Berlin",
    },
    2188: {
      LadenId: 2188,
      Telefon: "+493080581326",
      Strasse: "Clayallee 342",
      PLZ: "14169",
      Stadt: "Berlin",
    },
    2189: {
      LadenId: 2189,
      Telefon: "+493040534113",
      Strasse: "Am Borsigturm 2",
      PLZ: "13507",
      Stadt: "Berlin",
    },
    2190: {
      LadenId: 2190,
      Telefon: "+4925628163739",
      Strasse: "Ochtruper Straße 218",
      PLZ: "48599",
      Stadt: "Gronau",
    },
    2191: {
      LadenId: 2191,
      Telefon: "+4971929358632",
      Strasse: "Fritz-Schweizer-Straße 6",
      PLZ: "71540",
      Stadt: "Murrhardt",
    },
    2192: {
      LadenId: 2192,
      Telefon: "+493040003133",
      Strasse: "Schönhauser Allee 80",
      PLZ: "10439",
      Stadt: "Berlin",
    },
    2193: {
      LadenId: 2193,
      Telefon: "+498951114708",
      Strasse: "Arnulfstraße 2",
      PLZ: "80335",
      Stadt: "München",
    },
    2194: {
      LadenId: 2194,
      Telefon: "+4956152178717",
      Strasse: "Lange Wender 6",
      PLZ: "34246",
      Stadt: "Vellmar",
    },
    2195: {
      LadenId: 2195,
      Telefon: "+4933150540633",
      Strasse: "Nedlitzer Straße 19b",
      PLZ: "14469",
      Stadt: "Potsdam",
    },
    2198: {
      LadenId: 2198,
      Telefon: "+4991726859432",
      Strasse: "Pleinfelder Straße 66b-e",
      PLZ: "91166",
      Stadt: "Georgensgmünd",
    },
    2199: {
      LadenId: 2199,
      Telefon: "+4942184919900",
      Strasse: "Bahnhofsplatz 41",
      PLZ: "28195",
      Stadt: "Bremen",
    },
    2201: {
      LadenId: 2201,
      Telefon: "+4939961529917",
      Strasse: "Fischersteig 15",
      PLZ: "17166",
      Stadt: "Teterow",
    },
    2202: {
      LadenId: 2202,
      Telefon: "+4967624019736",
      Strasse: "Johann-Sebastian-Bach-Str. 26",
      PLZ: "56288",
      Stadt: "Kastellaun",
    },
    2203: {
      LadenId: 2203,
      Telefon: "+4953170734766",
      Strasse: "BraWo-Allee 1",
      PLZ: "38102",
      Stadt: "Braunschweig",
    },
    2205: {
      LadenId: 2205,
      Telefon: "+493091427816",
      Strasse: "Mehrower Allee 20",
      PLZ: "12687",
      Stadt: "Berlin",
    },
    2206: {
      LadenId: 2206,
      Telefon: "+4924711322071",
      Strasse: "Bundesstraße 30a",
      PLZ: "52159",
      Stadt: "Roetgen",
    },
    2212: {
      LadenId: 2212,
      Telefon: "+4986718838735",
      Strasse: "Mühldorfer Straße 114",
      PLZ: "84503",
      Stadt: "Altötting",
    },
    2213: {
      LadenId: 2213,
      Telefon: "+4980723720903",
      Strasse: "Gerberstraße 3",
      PLZ: "83527",
      Stadt: "Haag i. OB.",
    },
    2214: {
      LadenId: 2214,
      Telefon: "+4961312409915",
      Strasse: "Sertoriusring 102",
      PLZ: "55126",
      Stadt: "Mainz",
    },
    2217: {
      LadenId: 2217,
      Telefon: "+4960235016871",
      Strasse: "Emmy-Noether-Straße 1",
      PLZ: "63755",
      Stadt: "Alzenau",
    },
    2218: {
      LadenId: 2218,
      Telefon: "+4971154096747",
      Strasse: "Mailänder Platz 7",
      PLZ: "70173",
      Stadt: "Stuttgart",
    },
    2219: {
      LadenId: 2219,
      Telefon: "+4977739376813",
      Strasse: "Überlinger Straße 7",
      PLZ: "78351",
      Stadt: "Bodman-Ludwigshafen",
    },
    2220: {
      LadenId: 2220,
      Telefon: "+4972323013972",
      Strasse: "Hauptstraße 93/1",
      PLZ: "75196",
      Stadt: "Remchingen",
    },
    2223: {
      LadenId: 2223,
      Telefon: "+4938347737938",
      Strasse: "Lange Straße 90",
      PLZ: "17489",
      Stadt: "Greifswald",
    },
    2226: {
      LadenId: 2226,
      Telefon: "+4995688908870",
      Strasse: "Am Lerchenfeld 4",
      PLZ: "96465",
      Stadt: "Neustadt b. Coburg",
    },
    2227: {
      LadenId: 2227,
      Telefon: "+4941414089411",
      Strasse: "Drosselstieg 77",
      PLZ: "21682",
      Stadt: "Stade",
    },
    2228: {
      LadenId: 2228,
      Telefon: "+4986416979808",
      Strasse: "Eichelreuth 21",
      PLZ: "83224",
      Stadt: "Grassau",
    },
    2229: {
      LadenId: 2229,
      Telefon: "+498942001071",
      Strasse: "Kreillerstraße 200",
      PLZ: "81825",
      Stadt: "München",
    },
    2230: {
      LadenId: 2230,
      Telefon: "+4957138512317",
      Strasse: "Bäckerstraße 21-23",
      PLZ: "32423",
      Stadt: "Minden",
    },
    2231: {
      LadenId: 2231,
      Telefon: "+4972239129255",
      Strasse: "Hägenichstraße 7",
      PLZ: "77833",
      Stadt: "Ottersweier",
    },
    2232: {
      LadenId: 2232,
      Telefon: "+4982321847355",
      Strasse: "Gottlieb-Daimler-Straße 1a",
      PLZ: "86830",
      Stadt: "Schwabmünchen",
    },
    2234: {
      LadenId: 2234,
      Telefon: "+4951152489717",
      Strasse: "Tiergartenstraße 114",
      PLZ: "30559",
      Stadt: "Hannover",
    },
    2236: {
      LadenId: 2236,
      Telefon: "+4961335709802",
      Strasse: "Spiegelbergstraße 71",
      PLZ: "55283",
      Stadt: "Nierstein",
    },
    2237: {
      LadenId: 2237,
      Telefon: "+493051655938",
      Strasse: "Schloßstr. 33-36",
      PLZ: "12163",
      Stadt: "Berlin",
    },
    2238: {
      LadenId: 2238,
      Telefon: "+4935543099561",
      Strasse: "Thierbacher Straße 12-15",
      PLZ: "03048",
      Stadt: "Cottbus",
    },
    2239: {
      LadenId: 2239,
      Telefon: "+4933322670231",
      Strasse: "Platz der Befreiung 1",
      PLZ: "16303",
      Stadt: "Schwedt/Oder",
    },
    2242: {
      LadenId: 2242,
      Telefon: "+496980052302",
      Strasse: "Hafenplatz 1-3",
      PLZ: "63067",
      Stadt: "Offenbach am Main",
    },
    2243: {
      LadenId: 2243,
      Telefon: "+4961312406861",
      Strasse: "Elbestraße 68",
      PLZ: "55122",
      Stadt: "Mainz",
    },
    2244: {
      LadenId: 2244,
      Telefon: "+4964046580000",
      Strasse: "Ludwig-Seiboldt-Straße 1a",
      PLZ: "35423",
      Stadt: "Lich",
    },
    2246: {
      LadenId: 2246,
      Telefon: "+493060250710",
      Strasse: "Joachim-Gottschalk-Weg 21",
      PLZ: "12353",
      Stadt: "Berlin",
    },
    2248: {
      LadenId: 2248,
      Telefon: "+4983414386090",
      Strasse: "Am Graben 10",
      PLZ: "87600",
      Stadt: "Kaufbeuren",
    },
    2250: {
      LadenId: 2250,
      Telefon: "+4939206621736",
      Strasse: "Am Elbepark 1",
      PLZ: "39326",
      Stadt: "Hermsdorf",
    },
    2251: {
      LadenId: 2251,
      Telefon: "+4953049112882",
      Strasse: "Bahnhofstraße 15",
      PLZ: "38527",
      Stadt: "Meine",
    },
    2252: {
      LadenId: 2252,
      Telefon: "+4927372185038",
      Strasse: "Kälberhof 9a -11",
      PLZ: "57250",
      Stadt: "Netphen",
    },
    2253: {
      LadenId: 2253,
      Telefon: "+4981916571314",
      Strasse: "Kolpingstraße 10",
      PLZ: "86916",
      Stadt: "Kaufering",
    },
    2254: {
      LadenId: 2254,
      Telefon: "+4957219370863",
      Strasse: "Jahnstraße 35",
      PLZ: "31655",
      Stadt: "Stadthagen",
    },
    2255: {
      LadenId: 2255,
      Telefon: "+4991749719447",
      Strasse: "Allersberger Straße 21",
      PLZ: "91161",
      Stadt: "Hilpoltstein",
    },
    2256: {
      LadenId: 2256,
      Telefon: "+4960617062134",
      Strasse: "Industriestraße 1",
      PLZ: "64720",
      Stadt: "Michelstadt",
    },
    2257: {
      LadenId: 2257,
      Telefon: "+4968948950469",
      Strasse: "Südstraße 62",
      PLZ: "66386",
      Stadt: "St. Ingbert",
    },
    2260: {
      LadenId: 2260,
      Telefon: "+4938120746767",
      Strasse: "Rigaer Straße 5",
      PLZ: "18107",
      Stadt: "Rostock",
    },
    2261: {
      LadenId: 2261,
      Telefon: "+4961032021423",
      Strasse: "An der Winkelwiese 1",
      PLZ: "63225",
      Stadt: "Langen",
    },
    2262: {
      LadenId: 2262,
      Telefon: "+4968356070142",
      Strasse: "Wallerfanger Straße 60",
      PLZ: "66780",
      Stadt: "Rehlingen",
    },
    2263: {
      LadenId: 2263,
      Telefon: "+4943158590054",
      Strasse: "Herzog-Friedrich-Straße 30-42",
      PLZ: "24103",
      Stadt: "Kiel",
    },
    2264: {
      LadenId: 2264,
      Telefon: "+4980627252051",
      Strasse: "Kirchdorfer Straße 15d",
      PLZ: "83052",
      Stadt: "Bruckmühl",
    },
    2265: {
      LadenId: 2265,
      Telefon: "+493056553837",
      Strasse: "An der Schule 68",
      PLZ: "12623",
      Stadt: "Berlin",
    },
    2267: {
      LadenId: 2267,
      Telefon: "+4939140045597",
      Strasse: "Halberstädter Straße 179",
      PLZ: "39112",
      Stadt: "Magdeburg",
    },
    2269: {
      LadenId: 2269,
      Telefon: "+4996427027197",
      Strasse: "Röntgenstraße 30",
      PLZ: "95478",
      Stadt: "Kemnath",
    },
    2270: {
      LadenId: 2270,
      Telefon: "+4959227779990",
      Strasse: "Rheiner Straße 7d",
      PLZ: "48455",
      Stadt: "Bad Bentheim",
    },
    2271: {
      LadenId: 2271,
      Telefon: "+4936813516841",
      Strasse: "Würzburger Straße 33",
      PLZ: "98529",
      Stadt: "Suhl",
    },
    2272: {
      LadenId: 2272,
      Telefon: "+4998515549307",
      Strasse: "Bahnhofsplatz 1",
      PLZ: "91550",
      Stadt: "Dinkelsbühl",
    },
    2275: {
      LadenId: 2275,
      Telefon: "+4934293449864",
      Strasse: "Ladestraße 5a",
      PLZ: "04683",
      Stadt: "Naunhof",
    },
    2276: {
      LadenId: 2276,
      Telefon: "+4964149410048",
      Strasse: "Georg-Elser-Straße 1",
      PLZ: "35394",
      Stadt: "Gießen",
    },
    2277: {
      LadenId: 2277,
      Telefon: "+4986346241102",
      Strasse: "am Binderfeld 6a",
      PLZ: "84518",
      Stadt: "Garching a.d. Alz",
    },
    2278: {
      LadenId: 2278,
      Telefon: "+4975812007437",
      Strasse: "Platzstraße 22",
      PLZ: "88348",
      Stadt: "Bad Saulgau",
    },
    2279: {
      LadenId: 2279,
      Telefon: "+4971721877980",
      Strasse: "Maierhofstraße 22",
      PLZ: "73547",
      Stadt: "Lorch",
    },
    2280: {
      LadenId: 2280,
      Telefon: "+4955137075474",
      Strasse: "Weender Straße 22-24",
      PLZ: "37073",
      Stadt: "Göttingen",
    },
    2281: {
      LadenId: 2281,
      Telefon: "+4972039216852",
      Strasse: "Jöhlinger Straße 160",
      PLZ: "75045",
      Stadt: "Walzbachtal",
    },
    2283: {
      LadenId: 2283,
      Telefon: "+4986719242093",
      Strasse: "Burghauser Straße 72",
      PLZ: "84503",
      Stadt: "Altötting",
    },
    2285: {
      LadenId: 2285,
      Telefon: "+4937267892919",
      Strasse: "Augustusburger Straße 96",
      PLZ: "09557",
      Stadt: "Flöha",
    },
    2286: {
      LadenId: 2286,
      Telefon: "+493040049809",
      Strasse: "Breite Straße 18-23",
      PLZ: "13187",
      Stadt: "Berlin",
    },
    2288: {
      LadenId: 2288,
      Telefon: "+4933812099663",
      Strasse: "Neuendorfer Straße 76",
      PLZ: "14770",
      Stadt: "Brandenburg an der Havel",
    },
    2289: {
      LadenId: 2289,
      Telefon: "+4977627089450",
      Strasse: "Im Steinfacht 3",
      PLZ: "79739",
      Stadt: "Schwörstadt",
    },
    2290: {
      LadenId: 2290,
      Telefon: "+4963429230089",
      Strasse: "An der Schmalwiese 5",
      PLZ: "76889",
      Stadt: "Schweigen-Rechtenbach",
    },
    2291: {
      LadenId: 2291,
      Telefon: "+4955278419175",
      Strasse: "Bahnhofstraße 3",
      PLZ: "37115",
      Stadt: "Duderstadt",
    },
    2292: {
      LadenId: 2292,
      Telefon: "+4969380797410",
      Strasse: "Hahnstraße 39a",
      PLZ: "60528",
      Stadt: "Frankfurt am Main",
    },
    2293: {
      LadenId: 2293,
      Telefon: "+4934452348440",
      Strasse: "Bahnhofstraße 37",
      PLZ: "06618",
      Stadt: "Naumburg (Saale)",
    },
    2295: {
      LadenId: 2295,
      Telefon: "+4951369706416",
      Strasse: "Marktstraße 23",
      PLZ: "31303",
      Stadt: "Burgdorf",
    },
    2296: {
      LadenId: 2296,
      Telefon: "+4935184389926",
      Strasse: "Großenhainer Straße 119a",
      PLZ: "01127",
      Stadt: "Dresden",
    },
    2297: {
      LadenId: 2297,
      Telefon: "+4965117067903",
      Strasse: "Castelnauplatz 3",
      PLZ: "54294",
      Stadt: "Trier",
    },
    2298: {
      LadenId: 2298,
      Telefon: "+4943153778709",
      Strasse: "Kurt-Schumacher-Platz 4",
      PLZ: "24109",
      Stadt: "Kiel",
    },
    2299: {
      LadenId: 2299,
      Telefon: "+4941917243765",
      Strasse: "Kisdorfer Weg 11",
      PLZ: "24568",
      Stadt: "Kaltenkirchen",
    },
    2300: {
      LadenId: 2300,
      Telefon: "+4939712931924",
      Strasse: "Silostraße 1a",
      PLZ: "17389",
      Stadt: "Anklam",
    },
    2302: {
      LadenId: 2302,
      Telefon: "+4981219771411",
      Strasse: "Schwanenstraße 1",
      PLZ: "85586",
      Stadt: "Poing",
    },
    2303: {
      LadenId: 2303,
      Telefon: "+4936958509393",
      Strasse: "Bahnhofstraße 11",
      PLZ: "36433",
      Stadt: "Bad Salzungen",
    },
    2305: {
      LadenId: 2305,
      Telefon: "+4947432760329",
      Strasse: "Schmidtkuhlsweg 19",
      PLZ: "27607",
      Stadt: "Geestland",
    },
    2306: {
      LadenId: 2306,
      Telefon: "+4937135509911",
      Strasse: "Neumarkt 2",
      PLZ: "09111",
      Stadt: "Chemnitz",
    },
    2307: {
      LadenId: 2307,
      Telefon: "+4992658079838",
      Strasse: "Industriestraße 12",
      PLZ: "96342",
      Stadt: "Stockheim",
    },
    2309: {
      LadenId: 2309,
      Telefon: "+4976334061166",
      Strasse: "Bahnhofstraße 21",
      PLZ: "79189",
      Stadt: "Bad Krozingen",
    },
    2310: {
      LadenId: 2310,
      Telefon: "+4973094263269",
      Strasse: "Ulmer Straße 10",
      PLZ: "89264",
      Stadt: "Weißenhorn",
    },
    2311: {
      LadenId: 2311,
      Telefon: "+4963738290282",
      Strasse: "Sanderstraße 30",
      PLZ: "66901",
      Stadt: "Schönenberg-Kübelberg",
    },
    2312: {
      LadenId: 2312,
      Telefon: "+4974528398947",
      Strasse: "Freudenstädter Straße 2",
      PLZ: "72202",
      Stadt: "Nagold",
    },
    2315: {
      LadenId: 2315,
      Telefon: "+4938203733499",
      Strasse: "Am Handelspark 7",
      PLZ: "18209",
      Stadt: "Bad Doberan",
    },
    2316: {
      LadenId: 2316,
      Telefon: "+4970314149226",
      Strasse: "Tilsiter Straße 15",
      PLZ: "71065",
      Stadt: "Sindelfingen",
    },
    2318: {
      LadenId: 2318,
      Telefon: "+4963245930454",
      Strasse: "Herrenweg 4",
      PLZ: "67454",
      Stadt: "Haßloch",
    },
    2319: {
      LadenId: 2319,
      Telefon: "+4939236129133",
      Strasse: "Jütrichauer Straße 23",
      PLZ: "39261",
      Stadt: "Zerbst/Anhalt",
    },
    2322: {
      LadenId: 2322,
      Telefon: "+4940468973990",
      Strasse: "Stresemannstraße 213",
      PLZ: "22769",
      Stadt: "Hamburg",
    },
    2323: {
      LadenId: 2323,
      Telefon: "+494023939776",
      Strasse: "Osterstraße 111",
      PLZ: "20259",
      Stadt: "Hamburg",
    },
    2327: {
      LadenId: 2327,
      Telefon: "+4929379689021",
      Strasse: "Widayweg 2",
      PLZ: "59823",
      Stadt: "Arnsberg",
    },
    2329: {
      LadenId: 2329,
      Telefon: "+493043722861",
      Strasse: "Müllerstraße 35",
      PLZ: "13353",
      Stadt: "Berlin",
    },
    2332: {
      LadenId: 2332,
      Telefon: "+4978319699627",
      Strasse: "In den Brachfeldern 2",
      PLZ: "77756",
      Stadt: "Hausach",
    },
    2333: {
      LadenId: 2333,
      Telefon: "+4994055009358",
      Strasse: "Gutenbergring 5a",
      PLZ: "93077",
      Stadt: "Bad Abbach",
    },
    2335: {
      LadenId: 2335,
      Telefon: "+4950216000538",
      Strasse: "Celler Straße 3",
      PLZ: "31582",
      Stadt: "Nienburg (Weser)",
    },
    2338: {
      LadenId: 2338,
      Telefon: "+4981212235917",
      Strasse: "Burgerfeld 12",
      PLZ: "85570",
      Stadt: "Markt Schwaben",
    },
    2339: {
      LadenId: 2339,
      Telefon: "+493023455701",
      Strasse: "Luisenstraße 48-52",
      PLZ: "10117",
      Stadt: "Berlin",
    },
    2340: {
      LadenId: 2340,
      Telefon: "+4930403665040",
      Strasse: "Allee der Kosmonauten 194",
      PLZ: "12685",
      Stadt: "Berlin",
    },
    2342: {
      LadenId: 2342,
      Telefon: "+4961027999479",
      Strasse: "Schleussnerstraße 100b",
      PLZ: "63263",
      Stadt: "Neu-Isenburg",
    },
    2344: {
      LadenId: 2344,
      Telefon: "+4994015260931",
      Strasse: "Bischof-Sailer-Straße 3-7",
      PLZ: "93092",
      Stadt: "Barbing",
    },
    2345: {
      LadenId: 2345,
      Telefon: "+4996049099581",
      Strasse: "Klaus-Conrad-Straße 1a",
      PLZ: "92533",
      Stadt: "Wernberg-Köblitz",
    },
    2346: {
      LadenId: 2346,
      Telefon: "+4996317980390",
      Strasse: "Äußere Regensburger Straße 81a",
      PLZ: "95643",
      Stadt: "Tirschenreuth",
    },
    2348: {
      LadenId: 2348,
      Telefon: "+4961879026436",
      Strasse: "Gehrener Ring 3",
      PLZ: "61130",
      Stadt: "Nidderau",
    },
    2349: {
      LadenId: 2349,
      Telefon: "+4991239622702",
      Strasse: "Röthenbacher Straße 26",
      PLZ: "91207",
      Stadt: "Lauf",
    },
    2351: {
      LadenId: 2351,
      Telefon: "+4941013709109",
      Strasse: "Krupunder Heide 2b",
      PLZ: "25462",
      Stadt: "Rellingen",
    },
    2352: {
      LadenId: 2352,
      Telefon: "+4951234000160",
      Strasse: "Sandkuhle 1",
      PLZ: "31174",
      Stadt: "Schellerten",
    },
    2353: {
      LadenId: 2353,
      Telefon: "+4980915378379",
      Strasse: "Westring 7",
      PLZ: "85614",
      Stadt: "Kirchseeon",
    },
    2354: {
      LadenId: 2354,
      Telefon: "+4980259914670",
      Strasse: "Schlierseer Straße 28",
      PLZ: "83714",
      Stadt: "Miesbach",
    },
    2355: {
      LadenId: 2355,
      Telefon: "+4974526809490",
      Strasse: "Haiterbacher Straße 72",
      PLZ: "72202",
      Stadt: "Nagold",
    },
    2356: {
      LadenId: 2356,
      Telefon: "+4935144032872",
      Strasse: "Straßburger Platz 1",
      PLZ: "01307",
      Stadt: "Dresden",
    },
    2358: {
      LadenId: 2358,
      Telefon: "+4971130090574",
      Strasse: "Rechbergstrasse 5",
      PLZ: "73770",
      Stadt: "Denkendorf",
    },
    2360: {
      LadenId: 2360,
      Telefon: "+4966416409679",
      Strasse: "An der Gall 3",
      PLZ: "36341",
      Stadt: "Lauterbach (Hessen)",
    },
    2362: {
      LadenId: 2362,
      Telefon: "+4938231459989",
      Strasse: "Lange Straße 80a",
      PLZ: "18356",
      Stadt: "Barth",
    },
    2363: {
      LadenId: 2363,
      Telefon: "+4954627429764",
      Strasse: "Lindenstraße 1",
      PLZ: "49577",
      Stadt: "Ankum",
    },
    2364: {
      LadenId: 2364,
      Telefon: "+4943929146890",
      Strasse: "Niedernstraße 9b",
      PLZ: "24589",
      Stadt: "Nortorf",
    },
    2365: {
      LadenId: 2365,
      Telefon: "+493067068968",
      Strasse: "Dörpfeldstraße 33-35",
      PLZ: "12489",
      Stadt: "Berlin",
    },
    2366: {
      LadenId: 2366,
      Telefon: "+4968945908077",
      Strasse: "Industriestraße 12",
      PLZ: "66386",
      Stadt: "St. Ingbert",
    },
    2368: {
      LadenId: 2368,
      Telefon: "+4968243004324",
      Strasse: "Bliesstraße 8",
      PLZ: "66564",
      Stadt: "Ottweiler",
    },
    2369: {
      LadenId: 2369,
      Telefon: "+4996328409332",
      Strasse: "An der Tongrube 2",
      PLZ: "95652",
      Stadt: "Waldsassen",
    },
    2372: {
      LadenId: 2372,
      Telefon: "+498990937878",
      Strasse: "Freischützstraße 20",
      PLZ: "81927",
      Stadt: "München",
    },
    2373: {
      LadenId: 2373,
      Telefon: "+4995216191092",
      Strasse: "Godelstatt 10",
      PLZ: "97437",
      Stadt: "Haßfurt",
    },
    2375: {
      LadenId: 2375,
      Telefon: "+4938313055943",
      Strasse: "Rostocker Chaussee 16",
      PLZ: "18437",
      Stadt: "Stralsund",
    },
    2376: {
      LadenId: 2376,
      Telefon: "+4970331383978",
      Strasse: "Mönsheimer Straße 61",
      PLZ: "71296",
      Stadt: "Heimsheim",
    },
    2377: {
      LadenId: 2377,
      Telefon: "+4952518729635",
      Strasse: "Marienplatz 11",
      PLZ: "33098",
      Stadt: "Paderborn",
    },
    2378: {
      LadenId: 2378,
      Telefon: "+4971434099751",
      Strasse: "Papierfabrikstraße 1",
      PLZ: "74376",
      Stadt: "Gemmrigheim",
    },
    2379: {
      LadenId: 2379,
      Telefon: "+4983151210003",
      Strasse: "August-Fischer-Platz 1",
      PLZ: "87435",
      Stadt: "Kempten (Allgäu)",
    },
    2380: {
      LadenId: 2380,
      Telefon: "+4953814906369",
      Strasse: "Braunschweiger Straße 34",
      PLZ: "38723",
      Stadt: "Seesen",
    },
    2382: {
      LadenId: 2382,
      Telefon: "+4935716069550",
      Strasse: "Teschenstraße 1",
      PLZ: "02977",
      Stadt: "Hoyerswerda",
    },
    2384: {
      LadenId: 2384,
      Telefon: "+4968984480145",
      Strasse: "Bahnhofstraße 9",
      PLZ: "66352",
      Stadt: "Großrosseln",
    },
    2385: {
      LadenId: 2385,
      Telefon: "+4934757259214",
      Strasse: "Herner Straße 7",
      PLZ: "06295",
      Stadt: "Lutherstadt Eisleben",
    },
    2386: {
      LadenId: 2386,
      Telefon: "+4961116661552",
      Strasse: "Neugasse 15-19",
      PLZ: "65183",
      Stadt: "Wiesbaden",
    },
    2387: {
      LadenId: 2387,
      Telefon: "+4964214870187",
      Strasse: "Marburger Straße 100",
      PLZ: "35043",
      Stadt: "Marburg",
    },
    2388: {
      LadenId: 2388,
      Telefon: "+4961315016893",
      Strasse: "Vogelsbergstraße 2",
      PLZ: "55129",
      Stadt: "Mainz",
    },
    2389: {
      LadenId: 2389,
      Telefon: "+4991814089730",
      Strasse: "Dammstraße 1",
      PLZ: "92318",
      Stadt: "Neumarkt in der Oberpfalz",
    },
    2390: {
      LadenId: 2390,
      Telefon: "+4929195289221",
      Strasse: "Ruhrstraße 25",
      PLZ: "59872",
      Stadt: "Meschede",
    },
    2391: {
      LadenId: 2391,
      Telefon: "+4991197797177",
      Strasse: "Rudolf-Breitscheid-Straße 14",
      PLZ: "90762",
      Stadt: "Fürth",
    },
    2392: {
      LadenId: 2392,
      Telefon: "+4927362947910",
      Strasse: "Marktplatz 4",
      PLZ: "57299",
      Stadt: "Burbach",
    },
    2393: {
      LadenId: 2393,
      Telefon: "+4971369614271",
      Strasse: "Friedrichsplatz 9",
      PLZ: "74177",
      Stadt: "Bad Friedrichshall",
    },
    2395: {
      LadenId: 2395,
      Telefon: "+4978089132965",
      Strasse: "Franckensteinstraße 26",
      PLZ: "77749",
      Stadt: "Hohberg",
    },
    2396: {
      LadenId: 2396,
      Telefon: "+4957138512196",
      Strasse: "Ringstraße 74",
      PLZ: "32427",
      Stadt: "Minden",
    },
    2397: {
      LadenId: 2397,
      Telefon: "+4994929079065",
      Strasse: "Dr.-Boecale-Straße 8",
      PLZ: "92331",
      Stadt: "Parsberg",
    },
    2398: {
      LadenId: 2398,
      Telefon: "+4982131986873",
      Strasse: "Lechhauser Straße 13",
      PLZ: "86153",
      Stadt: "Augsburg",
    },
    2400: {
      LadenId: 2400,
      Telefon: "+4956525875600",
      Strasse: "Städtersweg 1-2",
      PLZ: "37242",
      Stadt: "Bad Sooden-Allendorf",
    },
    2402: {
      LadenId: 2402,
      Telefon: "+4971136533997",
      Strasse: "Rennstraße 6",
      PLZ: "70499",
      Stadt: "Stuttgart",
    },
    2403: {
      LadenId: 2403,
      Telefon: "+4934202300358",
      Strasse: "Dübener Straße 35",
      PLZ: "04509",
      Stadt: "Delitzsch",
    },
    2404: {
      LadenId: 2404,
      Telefon: "+494027144970",
      Strasse: "Großer Burstah 18",
      PLZ: "20457",
      Stadt: "Hamburg",
    },
    2405: {
      LadenId: 2405,
      Telefon: "+4936651385683",
      Strasse: "Poststraße 1",
      PLZ: "07356",
      Stadt: "Bad Lobenstein",
    },
    2407: {
      LadenId: 2407,
      Telefon: "+4965016039268",
      Strasse: "Wilde Acht 16",
      PLZ: "54329",
      Stadt: "Konz",
    },
    2408: {
      LadenId: 2408,
      Telefon: "+4934936058008",
      Strasse: "Brehnaer Strasse 34f",
      PLZ: "06749",
      Stadt: "Bitterfeld-Wolfen",
    },
    2409: {
      LadenId: 2409,
      Telefon: "+4996519180326",
      Strasse: "Waidhauser Straße 26a",
      PLZ: "92648",
      Stadt: "Vohenstrauß",
    },
    2410: {
      LadenId: 2410,
      Telefon: "+498933019222",
      Strasse: "Leopoldstraße 23",
      PLZ: "80802",
      Stadt: "München",
    },
    2411: {
      LadenId: 2411,
      Telefon: "+4928329716591",
      Strasse: "Lindenstraße 63",
      PLZ: "47623",
      Stadt: "Kevelaer",
    },
    2412: {
      LadenId: 2412,
      Telefon: "+4963211890370",
      Strasse: "Am Speyerbach 4",
      PLZ: "67433",
      Stadt: "Neustadt an der Weinstraße",
    },
    2413: {
      LadenId: 2413,
      Telefon: "+4991418731943",
      Strasse: "Augsburger Straße 67",
      PLZ: "91781",
      Stadt: "Weißenburg",
    },
    2414: {
      LadenId: 2414,
      Telefon: "+498931813967",
      Strasse: "Ingolstädter Straße 170b",
      PLZ: "80939",
      Stadt: "München",
    },
    2416: {
      LadenId: 2416,
      Telefon: "+493070509440",
      Strasse: "Hauptstraße 141-144",
      PLZ: "10827",
      Stadt: "Berlin",
    },
    2418: {
      LadenId: 2418,
      Telefon: "+4930403666040",
      Strasse: "Stromstraße 14",
      PLZ: "10551",
      Stadt: "Berlin",
    },
    2419: {
      LadenId: 2419,
      Telefon: "+4935165633343",
      Strasse: "Meißner Straße 475",
      PLZ: "01445",
      Stadt: "Radebeul",
    },
    2421: {
      LadenId: 2421,
      Telefon: "+493020453133",
      Strasse: "Friedrichstraße 191",
      PLZ: "10117",
      Stadt: "Berlin",
    },
    2422: {
      LadenId: 2422,
      Telefon: "+4963136123881",
      Strasse: "Fackelrondell 1",
      PLZ: "67655",
      Stadt: "Kaiserslautern",
    },
    2425: {
      LadenId: 2425,
      Telefon: "+4997178530272",
      Strasse: "Hartmannstraße 30a",
      PLZ: "97688",
      Stadt: "Bad Kissingen",
    },
    2426: {
      LadenId: 2426,
      Telefon: "+4941066129016",
      Strasse: "Feldbehnstraße 35",
      PLZ: "25451",
      Stadt: "Quickborn",
    },
    2427: {
      LadenId: 2427,
      Telefon: "+496924241884",
      Strasse: "Berger Straße 93-95",
      PLZ: "60316",
      Stadt: "Frankfurt am Main",
    },
    2428: {
      LadenId: 2428,
      Telefon: "+4997716302791",
      Strasse: "Saalestraße 2",
      PLZ: "97616",
      Stadt: "Bad Neustadt a. d. Saale",
    },
    2429: {
      LadenId: 2429,
      Telefon: "+498921567367",
      Strasse: "Hermann-Weinhauser-Straße 90",
      PLZ: "81673",
      Stadt: "München",
    },
    2430: {
      LadenId: 2430,
      Telefon: "+4956419299080",
      Strasse: "Paderborner Tor 167",
      PLZ: "34414",
      Stadt: "Warburg",
    },
    2433: {
      LadenId: 2433,
      Telefon: "+4926619168827",
      Strasse: "An der Lehmkaute 1a",
      PLZ: "56470",
      Stadt: "Bad Marienberg",
    },
    2434: {
      LadenId: 2434,
      Telefon: "+4956160290859",
      Strasse: "Obere Königsstraße 17",
      PLZ: "34117",
      Stadt: "Kassel",
    },
    2435: {
      LadenId: 2435,
      Telefon: "+4976339299133",
      Strasse: "Großmattenstraße 2",
      PLZ: "79219",
      Stadt: "Staufen im Breisgau",
    },
    2436: {
      LadenId: 2436,
      Telefon: "+4926629448058",
      Strasse: "Kleeberger Weg 35a",
      PLZ: "57627",
      Stadt: "Hachenburg",
    },
    2437: {
      LadenId: 2437,
      Telefon: "+4937527211768",
      Strasse: "Innere Plauensche Straße 14",
      PLZ: "08056",
      Stadt: "Zwickau",
    },
    2439: {
      LadenId: 2439,
      Telefon: "+4964713790163",
      Strasse: "Viehweg 12a",
      PLZ: "35781",
      Stadt: "Weilburg",
    },
    2440: {
      LadenId: 2440,
      Telefon: "+493097603117",
      Strasse: "Storkower Straße 207",
      PLZ: "10369",
      Stadt: "Berlin",
    },
    2442: {
      LadenId: 2442,
      Telefon: "+4995615110013",
      Strasse: "Callenberger Straße 16",
      PLZ: "96450",
      Stadt: "Coburg",
    },
    2443: {
      LadenId: 2443,
      Telefon: "+4934147839374",
      Strasse: "Zschochersche Straße 82a",
      PLZ: "04229",
      Stadt: "Leipzig",
    },
    2445: {
      LadenId: 2445,
      Telefon: "+4953515539240",
      Strasse: "Emmerstedter Straße 11",
      PLZ: "38350",
      Stadt: "Helmstedt",
    },
    2446: {
      LadenId: 2446,
      Telefon: "+4993428561855",
      Strasse: "Breslauer Straße 4",
      PLZ: "97877",
      Stadt: "Wertheim",
    },
    2447: {
      LadenId: 2447,
      Telefon: "+4984527329280",
      Strasse: "Nöttinger Straße 25",
      PLZ: "85290",
      Stadt: "Geisenfeld",
    },
    2448: {
      LadenId: 2448,
      Telefon: "+4968311270416",
      Strasse: "Großer Markt 22",
      PLZ: "66740",
      Stadt: "Saarlouis",
    },
    2450: {
      LadenId: 2450,
      Telefon: "+4978219599533",
      Strasse: "Im Götzmann 4",
      PLZ: "77933",
      Stadt: "Lahr",
    },
    2451: {
      LadenId: 2451,
      Telefon: "+4962216390250",
      Strasse: "Da-Vinci-Straße 5",
      PLZ: "69115",
      Stadt: "Heidelberg",
    },
    2452: {
      LadenId: 2452,
      Telefon: "+494028806708",
      Strasse: "Wandsbeker Marktstraße 1",
      PLZ: "22041",
      Stadt: "Hamburg",
    },
    2453: {
      LadenId: 2453,
      Telefon: "+4965711555890",
      Strasse: "Römerstraße 70",
      PLZ: "54516",
      Stadt: "Wittlich",
    },
    2454: {
      LadenId: 2454,
      Telefon: "+4938347737926",
      Strasse: "Bahnhofstraße 44a",
      PLZ: "17489",
      Stadt: "Greifswald",
    },
    2456: {
      LadenId: 2456,
      Telefon: "+4994224019704",
      Strasse: "Bärndorf 29h",
      PLZ: "94327",
      Stadt: "Bogen",
    },
    2457: {
      LadenId: 2457,
      Telefon: "+4980312314585",
      Strasse: "Münchener Str. 27",
      PLZ: "83022",
      Stadt: "Rosenheim",
    },
    2460: {
      LadenId: 2460,
      Telefon: "+4936065079752",
      Strasse: "Brüsseler Straße 1c",
      PLZ: "37308",
      Stadt: "Heilbad Heiligenstadt",
    },
    2461: {
      LadenId: 2461,
      Telefon: "+4972433240910",
      Strasse: "Schöllbronner Straße 21",
      PLZ: "76275",
      Stadt: "Ettlingen",
    },
    2462: {
      LadenId: 2462,
      Telefon: "+4994128007469",
      Strasse: "Regendorfer Straße 3a",
      PLZ: "93138",
      Stadt: "Lappersdorf",
    },
    2464: {
      LadenId: 2464,
      Telefon: "+4965929570928",
      Strasse: "Mehrener Straße 22",
      PLZ: "54550",
      Stadt: "Daun",
    },
    2466: {
      LadenId: 2466,
      Telefon: "+4994158437812",
      Strasse: "Königsstraße 8",
      PLZ: "93047",
      Stadt: "Regensburg",
    },
    2471: {
      LadenId: 2471,
      Telefon: "+4942169009081",
      Strasse: "Weserstrandstraße 17 F",
      PLZ: "28779",
      Stadt: "Bremen",
    },
    2472: {
      LadenId: 2472,
      Telefon: "+4984569168999",
      Strasse: "Ingolstädter Straße 141",
      PLZ: "85092",
      Stadt: "Kösching",
    },
    2473: {
      LadenId: 2473,
      Telefon: "+4971575348867",
      Strasse: "Auf dem Graben 4",
      PLZ: "71111",
      Stadt: "Waldenbuch",
    },
    2474: {
      LadenId: 2474,
      Telefon: "+4960311618392",
      Strasse: "Fauerbacher Straße 12-14",
      PLZ: "61169",
      Stadt: "Friedberg (Hessen)",
    },
    2476: {
      LadenId: 2476,
      Telefon: "+4972634089309",
      Strasse: "Neidensteiner Straße 8",
      PLZ: "74915",
      Stadt: "Waibstadt",
    },
    2477: {
      LadenId: 2477,
      Telefon: "+4978059120834",
      Strasse: "Im See 18",
      PLZ: "77767",
      Stadt: "Appenweier",
    },
    2479: {
      LadenId: 2479,
      Telefon: "+4974238101864",
      Strasse: "Sägewerkstraße 17",
      PLZ: "78727",
      Stadt: "Oberndorf am Neckar",
    },
    2480: {
      LadenId: 2480,
      Telefon: "+4948416657848",
      Strasse: "Siemensstraße 43",
      PLZ: "25813",
      Stadt: "Husum",
    },
    2482: {
      LadenId: 2482,
      Telefon: "+4960814427535",
      Strasse: "Neuer Marktplatz 2",
      PLZ: "61250",
      Stadt: "Usingen",
    },
    2483: {
      LadenId: 2483,
      Telefon: "+4928217133297",
      Strasse: "Ludwig-Jahn-Straße 7-15",
      PLZ: "47533",
      Stadt: "Kleve",
    },
    2487: {
      LadenId: 2487,
      Telefon: "+4958194879923",
      Strasse: "Veerßer Straße 59",
      PLZ: "29525",
      Stadt: "Uelzen",
    },
    2489: {
      LadenId: 2489,
      Telefon: "+498951114853",
      Strasse: "Sonnenstraße 12",
      PLZ: "80331",
      Stadt: "München",
    },
    2490: {
      LadenId: 2490,
      Telefon: "+4955150969243",
      Strasse: "Salinenweg 1",
      PLZ: "37081",
      Stadt: "Göttingen",
    },
    2491: {
      LadenId: 2491,
      Telefon: "+496966122889",
      Strasse: "Hainer Weg 74",
      PLZ: "60599",
      Stadt: "Frankfurt am Main",
    },
    2496: {
      LadenId: 2496,
      Telefon: "+4976121702918",
      Strasse: "Kaiser- Joseph-Straße 165",
      PLZ: "79098",
      Stadt: "Freiburg im Breisgau",
    },
    2499: {
      LadenId: 2499,
      Telefon: "+4965819956893",
      Strasse: "Am Leukbach 10",
      PLZ: "54439",
      Stadt: "Saarburg",
    },
    2500: {
      LadenId: 2500,
      Telefon: "+4943214896589",
      Strasse: "Gänsemarkt 1",
      PLZ: "24534",
      Stadt: "Neumünster",
    },
    2501: {
      LadenId: 2501,
      Telefon: "+4933283084925",
      Strasse: "Saganer Straße 10",
      PLZ: "14513",
      Stadt: "Teltow",
    },
    2502: {
      LadenId: 2502,
      Telefon: "+496954000994",
      Strasse: "Gundelandstraße 22",
      PLZ: "60435",
      Stadt: "Frankfurt am Main",
    },
    2503: {
      LadenId: 2503,
      Telefon: "+4941013693930",
      Strasse: "Bismarckstraße 13",
      PLZ: "25421",
      Stadt: "Pinneberg",
    },
    2504: {
      LadenId: 2504,
      Telefon: "+4981929337350",
      Strasse: "Aumühle 2",
      PLZ: "86938",
      Stadt: "Schondorf am Ammersee",
    },
    2505: {
      LadenId: 2505,
      Telefon: "+4939322722583",
      Strasse: "Kirschallee 11",
      PLZ: "39590",
      Stadt: "Tangermünde",
    },
    2506: {
      LadenId: 2506,
      Telefon: "+4933342779297",
      Strasse: "Heegermühler Straße 19a",
      PLZ: "16225",
      Stadt: "Eberswalde",
    },
    2507: {
      LadenId: 2507,
      Telefon: "+4959325009530",
      Strasse: "Brinkerweg 1a",
      PLZ: "49733",
      Stadt: "Haren (Ems)",
    },
    2508: {
      LadenId: 2508,
      Telefon: "+4933782051973",
      Strasse: "Potsdamer Straße 52f",
      PLZ: "14974",
      Stadt: "Ludwigsfelde",
    },
    2510: {
      LadenId: 2510,
      Telefon: "+4987239785910",
      Strasse: "Bürgermeister-Haberl-Straße 1a",
      PLZ: "94424",
      Stadt: "Arnstorf",
    },
    2512: {
      LadenId: 2512,
      Telefon: "+4961047809003",
      Strasse: "Raiffeisenstraße 16",
      PLZ: "63179",
      Stadt: "Obertshausen",
    },
    2513: {
      LadenId: 2513,
      Telefon: "+4962616729996",
      Strasse: "Pfalzgraf-Otto-Straße 95",
      PLZ: "74821",
      Stadt: "Mosbach",
    },
    2516: {
      LadenId: 2516,
      Telefon: "+4993140409628",
      Strasse: "Unterdürrbacher Straße 2",
      PLZ: "97080",
      Stadt: "Würzburg",
    },
    2517: {
      LadenId: 2517,
      Telefon: "+4961314640010",
      Strasse: "Rheinallee 120",
      PLZ: "55120",
      Stadt: "Mainz",
    },
    2518: {
      LadenId: 2518,
      Telefon: "+4937254499881",
      Strasse: "Waldkirchener Straße 17a",
      PLZ: "09405",
      Stadt: "Zschopau",
    },
    2519: {
      LadenId: 2519,
      Telefon: "+4962154590039",
      Strasse: "Von-Kieffer-Straße 14",
      PLZ: "67065",
      Stadt: "Ludwigshafen am Rhein",
    },
    2521: {
      LadenId: 2521,
      Telefon: "+4935255130896",
      Strasse: "Hauptstraße 97",
      PLZ: "01587",
      Stadt: "Riesa",
    },
    2522: {
      LadenId: 2522,
      Telefon: "+4961236206806",
      Strasse: "Kiedricher Straße 7",
      PLZ: "65343",
      Stadt: "Eltville am Rhein",
    },
    2523: {
      LadenId: 2523,
      Telefon: "+4965114576856",
      Strasse: "Robert-Schuman-Allee 16",
      PLZ: "54296",
      Stadt: "Trier",
    },
    2526: {
      LadenId: 2526,
      Telefon: "+494072340813",
      Strasse: "Süderquerweg 101",
      PLZ: "21037",
      Stadt: "Hamburg",
    },
    2528: {
      LadenId: 2528,
      Telefon: "+498923000736",
      Strasse: "Klenzestraße 52a",
      PLZ: "80469",
      Stadt: "München",
    },
    2530: {
      LadenId: 2530,
      Telefon: "+4996618119006",
      Strasse: "Dieselstraße 30",
      PLZ: "92237",
      Stadt: "Sulzbach-Rosenberg",
    },
    2531: {
      LadenId: 2531,
      Telefon: "+4980313045446",
      Strasse: "Am Rothbachl 2",
      PLZ: "83059",
      Stadt: "Kolbermoor",
    },
    2532: {
      LadenId: 2532,
      Telefon: "+4971412984913",
      Strasse: "Marstall 000",
      PLZ: "71634",
      Stadt: "Ludwigsburg",
    },
    2534: {
      LadenId: 2534,
      Telefon: "+4942122379197",
      Strasse: "Gerold-Janssen-Straße 2 A",
      PLZ: "28359",
      Stadt: "Bremen",
    },
    2535: {
      LadenId: 2535,
      Telefon: "+4992315076883",
      Strasse: "Wölsauerstraße 35b",
      PLZ: "95615",
      Stadt: "Marktredwitz",
    },
    2536: {
      LadenId: 2536,
      Telefon: "+4939094739388",
      Strasse: "Poppauer Straße 1",
      PLZ: "38486",
      Stadt: "Klötze",
    },
    2538: {
      LadenId: 2538,
      Telefon: "+4934513176961",
      Strasse: "Leipziger Straße 5",
      PLZ: "06108",
      Stadt: "Halle (Saale)",
    },
    2542: {
      LadenId: 2542,
      Telefon: "+4934379779980",
      Strasse: "Wurzener Straße 1",
      PLZ: "04668",
      Stadt: "Grimma",
    },
    2545: {
      LadenId: 2545,
      Telefon: "+4981913051753",
      Strasse: "Augsburger Straße 60",
      PLZ: "86899",
      Stadt: "Landsberg am Lech",
    },
    2546: {
      LadenId: 2546,
      Telefon: "+4959211797039",
      Strasse: "Friedrich-Ebert-Strasse 2",
      PLZ: "48529",
      Stadt: "Nordhorn",
    },
    2547: {
      LadenId: 2547,
      Telefon: "+4961849047205",
      Strasse: "Ringstraße 33-39",
      PLZ: "63505",
      Stadt: "Langenselbold",
    },
    2548: {
      LadenId: 2548,
      Telefon: "+4953225531284",
      Strasse: "Ilsenburger Straße 6",
      PLZ: "38667",
      Stadt: "Bad Harzburg",
    },
    2549: {
      LadenId: 2549,
      Telefon: "+4988192482358",
      Strasse: "Münchener Straße 64",
      PLZ: "82362",
      Stadt: "Weilheim i.OB",
    },
    2552: {
      LadenId: 2552,
      Telefon: "+4985819875622",
      Strasse: "Bannholzstraße 34b",
      PLZ: "94065",
      Stadt: "Waldkirchen",
    },
    2554: {
      LadenId: 2554,
      Telefon: "+4994015277495",
      Strasse: "Pommernstraße 17",
      PLZ: "93073",
      Stadt: "Neutraubling",
    },
    2556: {
      LadenId: 2556,
      Telefon: "+4928712180941",
      Strasse: "Münsterstraße 7a",
      PLZ: "46397",
      Stadt: "Bocholt",
    },
    2557: {
      LadenId: 2557,
      Telefon: "+4982142092008",
      Strasse: "Am Lehenauweg 2c",
      PLZ: "86462",
      Stadt: "Langweid a.Lech",
    },
    2558: {
      LadenId: 2558,
      Telefon: "+4974629464766",
      Strasse: "Am Freizeitzentrum 5",
      PLZ: "78194",
      Stadt: "Immendingen",
    },
    2559: {
      LadenId: 2559,
      Telefon: "+4977449299639",
      Strasse: "Lindenweg 1",
      PLZ: "79780",
      Stadt: "Stühlingen",
    },
    2560: {
      LadenId: 2560,
      Telefon: "+4970237433302",
      Strasse: "Im Stockach 3",
      PLZ: "73235",
      Stadt: "Weilheim an der Teck",
    },
    2561: {
      LadenId: 2561,
      Telefon: "+4962177735548",
      Strasse: "Amselstraße 10",
      PLZ: "68307",
      Stadt: "Mannheim",
    },
    2562: {
      LadenId: 2562,
      Telefon: "+4966819170243",
      Strasse: "Aue 20",
      PLZ: "36115",
      Stadt: "Hilders",
    },
    2564: {
      LadenId: 2564,
      Telefon: "+4951026736817",
      Strasse: "Hildesheimer Straße 352",
      PLZ: "30880",
      Stadt: "Laatzen",
    },
    2565: {
      LadenId: 2565,
      Telefon: "+493034353447",
      Strasse: "Prerower Platz 1",
      PLZ: "13051",
      Stadt: "Berlin",
    },
    2566: {
      LadenId: 2566,
      Telefon: "+4934051980805",
      Strasse: "Franzstraße 159",
      PLZ: "06842",
      Stadt: "Dessau-Roßlau",
    },
    2568: {
      LadenId: 2568,
      Telefon: "+4935208346628",
      Strasse: "Lindenweg 1a",
      PLZ: "01471",
      Stadt: "Radeburg",
    },
    2569: {
      LadenId: 2569,
      Telefon: "+4946180726608",
      Strasse: "Holm 2-8",
      PLZ: "24937",
      Stadt: "Flensburg",
    },
    2571: {
      LadenId: 2571,
      Telefon: "+4961512786022",
      Strasse: "Ettesterstraße 1",
      PLZ: "64291",
      Stadt: "Darmstadt",
    },
    2572: {
      LadenId: 2572,
      Telefon: "+4933752164980",
      Strasse: "Chausseestraße 1",
      PLZ: "15745",
      Stadt: "Wildau",
    },
    2575: {
      LadenId: 2575,
      Telefon: "+498972447009",
      Strasse: "Hainbuchenring 15",
      PLZ: "82061",
      Stadt: "Neuried",
    },
    2576: {
      LadenId: 2576,
      Telefon: "+4954049144937",
      Strasse: "Heerstraße 3 a",
      PLZ: "49492",
      Stadt: "Westerkappeln",
    },
    2577: {
      LadenId: 2577,
      Telefon: "+4944121706598",
      Strasse: "Achternstr. 4-5",
      PLZ: "26122",
      Stadt: "Oldenburg",
    },
    2578: {
      LadenId: 2578,
      Telefon: "+493082407115",
      Strasse: "Berkaer Straße 2-3",
      PLZ: "14199",
      Stadt: "Berlin",
    },
    2579: {
      LadenId: 2579,
      Telefon: "+4968259706975",
      Strasse: "Quierschieder Straße 19",
      PLZ: "66589",
      Stadt: "Merchweiler",
    },
    2580: {
      LadenId: 2580,
      Telefon: "+4974279425988",
      Strasse: "Robert-Koch-Straße 5",
      PLZ: "72359",
      Stadt: "Dotternhausen",
    },
    2581: {
      LadenId: 2581,
      Telefon: "+498951618772",
      Strasse: "Sonnenstraße 7",
      PLZ: "80331",
      Stadt: "München",
    },
    2583: {
      LadenId: 2583,
      Telefon: "+4934112457135",
      Strasse: "Nürnberger Straße 50",
      PLZ: "04103",
      Stadt: "Leipzig",
    },
    2584: {
      LadenId: 2584,
      Telefon: "+4935817049550",
      Strasse: "Reichenbacher Str. 53/55",
      PLZ: "02827",
      Stadt: "Görlitz",
    },
    2586: {
      LadenId: 2586,
      Telefon: "+4961816750734",
      Strasse: "Otto-Hahn Straße 18",
      PLZ: "63456",
      Stadt: "Hanau",
    },
    2587: {
      LadenId: 2587,
      Telefon: "+4976158531497",
      Strasse: "Ellen-Gottlieb-Straße 2",
      PLZ: "79106",
      Stadt: "Freiburg im Breisgau",
    },
    2592: {
      LadenId: 2592,
      Telefon: "+4942142758906",
      Strasse: "Hans-Bredow-Str. 19",
      PLZ: "28307",
      Stadt: "Bremen",
    },
    2593: {
      LadenId: 2593,
      Telefon: "+4923919143701",
      Strasse: "Waskebieke 2",
      PLZ: "58840",
      Stadt: "Plettenberg",
    },
    2594: {
      LadenId: 2594,
      Telefon: "+4991936059930",
      Strasse: "Kieferndorfer Weg 58c",
      PLZ: "91315",
      Stadt: "Höchstadt a.d.Aisch",
    },
    2596: {
      LadenId: 2596,
      Telefon: "+4972513480536",
      Strasse: "Erich-Keßler-Straße 1",
      PLZ: "76689",
      Stadt: "Karlsdorf-Neuthard",
    },
    2597: {
      LadenId: 2597,
      Telefon: "+4939125443372",
      Strasse: "Olvenstedter Graseweg 37",
      PLZ: "39128",
      Stadt: "Magdeburg",
    },
    2598: {
      LadenId: 2598,
      Telefon: "+4968347820015",
      Strasse: "Saarbrücker  Straße 200-201",
      PLZ: "66359",
      Stadt: "Bous",
    },
    2599: {
      LadenId: 2599,
      Telefon: "+4940999967500",
      Strasse: "Im Fischbeker Heidbrook 2c",
      PLZ: "21149",
      Stadt: "Hamburg",
    },
    2600: {
      LadenId: 2600,
      Telefon: "+498994004808",
      Strasse: "Ohmstraße 3",
      PLZ: "85609",
      Stadt: "Aschheim",
    },
    2601: {
      LadenId: 2601,
      Telefon: "+4942152079467",
      Strasse: "Duckwitzstraße 55",
      PLZ: "28199",
      Stadt: "Bremen",
    },
    2605: {
      LadenId: 2605,
      Telefon: "+4983414386950",
      Strasse: "Mindelheimer Straße 19",
      PLZ: "87600",
      Stadt: "Kaufbeuren",
    },
    2606: {
      LadenId: 2606,
      Telefon: "+4930403639720",
      Strasse: "Tamara-Danz-Straße 11",
      PLZ: "10243",
      Stadt: "Berlin",
    },
    2607: {
      LadenId: 2607,
      Telefon: "+4943427899819",
      Strasse: "Hufenweg 24",
      PLZ: "24211",
      Stadt: "Preetz",
    },
    2608: {
      LadenId: 2608,
      Telefon: "+493034093874",
      Strasse: "Siemensdamm 43/44",
      PLZ: "13629",
      Stadt: "Berlin",
    },
    2609: {
      LadenId: 2609,
      Telefon: "+4964419740039",
      Strasse: "Budenweisgraben 1",
      PLZ: "35625",
      Stadt: "Hüttenberg",
    },
    2611: {
      LadenId: 2611,
      Telefon: "+4951199987420",
      Strasse: "Anderter Straße 13",
      PLZ: "30629",
      Stadt: "Hannover",
    },
    2612: {
      LadenId: 2612,
      Telefon: "+494030085087",
      Strasse: "Steinstraße 8",
      PLZ: "20095",
      Stadt: "Hamburg",
    },
    2613: {
      LadenId: 2613,
      Telefon: "+4970717704055",
      Strasse: "Wohlboldstraße 7",
      PLZ: "72072",
      Stadt: "Tübingen",
    },
    2614: {
      LadenId: 2614,
      Telefon: "+4995199395920",
      Strasse: "Biegenhofstraße 2",
      PLZ: "96103",
      Stadt: "Hallstadt",
    },
    2615: {
      LadenId: 2615,
      Telefon: "+4927125093865",
      Strasse: "Eiserfelder Straße 460",
      PLZ: "57080",
      Stadt: "Siegen",
    },
    2616: {
      LadenId: 2616,
      Telefon: "+4945129019639",
      Strasse: "Herrenholz 14",
      PLZ: "23556",
      Stadt: "Lübeck",
    },
    2617: {
      LadenId: 2617,
      Telefon: "+4975319161159",
      Strasse: "Marktstätte 22",
      PLZ: "78462",
      Stadt: "Konstanz",
    },
    2618: {
      LadenId: 2618,
      Telefon: "+4971412982037",
      Strasse: "Eglosheimer Straße 75",
      PLZ: "71679",
      Stadt: "Asperg",
    },
    2619: {
      LadenId: 2619,
      Telefon: "+4936155048334",
      Strasse: "Neuwerkstraße 3-5",
      PLZ: "99084",
      Stadt: "Erfurt",
    },
    2620: {
      LadenId: 2620,
      Telefon: "+4971128413353",
      Strasse: "Königstraße 27",
      PLZ: "70173",
      Stadt: "Stuttgart",
    },
    2623: {
      LadenId: 2623,
      Telefon: "+4933540070045",
      Strasse: "August-Bebel-Straße 1a",
      PLZ: "15234",
      Stadt: "Frankfurt (Oder)",
    },
    2624: {
      LadenId: 2624,
      Telefon: "+4936289989060",
      Strasse: "Turnvater-Jahn-Straße 7",
      PLZ: "99310",
      Stadt: "Arnstadt",
    },
    2625: {
      LadenId: 2625,
      Telefon: "+4956029134841",
      Strasse: "Mühlweg 7",
      PLZ: "37235",
      Stadt: "Hessisch Lichtenau",
    },
    2626: {
      LadenId: 2626,
      Telefon: "+4962099099000",
      Strasse: "Jahnstraße 10 a",
      PLZ: "69509",
      Stadt: "Mörlenbach",
    },
    2627: {
      LadenId: 2627,
      Telefon: "+4968130140384",
      Strasse: "Bahnhofstraße 71-73",
      PLZ: "66111",
      Stadt: "Saarbrücken",
    },
    2628: {
      LadenId: 2628,
      Telefon: "+4922627077472",
      Strasse: "Wiesenstraße 6",
      PLZ: "51674",
      Stadt: "Wiehl",
    },
    2629: {
      LadenId: 2629,
      Telefon: "+4937314470390",
      Strasse: "Friedeburger Straße 9",
      PLZ: "09599",
      Stadt: "Freiberg",
    },
    2632: {
      LadenId: 2632,
      Telefon: "+4935179991300",
      Strasse: "Wallstraße 2a",
      PLZ: "01067",
      Stadt: "Dresden",
    },
    2633: {
      LadenId: 2633,
      Telefon: "+4953112167499",
      Strasse: "Damm 16",
      PLZ: "38100",
      Stadt: "Braunschweig",
    },
    2635: {
      LadenId: 2635,
      Telefon: "+4936155048136",
      Strasse: "Bahnhofstraße 5 a",
      PLZ: "99084",
      Stadt: "Erfurt",
    },
    2636: {
      LadenId: 2636,
      Telefon: "+4974419309340",
      Strasse: "Stuttgarter Straße 94",
      PLZ: "72250",
      Stadt: "Freudenstadt",
    },
    2637: {
      LadenId: 2637,
      Telefon: "+4965259499070",
      Strasse: "Am Spitulersberg 3",
      PLZ: "54666",
      Stadt: "Irrel",
    },
    2638: {
      LadenId: 2638,
      Telefon: "+4935813609540",
      Strasse: "Nieskyer Straße 100",
      PLZ: "02828",
      Stadt: "Görlitz",
    },
    2640: {
      LadenId: 2640,
      Telefon: "+4981669921850",
      Strasse: "Im Glonnfeld 2",
      PLZ: "85391",
      Stadt: "Allershausen",
    },
    2642: {
      LadenId: 2642,
      Telefon: "+4951151524520",
      Strasse: "Vahrenwalder Straße 11",
      PLZ: "30165",
      Stadt: "Hannover",
    },
    2643: {
      LadenId: 2643,
      Telefon: "+4982828905248",
      Strasse: "Höllgehau 2b",
      PLZ: "86381",
      Stadt: "Krumbach (Schwaben)",
    },
    2644: {
      LadenId: 2644,
      Telefon: "+4945140039000",
      Strasse: "Ziegelstraße 2",
      PLZ: "23556",
      Stadt: "Lübeck",
    },
    2645: {
      LadenId: 2645,
      Telefon: "+496913387675",
      Strasse: "Zeil 47-49",
      PLZ: "60313",
      Stadt: "Frankfurt am Main",
    },
    2646: {
      LadenId: 2646,
      Telefon: "+4939771527781",
      Strasse: "Belliner Straße 9",
      PLZ: "17373",
      Stadt: "Ueckermünde",
    },
    2650: {
      LadenId: 2650,
      Telefon: "+4953535808000",
      Strasse: "Fischersteg 2",
      PLZ: "38154",
      Stadt: "Königslutter am Elm",
    },
    2653: {
      LadenId: 2653,
      Telefon: "+4993130418594",
      Strasse: "Marktplatz 38",
      PLZ: "97070",
      Stadt: "Würzburg",
    },
    2657: {
      LadenId: 2657,
      Telefon: "+4948419988060",
      Strasse: "Großstraße 15-17",
      PLZ: "25813",
      Stadt: "Husum",
    },
    2658: {
      LadenId: 2658,
      Telefon: "+4971427885672",
      Strasse: "Mühlwiesenstraße 27",
      PLZ: "74321",
      Stadt: "Bietigheim-Bissingen",
    },
    2659: {
      LadenId: 2659,
      Telefon: "+4974225605268",
      Strasse: "Heiligenbronner Straße 6",
      PLZ: "78713",
      Stadt: "Schramberg",
    },
    2660: {
      LadenId: 2660,
      Telefon: "+4961339899000",
      Strasse: "In den Weingärten 80",
      PLZ: "55276",
      Stadt: "Oppenheim",
    },
    2661: {
      LadenId: 2661,
      Telefon: "+498944118306",
      Strasse: "Kühbachstraße 3-9",
      PLZ: "81543",
      Stadt: "München",
    },
    2663: {
      LadenId: 2663,
      Telefon: "+4936214290190",
      Strasse: "Gartenstraße 13",
      PLZ: "99867",
      Stadt: "Gotha",
    },
    2664: {
      LadenId: 2664,
      Telefon: "+4936438819060",
      Strasse: "Damaschkestraße 1",
      PLZ: "99425",
      Stadt: "Weimar",
    },
    2666: {
      LadenId: 2666,
      Telefon: "+4934121555986",
      Strasse: "Hainstraße 21",
      PLZ: "04109",
      Stadt: "Leipzig",
    },
    2668: {
      LadenId: 2668,
      Telefon: "+4934217609900",
      Strasse: "Außenring 1",
      PLZ: "04860",
      Stadt: "Torgau",
    },
    2669: {
      LadenId: 2669,
      Telefon: "+4972569254008",
      Strasse: "Gerstenfeldstraße 2",
      PLZ: "76661",
      Stadt: "Philippsburg",
    },
    2671: {
      LadenId: 2671,
      Telefon: "+4982132911020",
      Strasse: "Viktoriastraße 3",
      PLZ: "86150",
      Stadt: "Augsburg",
    },
    2675: {
      LadenId: 2675,
      Telefon: "+494031768293",
      Strasse: "Millerntorplatz 1",
      PLZ: "20359",
      Stadt: "Hamburg",
    },
    2679: {
      LadenId: 2679,
      Telefon: "+4933032109630",
      Strasse: "Schönfließer Straße 25f",
      PLZ: "16540",
      Stadt: "Hohen Neuendorf",
    },
    2680: {
      LadenId: 2680,
      Telefon: "+4963311494894",
      Strasse: "Bitscher Straße 85",
      PLZ: "66955",
      Stadt: "Pirmasens",
    },
    2681: {
      LadenId: 2681,
      Telefon: "+4933217465542",
      Strasse: "Dammstr. 9",
      PLZ: "14641",
      Stadt: "Nauen",
    },
    2682: {
      LadenId: 2682,
      Telefon: "+4984188197908",
      Strasse: "Eriagstraße 30",
      PLZ: "85053",
      Stadt: "Ingolstadt",
    },
    2683: {
      LadenId: 2683,
      Telefon: "+4946639598100",
      Strasse: "Hauptstraße 21-25",
      PLZ: "25923",
      Stadt: "Süderlügum",
    },
    2684: {
      LadenId: 2684,
      Telefon: "+4983742325344",
      Strasse: "Raiffeisenstraße 4",
      PLZ: "87463",
      Stadt: "Dietmannsried",
    },
    2687: {
      LadenId: 2687,
      Telefon: "+4945129630821",
      Strasse: "Königstraße 54-56",
      PLZ: "23552",
      Stadt: "Lübeck",
    },
    2688: {
      LadenId: 2688,
      Telefon: "+4939545838000",
      Strasse: "Treptower Straße 3",
      PLZ: "17033",
      Stadt: "Neubrandenburg",
    },
    2690: {
      LadenId: 2690,
      Telefon: "+4945148785010",
      Strasse: "Weidentrift 4-6",
      PLZ: "23562",
      Stadt: "Lübeck",
    },
    2691: {
      LadenId: 2691,
      Telefon: "+4989997437240",
      Strasse: "Theresienhöhe 5",
      PLZ: "80339",
      Stadt: "München",
    },
    2692: {
      LadenId: 2692,
      Telefon: "+4968138753390",
      Strasse: "Im Knappenroth 1c",
      PLZ: "66113",
      Stadt: "Saarbrücken",
    },
    2693: {
      LadenId: 2693,
      Telefon: "+4940571990560",
      Strasse: "Rönneburger Straße 19",
      PLZ: "21079",
      Stadt: "Hamburg",
    },
    2695: {
      LadenId: 2695,
      Telefon: "+4962180619561",
      Strasse: "Rheinauer Ring 1",
      PLZ: "68219",
      Stadt: "Mannheim",
    },
    2696: {
      LadenId: 2696,
      Telefon: "+4972446098422",
      Strasse: "Am Hasenbiel 2a",
      PLZ: "76297",
      Stadt: "Stutensee",
    },
    2697: {
      LadenId: 2697,
      Telefon: "+4971160133462",
      Strasse: "Karlstraße 94",
      PLZ: "70794",
      Stadt: "Filderstadt",
    },
    2698: {
      LadenId: 2698,
      Telefon: "+4975312090310",
      Strasse: "Am Wollmatinger Ried 4",
      PLZ: "78479",
      Stadt: "Reichenau",
    },
    2699: {
      LadenId: 2699,
      Telefon: "+4990812408790",
      Strasse: "Raiffeisenstraße 4",
      PLZ: "86720",
      Stadt: "Nördlingen",
    },
    2700: {
      LadenId: 2700,
      Telefon: "+4972626139960",
      Strasse: "Postweg 17",
      PLZ: "75031",
      Stadt: "Eppingen",
    },
    2701: {
      LadenId: 2701,
      Telefon: "+4927719329960",
      Strasse: "Am Sportzentrum 3",
      PLZ: "35683",
      Stadt: "Dillenburg",
    },
    2702: {
      LadenId: 2702,
      Telefon: "+4973489673028",
      Strasse: "Lerchenbergstrasse 16",
      PLZ: "89160",
      Stadt: "Dornstadt",
    },
    2703: {
      LadenId: 2703,
      Telefon: "+4981312720985",
      Strasse: "Kopernikusstraße 2",
      PLZ: "85221",
      Stadt: "Dachau",
    },
    2704: {
      LadenId: 2704,
      Telefon: "+4939435575793",
      Strasse: "Ilsenburger Straße 31a",
      PLZ: "38855",
      Stadt: "Wernigerode",
    },
    2706: {
      LadenId: 2706,
      Telefon: "+4966317098451",
      Strasse: "Alicestraße 11",
      PLZ: "36304",
      Stadt: "Alsfeld",
    },
    2707: {
      LadenId: 2707,
      Telefon: "+4933528499856",
      Strasse: "Alexej-Leonow-Straße 2a",
      PLZ: "15236",
      Stadt: "Frankfurt (Oder)",
    },
    2708: {
      LadenId: 2708,
      Telefon: "+4981589169060",
      Strasse: "Lindemannstraße 13",
      PLZ: "82327",
      Stadt: "Tutzing",
    },
    2709: {
      LadenId: 2709,
      Telefon: "+494053753328",
      Strasse: "Heegbarg 31",
      PLZ: "22391",
      Stadt: "Hamburg",
    },
    2711: {
      LadenId: 2711,
      Telefon: "+4982189982180",
      Strasse: "Brahmsstraße 2",
      PLZ: "86179",
      Stadt: "Augsburg",
    },
    2713: {
      LadenId: 2713,
      Telefon: "+4951412995180",
      Strasse: "Bergstraße 26b",
      PLZ: "29221",
      Stadt: "Celle",
    },
    2714: {
      LadenId: 2714,
      Telefon: "+4992875009003",
      Strasse: "Unterweißenbacher Weg 1",
      PLZ: "95100",
      Stadt: "Selb",
    },
    2715: {
      LadenId: 2715,
      Telefon: "+4976319329280",
      Strasse: "Östliche Allee 10",
      PLZ: "79379",
      Stadt: "Müllheim",
    },
    2716: {
      LadenId: 2716,
      Telefon: "+4997222349010",
      Strasse: "Mittlerer Weg 23a",
      PLZ: "97440",
      Stadt: "Werneck",
    },
    2717: {
      LadenId: 2717,
      Telefon: "+4977459260639",
      Strasse: "Hauptstraße 10",
      PLZ: "79807",
      Stadt: "Lottstetten",
    },
    2721: {
      LadenId: 2721,
      Telefon: "+4934918016464",
      Strasse: "Teucheler Weg 2",
      PLZ: "06886",
      Stadt: "Lutherstadt Wittenberg",
    },
    2723: {
      LadenId: 2723,
      Telefon: "+4963494192100",
      Strasse: "Hauptstraße 93",
      PLZ: "76865",
      Stadt: "Rohrbach",
    },
    2725: {
      LadenId: 2725,
      Telefon: "+4977335062032",
      Strasse: "Hohenkräher Brühl 16",
      PLZ: "78259",
      Stadt: "Mühlhausen-Ehingen",
    },
    2726: {
      LadenId: 2726,
      Telefon: "+4994416832877",
      Strasse: "Schäfflerstraße 1",
      PLZ: "93309",
      Stadt: "Kelheim",
    },
    2727: {
      LadenId: 2727,
      Telefon: "+4961269780200",
      Strasse: "Am Hexenturm 2",
      PLZ: "65510",
      Stadt: "Idstein",
    },
    2728: {
      LadenId: 2728,
      Telefon: "+493080406249",
      Strasse: "Landsberger Allee 117",
      PLZ: "10407",
      Stadt: "Berlin",
    },
    2730: {
      LadenId: 2730,
      Telefon: "+4970259079970",
      Strasse: "Bodelschwinghstr. 65",
      PLZ: "72636",
      Stadt: "Frickenhausen",
    },
    2732: {
      LadenId: 2732,
      Telefon: "+4973179032390",
      Strasse: "Albert-Einsteinplatz 3/10",
      PLZ: "89073",
      Stadt: "Ulm",
    },
    2733: {
      LadenId: 2733,
      Telefon: "+4960419688399",
      Strasse: "Frankfurter Straße 51",
      PLZ: "61197",
      Stadt: "Florstadt",
    },
    2736: {
      LadenId: 2736,
      Telefon: "+4938312899886",
      Strasse: "Ossenreyerstraße 54",
      PLZ: "18439",
      Stadt: "Stralsund",
    },
    2738: {
      LadenId: 2738,
      Telefon: "+4962065607110",
      Strasse: "Otto-Hahn-Straße 18",
      PLZ: "68623",
      Stadt: "Lampertheim",
    },
    2739: {
      LadenId: 2739,
      Telefon: "+493091148957",
      Strasse: "Landsberger Chaussee 17",
      PLZ: "16356",
      Stadt: "Ahrensfelde",
    },
    2740: {
      LadenId: 2740,
      Telefon: "+4933158565590",
      Strasse: "Marlene-Dietrich-Allee 15b",
      PLZ: "14482",
      Stadt: "Potsdam",
    },
    2741: {
      LadenId: 2741,
      Telefon: "+4980632339990",
      Strasse: "Aiblinger Straße 45",
      PLZ: "83620",
      Stadt: "Feldkirchen-Westerham",
    },
    2742: {
      LadenId: 2742,
      Telefon: "+4972756170153",
      Strasse: "Lauterburger Straße 13a",
      PLZ: "76870",
      Stadt: "Kandel",
    },
    2744: {
      LadenId: 2744,
      Telefon: "+4934130855668",
      Strasse: "Petersstraße 50",
      PLZ: "04109",
      Stadt: "Leipzig",
    },
    2746: {
      LadenId: 2746,
      Telefon: "+4962014708358",
      Strasse: "Berliner Platz 1",
      PLZ: "69469",
      Stadt: "Weinheim",
    },
    2747: {
      LadenId: 2747,
      Telefon: "+4975319160248",
      Strasse: "Bodanstraße 1",
      PLZ: "78462",
      Stadt: "Konstanz",
    },
    2748: {
      LadenId: 2748,
      Telefon: "+4958419586320",
      Strasse: "Dannenberger Straße 4",
      PLZ: "29439",
      Stadt: "Lüchow (Wendland)",
    },
    2749: {
      LadenId: 2749,
      Telefon: "+4989125036350",
      Strasse: "August-Everding-Straße 20",
      PLZ: "81671",
      Stadt: "München",
    },
    2750: {
      LadenId: 2750,
      Telefon: "+4964035046980",
      Strasse: "Gießener Pforte 33",
      PLZ: "35440",
      Stadt: "Linden",
    },
    2752: {
      LadenId: 2752,
      Telefon: "+4997618959020",
      Strasse: "Kapelleinsweg 13",
      PLZ: "97631",
      Stadt: "Bad Königshofen",
    },
    2753: {
      LadenId: 2753,
      Telefon: "+4995199339780",
      Strasse: "Franz-Ludwig-Straße 12",
      PLZ: "96047",
      Stadt: "Bamberg",
    },
    2754: {
      LadenId: 2754,
      Telefon: "+4973628380670",
      Strasse: "Ipf-Treff 2",
      PLZ: "73441",
      Stadt: "Bopfingen",
    },
    2756: {
      LadenId: 2756,
      Telefon: "+4977319225134",
      Strasse: "Brühlstraße 7",
      PLZ: "78247",
      Stadt: "Hilzingen",
    },
    2757: {
      LadenId: 2757,
      Telefon: "+498942036593",
      Strasse: "Willy-Brandt-Platz 5",
      PLZ: "81829",
      Stadt: "München",
    },
    2758: {
      LadenId: 2758,
      Telefon: "+4978359989010",
      Strasse: "Keramikstraße 6",
      PLZ: "77736",
      Stadt: "Zell am Harmersbach",
    },
    2759: {
      LadenId: 2759,
      Telefon: "+494030033816",
      Strasse: "Hoheluftchaussee 18",
      PLZ: "20253",
      Stadt: "Hamburg",
    },
    2760: {
      LadenId: 2760,
      Telefon: "+4989244193090",
      Strasse: "Bahnhofstraße 27",
      PLZ: "82131",
      Stadt: "Gauting",
    },
    2761: {
      LadenId: 2761,
      Telefon: "+4925621874710",
      Strasse: "Königstraße 66",
      PLZ: "48599",
      Stadt: "Gronau",
    },
    2762: {
      LadenId: 2762,
      Telefon: "+4938136446067",
      Strasse: "Kröpeliner Straße 54",
      PLZ: "18055",
      Stadt: "Rostock",
    },
    2763: {
      LadenId: 2763,
      Telefon: "+4998619329010",
      Strasse: "Schlachthofstraße 33",
      PLZ: "91541",
      Stadt: "Rothenburg ob der Tauber",
    },
    2766: {
      LadenId: 2766,
      Telefon: "+4934198975470",
      Strasse: "Mühlenstraße 2",
      PLZ: "04159",
      Stadt: "Leipzig",
    },
    2767: {
      LadenId: 2767,
      Telefon: "+4994211853889",
      Strasse: "Ittlinger Straße 150",
      PLZ: "94315",
      Stadt: "Straubing",
    },
    2768: {
      LadenId: 2768,
      Telefon: "+4933186750480",
      Strasse: "Stern-Center 1-10",
      PLZ: "14480",
      Stadt: "Potsdam",
    },
    2769: {
      LadenId: 2769,
      Telefon: "+4964218079940",
      Strasse: "Universitätsstraße 8",
      PLZ: "35037",
      Stadt: "Marburg",
    },
    2770: {
      LadenId: 2770,
      Telefon: "+4999199138471",
      Strasse: "Graflinger Straße 135",
      PLZ: "94469",
      Stadt: "Deggendorf",
    },
    2771: {
      LadenId: 2771,
      Telefon: "+4968679998000",
      Strasse: "In der Dörrwiese 1-3",
      PLZ: "66706",
      Stadt: "Perl",
    },
    2773: {
      LadenId: 2773,
      Telefon: "+494075114643",
      Strasse: "Liebigstraße 1",
      PLZ: "21465",
      Stadt: "Reinbek",
    },
    2774: {
      LadenId: 2774,
      Telefon: "+4968539019010",
      Strasse: "St.Wendeler Straße 4",
      PLZ: "66636",
      Stadt: "Tholey",
    },
    2775: {
      LadenId: 2775,
      Telefon: "+4968679998010",
      Strasse: "Erlenweg 6",
      PLZ: "66706",
      Stadt: "Perl",
    },
    2776: {
      LadenId: 2776,
      Telefon: "+4954168549922",
      Strasse: "Lengericher Landstraße 2",
      PLZ: "49078",
      Stadt: "Osnabrück",
    },
    2777: {
      LadenId: 2777,
      Telefon: "+4989716775840",
      Strasse: "Leopoldstraße 158",
      PLZ: "80804",
      Stadt: "München",
    },
    2778: {
      LadenId: 2778,
      Telefon: "+4995728170900",
      Strasse: "Seewiese 5",
      PLZ: "96224",
      Stadt: "Burgkunstadt",
    },
    2781: {
      LadenId: 2781,
      Telefon: "+4930221829690",
      Strasse: "Hardenbergplatz 11",
      PLZ: "10623",
      Stadt: "Berlin",
    },
    2783: {
      LadenId: 2783,
      Telefon: "+4936918029910",
      Strasse: "Karlstraße 36-42",
      PLZ: "99817",
      Stadt: "Eisenach",
    },
    2787: {
      LadenId: 2787,
      Telefon: "+4935185470040",
      Strasse: "Nürnberger Platz 1",
      PLZ: "01187",
      Stadt: "Dresden",
    },
    2790: {
      LadenId: 2790,
      Telefon: "+4962335111060",
      Strasse: "Wormser Straße 101",
      PLZ: "67227",
      Stadt: "Frankenthal (Pfalz)",
    },
    2791: {
      LadenId: 2791,
      Telefon: "+493042082675",
      Strasse: "Frankfurter Allee 57/59",
      PLZ: "10247",
      Stadt: "Berlin",
    },
    2792: {
      LadenId: 2792,
      Telefon: "+4933014290470",
      Strasse: "Berliner Straße 115",
      PLZ: "16515",
      Stadt: "Oranienburg",
    },
    2793: {
      LadenId: 2793,
      Telefon: "+4930403666740",
      Strasse: "Albrechtstraße 3",
      PLZ: "12165",
      Stadt: "Berlin",
    },
    2794: {
      LadenId: 2794,
      Telefon: "+4964198620250",
      Strasse: "Rodheimer Straße 107",
      PLZ: "35452",
      Stadt: "Heuchelheim",
    },
    2795: {
      LadenId: 2795,
      Telefon: "+4989693139950",
      Strasse: "Goethestraße 30",
      PLZ: "80336",
      Stadt: "München",
    },
    2798: {
      LadenId: 2798,
      Telefon: "+4934596397990",
      Strasse: "Richard-Paulick-Straße 31",
      PLZ: "06124",
      Stadt: "Halle (Saale)",
    },
    2799: {
      LadenId: 2799,
      Telefon: "+4928217385420",
      Strasse: "Stechbahn 2-8",
      PLZ: "47533",
      Stadt: "Kleve",
    },
    2800: {
      LadenId: 2800,
      Telefon: "+4998417919070",
      Strasse: "Im Häspelein 11",
      PLZ: "91438",
      Stadt: "Bad Windsheim",
    },
    2802: {
      LadenId: 2802,
      Telefon: "+4976669039950",
      Strasse: "Rosenstraße 2/2",
      PLZ: "79211",
      Stadt: "Denzlingen",
    },
    2804: {
      LadenId: 2804,
      Telefon: "+4960369869030",
      Strasse: "Biedrichstraße 14",
      PLZ: "61200",
      Stadt: "Wölfersheim",
    },
    2805: {
      LadenId: 2805,
      Telefon: "+4953617095380",
      Strasse: "Schlesierweg 15",
      PLZ: "38440",
      Stadt: "Wolfsburg",
    },
    2806: {
      LadenId: 2806,
      Telefon: "+4992324096340",
      Strasse: "Egerstraße 129",
      PLZ: "95632",
      Stadt: "Wunsiedel",
    },
    2807: {
      LadenId: 2807,
      Telefon: "+4971629209960",
      Strasse: "Eislinger Straße 60",
      PLZ: "73084",
      Stadt: "Salach",
    },
    2808: {
      LadenId: 2808,
      Telefon: "+4985529748769",
      Strasse: "Frauenberg 25",
      PLZ: "94481",
      Stadt: "Grafenau",
    },
    2809: {
      LadenId: 2809,
      Telefon: "+493020626938",
      Strasse: "Leipziger Platz 12",
      PLZ: "10117",
      Stadt: "Berlin",
    },
    2810: {
      LadenId: 2810,
      Telefon: "+4967188809550",
      Strasse: "Rüdesheimer Straße 92",
      PLZ: "55545",
      Stadt: "Bad Kreuznach",
    },
    2812: {
      LadenId: 2812,
      Telefon: "+4989588087860",
      Strasse: "Tal 24",
      PLZ: "80331",
      Stadt: "München",
    },
    2813: {
      LadenId: 2813,
      Telefon: "+4981519659560",
      Strasse: "Weilheimer Straße 14",
      PLZ: "82319",
      Stadt: "Starnberg",
    },
    2814: {
      LadenId: 2814,
      Telefon: "+493032765729",
      Strasse: "Wilmersdorfer Straße 126/127",
      PLZ: "10627",
      Stadt: "Berlin",
    },
    2815: {
      LadenId: 2815,
      Telefon: "+493091748557",
      Strasse: "Karl-Marx-Straße 66",
      PLZ: "12043",
      Stadt: "Berlin",
    },
    2816: {
      LadenId: 2816,
      Telefon: "+4935737990100",
      Strasse: "Straße des Bergmanns 12",
      PLZ: "01968",
      Stadt: "Senftenberg",
    },
    2817: {
      LadenId: 2817,
      Telefon: "+4965843059950",
      Strasse: "Wiesenstraße 4",
      PLZ: "54453",
      Stadt: "Nittel",
    },
    2819: {
      LadenId: 2819,
      Telefon: "+4972459036080",
      Strasse: "Schneidergarten 1",
      PLZ: "76467",
      Stadt: "Bietigheim",
    },
    2820: {
      LadenId: 2820,
      Telefon: "+4937442234950",
      Strasse: "Göltzschtalstraße 45",
      PLZ: "08209",
      Stadt: "Auerbach/Vogtland",
    },
    2821: {
      LadenId: 2821,
      Telefon: "+4994027882180",
      Strasse: "Schwandorfer Straße 25",
      PLZ: "93128",
      Stadt: "Regenstauf",
    },
    2822: {
      LadenId: 2822,
      Telefon: "+4973634090300",
      Strasse: "In der Waage 2",
      PLZ: "73463",
      Stadt: "Westhausen",
    },
    2823: {
      LadenId: 2823,
      Telefon: "+4961232080130",
      Strasse: "Untere Martinsthaler Straße 3",
      PLZ: "65396",
      Stadt: "Walluf",
    },
    2824: {
      LadenId: 2824,
      Telefon: "+4976215881690",
      Strasse: "Gewerbestraße 2",
      PLZ: "79576",
      Stadt: "Weil am Rhein",
    },
    2825: {
      LadenId: 2825,
      Telefon: "+4972190997010",
      Strasse: "Karlstraße 36",
      PLZ: "76133",
      Stadt: "Karlsruhe",
    },
    2827: {
      LadenId: 2827,
      Telefon: "+4935586959880",
      Strasse: "Berliner Straße 114b",
      PLZ: "03099",
      Stadt: "Kolkwitz",
    },
    2828: {
      LadenId: 2828,
      Telefon: "+4939495039820",
      Strasse: "Anderslebener Straße 42",
      PLZ: "39387",
      Stadt: "Oschersleben (Bode)",
    },
    2829: {
      LadenId: 2829,
      Telefon: "+4930403645310",
      Strasse: "Alexanderplatz 2",
      PLZ: "10178",
      Stadt: "Berlin",
    },
    2831: {
      LadenId: 2831,
      Telefon: "+4972190990430",
      Strasse: "Durlacher Allee 111",
      PLZ: "76137",
      Stadt: "Karlsruhe",
    },
    2833: {
      LadenId: 2833,
      Telefon: "+4933275693360",
      Strasse: "Apfelweg 5",
      PLZ: "14542",
      Stadt: "Werder (Havel)",
    },
    2834: {
      LadenId: 2834,
      Telefon: "+4940999996690",
      Strasse: "Harkortstraße 81e",
      PLZ: "22765",
      Stadt: "Hamburg",
    },
    2835: {
      LadenId: 2835,
      Telefon: "+4993822272360",
      Strasse: "Spielsee 1",
      PLZ: "97447",
      Stadt: "Gerolzhofen",
    },
    2836: {
      LadenId: 2836,
      Telefon: "+4995429439020",
      Strasse: "Bamberger Straße 20",
      PLZ: "96110",
      Stadt: "Scheßlitz",
    },
    2838: {
      LadenId: 2838,
      Telefon: "+4970819509010",
      Strasse: "Häberlenstraße 10",
      PLZ: "75323",
      Stadt: "Bad Wildbad",
    },
    2842: {
      LadenId: 2842,
      Telefon: "+4969254742130",
      Strasse: "Tituscorso 11",
      PLZ: "60439",
      Stadt: "Frankfurt am Main",
    },
    2843: {
      LadenId: 2843,
      Telefon: "+4971140099980",
      Strasse: "Wäldenbronner Straße 46",
      PLZ: "73732",
      Stadt: "Esslingen am Neckar",
    },
    2846: {
      LadenId: 2846,
      Telefon: "+4936828590290",
      Strasse: "Talstraße 50",
      PLZ: "98544",
      Stadt: "Zella-Mehlis",
    },
    2847: {
      LadenId: 2847,
      Telefon: "+4974132075850",
      Strasse: "Saline 5",
      PLZ: "78628",
      Stadt: "Rottweil",
    },
    2848: {
      LadenId: 2848,
      Telefon: "+4935765469990",
      Strasse: "Berliner Straße 106",
      PLZ: "02943",
      Stadt: "Weißwasser/Oberlausitz",
    },
    2850: {
      LadenId: 2850,
      Telefon: "+4973289298030",
      Strasse: "Aalenerstraße 25",
      PLZ: "89551",
      Stadt: "Königsbronn",
    },
    2851: {
      LadenId: 2851,
      Telefon: "+4933294359090",
      Strasse: "Wannseestraße 44",
      PLZ: "14532",
      Stadt: "Stahnsdorf",
    },
    2852: {
      LadenId: 2852,
      Telefon: "+4995617459310",
      Strasse: "Bamberger Straße 32b",
      PLZ: "96450",
      Stadt: "Coburg",
    },
    2854: {
      LadenId: 2854,
      Telefon: "+4935128708770",
      Strasse: "Hüblerstraße 11",
      PLZ: "01309",
      Stadt: "Dresden",
    },
    2855: {
      LadenId: 2855,
      Telefon: "+4975319459900",
      Strasse: "Zähringerplatz 9",
      PLZ: "78464",
      Stadt: "Konstanz",
    },
    2857: {
      LadenId: 2857,
      Telefon: "+4968989032330",
      Strasse: "Senftenberger Platz 5-7",
      PLZ: "66346",
      Stadt: "Püttlingen",
    },
    2858: {
      LadenId: 2858,
      Telefon: "+4976248089020",
      Strasse: "Markgrafenstraße 1",
      PLZ: "79639",
      Stadt: "Grenzach-Wyhlen",
    },
    2859: {
      LadenId: 2859,
      Telefon: "+4959719761600",
      Strasse: "Bahnhofstraße 1",
      PLZ: "48431",
      Stadt: "Rheine",
    },
    2860: {
      LadenId: 2860,
      Telefon: "+4930398216010",
      Strasse: "Mariendorfer Damm 298",
      PLZ: "12107",
      Stadt: "Berlin",
    },
    2861: {
      LadenId: 2861,
      Telefon: "+4953120978870",
      Strasse: "Platz am Ritterbrunnen 1",
      PLZ: "38100",
      Stadt: "Braunschweig",
    },
    2863: {
      LadenId: 2863,
      Telefon: "+4985639009000",
      Strasse: "Emil-Schwate-Straße 22",
      PLZ: "84364",
      Stadt: "Bad Birnbach",
    },
    2864: {
      LadenId: 2864,
      Telefon: "+4928529589960",
      Strasse: "Raiffeisenstraße 3",
      PLZ: "46499",
      Stadt: "Hamminkeln",
    },
    2867: {
      LadenId: 2867,
      Telefon: "+4943131040760",
      Strasse: "Falckensteiner Straße 40a",
      PLZ: "24159",
      Stadt: "Kiel",
    },
    2869: {
      LadenId: 2869,
      Telefon: "+4943618009060",
      Strasse: "Göhler Straße 5",
      PLZ: "23758",
      Stadt: "Oldenburg in Holstein",
    },
    2870: {
      LadenId: 2870,
      Telefon: "+4970222082080",
      Strasse: "Eichendorffstraße 30",
      PLZ: "72622",
      Stadt: "Nürtingen",
    },
    2871: {
      LadenId: 2871,
      Telefon: "+4933158569540",
      Strasse: "Potsdamer Straße 177",
      PLZ: "14469",
      Stadt: "Potsdam",
    },
    2872: {
      LadenId: 2872,
      Telefon: "+4965199790380",
      Strasse: "Domfreihof 1",
      PLZ: "54290",
      Stadt: "Trier",
    },
    2873: {
      LadenId: 2873,
      Telefon: "+4983428949030",
      Strasse: "Brückenstraße 11",
      PLZ: "87616",
      Stadt: "Marktoberdorf",
    },
    2874: {
      LadenId: 2874,
      Telefon: "+4978112559280",
      Strasse: "Gustav-Ree-Anlage 2",
      PLZ: "77652",
      Stadt: "Offenburg",
    },
    2876: {
      LadenId: 2876,
      Telefon: "+4980414390490",
      Strasse: "Moser Säge 5",
      PLZ: "83674",
      Stadt: "Gaißach",
    },
    2877: {
      LadenId: 2877,
      Telefon: "+4930403639580",
      Strasse: "Chausseestraße 40",
      PLZ: "10115",
      Stadt: "Berlin",
    },
    2879: {
      LadenId: 2879,
      Telefon: "+4928349458980",
      Strasse: "Ostwall 33",
      PLZ: "47638",
      Stadt: "Straelen",
    },
    2881: {
      LadenId: 2881,
      Telefon: "+4961819910770",
      Strasse: "Römerstraße 5",
      PLZ: "63486",
      Stadt: "Bruchköbel",
    },
    2882: {
      LadenId: 2882,
      Telefon: "+4942098859980",
      Strasse: "Blumenthaler Straße 5",
      PLZ: "28790",
      Stadt: "Schwanewede",
    },
    2883: {
      LadenId: 2883,
      Telefon: "+4935022979000",
      Strasse: "Basteistraße 31",
      PLZ: "01814",
      Stadt: "Bad Schandau",
    },
    2884: {
      LadenId: 2884,
      Telefon: "+4971616270080",
      Strasse: "Apostelhof 10",
      PLZ: "73033",
      Stadt: "Göppingen",
    },
    2888: {
      LadenId: 2888,
      Telefon: "+4951133099650",
      Strasse: "Weetzener Landstraße 102 A",
      PLZ: "30966",
      Stadt: "Hemmingen",
    },
    2889: {
      LadenId: 2889,
      Telefon: "+4976549199030",
      Strasse: "Studerstraße 18",
      PLZ: "79843",
      Stadt: "Löffingen",
    },
    2893: {
      LadenId: 2893,
      Telefon: "+4930403646730",
      Strasse: "Wilhelmsruher Damm 140",
      PLZ: "13439",
      Stadt: "Berlin",
    },
    2894: {
      LadenId: 2894,
      Telefon: "+4940999966240",
      Strasse: "Sülldorfer Kirchenweg 2b",
      PLZ: "22587",
      Stadt: "Hamburg",
    },
    2896: {
      LadenId: 2896,
      Telefon: "+4986777679250",
      Strasse: "Robert-Koch-Straße 15",
      PLZ: "84489",
      Stadt: "Burghausen",
    },
    2899: {
      LadenId: 2899,
      Telefon: "+4993526069180",
      Strasse: "Alfred-Stumpf-Straße 4",
      PLZ: "97816",
      Stadt: "Lohr a. Main",
    },
    2901: {
      LadenId: 2901,
      Telefon: "+4936438788890",
      Strasse: "Marcel-Paul-Straße 50 b",
      PLZ: "99427",
      Stadt: "Weimar",
    },
    2903: {
      LadenId: 2903,
      Telefon: "+4977311692780",
      Strasse: "Bahnhofstraße 29",
      PLZ: "78224",
      Stadt: "Singen (Hohentwiel)",
    },
    2905: {
      LadenId: 2905,
      Telefon: "+4971313822790",
      Strasse: "Mühlweg 18",
      PLZ: "74199",
      Stadt: "Untergruppenbach",
    },
    2907: {
      LadenId: 2907,
      Telefon: "+4939214599650",
      Strasse: "Fruchtstraße 1a",
      PLZ: "39288",
      Stadt: "Burg",
    },
    2908: {
      LadenId: 2908,
      Telefon: "+4951319779030",
      Strasse: "Havelser Straße 2-10",
      PLZ: "30823",
      Stadt: "Garbsen",
    },
    2909: {
      LadenId: 2909,
      Telefon: "+4928618130760",
      Strasse: "Wilbecke 19",
      PLZ: "46325",
      Stadt: "Borken",
    },
    2910: {
      LadenId: 2910,
      Telefon: "+4935867669360",
      Strasse: "Unterer Kirchweg 3",
      PLZ: "02730",
      Stadt: "Ebersbach-Neugersdorf",
    },
    2911: {
      LadenId: 2911,
      Telefon: "+4968056299660",
      Strasse: "Brachalmeth 2",
      PLZ: "66271",
      Stadt: "Kleinblittersdorf",
    },
    2912: {
      LadenId: 2912,
      Telefon: "+4949318204350",
      Strasse: "Gewerbestraße 13",
      PLZ: "26506",
      Stadt: "Norden",
    },
    2913: {
      LadenId: 2913,
      Telefon: "+4935601988990",
      Strasse: "Cottbuser Straße 3 B",
      PLZ: "03185",
      Stadt: "Peitz",
    },
    2917: {
      LadenId: 2917,
      Telefon: "+4948329859070",
      Strasse: "Altentreptower Straße 2a",
      PLZ: "25704",
      Stadt: "Meldorf",
    },
    2918: {
      LadenId: 2918,
      Telefon: "+4993815829060",
      Strasse: "Im Seelein 26",
      PLZ: "97332",
      Stadt: "Volkach",
    },
    2920: {
      LadenId: 2920,
      Telefon: "+4942114629060",
      Strasse: "AG-Weser-Straße 3",
      PLZ: "28237",
      Stadt: "Bremen",
    },
    2921: {
      LadenId: 2921,
      Telefon: "+4972299999020",
      Strasse: "Beinheimer Straße 1",
      PLZ: "76437",
      Stadt: "Rastatt",
    },
    2922: {
      LadenId: 2922,
      Telefon: "+4938558979350",
      Strasse: "Marienplatz 5-6",
      PLZ: "19053",
      Stadt: "Schwerin",
    },
    2924: {
      LadenId: 2924,
      Telefon: "+4953217799990",
      Strasse: "Marienburger Straße 61",
      PLZ: "38642",
      Stadt: "Goslar",
    },
    2926: {
      LadenId: 2926,
      Telefon: "+4990909639770",
      Strasse: "Neuburger Straße 34a",
      PLZ: "86641",
      Stadt: "Rain",
    },
    2927: {
      LadenId: 2927,
      Telefon: "+4946219859130",
      Strasse: "Mühlenberg 18",
      PLZ: "24857",
      Stadt: "Fahrdorf",
    },
    2930: {
      LadenId: 2930,
      Telefon: "+4989954572210",
      Strasse: "Neuhauser Straße 5",
      PLZ: "80331",
      Stadt: "München",
    },
    2931: {
      LadenId: 2931,
      Telefon: "+4976429159160",
      Strasse: "Im Kleinfeldele 2",
      PLZ: "79359",
      Stadt: "Riegel am Kaiserstuhl",
    },
    2932: {
      LadenId: 2932,
      Telefon: "+4963819999010",
      Strasse: "Industriestraße 29a",
      PLZ: "66869",
      Stadt: "Kusel",
    },
    2933: {
      LadenId: 2933,
      Telefon: "+4963449429040",
      Strasse: "Im Breiten Pfuhl 6",
      PLZ: "67365",
      Stadt: "Schwegenheim",
    },
    2934: {
      LadenId: 2934,
      Telefon: "+4935127186690",
      Strasse: "Löbtauer Straße 34",
      PLZ: "01159",
      Stadt: "Dresden",
    },
    2935: {
      LadenId: 2935,
      Telefon: "+4930235991510",
      Strasse: "Pücklerstraße 34",
      PLZ: "10997",
      Stadt: "Berlin",
    },
    2936: {
      LadenId: 2936,
      Telefon: "+4976449209050",
      Strasse: "Feuerwehrweg 4",
      PLZ: "79341",
      Stadt: "Kenzingen",
    },
    2937: {
      LadenId: 2937,
      Telefon: "+4930814598360",
      Strasse: "Gorkistraße 11/21",
      PLZ: "13507",
      Stadt: "Berlin",
    },
    2938: {
      LadenId: 2938,
      Telefon: "+4964213801760",
      Strasse: "Am Kaufmarkt 1",
      PLZ: "35041",
      Stadt: "Marburg",
    },
    2940: {
      LadenId: 2940,
      Telefon: "+4976219513640",
      Strasse: "Bahnhofsplatz 10+11",
      PLZ: "79539",
      Stadt: "Lörrach",
    },
    2943: {
      LadenId: 2943,
      Telefon: "+4991879389120",
      Strasse: "Pfaffentalstraße 31",
      PLZ: "90518",
      Stadt: "Altdorf bei Nürnberg",
    },
    2944: {
      LadenId: 2944,
      Telefon: "+4930439701780",
      Strasse: "Quickborner Straße 72",
      PLZ: "13439",
      Stadt: "Berlin",
    },
    2945: {
      LadenId: 2945,
      Telefon: "+4967259189970",
      Strasse: "Rheinstraße 7",
      PLZ: "55435",
      Stadt: "Gau-Algesheim",
    },
    2947: {
      LadenId: 2947,
      Telefon: "+4971564260750",
      Strasse: "Heimerdinger Straße 60/1",
      PLZ: "71254",
      Stadt: "Ditzingen",
    },
    2948: {
      LadenId: 2948,
      Telefon: "+4942133709590",
      Strasse: "Sögestraße 16",
      PLZ: "28195",
      Stadt: "Bremen",
    },
    2949: {
      LadenId: 2949,
      Telefon: "+4936038389590",
      Strasse: "Tonnaer Straße 36",
      PLZ: "99947",
      Stadt: "Bad Langensalza",
    },
    2952: {
      LadenId: 2952,
      Telefon: "+4970444009850",
      Strasse: "Im Gödelmann 2",
      PLZ: "71297",
      Stadt: "Mönsheim",
    },
    2954: {
      LadenId: 2954,
      Telefon: "+4928253079450",
      Strasse: "Meursfeldstraße 35",
      PLZ: "47589",
      Stadt: "Uedem",
    },
    2957: {
      LadenId: 2957,
      Telefon: "+4972513264050",
      Strasse: "Prinz-Wilhelm-Straße 8",
      PLZ: "76646",
      Stadt: "Bruchsal",
    },
    2958: {
      LadenId: 2958,
      Telefon: "+4973259679990",
      Strasse: "Brenzer Straße 33",
      PLZ: "89567",
      Stadt: "Sontheim an der Brenz",
    },
    2959: {
      LadenId: 2959,
      Telefon: "+4986777679270",
      Strasse: "Lindach 10",
      PLZ: "84489",
      Stadt: "Burghausen",
    },
    2960: {
      LadenId: 2960,
      Telefon: "+4968138753530",
      Strasse: "Jakobstraße 38",
      PLZ: "66115",
      Stadt: "Saarbrücken",
    },
    2961: {
      LadenId: 2961,
      Telefon: "+4989588043200",
      Strasse: "Maria-Nindl-Platz 2",
      PLZ: "81927",
      Stadt: "München",
    },
    2962: {
      LadenId: 2962,
      Telefon: "+4989277819050",
      Strasse: "Tübinger Straße 3e",
      PLZ: "80686",
      Stadt: "München",
    },
    2963: {
      LadenId: 2963,
      Telefon: "+4971233741280",
      Strasse: "Stuttgarter Straße 54",
      PLZ: "72555",
      Stadt: "Metzingen",
    },
    2965: {
      LadenId: 2965,
      Telefon: "+4968385020920",
      Strasse: "Vorstadtstraße 21",
      PLZ: "66793",
      Stadt: "Saarwellingen",
    },
    2966: {
      LadenId: 2966,
      Telefon: "+4991147717830",
      Strasse: "Schwabacher Straße 5",
      PLZ: "90762",
      Stadt: "Fürth",
    },
    2967: {
      LadenId: 2967,
      Telefon: "+4930232568470",
      Strasse: "Tempelhofer Damm 227",
      PLZ: "12099",
      Stadt: "Berlin",
    },
    2969: {
      LadenId: 2969,
      Telefon: "+4991549109790",
      Strasse: "Happurger Straße 17",
      PLZ: "91224",
      Stadt: "Pommelsbrunn",
    },
    2970: {
      LadenId: 2970,
      Telefon: "+4989248892460",
      Strasse: "Eugen-Sänger-Ring 4",
      PLZ: "85649",
      Stadt: "Brunnthal",
    },
    2971: {
      LadenId: 2971,
      Telefon: "+4970322899320",
      Strasse: "Römerweg 57",
      PLZ: "71083",
      Stadt: "Herrenberg",
    },
    2972: {
      LadenId: 2972,
      Telefon: "+4961345844100",
      Strasse: "Kostheimer Landstraße 40c",
      PLZ: "55246",
      Stadt: "Wiesbaden",
    },
    2973: {
      LadenId: 2973,
      Telefon: "+4964019699380",
      Strasse: "Göbelnröder Straße 3",
      PLZ: "35305",
      Stadt: "Grünberg",
    },
    2974: {
      LadenId: 2974,
      Telefon: "+4962140547850",
      Strasse: "Im Zollhof 4",
      PLZ: "67061",
      Stadt: "Ludwigshafen am Rhein",
    },
    2977: {
      LadenId: 2977,
      Telefon: "+4935284019980",
      Strasse: "An der Ziegelei 2",
      PLZ: "01454",
      Stadt: "Radeberg",
    },
    2979: {
      LadenId: 2979,
      Telefon: "+4989248853790",
      Strasse: "Naupliastraße 108",
      PLZ: "81545",
      Stadt: "München",
    },
    2980: {
      LadenId: 2980,
      Telefon: "+4981363089610",
      Strasse: "Gewerbestraße 4",
      PLZ: "85229",
      Stadt: "Markt Indersdorf",
    },
    2981: {
      LadenId: 2981,
      Telefon: "+4980363019540",
      Strasse: "Habichtstraße 5",
      PLZ: "83071",
      Stadt: "Stephanskirchen",
    },
    2983: {
      LadenId: 2983,
      Telefon: "+4936588268400",
      Strasse: "Heinrichstraße 30",
      PLZ: "07545",
      Stadt: "Gera",
    },
    2984: {
      LadenId: 2984,
      Telefon: "+4945158347440",
      Strasse: "Kantstraße 15-17",
      PLZ: "23566",
      Stadt: "Lübeck",
    },
    2991: {
      LadenId: 2991,
      Telefon: "+4976188793880",
      Strasse: "Bismarckallee 10",
      PLZ: "79098",
      Stadt: "Freiburg im Breisgau",
    },
    2992: {
      LadenId: 2992,
      Telefon: "+4933716954940",
      Strasse: "Schützenstraße 46",
      PLZ: "14943",
      Stadt: "Luckenwalde",
    },
    2993: {
      LadenId: 2993,
      Telefon: "+4934338599980",
      Strasse: "Leipziger Straße 10",
      PLZ: "04552",
      Stadt: "Borna",
    },
    2994: {
      LadenId: 2994,
      Telefon: "+4977619276050",
      Strasse: "Lohgerbe 1",
      PLZ: "79713",
      Stadt: "Bad Säckingen",
    },
    2996: {
      LadenId: 2996,
      Telefon: "+4945158349790",
      Strasse: "Schwartauer Landstraße 4",
      PLZ: "23554",
      Stadt: "Lübeck",
    },
    2997: {
      LadenId: 2997,
      Telefon: "+4944863049950",
      Strasse: "Friedrichsfehner Straße 1b",
      PLZ: "26188",
      Stadt: "Edewecht",
    },
    2999: {
      LadenId: 2999,
      Telefon: "+4944211814290",
      Strasse: "Hundertwasserallee 5",
      PLZ: "26389",
      Stadt: "Wilhelmshaven",
    },
    3001: {
      LadenId: 3001,
      Telefon: "+4949515529960",
      Strasse: "Neue Feldstraße 9-11",
      PLZ: "26826",
      Stadt: "Weener",
    },
    3002: {
      LadenId: 3002,
      Telefon: "+4977416983540",
      Strasse: "Bertold-Schmidt-Platz 4",
      PLZ: "79787",
      Stadt: "Lauchringen",
    },
    3004: {
      LadenId: 3004,
      Telefon: "+4991147713330",
      Strasse: "Am Plärrer 19",
      PLZ: "90443",
      Stadt: "Nürnberg",
    },
    3005: {
      LadenId: 3005,
      Telefon: "+4968984968990",
      Strasse: "Im Betzen 2",
      PLZ: "66333",
      Stadt: "Völklingen",
    },
    3008: {
      LadenId: 3008,
      Telefon: "+4951154684190",
      Strasse: "Georgstraße 21",
      PLZ: "30159",
      Stadt: "Hannover",
    },
    3009: {
      LadenId: 3009,
      Telefon: "+4979416978920",
      Strasse: "Dieselstraße 6",
      PLZ: "74629",
      Stadt: "Pfedelbach",
    },
    3011: {
      LadenId: 3011,
      Telefon: "+4980516970920",
      Strasse: "Chiemseestraße 89",
      PLZ: "83233",
      Stadt: "Bernau am Chiemsee",
    },
    3012: {
      LadenId: 3012,
      Telefon: "+4930814571450",
      Strasse: "Heidestraße 40",
      PLZ: "10557",
      Stadt: "Berlin",
    },
    3013: {
      LadenId: 3013,
      Telefon: "+4992218870960",
      Strasse: "Am Kreuzstein 19",
      PLZ: "95326",
      Stadt: "Kulmbach",
    },
    3016: {
      LadenId: 3016,
      Telefon: "+4961194917080",
      Strasse: "Flachstraße 5a",
      PLZ: "65199",
      Stadt: "Wiesbaden",
    },
    3017: {
      LadenId: 3017,
      Telefon: "+4975522209970",
      Strasse: "Paul-Heilig-Straße 10",
      PLZ: "88630",
      Stadt: "Pfullendorf",
    },
    3018: {
      LadenId: 3018,
      Telefon: "+4930233272600",
      Strasse: "Kurfürstendamm 227",
      PLZ: "10719",
      Stadt: "Berlin",
    },
    3019: {
      LadenId: 3019,
      Telefon: "+4947313249690",
      Strasse: "Atenser Allee 108b",
      PLZ: "26954",
      Stadt: "Nordenham",
    },
    3021: {
      LadenId: 3021,
      Telefon: "+4961965825790",
      Strasse: "Ginnheimer Straße 10-14",
      PLZ: "65760",
      Stadt: "Eschborn",
    },
    3022: {
      LadenId: 3022,
      Telefon: "+4935127186640",
      Strasse: "Kesselsdorfer Straße 158",
      PLZ: "01169",
      Stadt: "Dresden",
    },
    3023: {
      LadenId: 3023,
      Telefon: "+4964120103120",
      Strasse: "Industriestraße 2",
      PLZ: "35582",
      Stadt: "Wetzlar",
    },
    3024: {
      LadenId: 3024,
      Telefon: "+4930209668380",
      Strasse: "Kurfürstenstraße 43",
      PLZ: "10785",
      Stadt: "Berlin",
    },
    3026: {
      LadenId: 3026,
      Telefon: "+4965916658930",
      Strasse: "Sarresdorfer Straße 59b",
      PLZ: "54568",
      Stadt: "Gerolstein",
    },
    3029: {
      LadenId: 3029,
      Telefon: "+4940285304470",
      Strasse: "Lämmersieth 23",
      PLZ: "22305",
      Stadt: "Hamburg",
    },
    3032: {
      LadenId: 3032,
      Telefon: "+4930235923440",
      Strasse: "Großbeerenstraße 64",
      PLZ: "12107",
      Stadt: "Berlin",
    },
    3034: {
      LadenId: 3034,
      Telefon: "+4991147774720",
      Strasse: "Königstraße 41",
      PLZ: "90402",
      Stadt: "Nürnberg",
    },
    3035: {
      LadenId: 3035,
      Telefon: "+4939125191170",
      Strasse: "Ebendorfer Straße 24",
      PLZ: "39108",
      Stadt: "Magdeburg",
    },
    3038: {
      LadenId: 3038,
      Telefon: "+49754298743800",
      Strasse: "Sömmeringstraße 1",
      PLZ: "88074",
      Stadt: "Meckenbeuren",
    },
    3040: {
      LadenId: 3040,
      Telefon: "+4938438599020",
      Strasse: "Lindenallee 17",
      PLZ: "18273",
      Stadt: "Güstrow",
    },
    3041: {
      LadenId: 3041,
      Telefon: "+4961195012210",
      Strasse: "Berliner Straße 279",
      PLZ: "65205",
      Stadt: "Wiesbaden",
    },
    3049: {
      LadenId: 3049,
      Telefon: "+4941218301370",
      Strasse: "Hans-Böckler-Straße 1",
      PLZ: "25337",
      Stadt: "Elmshorn",
    },
    3056: {
      LadenId: 3056,
      Telefon: "+4934321569990",
      Strasse: "Wilhelm-Ostwald-Straße 8a",
      PLZ: "04703",
      Stadt: "Leisnig",
    },
    3058: {
      LadenId: 3058,
      Telefon: "+4947190083320",
      Strasse: "Schiffdorfer Chaussee 18",
      PLZ: "27574",
      Stadt: "Bremerhaven",
    },
    3059: {
      LadenId: 3059,
      Telefon: "+4976229017190",
      Strasse: "In der Teichmatt 12a",
      PLZ: "79689",
      Stadt: "Maulburg",
    },
    3061: {
      LadenId: 3061,
      Telefon: "+4977116280420",
      Strasse: "Lindenpark 4",
      PLZ: "78183",
      Stadt: "Hüfingen",
    },
    3066: {
      LadenId: 3066,
      Telefon: "+4992153030560",
      Strasse: "Otto-Hahn-Straße 1b",
      PLZ: "95447",
      Stadt: "Bayreuth",
    },
    3071: {
      LadenId: 3071,
      Telefon: "+4985419499810",
      Strasse: "Aidenbacher Straße 74",
      PLZ: "94474",
      Stadt: "Vilshofen an der Donau",
    },
    3080: {
      LadenId: 3080,
      Telefon: "+4974466399970",
      Strasse: "Freudenstädter Straße 17",
      PLZ: "72290",
      Stadt: "Loßburg",
    },
    3083: {
      LadenId: 3083,
      Telefon: "+4976121634890",
      Strasse: "Sankt Georgener Straße 2",
      PLZ: "79111",
      Stadt: "Freiburg im Breisgau",
    },
    3091: {
      LadenId: 3091,
      Telefon: "+4949218209960",
      Strasse: "Ubierstraße 2a",
      PLZ: "26723",
      Stadt: "Emden",
    },
    3102: {
      LadenId: 3102,
      Telefon: "+4971414930390",
      Strasse: "Heinkelstraße 1",
      PLZ: "71634",
      Stadt: "Ludwigsburg",
    },
  };
